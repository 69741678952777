import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { SharedListingDetailsApiService } from '@shared-listing-details/store/services/shared-listing-details-api.service';
import { AuthorizedOnlyGuard } from './authorized-only.guard';

@Injectable({
    providedIn: 'root'
})
export class ListingAuthorizedGuard extends AuthorizedOnlyGuard {
    constructor(
        routeService: RouteService,
        userStoreService: UserStoreService,
        private readonly sharedListingDetailsApiService: SharedListingDetailsApiService
    ) {
        super(routeService, userStoreService);
    }

    protected onUnauthorizedUser(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> {
        const listingCode: string = route.params.id;

        return this.sharedListingDetailsApiService.getPublicListingCode(listingCode)
            .pipe(
                map((code) => {
                    this.routeService.navigate(RpcRoute.SharedListingWithCode, null, null, code).catch(() => { });
                    return false;
                })
            );
    }
}
