import { FormGroup } from '@angular/forms';

import { ValidatorsMapItem } from '@core-controls/models/validators-map-item';

import { RpcSelectOption } from './rpc-select-option';

export class RpcSelectControltOptions {
    formGroup: FormGroup;
    controlName: string;
    options: RpcSelectOption[];
    selectedValue?: any;
    validatorsMap?: ValidatorsMapItem[];

    constructor(
        formGroup: FormGroup,
        controlName: string,
        options: RpcSelectOption[],
        selectedValue: any = null,
        validatorsMap: ValidatorsMapItem[] = null
    ) {
        this.formGroup = formGroup;
        this.controlName = controlName;
        this.options = options;
        this.selectedValue = selectedValue;
        this.validatorsMap = validatorsMap;
    }
}