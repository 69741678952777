import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CommonMessage } from '@core-models/messages/common-message';
import { ServerMessagesMapping } from '@core-models/messages/server-messages-mapping';

@Injectable({ providedIn: 'root' })
export class ServerMessageService {

    private serverMessagesMapping: ServerMessagesMapping = {};

    constructor(
        private readonly translateService: TranslateService
    ) { }

    public loadServerMessagesMapping(messagesMapping: ServerMessagesMapping): void {
        this.serverMessagesMapping = { ...this.serverMessagesMapping, ...messagesMapping };
    }

    public getMessage(serverMessageMappingkey: string): CommonMessage {
        let title = '';
        let message = '';

        const serverMessageMapping = this.serverMessagesMapping[serverMessageMappingkey];
        if (serverMessageMapping != null) {
            if (serverMessageMapping.messageTranslationPath !== '') {
                message = this.translateService.instant(serverMessageMapping.messageTranslationPath) as string;
            }
            if (serverMessageMapping.title !== '') {
                title = this.translateService.instant(serverMessageMapping.title) as string;
            }

            return {
                code: serverMessageMapping.code,
                message: message,
                title: title
            };
        }

        return null;
    }
}