<rpc-icon-button *ngIf="!isMobile"
    class="rpc-activity-button" [ngClass]="{
        'liked': calculatedActivities?.isLiked,
        'disliked': calculatedActivities?.isDisliked,
        'shown': calculatedActivities?.isShown && !calculatedActivities?.isLiked && !calculatedActivities?.isDisliked
        }"
    [iconName]="iconName" [actionClass]="'active'"
    [actionTrigger]="calculatedActivities?.isLiked || calculatedActivities?.isDisliked || calculatedActivities?.isShown"
    [badgeIcon]="badgeIcon" [badgeIconName]="'eye'" [enableCustomBackground]="true"
    rpcTooltip [customClass]="'tooltip-list--inline-padding-empty'" [component]="tooltipComponent"
    [componentScopeData]="tooltipData" (tooltipInitialized)="tooltipInitialized($event)"
    (clicked)="onClick()">
</rpc-icon-button>
<rpc-icon-button *ngIf="isMobile"
    class="rpc-activity-button" [ngClass]="{
        'liked': calculatedActivities?.isLiked,
        'disliked': calculatedActivities?.isDisliked,
        'shown': calculatedActivities?.isShown && !calculatedActivities?.isLiked && !calculatedActivities?.isDisliked
        }" [iconName]="iconName" [actionClass]="'active'"
    [actionTrigger]="calculatedActivities?.isLiked || calculatedActivities?.isDisliked || calculatedActivities?.isShown"
    [badgeIcon]="badgeIcon" [badgeIconName]="'eye'" [enableCustomBackground]="true"
    rpcTooltip [trigger]="'custom'" [customClass]="'tooltip-list--inline-padding-empty'"
    [component]="tooltipComponent" [componentScopeData]="tooltipData" (tooltipInitialized)="tooltipInitialized($event)"
    rpcClickHandler (singleClicked)="onClick()" (doubleClicked)="onDoubleClick()">
</rpc-icon-button>