export const locale = {
    lang: 'en',
    data: {
        MULTI_ACTIVITY_COMPACT_CONTROLS: {
            TITLES: {
                APPLY_TO: 'Apply action to ...',
                CURRENT_LISTING: 'Current listing',
                ALL_SELECTED_LISTINGS: 'All selected listings'
            },
            BUTTONS: {
                CLOSE: 'Close'
            }
        }
    }
};