import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';

export class AppointmentUpdateActionModel {
    public readonly listingId: string;
    public readonly listingAddress: string;
    public readonly listingAppointment: ListingAppointment;
    public readonly oldListingAppointment: ListingAppointment;

    constructor(listingId: string, listingAddress: string, listingAppointment: ListingAppointment, oldListingAppointment: ListingAppointment) {
        this.listingId = listingId;
        this.listingAddress = listingAddress;
        this.listingAppointment = listingAppointment;
        this.oldListingAppointment = oldListingAppointment;
    }
}