export const locale = {
    lang: 'en',
    data: {
        MEDIA_SLIDER: {
            TITLES: {
                PREVIOUS_PHOTO: 'Previous Photo',
                NEXT_PHOTO: 'Next Photo',
                OPEN_VIDEO: 'Open Video',
                OPEN_TOUR: 'Open 3D Tour',
                IN_NEW_TAB: 'in New Tab',
                VIDEO: 'Video',
                TOUR: '3D Tour',
                FLOORPLAN: 'Floorplan',
                BACK: 'Back'
            }
        }
    }
};