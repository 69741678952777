import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { AgentBoxComponent } from '@agents/components/agent-box/agent-box.component';
import { AgentListComponent } from '@agents/components/agent-list/agent-list.component';
import { AgentShortInfoComponent } from '@agents/components/agent-short-info/agent-short-info.component';
import { locale as english } from '@agents/i18n/en';
import { RpcControlsModule } from '@core-controls/controls.module';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { UsersModule } from '@users/users.module';
import { ChangeAgentDialogComponent } from './components/change-agent-dialog/change-agent-dialog.component';
import { PortfolioAgentInfoComponent } from './components/portfolio-agent-info/portfolio-agent-info.component';
import { PortfolioAgentsListComponent } from './components/portfolio-agents-list/portfolio-agents-list.component';
import { AgentsEffects } from './store/effects/agents.effects';
import { agentsReducer } from './store/reducers/agents.reducer';

@NgModule({
    declarations: [
        AgentShortInfoComponent,
        AgentListComponent,
        AgentBoxComponent,
        PortfolioAgentsListComponent,
        PortfolioAgentInfoComponent,
        ChangeAgentDialogComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        MatRippleModule,
        TranslateModule,
        MatDividerModule,
        MatDialogModule,

        // Application Modules
        RpcControlsModule,
        RpcDirectivesModule,
        RpcIconModule,
        UsersModule,

        // Module Configuration
        StoreModule.forFeature('agents', agentsReducer),
        EffectsModule.forFeature([AgentsEffects])
    ],
    exports: [
        AgentShortInfoComponent,
        AgentListComponent,
        AgentBoxComponent,
        PortfolioAgentsListComponent,
        PortfolioAgentInfoComponent,
        ChangeAgentDialogComponent
    ]
})
export class AgentsModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}