import { FolderUrlName } from '@folders/enums/folder-url-name';
import { FolderDetails } from '@folders/models/folder-details';

export const SYSTEM_FOLDERS_INFO = {
    AddedToPortfolio: { id: -1, name: 'ADDED_TO_PORTFOLIO' },
    ExternalListings: { id: -2, name: 'EXTERNAL_LISTINGS' },
    NotAssignedToFolder: { id: -3, name: 'NOT_ASSIGNED_TO_FOLDER' },
    RemovedListings: { id: -4, name: 'REMOVED_LISTINGS' },
};

export const SYSTEM_FOLDERS: FolderDetails[] = Object.values(SYSTEM_FOLDERS_INFO).map(({ id, name }): FolderDetails => {
    return {
        id: id,
        name: name,
        creatorInfo: null,
        createDateTime: null,
        isSystemFolder: true
    };
});

export const SYSTEM_FOLDERS_WITH_ACTIVITY_PANEL = [SYSTEM_FOLDERS_INFO.AddedToPortfolio.id, SYSTEM_FOLDERS_INFO.NotAssignedToFolder.id];

export const MAX_IMAGES_ON_FOLDER_COVER = 6;

export const INITIAL_DEFAULT_FOLDER_ID = 0;

export const SYSTEM_FOLDERS_URL_NAME_TO_ID_MAP = new Map<FolderUrlName, number>([
    [FolderUrlName.AddedToPortfolio, SYSTEM_FOLDERS_INFO.AddedToPortfolio.id],
    [FolderUrlName.ExternalListings, SYSTEM_FOLDERS_INFO.ExternalListings.id],
    [FolderUrlName.NotAssignedToFolder, SYSTEM_FOLDERS_INFO.NotAssignedToFolder.id],
    [FolderUrlName.RemovedListings, SYSTEM_FOLDERS_INFO.RemovedListings.id],
]);

export const SYSTEM_FOLDERS_ID_TO_URL_NAME_MAP = new Map<number, FolderUrlName>([
    [SYSTEM_FOLDERS_INFO.AddedToPortfolio.id, FolderUrlName.AddedToPortfolio],
    [SYSTEM_FOLDERS_INFO.ExternalListings.id, FolderUrlName.ExternalListings],
    [SYSTEM_FOLDERS_INFO.NotAssignedToFolder.id, FolderUrlName.NotAssignedToFolder],
    [SYSTEM_FOLDERS_INFO.RemovedListings.id, FolderUrlName.RemovedListings],
]);