import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { RpcDirectivesModule } from '@core-directives/directives.module';
import { RpcTooltipComponent } from './components/rpc-tooltip.component';
import { RpcTooltipDirective } from './directive/rpc-tooltip.directive';

@NgModule({
    declarations: [
        RpcTooltipComponent,
        RpcTooltipDirective
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        MatTooltipModule,

        // Application Modules
        RpcDirectivesModule
    ],
    exports: [
        RpcTooltipDirective
    ]
})
export class RpcTooltipModule { }