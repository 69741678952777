<div *ngIf="data.mobileType === 'android'" class="prompt prompt--android">
    <rpc-button class="prompt-main-btn" [appearanceType]="'flat'" [color]="'accent'" (clicked)="installPwa()"
        [title]="'PROMPT.TITLES.ANDROID'"></rpc-button>
    <rpc-icon-button class="prompt-btn-close" [title]="'PROMPT.TITLES.CLOSE'" (clicked)="close()" [iconName]="'cross'">
    </rpc-icon-button>
</div>
<div *ngIf="data.mobileType === 'ios'" class="prompt prompt--ios">
    <div class="prompt-text">
        {{ 'PROMPT.TITLES.IOS_1' | translate }}
        (<rpc-icon [iconName]="'share-ios'" class="prompt-text-icon"></rpc-icon>)
        {{ 'PROMPT.TITLES.IOS_2' | translate }}
        (<rpc-icon [iconName]="'plus-ios'" class="prompt-text-icon"></rpc-icon>)
        {{ 'PROMPT.TITLES.IOS_3' | translate }}
    </div>
    <rpc-icon-button class="prompt-btn-close" [title]="'PROMPT.TITLES.CLOSE'" (clicked)="close()" [iconName]="'cross'">
    </rpc-icon-button>
</div>