import { Listings } from '@listings/models/listing/listings';
import { ListingsMedia } from '@listings/models/listing/listings-media';

export class ListingsState {

    /**
     * Stores all loaded listings (including customer, market, etc.).
     */
    listings: Listings;
    media: ListingsMedia;

    /**
     * Stores selected listings.
     */
    selectedListingIds: string[];

    customerListingsLoaded: boolean;
    marketListingsLoaded: boolean;
    marketListingsLoading: boolean;
    mediaLoaded: boolean;
    isListingsLoading = false;
    isListingLoading = false;

    /**
     * Listing ids that should be removed when leaving listing details page.
     * Expected to be NM duplicates that got an activity (duplicates are visible while they are NM).
     */
    removalCandidateIds: string[];

    constructor() {
        this.listings = {};
        this.selectedListingIds = [];

        this.customerListingsLoaded = false;
        this.marketListingsLoaded = false;
        this.marketListingsLoading = false;
        this.mediaLoaded = false;
        this.removalCandidateIds = [];
    }
}