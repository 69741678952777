import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'commaSeparator' })
export class CommaSeparatorPipe implements PipeTransform {
    transform(value: string): string {
        if (value == null || typeof value !== 'string') {
            return '---';
        }

        const slicedString = value.split(',');
        if (slicedString.length > 0) {
            slicedString.forEach(substr => substr = substr.trim());
            return slicedString.join(', ');
        }

        return '---';
    }
}