export class DateConstants {
    public static Formats = {
        ShortDateSlash: 'MM/DD/YYYY',
        FullMonthDate: 'D MMMM YYYY',
        ShortDateMinus: 'YYYY-MM-DD',
        ShortTime: 'h:mm A',
        AngularPipeShortDateSlash: 'MM/dd/yyyy',
        AngularPipeShortTime: 'h:mm a',
        DayOfWeek: 'dddd'
    };
}