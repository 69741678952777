import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';

@Directive({ selector: '[wrapWords]' })
export class WrapWordsDirective implements AfterViewInit, OnDestroy {
    private readonly DOUBLE_ASTERISK_PATTERN: RegExp = /\*\*[^*]+\*\*/g;
    private readonly SINGLE_ASTERISK_PATTERN: RegExp = /\*/g;
    private readonly observer = new MutationObserver(() => this.decorateWords());
    private processing = false;

    constructor(private readonly container: ElementRef<HTMLElement>, private readonly renderer: Renderer2) { }

    public ngAfterViewInit(): void {
        this.decorateWords();
        this.registerListenerForDomChanges();
    }

    public ngOnDestroy(): void {
        this.observer.disconnect();
    }

    private registerListenerForDomChanges(): void {
        this.observer.observe(this.container.nativeElement, { attributes: false, childList: true, subtree: true });
    }

    private decorateWords(): void {
        if (this.processing) {
            return;
        }

        this.processing = true;

        const text = this.container.nativeElement.innerHTML ?? '';

        const wordsToReplace = text.match(this.DOUBLE_ASTERISK_PATTERN);

        if (wordsToReplace == null || wordsToReplace.length === 0) {
            this.processing = false;

            return;
        }

        const textWithMarkup = wordsToReplace.reduce(
            (result, wordWithAsterisks) => {
                const wordWithoutAsterisks = wordWithAsterisks.replace(this.SINGLE_ASTERISK_PATTERN, '');
                return result.replace(wordWithAsterisks, this.createSpanWrapperAsHtml(wordWithoutAsterisks));
            },
            text
        );

        this.container.nativeElement.innerHTML = textWithMarkup;
        this.processing = false;
    }

    private createSpanWrapperAsHtml(text: string): string {
        const spanElement = this.renderer.createElement('span') as HTMLSpanElement;
        spanElement.innerHTML = text;

        return spanElement.outerHTML;
    }
}
