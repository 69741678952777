import { SortDirection } from '@core-models/sort/sort-direction';
import { SortOptionBase } from '@core-models/sort/sort-option';
import { MyListingsFilterOptions } from '@my-listings/models/filter/my-listings-filter-options';
import { FoldersSettings } from '@settings/models/settings/folders-settings';
import { ViewModes } from '@view-base/models/view-modes.enum';

const folderIdKey: keyof FoldersSettings = 'folderId';

export const DEFAULT_FOLDER_SETTINGS: Omit<FoldersSettings, typeof folderIdKey> = {
    filterSettings: MyListingsFilterOptions.getDefaultFilter(),
    sortSettings: { direction: SortDirection.Desc, propertyName: 'statusDates.listedDate' } as SortOptionBase,
    viewMode: ViewModes.gallery,
    mapSizePercent: 25
};