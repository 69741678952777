<div class="mobile-side-wrap">
    <div *ngLet="{customerFullName: customerFullName$ | async, customerProfileImage: customerProfileImage$ | async} as customer"
        class="mobile-customer-info">
        <avatar-initials mat-icon-button class="mobile-customer-info-avatar" [userFullName]="customer.customerFullName"
            [userProfileImageUrl]="customer.customerProfileImage">
        </avatar-initials>
        <div class="mobile-customer-info-name">{{ customer.customerFullName }}</div>
    </div>
    <div class="mobile-nav">
        <rpc-button *ngLet="{listingsCount: listingsCount$ | async, unviewedListingsCount: unviewedListingsCount$ | async} as listingCounts"
            class="mobile-nav-item" [link]="portfolioRouterLink$ | async" [routerLinkActive]="'active'" [title]="'SIDE_MENU.TITLES.MY_LISTINGS'"
            [badgeCount]="listingCounts.listingsCount" [badgeCountAlert]="listingCounts.unviewedListingsCount" [alt]="listingCounts.unviewedListingsCount > 0
                ? ('TOOLBAR.TOOLTIPS.NEW_LISTINGS_IN_PORTFOLIO'  | translate : {count: listingCounts.unviewedListingsCount})
                : listingCounts.listingsCount > 0
                    ? ('TOOLBAR.TOOLTIPS.LISTINGS_IN_PORTFOLIO'  | translate : {count: listingCounts.listingsCount})
                    : 'TOOLBAR.TITLES.MY_LISTINGS'">
        </rpc-button>
        <rpc-button class="mobile-nav-item" *ngIf="canSearchForListings$ | async"
            [link]="RpcRoute.SearchListings" [routerLinkActive]="'active'" [title]="'SIDE_MENU.TITLES.SEARCH_LISTINGS'">
        </rpc-button>
        <rpc-button *ngLet="{unviewedAppointmentsCount: unviewedAppointmentsCount$ | async, appointmentsCount: appointmentsCount$ | async }as appointmentCounts"
            class="mobile-nav-item" [link]="RpcRoute.Appointments" [routerLinkActive]="'active'"
            [title]="'SIDE_MENU.TITLES.MY_APPOINTMENTS'" [badgeCount]="appointmentCounts.appointmentsCount"
            [badgeCountAlert]="appointmentCounts.unviewedAppointmentsCount"
            [alt]="appointmentCounts.unviewedAppointmentsCount > 0 
                ? ('TOOLBAR.TOOLTIPS.NEW_UPCOMING_APPOINTMENTS_IN_PORTFOLIO'  | translate : {count: (appointmentCounts.unviewedAppointmentsCount)})
                : appointmentCounts.appointmentsCount > 0
                    ? ('TOOLBAR.TOOLTIPS.APPOINTMENTS_IN_PORTFOLIO'  | translate : {count: appointmentCounts.appointmentsCount})
                    : 'TOOLBAR.TITLES.MY_APPOINTMENTS'">
        </rpc-button>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="mobile-nav-secondary">
        <rpc-button [title]="'SIDE_MENU.TITLES.PROFILE'" iconName="user" [routerLinkActive]="'active'"
            (clicked)="onMyProfileClicked()"></rpc-button>
        <rpc-button *ngIf="canSeeAdditionalProfiles$ | async"
            [title]="'TOOLBAR.TITLES.INVITE_USER'"
            iconName="invite-user"
            (clicked)="onInviteUser()"
            [disabled]="!(canAddAdditionalProfile$ | async)">
        </rpc-button>
        <rpc-button [title]="'SIDE_MENU.TITLES.SETTINGS'" iconName="cog" [routerLinkActive]="'active'"
            (clicked)="onSettingsClicked()"></rpc-button>
        <rpc-button *ngIf="hasMultipleCollaborationSpaces$ | async" [title]="'SIDE_MENU.TITLES.CHANGE_AGENT'" iconName="agent-switch" [routerLinkActive]="'active'"
            (clicked)="onChangeAgentClicked()"></rpc-button>
        <rpc-button [title]="'SIDE_MENU.TITLES.KNOWLEDGE_BASE'" [iconName]="'question'" (clicked)="onKnowledgeBaseClicked()"></rpc-button>
        <rpc-button [title]="'SIDE_MENU.TITLES.LOGOUT'" [iconName]="'sign-out-square-fill'" (clicked)="onLogoutClicked()"></rpc-button>
    </div>
    <mat-divider class="divider"></mat-divider>
    <portfolio-agents-list class="mobile-side-agents" [agents]="agents$ | async"></portfolio-agents-list>
    <mat-divider class="divider" *ngIf="showInstallationButton"></mat-divider>
    <div (click)="addToHomeScreen()" *ngIf="showInstallationButton">
        <rpc-icon [iconName]="'download'"></rpc-icon>
        <span>{{ 'SIDE_MENU.TITLES.PROFILE' | translate }}</span>
    </div>
</div>