import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'sanitize' })
export class SanitizePipe implements PipeTransform {
    constructor(private readonly sanitizer: DomSanitizer) { }

    transform(value: string, context: 'none' | 'html' | 'style' | 'script' | 'url' | 'resourceUrl'): string {
        const securityContextMap = new Map<string, SecurityContext>([
            ['none', SecurityContext.NONE],
            ['html', SecurityContext.HTML],
            ['style', SecurityContext.STYLE],
            ['script', SecurityContext.SCRIPT],
            ['url', SecurityContext.URL],
            ['resourceUrl', SecurityContext.RESOURCE_URL],
        ]);

        return this.sanitizer.sanitize(securityContextMap.get(context), value);
    }
}