export class RegistrationData {
    agentId: number;
    customerId?: number;
    accountName: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneId: number;
    phoneNumber: string;
    countryCode: string;
    agentEmail: string;
    agentName: string;
    primaryCustomerId?: number;
    invitatingAgentId?: number;
}