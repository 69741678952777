export enum GoogleAnalyticsEventName {
    SignUp = 'sign_up',
    ChangeAgent = 'change_agent',
    Chatbot_FirstQuestionPrompted = 'chatbot-first_question_prompted',
    KnowledgeBase_ViewTab = 'knowledge_base-view_tab',
    KnowledgeBase_Search = 'knowledge_base-search',
    ExternalListings_ListingCreated = 'external_listings-listing_created',
    ExternalListings_GetListingInfo = 'external_listings-get_listing_info',
    Portfolio_ChangeSystemFoldersVisibility = 'portfolio-change_system_folders_visibility',
    Folder_Create = 'folder-create',
    Folder_Open = 'folder-open',
    Folder_RemoveDefault_AddedToPortfolio = 'folder-remove_default-added_to_portfolio',
    Folder_SetDefault_Custom = 'folder-set_default-custom',
    Share = 'share',
    Settings_DarkMode_Changed = 'settings-dark_mode-changed',
    MortgageCalculator_Open = 'mortgage_calculator-open',
    SavedSearchCreation = 'saved_search_creation',
    SavedSearch_SetDefault = 'saved_search-set_default',
    SavedSearchCreation_AttachFolder = 'saved_search_creation-attach_folder',
    SavedSearchEdition_AttachFolder = 'saved_search_edition-attach_folder',
    ContactAgent_Click = 'contact_agent-click',
    ChildCustomer_Invite = 'child_customer-invite',
    Profile_ViewTab = 'profile-view_tab',
    ViewChanged = 'view_changed',
    SaveLayout = 'save_layout',
    Search_ApplyFilter = 'search-apply_filter',
    Search_OmniBox = 'search-omni_box',
    Map_VisibilityChanged = 'map-visibility_changed',
    Map_SelectNeighborhood = 'map-select_neighborhood',
    Map_SizeChanged = 'map-size_changed',
    Map_Draw = 'map-draw',
    FolderManagement = 'folder_management',
    ListingAddReaction = 'listing-add-reaction',
    NotificationBell_Toggle = 'notification_bell-toggle',
    NotificationBell_View = 'notification_bell-view',
    NotificationBell_Redirect = 'notification_bell-redirect',
}