import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { CurrencyConstants } from '@core-constants/currency/currency.constants';
import { DateConstants } from '@core-constants/dates/date.constants';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { FormattedPriceChangeData } from '@listings/components/last-price-change/models/formatted-price-change-data';
import { ListingPriceChange } from '@listings/models/listing/listing-price-change';
import { ListingPrices } from '@listings/models/listing/listing-prices';

@Component({
    selector: 'last-price-change',
    templateUrl: './last-price-change.component.html',
    styleUrls: ['./last-price-change.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastPriceChangeComponent implements OnChanges, AfterViewInit {

    @Input() prices: ListingPrices;
    @Input() priceChange: ListingPriceChange;

    @Output() opened = new EventEmitter<void>();
    @Output() closed = new EventEmitter<void>();

    private readonly decreasePriceChangeType = 'D';

    public formattedLastPrice: string;
    public formattedPriceChangeData: FormattedPriceChangeData;

    public isPriceIncreased: boolean;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef
    ) { }

    public ngOnChanges(changes: SimpleChanges<LastPriceChangeComponent>): void {
        if (changes != null &&
            (changes.prices != null && changes.prices.currentValue !== changes.prices.previousValue) ||
            (changes.priceChange != null && changes.priceChange.currentValue !== changes.priceChange.previousValue)
        ) {
            this.formattedLastPrice = new CurrencyPipe('en').transform(
                this.prices != null ? this.prices.soldPrice : null,
                CurrencyConstants.Codes.USD,
                CurrencyConstants.Symbols.USD,
                CurrencyConstants.Formats.WithoutFraction);
            this.formattedPriceChangeData = this.formatPriceChangeData();

            this.changeDetectorRef.detectChanges();
        }
    }

    public ngAfterViewInit(): void {
        this.changeDetectorRef.detach();
    }

    public onLastPriceChangeClicked(): void {
        this.opened.emit();
    }

    public onLastPriceChangeClosed(): void {
        setTimeout(() => this.closed.emit());
    }

    private formatPriceChangeData(): FormattedPriceChangeData {
        if (this.priceChange != null) {
            const isPriceIncreased = this.priceChange.type !== this.decreasePriceChangeType;
            return {
                isPriceIncreased: isPriceIncreased,
                changeTypeLocalizationKey: isPriceIncreased ? 'LAST_PRICE_CHANGE.TITLES.INCREASE' : 'LAST_PRICE_CHANGE.TITLES.DECREASE',
                changeDate: new DatePipe('en').transform(this.priceChange.date, DateConstants.Formats.AngularPipeShortDateSlash),
                previousPrice: new CurrencyPipe('en').transform(
                    this.priceChange.previousPrice,
                    CurrencyConstants.Codes.USD,
                    CurrencyConstants.Symbols.USD,
                    CurrencyConstants.Formats.WithoutFraction),
                delta: new CurrencyPipe('en').transform(
                    Math.abs(this.priceChange.delta),
                    CurrencyConstants.Codes.USD,
                    CurrencyConstants.Symbols.USD,
                    CurrencyConstants.Formats.WithoutFraction),
                percentDelta: this.priceChange.percent
            };
        }

        return null;
    }
}