export const locale = {
    lang: 'en',
    data: {
        SIINGLE_ACTIVITY_CONTROLS: {
            TITLES: {
                SINGLE: {
                    PICK_LISTED: 'Pick Listed',
                    LIKED: 'Like',
                    SHOWN: 'Shown',
                    PICKED: 'Picked',
                    DISLIKED: 'Dislike',
                    ZERO: 'Listing Activity'
                },
                MULTIPLE: {
                    LIKED: 'Liked / Shown',
                    PICKED: 'Picked / Shown',
                    DISLIKED: 'Disliked / Shown',
                },
            }
        }
    }
};