<form [formGroup]="inviteUserFormGroup">
    <rpc-input [label]="'INVITE_USER_FORM.FIELDS.RECIPIENT_EMAIL'" [type]="'email'" [appearance]="'outline'"
        [options]="recepientEmailControlOptions">
    </rpc-input>

    <mat-form-field class="rpc-textarea" [appearance]="'outline'">
        <mat-label>{{ 'INVITE_USER_FORM.FIELDS.MESSAGE' | translate }}</mat-label>
        <textarea matInput [formControlName]="'message'"></textarea>
        <mat-hint [align]="'end'">
            {{ message.length }} / {{ maximumMessageLength }}
        </mat-hint>
    </mat-form-field>

    <div class="ium-action-wrap">
        <rpc-button class="ium-action ium-action--cancel" [appearanceType]="'stroke'" [color]="'accent'"
            [mainButton]="true" [title]="'INVITE_USER_DIALOG.BUTTONS.CANCEL'" (click)="cancel()"></rpc-button>
        <rpc-button class="ium-action ium-action--action" [appearanceType]="'flat'" [color]="'accent'"
            [mainButton]="true" [title]="'INVITE_USER_DIALOG.BUTTONS.SEND_EMAIL'"
            [disabled]="inviteUserFormGroup.invalid" (click)="onSendEmailClicked()"></rpc-button>
    </div>
</form>