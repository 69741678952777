import { AbstractControl, ValidationErrors } from '@angular/forms';

export const maxFileSizeValidator = (maxFileSize: number): (control: AbstractControl) => ValidationErrors | null => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        const currentValue = currentControl.value as File;
        if (currentValue != null && currentValue instanceof File) {
            return currentValue.size > maxFileSize
                ? { maxFileSize: true }
                : null;
        }
        return null;
    };
};