import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClickOutsideDirective } from '@core-directives/click-outside/click-outside.directive';
import { DynamicComponentHostDirective } from '@core-directives/dynamic-component-host/dynamic-component-host';
import { HideToggleDirective } from '@core-directives/hide-toggle/hide-toggle.directive';
import { IfOnDomDirective } from '@core-directives/if-on-dom/if-on-dom.directive';
import { RpcMatTabGroupScrollFixDirective } from '@core-directives/mat-tab-group-scroll-fix/mat-tab-group-scroll-fix.directive';
import { ResizedDirective } from '@core-directives/resized/resized.directive';
import { ShowMoreDirective } from '@core-directives/show-more/show-more.directive';
import { DropZoneDirective } from './drop-zone/drop-zone.directive';
import { FormatCurrencyDirective } from './format-currency/format-currency.directive';
import { RpcBadgeDirective } from './rpc-badge/rpc-badge.directive';
import { RpcClickHandlerDirective } from './rpc-click-handler/rpc-click-handler.directive';
import { RpcOnLoadDirective } from './rpc-on-load/rpc-on-load.directive';
import { ScrollableDirective } from './scrollable/scrollable.directive';
import { SplitAreaContentCorrectorDirective } from './split-area-content-correct/split-area-content-corrector.directive';
import { TextEllipsisDirective } from './text-ellipsis/text-ellipsis.directive';
import { WrapWordsDirective } from './wrap-words/wrap-words.directive';

const declaredExportModules = [
    IfOnDomDirective,
    ClickOutsideDirective,
    ShowMoreDirective,
    DynamicComponentHostDirective,
    HideToggleDirective,
    ResizedDirective,
    RpcMatTabGroupScrollFixDirective,
    ScrollableDirective,
    RpcBadgeDirective,
    FormatCurrencyDirective,
    SplitAreaContentCorrectorDirective,
    TextEllipsisDirective,
    DropZoneDirective,
    RpcOnLoadDirective,
    RpcClickHandlerDirective,
    WrapWordsDirective
];

@NgModule({
    declarations: [declaredExportModules],
    imports: [],
    exports: [declaredExportModules],
    providers: [CurrencyPipe]
})
export class RpcDirectivesModule { }