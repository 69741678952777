import * as moment from 'moment';

import { ChareEventDateFormatConstant } from '@appointments/constants/share-event-date-format-constant';
import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { ShareEventBase } from '@appointments/models/share-event/share-event-base';

export class ICSShareEvent extends ShareEventBase {
    constructor(location: string, appointment: ListingAppointment) {
        super(location, appointment);
    }

    public getURL(): string {
        return encodeURI(
            'data:text/calendar;charset=utf8,' + [
                'BEGIN:VCALENDAR',
                'VERSION:2.0',
                'METHOD:PUBLISH',
                'BEGIN:VEVENT',
                `DTSTART:${moment(this.start).format(ChareEventDateFormatConstant.GoogleDateFormat)}`,
                `DTEND:${moment(this.end).format(ChareEventDateFormatConstant.GoogleDateFormat)}`,
                `SUMMARY:${this.title}`,
                `DESCRIPTION:${this.description}`,
                `LOCATION:${this.location}`,
                'END:VEVENT',
                'END:VCALENDAR'].join('\n'));
    }
}