import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { CreateNotificationApiService } from '../services/create-notification-api.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import * as foldersActions from '@folders/store/actions/folders.actions';

@Injectable()
export class CreateFolderNotificationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly createNotificationApiService: CreateNotificationApiService,
        private readonly userStoreService: UserStoreService
    ) { }

    public readonly createNewFolderNotification$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.createSuccess),
        concatLatestFrom(() => this.userStoreService.customerCollaborationId$),
        switchMap(([{ id, customerId }, collaborationId]) => {
            const newFolderNotification = {
                folderId: id,
                collaborationId: collaborationId,
                createId: customerId
            };

            return this.createNotificationApiService.createFolderAddedNotification(newFolderNotification);
        })
    ));

    public readonly createFolderUpdatedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.updateSuccess),
        concatLatestFrom(() => [
            this.userStoreService.customerCollaborationId$,
            this.userStoreService.customerId$
        ]),
        switchMap(([{ folderData }, collaborationId, customerId]) => {
            const folderUpdatedNotification = {
                folderId: folderData.id,
                collaborationId: collaborationId,
                createId: customerId
            };

            return this.createNotificationApiService.createFolderUpdatedNotification(folderUpdatedNotification);
        })
    ));

    public readonly createFolderRemovedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.removeSuccess),
        concatLatestFrom(() => [
            this.userStoreService.customerCollaborationId$,
            this.userStoreService.customerId$
        ]),
        switchMap(([{ model }, collaborationId, customerId]) => {
            const folderRemovedNotification = {
                folderId: model.id,
                collaborationId: collaborationId,
                createId: customerId
            };

            return this.createNotificationApiService.createFolderRemovedNotification(folderRemovedNotification);
        })
    ));

    public readonly createListingFoldersChangedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.manageListingsFoldersSuccess),
        concatLatestFrom(() => [this.userStoreService.customerCollaborationId$, this.userStoreService.customerId$]),
        switchMap(([{ models }, collaborationId, customerId]) => {
            const input = { folderId: models[0].folderId, collaborationId, createId: customerId };

            return this.createNotificationApiService.createFolderListingsChangedNotification(input);
        })
    ));
}