import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { fadeInScaleAnimation } from '@core-animations/element-animations';

@Component({
    selector: 'terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss'],
    animations: [fadeInScaleAnimation]
})
export class TermsAndConditionsComponent {
    constructor(
        private readonly configurationService: AppConfigurationService,
        private readonly location: Location
    ) {
        configurationService.configuration = {
            layout: {
                secondaryToolbar: { hidden: true, component: null, layout: 'full', allowScrollLayoutChange: true },
                spinner: { hidden: true }
            }
        };
    }

    public onBackClick(): void {
        this.location.back();
    }
}