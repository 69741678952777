import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Apollo } from 'apollo-angular';

import { NotificationApiItem } from "@notifications/models/notification-api-item";
import * as notificationActions from '../actions/notifications.actions';
import * as notificationGraphqlConstants from '@notifications/constants/notification-graphql.constants';
import { RemoveByEntitiesInput, RemoveNotificationsByListingsInput } from 'app/graphql/graphql';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService {
    constructor(private readonly apollo: Apollo) { }

    public getNotifications(): Observable<Action> {
        return this.apollo.query({ query: notificationGraphqlConstants.GET_ALL_NOTIFICATIONS, fetchPolicy: 'no-cache' }).pipe(
            map((response) => notificationActions.loadNotificationsSuccess({ apiNotifications: response.data.notifications as unknown as NotificationApiItem[] })),
            catchError(() => of(notificationActions.loadNotificationsFailed()))
        );
    }

    public viewUnviewNotifications(notificationIds: number[]): Observable<Action> {
        return this.apollo.mutate({
            mutation: notificationGraphqlConstants.VIEW_UNVIEW_NOTIFICATIONS,
            variables: { notificationIds }
        }).pipe(
            map(() => notificationActions.viewUnviewNotificationsSuccess()),
            catchError(() => of(notificationActions.viewUnviewNotificationsFailed({ notificationIds })))
        );
    }

    public removeListingsNotifications(input: RemoveNotificationsByListingsInput): Observable<Action> {
        return this.apollo.mutate({
            mutation: notificationGraphqlConstants.REMOVE_NOTIFICATIONS_BY_LISTINGS,
            variables: { input }
        }).pipe(
            map(() => notificationActions.removeListingsNotificationsSuccess({ listingsHashCodes: input.listingsIds })),
            catchError(() => of(notificationActions.removeListingsNotificationsFailed()))
        );
    }

    public removeNotifications(ids: number[]): Observable<Action> {
        return this.apollo.mutate({
            mutation: notificationGraphqlConstants.REMOVE_NOTIFICATIONS,
            variables: { ids }
        }).pipe(
            map(() => notificationActions.removeNotificationsSuccess({ ids })),
            catchError(() => of(notificationActions.removeNotificationsFailed()))
        );
    }

    public removeEntitiesNotifications(input: RemoveByEntitiesInput): Observable<Action> {
        return this.apollo.mutate({
            mutation: notificationGraphqlConstants.REMOVE_ENTITIES_NOTIFICATIONS,
            variables: { input }
        }).pipe(
            map(() => notificationActions.removeEntitiesNotificationsSuccess({ entitiesIds: input.entitiesIds })),
            catchError(() => of(notificationActions.removeEntitiesNotificationsFailed()))
        );
    }
}
