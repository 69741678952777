import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';

export class AppointmentDeleteActionModel {

    public readonly appointmentId: number;
    public readonly listingId: string;
    public readonly listingIdHashCode: number;
    public readonly listingAddress: string;
    public readonly listingAppointment: ListingAppointment;

    constructor(appointmentId: number, listingId: string,listingIdHashCode: number, listingAddress: string, listingAppointment: ListingAppointment) {
        this.appointmentId = appointmentId;
        this.listingId = listingId;
        this.listingIdHashCode = listingIdHashCode;
        this.listingAddress = listingAddress;
        this.listingAppointment = listingAppointment;
    }
}