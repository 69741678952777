export const locale = {
    lang: 'en',
    data: {
        REGISTRATION_FORM: {
            TITLES: {
                AGREE_TERMS: 'I Agree to',
                TERMS_CONDITIONS: 'the Terms and Conditions',
            },
            VALIDATION_ERRORS: {
                EMAIL_REQUIRED: 'Email is required',
                WRONG_EMAIL: 'Please enter a valid email address',
                PASSWORD_REQUIRED: 'Password is required',
                PASSWORD_CONFIRMATION_REQUIRED: 'Password confirmation is required',
                PASSWORDS_NOT_MATCH: 'Passwords must match',
                FIRST_NAME_REQUIRED: 'First name is required',
                LAST_NAME_REQUIRED: 'Last name is required',
                NUMBER_REQIRED: 'At least 1 digit is required',
                CAPITAL_REQUIRED: 'At least 1 capital character is required',
                SMALL_REQUIRED: 'At least 1 small character is required',
                SPECIAL_REQIRED: 'At least 1 special character is required',
                MINIMUM_LENGTH: 'Password must be at least 6 characters',
                MAXIMUM_LENGTH: 'Password must be not more than 16 characters.'
            },
            FIELDS: {
                EMAIL: 'Email',
                PASSWORD: 'Password',
                PASSWORD_CONFIRM: 'Confirm Password',
                FIRST_NAME: 'First Name',
                LAST_NAME: 'Last Name',
                PHONE: 'Phone'
            },
            BUTTONS: {
                CREATE: 'SIGN UP',
                WORKSPACE_ADD: 'ADD TO YOUR PORTFOLIO'
            }
        }
    }
};