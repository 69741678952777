import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';

export const STATES = [
    new RpcSelectOption('AL', 'Alabama'),
    new RpcSelectOption('AK', 'Alaska'),
    new RpcSelectOption('AZ', 'Arizona'),
    new RpcSelectOption('AR', 'Arkansas'),
    new RpcSelectOption('CA', 'California'),
    new RpcSelectOption('CO', 'Colorado'),
    new RpcSelectOption('CT', 'Connecticut'),
    new RpcSelectOption('DE', 'Delaware'),
    new RpcSelectOption('DC', 'District of Col'),
    new RpcSelectOption('FL', 'Florida'),
    new RpcSelectOption('GA', 'Georgia'),
    new RpcSelectOption('HI', 'Hawaii'),
    new RpcSelectOption('ID', 'Idaho'),
    new RpcSelectOption('IL', 'Illinois'),
    new RpcSelectOption('IN', 'Indiana'),
    new RpcSelectOption('IA', 'Iowa'),
    new RpcSelectOption('KS', 'Kansas'),
    new RpcSelectOption('KY', 'Kentucky'),
    new RpcSelectOption('LA', 'Louisiana'),
    new RpcSelectOption('ME', 'Maine'),
    new RpcSelectOption('MD', 'Maryland'),
    new RpcSelectOption('MA', 'Massachusetts'),
    new RpcSelectOption('MI', 'Michigan'),
    new RpcSelectOption('MN', 'Minnesota'),
    new RpcSelectOption('MS', 'Mississippi'),
    new RpcSelectOption('MO', 'Missouri'),
    new RpcSelectOption('MT', 'Montana'),
    new RpcSelectOption('NE', 'Nebraska'),
    new RpcSelectOption('NV', 'Nevada'),
    new RpcSelectOption('NH', 'New Hampshire'),
    new RpcSelectOption('NJ', 'New Jersey'),
    new RpcSelectOption('NM', 'New Mexico'),
    new RpcSelectOption('NY', 'New York'),
    new RpcSelectOption('NC', 'North Carolina'),
    new RpcSelectOption('ND', 'North Dakota'),
    new RpcSelectOption('OH', 'Ohio'),
    new RpcSelectOption('OK', 'Oklahoma'),
    new RpcSelectOption('OR', 'Oregon'),
    new RpcSelectOption('PA', 'Pennsylvania'),
    new RpcSelectOption('RI', 'Rhode Island'),
    new RpcSelectOption('SC', 'South Carolina'),
    new RpcSelectOption('SD', 'South Dakota'),
    new RpcSelectOption('TN', 'Tennessee'),
    new RpcSelectOption('TX', 'Texas'),
    new RpcSelectOption('UT', 'Utah'),
    new RpcSelectOption('VT', 'Vermont'),
    new RpcSelectOption('VA', 'Virginia'),
    new RpcSelectOption('WA', 'Washington'),
    new RpcSelectOption('WV', 'West Virginia 3'),
    new RpcSelectOption('WI', 'Wisconsin'),
    new RpcSelectOption('WY', 'Wyoming')
];