import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';

@Injectable({ providedIn: 'root' })
export class SearchGuard implements CanActivateChild, CanActivate {

    constructor(
        private readonly routeService: RouteService,
        private readonly settingsStoreService: SettingsStoreService
    ) { }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute();
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute();
    }

    private canActivateRoute(): Observable<boolean> {
        return this.settingsStoreService.getSettings()
            .pipe(
                take(1),
                switchMap((settings: CustomerSettings) => {
                    if (settings?.permissionSettings?.canSearchForListings != null && settings?.permissionSettings?.canSearchForListings) {
                        return of(true);
                    } 
                        this.routeService.navigate(RpcRoute.Login).catch(() => { });
                        return of(false);
                    
                }));
    }
}