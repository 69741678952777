import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { IInviteUserModel } from 'app/modules/user-modules/profile-modules/profile-base/invite-user/invite-user-model';
import { ChildAccount } from '../../interfaces/child-account/child-account';
import { IResendInvitationModel } from '../../invite-user/resend-invitation-model';

export const loadAdditionalProfiles = createAction('[Profile Base] Load Additional Profiles', props<{ isActiveOnly: boolean }>());
export const loadAdditionalProfilesSuccessful = createAction('[Profile Base] Load Additional Profiles Successful', props<{ additionalProfiles: ChildAccount[] }>());
export const loadAdditionalProfilesFailed = createAction('[Profile Base] Load Additional Profiles Failed', (error: ApiError) => error);

export const inviteUser = createAction('[Profile Base] Invite User', props<{ inviteUserModel: IInviteUserModel }>());
export const inviteUserSuccessful = createAction('[Profile Base] Invite User Successful', props<{ childEmail: string }>());
export const inviteUserFailed = createAction('[Profile Base] Invite User Failed', props<{ error: ApiError }>());

export const deactivateChildAccountByPrimary = createAction('[Profile Base] Deactivate Account Child Account By Primary', props<{ childCustomerId?: number }>());
export const deactivateChildAccountByPrimarySuccessful = createAction('[Profile Base] Deactivate Child Account By Primary Account Successful', props<{ childCustomerId: number }>());
export const deactivateChildAccountByPrimaryFailed = createAction('[Profile Base] Deactivate Account Child Account By Primary Failed', (error: ApiError) => error);

export const reSendInvite = createAction('[Profile Base] Re-Send Invite', props<{ resendInvitationModel: IResendInvitationModel }>());
export const reSendInviteSuccessful = createAction('[Profile Base] Re-Send Invite Successful', props<{ oldEmail: string, newEmail?: string | null }>());
export const reSendInviteFailed = createAction('[Profile Base] Re-Send Invite Failed', props<{ error: ApiError }>());

export const cancelInvitation = createAction('[Profile Base] Cancel Invitation', props<{ childEmail: string }>());
export const cancelInvitationSuccessful = createAction('[Profile Base] Cancel Invitation Successful', props<{ childEmail: string }>());
export const cancelInvitationFailed = createAction('[Profile Base] Cancel Invitation Failed', props<{ error: ApiError }>());

export const showInviteUserDialog = createAction('[Profile Base] Show Invite User Dialog');