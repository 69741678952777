export const locale = {
    lang: 'en',
    data: {
        NOTIFICATION_GROUP: {
            TITLES: {
                LISTINGS_ADDED: '{{numberOfListings}} new listings were **added** to the portfolio.',
                LISTINGS_LIKED: '{{numberOfListings}} listings in the portfolio were **liked**.',
                LISTINGS_DISLIKED: '{{numberOfListings}} listings in the portfolio were **disliked**.',
                LISTINGS_SHOWN: '{{numberOfListings}} listings in the portfolio were **shown**.',
                MARK_AS_READ: 'Mark as Read',
                MARK_AS_READ_ALL_GROUP: 'Mark as Read All Notifications in the Group',
                SHOW_MORE: 'Show more',
                SHOW_LESS: 'Show less',
            }
        }
    }
};