import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { ExternalListing } from '@external-listings/models/external-listing';
import { ExternalListingRequest } from '@external-listings/models/external-listing-request';
import { ExternalListingSubmit } from '@external-listings/models/external-listing-submit';

export const load = createAction('[External Listings] Load');

export const loadListings = createAction(
    '[External Listings] Load Listings',
    props<{ shouldSetLoading: boolean }>()
);

export const countListings = createAction(
    '[External Listings] Count Listings'
);

export const loadListingsSuccess = createAction(
    '[External Listings] Load Listing Success',
    props<{ listings: ExternalListing[] }>());

export const loadListingsFailed = createAction('[External Listings] Load Listing Failed', props<{ error: ApiError }>());

export const countListingsSuccess = createAction(
    '[External Listings] Count Listings Success',
    props<{ count: number }>());

export const createNewListing = createAction(
    '[External Listings] Create New Listing',
    props<ExternalListingSubmit>());

export const updateListing = createAction(
    '[External Listings] Update Listing',
    props<ExternalListingSubmit>());

export const deleteListing = createAction(
    '[External Listing] Delete Listing',
    props<{ listingId: number }>());

export const createListingSuccess = createAction(
    '[External Listings] Create Listing Success',
    props<{ listing: ExternalListing }>());

export const createListingFailed = createAction(
    '[External Listings] Create Listing Failed',
    (error: ApiError, request: ExternalListingRequest) => {
        return { error: error, request: request };
    });

export const updateListingSuccess = createAction(
    '[External Listings] Update Listing Success',
    props<{ listing: ExternalListing }>());

export const updateListingFailed = createAction(
    '[External Listings] Update Listing Failed',
    (error: ApiError, request: ExternalListingRequest) => {
        return { error: error, request: request };
    });

export const deleteListingSuccess = createAction(
    '[External Listings] Delete Listing Success',
    props<{ deletedListingId: number }>());

export const deleteListingFailed = createAction(
    '[External Listings] Delete Listing Failed',
    (error: ApiError, listingId: number) => {
        return { error: error, listingId: listingId };
    });

export const getListingInfo = createAction(
    '[External Listings] Get Listing Info',
    props<{ listingUrl: string }>()
);

export const getListingInfoSuccess = createAction(
    '[External Listings] Get Listing Info Success',
    props<{ isFullyLoaded: boolean, listingInfo: ExternalListing }>()
);

export const getListingInfoFailed = createAction(
    '[External Listing] Get Listing Info Failed',
    (error: ApiError, listingUrl: string) => {
        return { error: error, listingUrl: listingUrl };
    }
);

export const resetState = createAction('[External Listing] Reset State');