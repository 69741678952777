import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';
import { map, Observable } from 'rxjs';

import { DateConstants } from '@core-constants/dates/date.constants';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { RpcTooltipDirective } from '@core-utils/rpc-tooltip/directive/rpc-tooltip.directive';
import { ListingCategoryTexts } from '@listings/enums/listing-category-texts.enum';
import { CommonListing } from '@listings/models/listing/common-listing';
import { MatchMediaService } from '@media/services/match-media.service';
import { NewMatchTooltipComponent } from '../new-match-tooltip/new-match-tooltip.component';
import { ListingActivityContribution } from '@portfolio/models/listing-activity-contribution';

@Component({
    selector: 'listing-labels',
    templateUrl: './listing-labels.component.html',
    styleUrls: ['./listing-labels.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingLabelsComponent implements OnChanges, OnInit {

    @Input() listing: CommonListing;
    @Input() includeText?: boolean;
    @Input() showNewlyAddedTag?: boolean;
    @Input() listingActivityContribution?: ListingActivityContribution;

    @ViewChildren(RpcTooltipDirective) public tooltipDirectives: QueryList<RpcTooltipDirective>;

    public DateConstants = DateConstants;
    public ListingCategory = ListingCategoryTexts;
    public readonly NewMatchTooltipComponent = NewMatchTooltipComponent;
    public newMatchTooltipData: { listingActivityContribution: ListingActivityContribution };
    public isMobile$: Observable<boolean>;
    public readonly customTriggerName = 'singleClicked';

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly matchMediaService: MatchMediaService,
    ) { }

    public ngOnInit(): void {
        this.isMobile$ = this.matchMediaService.onMediaChange.pipe(
            map(mediaQuery => ['xs', 'sm', 'md'].includes(mediaQuery))
        );
    }

    public ngOnChanges(changes: SimpleChanges<ListingLabelsComponent>): void {
        if (changes != null &&
            (changes.listing != null && JSON.stringify(changes.listing.currentValue) !== JSON.stringify(changes.listing.previousValue)) ||
            (changes.includeText != null && changes.includeText.currentValue !== changes.includeText.previousValue) ||
            changes.listingActivityContribution?.currentValue != null
        ) {
            this.newMatchTooltipData = { listingActivityContribution: changes.listingActivityContribution.currentValue };

            this.changeDetectorRef.detectChanges();
        }
    }

    public menuOpened(): void {
        this.changeDetectorRef.detectChanges();
    }

    public onMobileClick(): void {
        this.tooltipDirectives.filter(({ trigger }) => trigger === this.customTriggerName).forEach(t => t.show());
    }
}