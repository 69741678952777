import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { TokenInitializerService } from '@auth/services/token-initializer.service';
import { UserStoreService } from '@auth/store/services/user-store.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private readonly NOT_AUTHORIZED_CODE = 401;
    private refreshTokenInProgress = false;
    private readonly tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private readonly tokenInitializerService: TokenInitializerService,
        private readonly userStoreService: UserStoreService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.userStoreService.getUser()
            .pipe(
                take(1),
                switchMap(user => {
                    if (user != null && user.loginData != null) {
                        request = this.getAuthorizationRequest(request, user.loginData.accessToken);
                    }

                    return next.handle(request).pipe(catchError(error => {
                        if (error instanceof HttpErrorResponse && error.status === this.NOT_AUTHORIZED_CODE) {
                            return this.handleUnauthorizedError(request, next);
                        } 
                            return throwError(error);
                        
                    }));
                }));
    }

    private getAuthorizationRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: `Bearer ${token}`
            }
        });
    }

    private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.tokenSubject.next(null);
            return this.tokenInitializerService.refreshToken().pipe(
                switchMap(token => {
                    this.tokenSubject.next(token);
                    return next.handle(this.getAuthorizationRequest(request, token));
                }),
                catchError(error => {
                    this.userStoreService.logout();
                    return throwError(error);
                }),
                finalize(() => {
                    this.refreshTokenInProgress = false;
                })
            );
        } 
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    return next.handle(this.getAuthorizationRequest(request, token));
                })
            );
        
    }
}