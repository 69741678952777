import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { interval, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { DateConstants } from '@core-constants/dates/date.constants';
import { ToastService } from '@core-services/toast.service';

@Injectable({ providedIn: 'root' })
export class ApplicationUpdateService {

    private readonly currentVersion = new Subject<string>();

    private storedVersion: AppVersion;

    constructor(
        private readonly translateService: TranslateService,
        private readonly toaster: ToastService
    ) { }

    public initialize(unsubscribe: Subject<any>): void {
        this.fetchVersion('force-cache');
        interval(15 * 1000)
            .pipe(takeUntil(unsubscribe))
            .subscribe(() => this.fetchVersion('reload'));
    }

    public getCurrentVersion(): Observable<string> {
        return this.currentVersion;
    }

    public showUpdateNotification(): void {
        // TODO : create custom toastr component
        const message = `<a>${this.translateService.instant('NOTIFICATIONS_COMMON.ALERTS.NEW_VERSION_TOAST')}</a>`;
        const toast = this.toaster.showTranslatedClientInfo(
            message,
            null,
            { disableTimeOut: true, timeOut: 0, extendedTimeOut: 0, enableHtml: true, toastClass: 'ngx-toastr update-toast' });
        toast.toastRef.afterActivate().pipe(take(1)).subscribe(() => {
            const linkElement = document.querySelector('.update-toast a');
            if (linkElement != null) {
                linkElement.addEventListener('click', () => window.location.reload());
            }
        });
    }

    public getVersion(version: AppVersion = this.storedVersion): string {
        return version != null ? `${version.version.major}.${version.version.minor}.${version.version.patch}` : '';
    }

    public getBuildDate(version: AppVersion = this.storedVersion): string {
        return version != null ? `${moment(new Date(version.build.date)).format(`${DateConstants.Formats.ShortDateSlash} h:mm:ss A`)}` : '';
    }

    private fetchVersion(cache: 'force-cache' | 'reload'): void {
        fetch('version.json', { cache: cache }).then(response => {
            response.json().then((appVersion: AppVersion) => {
                if (this.storedVersion == null) {
                    this.storedVersion = appVersion;
                    this.currentVersion.next(this.formatCurrentVersion());

                    return;
                }

                if (moment(new Date(appVersion.build.date)).isAfter(new Date(this.storedVersion.build.date))) {
                    this.showUpdateNotification();
                }
            }).catch(() => { });
        }).catch(() => { });
    }

    private formatCurrentVersion(): string {
        return this.storedVersion == null
            ? null
            : `Version: ${this.getVersion(this.storedVersion)} | Published: ${this.getBuildDate(this.storedVersion)}`;
    }
}

export class AppVersion {
    version: {
        major: number;
        minor: number;
        patch: number;
    };

    build: {
        date: string;
    };
}