import { SortOptionSecondLevel } from '@core-models/sort/sort-option-second-level';
import { ListingCategories } from '@listings/enums/listing-categories.enum';
import { CommonListing } from '@listings/models/listing/common-listing';
import { ListingStatusDates } from '@listings/models/listing/listing-status-dates';
import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { MapShapesSearchOptions } from '@listings/models/search/map-shapes-search-options';
import { ViewModes } from '@view-base/models/view-modes.enum';

export class OnMarketStateHelper {

    public static defaultSort = new SortOptionSecondLevel<CommonListing, ListingStatusDates>('statusDates', 'listedDate');
    public static defaultViewMode = ViewModes.gallery;
    public static defaultMapSizePercent = 25;
    public static createDefaultSearchOptions(category: ListingCategories = ListingCategories.Sales): ListingSearchOptions {
        return {
            categoryId: category,
            priceSearchOption: { min: null, max: null },
            bedSearchOption: { any: true, bedrooms: [], exactMatch: false },
            bathSearchOption: { any: true, bathrooms: [], exactMatch: false },
            ownershipTypeSearchOptions: { ownershipTypeIds: [] },
            roomSearchOption: { any: true, rooms: [], exactMatch: false },
            squareFootageSearchOption: { min: null, max: null },
            monthlyExpenceSearchOption: { min: null, max: null },
            buildingPeriodSearchOption: { buildingPeriodIds: [] },
            miscellaneousDetailsSearchOption: { miscellaneousDetailIds: [] },
            privateOutdoorSpaceSearchOption: { privateOutdoorSpaceIds: [] },
            commonOutdoorSpaceSearchOption: { commonOutdoorSpaceIds: [] },
            buildingTypeSearchOption: { buildingTypeIds: [] },
            attendedLobbySearchOption: { attendedLobbyIds: [] },
            featuresSearchOption: { featureIds: [] },
            listingNumbers: [],
            neighborhoodIds: [],
            rpBins: [],
            webNumbers: [],
            patternSearchResult: [],
            mapShapesSearchOptions: new MapShapesSearchOptions()
        };
    }

}