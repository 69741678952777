export const locale = {
    lang: 'en',
    data: {
        MEDIA_GALLERY: {
            TITLE: {
                CLOSE: 'Close',
                PREVIOUS_MEDIA: 'Previous Media',
                NEXT_MEDIA: 'Next Media',
                VIDEO: 'Video',
                TOUR: '3D-tour',
            }
        }
    }
};