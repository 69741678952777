import { SYSTEM_FOLDERS_INFO } from '@folders/constants/folder.constants';
import { FolderManagementAction } from '@folders/enums/folder-management-action.enum';
import { ListingFolderManagement } from '@folders/models/folder-management/listing-folder-management';
import { ListingFolderIdsMap } from '@folders/models/listing-folder-ids-map';
import { CommonListing } from '@listings/models/listing/common-listing';

export class ListingFolderIdsMapHelper {

    public static organizeListingsPerFolder(
        map: Map<number, CommonListing[]>,
        listing: CommonListing,
        listingFolderIdsMap: ListingFolderIdsMap[]
    ): Map<number, CommonListing[]> {
        if (listing.isDeleted) {
            return ListingFolderIdsMapHelper.addListingToFolder(map, listing, SYSTEM_FOLDERS_INFO.RemovedListings.id);
        }

        const folders = listingFolderIdsMap.reduce(
            (foldersSet, listingFolderIdMapItem: ListingFolderIdsMap) => {
                if (listing.newMatches.some(x => x.id === listingFolderIdMapItem.newMatchRecordId)) {
                    foldersSet.add(listingFolderIdMapItem.folderId);
                }
                if (listingFolderIdMapItem.listingId === listing.hashCode) {
                    foldersSet.add(listingFolderIdMapItem.folderId);
                }

                return foldersSet;
            },
            new Set<number>()
        );

        if (folders.size === 0) {
            map = ListingFolderIdsMapHelper.addListingToFolder(map, listing, SYSTEM_FOLDERS_INFO.NotAssignedToFolder.id);
        }

        folders.forEach(id => {
            map = ListingFolderIdsMapHelper.addListingToFolder(map, listing, id);
        });

        map = ListingFolderIdsMapHelper.addListingToFolder(map, listing, SYSTEM_FOLDERS_INFO.AddedToPortfolio.id);

        return map;
    }

    public static updateListingFolderIdsMap(
        currentListingFolderIdsMap: ListingFolderIdsMap[],
        models: ListingFolderManagement[],
        isRestore: boolean
    ): ListingFolderIdsMap[] {
        let listingFolderIdsMap = [...currentListingFolderIdsMap];

        for (const model of models) {
            if ((model.action === FolderManagementAction.Attach && !isRestore) || (model.action === FolderManagementAction.Detach && isRestore)) {
                model.listingsHashCodes.forEach(hashCode => {
                    listingFolderIdsMap.push({ listingId: hashCode, folderId: model.folderId, newMatchRecordId: null });
                });
            } else if ((model.action === FolderManagementAction.Detach && !isRestore) || (model.action === FolderManagementAction.Attach && isRestore)) {
                listingFolderIdsMap = listingFolderIdsMap.filter(({ listingId, folderId }) =>
                    folderId !== model.folderId || !model.listingsHashCodes.includes(listingId));
            }
        }

        return listingFolderIdsMap;
    }

    /**
     * Update existing mapping when activity change candidates has New Matches to get rid of NM info and possible NM duplicates.
     * @param currentListingFolderIdsMap Current Listing/New Match/Folder ids mapping.
     * @param listings Listings activity change candidates.
     * @returns Updated Listing/New Match/Folder ids mapping.
     */
    public static updateNewMatchesMapping(
        currentListingFolderIdsMap: ListingFolderIdsMap[],
        listings: { hashCode: number, isNewMatch: boolean }[]
    ): ListingFolderIdsMap[] {
        if (listings.every(candidate => !candidate.isNewMatch)) {
            return currentListingFolderIdsMap;
        }

        const listingFolderIdsMap: ListingFolderIdsMap[] = currentListingFolderIdsMap
            .filter(mapItem => mapItem.newMatchRecordId == null || listings.every(listing => listing.hashCode !== mapItem.listingId));

        const groupedListingFoldersMap = currentListingFolderIdsMap.reduce((listingFoldersMap, mapItem) => {
            if (listingFoldersMap.has(mapItem.listingId)) {
                const folderIds = listingFoldersMap.get(mapItem.listingId);
                if (!folderIds.includes(mapItem.folderId)) {
                    folderIds.push(mapItem.folderId);
                }
            } else {
                listingFoldersMap.set(mapItem.listingId, [mapItem.folderId]);
            }
            return listingFoldersMap;
        }, new Map<number, number[]>());

        const newMatchesFolderIdsMap = listings.reduce((map, listing) => {
            groupedListingFoldersMap.get(listing.hashCode).forEach(folderId => {
                if (!listingFolderIdsMap.some(mapItem => mapItem.listingId === listing.hashCode && mapItem.folderId === folderId)) {
                    map.push({
                        listingId: listing.hashCode,
                        folderId: folderId,
                        newMatchRecordId: null
                    });
                }
            });
            return map;
        }, []);

        return listingFolderIdsMap.concat(newMatchesFolderIdsMap);
    }

    private static addListingToFolder(map: Map<number, CommonListing[]>, listing: CommonListing, folderId: number): Map<number, CommonListing[]> {
        const folderListings = map.has(folderId) ? [...map.get(folderId), listing] : [listing];

        return map.set(folderId, folderListings);
    }
}