const settingsCommon = {
    SETTINGS_COMMON: {
        ERRORS: {
            SETTINGS_LOADING: 'Failed to load settings. Please, refresh the page.',
            SETTINGS_UPDATE: 'Failed to update settings. Please, try again.',
            NEIGHBORHOODS_MAPPING_LOADING: 'Failed to load map neighborhoods. Please, refresh the page.'
        }
    }
};

export const locale = {
    lang: 'en',
    data: {
        ...settingsCommon
    }
};