import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, mergeMap, share, switchMap } from 'rxjs/operators';

import { ProfileStoreService } from '@profile/store/services/profile-store.service';
import { InviteUserDialogComponent } from '../../components/invite-user-dialog/invite-user-dialog.component';
import { DEFAULT_INVITE_USER_DIALOG_CONFIG } from '../../components/invite-user-dialog/invite-user-dialog.constants';
import { IInviteUserModel } from '../../invite-user/invite-user-model';
import * as profileBaseActions from '../actions/profile-base.actions';
import { ProfileBaseApiService } from '../services/profile-base-api.service';
import { ProfileBaseStoreService } from '../services/profile-base-store.service';
import { GoogleAnalyticsEventName } from 'app/modules/core-modules/enums/google-analytics-event-name';
import * as googleAnalyticsActions from '@core-layout/app/store/actions/google-analytics.actions';

@Injectable()
export class ProfileBaseEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly profileBaseApiService: ProfileBaseApiService,
        private readonly profileStoreService: ProfileStoreService,
        private readonly profileBaseStoreService: ProfileBaseStoreService,
        private readonly matDialog: MatDialog,
    ) { }

    public readonly getAdditionalProfiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(profileBaseActions.loadAdditionalProfiles),
            switchMap((props) => this.profileBaseApiService.loadAdditionalProfiles(props.isActiveOnly)),
            share()
        )
    );

    public readonly inviteUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(profileBaseActions.inviteUser),
            switchMap(params => this.profileBaseApiService.inviteUser(params.inviteUserModel)),
            share()
        )
    );

    public reSendInvite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(profileBaseActions.reSendInvite),
            switchMap(params => this.profileBaseApiService.reSendInvite(params.resendInvitationModel)),
            share()
        )
    );

    public readonly cancelInvitation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(profileBaseActions.cancelInvitation),
            switchMap(params => this.profileBaseApiService.cancelInvitation(params.childEmail))
        )
    );

    public deactivateChildAccountByPrimary$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.deactivateChildAccountByPrimary),
        concatLatestFrom(() => this.profileStoreService.selectedAdditionalProfile$),
        mergeMap(([params, selectedAdditionalProfile]) => {
            const customerId = params.childCustomerId ?? selectedAdditionalProfile.customerId;

            return this.profileBaseApiService.deactivateChildAccountByPrimary(customerId);
        }),
        share()
    ));

    public readonly showInviteUserDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.showInviteUserDialog),
        concatLatestFrom(() => this.profileBaseStoreService.additionalProfilesEmails$),
        switchMap(([, additionalProfilesEmails]) => {
            const data = { portfolioEmails: additionalProfilesEmails };
            return this.matDialog.open(InviteUserDialogComponent, { ...DEFAULT_INVITE_USER_DIALOG_CONFIG, data }).afterClosed();
        }),
        filter(Boolean),
        switchMap((inviteUserModel: IInviteUserModel) => [
            profileBaseActions.inviteUser({ inviteUserModel }),
            googleAnalyticsActions.addEvent({ name: GoogleAnalyticsEventName.ChildCustomer_Invite })
        ])
    ));
}