import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DELETED_USER_BASE_INFO } from '@users/constants/deleted-user.constants';
import * as usersSelectors from '@users/store/selectors/users.selectors';
import { ExternalListingsState } from '../states/external-listings.state';

const listingsStateSelector = createFeatureSelector<ExternalListingsState>('external-listings');

export const externalListingsCountSelector = createSelector(
    listingsStateSelector,
    listingsState => listingsState == null ? 0 : listingsState.listingsCount
);

export const externalListingsLoadedSelector = createSelector(
    listingsStateSelector,
    listingsState => listingsState == null ? false : listingsState.externalListingsLoaded
);

export const externalListingsCountLoadedSelector = createSelector(
    listingsStateSelector,
    listingsState => listingsState == null ? false : listingsState.listingCountLoaded
);

export const selectIsLoading = createSelector(listingsStateSelector, listingsState => listingsState?.isLoading);

export const externalListingsSelector = createSelector(
    usersSelectors.selectCustomersAndAgents,
    listingsStateSelector,
    (sharedPortfolioUsers, listingsState) => {
        listingsState?.listings.forEach(listing => {
            const customer = sharedPortfolioUsers.find(x => x.customerId === listing.createId) ?? DELETED_USER_BASE_INFO;
            listing.customerName = customer.fullName;
            listing.customerProfilePictureUrl = customer.profileImage;
        });
        return listingsState?.listings ?? [];
    }
);

export const selectNewExternalListingsCount = createSelector(
    listingsStateSelector,
    state => state?.listings == null || !state.externalListingsLoaded ? 0 : state.listingsCount - state.listings.length
);