import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ListingComment } from '@comments/models/comments/listing-comment';
import * as commentsActions from '../actions/comments.actions';

@Injectable({ providedIn: 'root' })
export class CommentsApiService {
    constructor(private readonly http: ApiHttpClient) { }

    public loadComment(id: number) {
        return this.http.get(`comments/${id}`).pipe(
            map((response: ApiDataResult<ListingComment>) => {
                return response.error != null
                    ? commentsActions.loadCommentFailed()
                    : commentsActions.loadCommentSuccess({ comment: response.result });
            }),
            catchError(() => of(commentsActions.loadCommentFailed()))
        );
    }
}