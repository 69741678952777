<div class="rpc-toast">

    <div class="rpc-toast-title" wrapWords>{{translationInfo.key | translate: translationInfo?.params}}</div>

    <div class="rpc-toast-link" (click)="onReviewClick()">

        <div class="rpc-toast-link-text">{{'TOAST.TITLE.REVIEW' | translate }}</div>

        <rpc-icon class="rpc-toast-link-icon" [iconName]="'arrow-right'"></rpc-icon>

    </div>

    <rpc-icon-button class="rpc-toast-close" [title]="'TOAST.TITLE.CLOSE'" [iconName]="'cross'" (clicked)="onClose()"></rpc-icon-button>

    <div class="rpc-toast-decoration">

        <svg class="rpc-toast-decoration-bg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 110.97 103">
            <defs>
                <linearGradient id="toast-wave" y1="51.5" x2="110.97" y2="51.5" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#344a73"/>
                    <stop offset="1" stop-color="#8c4d98"/>
                </linearGradient>
                <linearGradient id="toast-wave-a" y1="55.31" x2="90.2" y2="55.31" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#466196"/>
                    <stop offset="1" stop-color="#a25aaf"/>
                </linearGradient>
            </defs>
            <path d="M0,13.5v76A13.49,13.49,0,0,0,13.5,103H111c-5.68-2.33-12.3-6.5-12-13.25C99.42,78,100.84,72.2,92.73,66s-10.85-12.42-11-24.16S77.17,27.41,69.18,25.44s-25-.59-36.76-18.78A20.61,20.61,0,0,0,25.27,0H13.5A13.49,13.49,0,0,0,0,13.5Z" fill="url(#toast-wave)"/>
            <path d="M0,89.5A13.49,13.49,0,0,0,13.5,103H90.2C83,100.67,70.47,94.65,66.45,80.15c-7.29-26.33-50.34-8.71-49.23-43.9.45-14.18-7.4-23.12-15.86-28.62A13.43,13.43,0,0,0,0,13.5Z" opacity="0.63" fill="url(#toast-wave-a)" style="isolation:isolate"/>
        </svg>

    </div>

    <div class="rpc-toast-decoration-img"></div>

</div>