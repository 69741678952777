import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { map, tap } from "rxjs/operators";

import * as googleAnalyticsActions from "../actions/google-analytics.actions";
import { RouterStoreService } from "../services/router-store.service";
import { UserStoreService } from "@auth/store/services/user-store.service";
import { RpcRoute } from "@core-layout/app/models/rpc-route";
import { URL_NAME_MAP } from "@core-constants/google-analytics.constants";
import { environment } from "environments/environment";

// function declared in app.service initializeGoogleAnalytics method
declare const gtag: (...params: any[]) => void;

@Injectable()
export class GoogleAnalyticsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly routerStoreService: RouterStoreService,
        private readonly userStoreService: UserStoreService,
    ) { }

    public readonly setCustomerAttributes$ = createEffect(
        () => this.actions$.pipe(
            ofType(googleAnalyticsActions.setCustomerAttributes),
            concatLatestFrom(() => [this.userStoreService.getUser(), this.userStoreService.customerFullName$]),
            tap(([, user, customerFullName]) => {
                if (environment.production) {
                    const userAttributes = user == null ? null : { customerId: user.customerId, customerEmail: user.customerUserName, customerName: customerFullName };
                    gtag('set', 'user_properties', userAttributes);
                }
            })
        ),
        { dispatch: false }
    );

    public readonly addPageEvent$ = createEffect(() => this.actions$.pipe(
        ofType(googleAnalyticsActions.addPageEvent),
        concatLatestFrom(() => [this.routerStoreService.routeParams$, this.routerStoreService.url$]),
        map(([{ name, payload }, routeParams, url]) => {
            const page = routeParams.folder != null
                ? 'folder'
                : url.includes('/listing/')
                    ? 'listing-details'
                    : URL_NAME_MAP.get(url.replace('/', '') as RpcRoute);

            return googleAnalyticsActions.addEvent({ name, payload: { ...(payload != null ? payload : {}), page } });
        })
    ));

    public readonly addEvent$ = createEffect(
        () => this.actions$.pipe(
            ofType(googleAnalyticsActions.addEvent),
            concatLatestFrom(() => this.userStoreService.getIsAuthorized()),// is_loginned_in
            tap(([{ name, payload }, isAuthorized]) => {
                if (typeof gtag != 'undefined') {
                    gtag('event', name, { ...payload, isAuthorized });
                }
            })
        ),
        { dispatch: false }
    );
}