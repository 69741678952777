export const locale = {
    lang: 'en',
    data: {
        ACTIVITIES_TOOLTIP: {
            TITLES: {
                LIKE: 'Like',
                DISLIKE: 'Dislike',
                SHOWN: 'Shown',
            }
        }
    }
};