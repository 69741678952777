import { AfterViewInit, Component, ComponentRef, ElementRef, EventEmitter, Input, Output, TemplateRef, Type, ViewChild } from '@angular/core';
import { DynamicComponentHostDirective } from '@core-directives/dynamic-component-host/dynamic-component-host';

@Component({
    selector: 'rpc-tooltip',
    templateUrl: './rpc-tooltip.component.html',
    styleUrls: ['./rpc-tooltip.component.scss']
})
export class RpcTooltipComponent implements AfterViewInit {

    @ViewChild(DynamicComponentHostDirective) dynamicComponentHost: DynamicComponentHostDirective;

    @Input() text: string;

    @Input() contentTemplate: TemplateRef<HTMLElement>;

    @Input() component: Type<any>;

    @Input() componentScopeData: any;

    @Input() customClass: string | null;
    @Output() initialized = new EventEmitter<ComponentRef<DynamicComponentHostDirective> | undefined>();

    public componentRef?: ComponentRef<DynamicComponentHostDirective>;

    constructor(public readonly elementRef: ElementRef) { }

    public ngAfterViewInit(): void {
        if (this.dynamicComponentHost != null && this.dynamicComponentHost.viewContainerRef != null && this.component != null) {
            this.dynamicComponentHost.viewContainerRef.clear();
            this.componentRef = this.dynamicComponentHost.viewContainerRef.createComponent<DynamicComponentHostDirective>(this.component);
            if (this.componentScopeData != null) {
                Object.keys(this.componentScopeData).forEach(propertyName => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                    this.componentRef.instance[propertyName] = this.componentScopeData[propertyName];
                });
            }

        }

        this.initialized.emit(this.componentRef);
    }
}