import { NgModule } from '@angular/core';

import { SidebarComponent } from '@core-layout/sidebar/sidebar.component';

@NgModule({
    declarations: [
        SidebarComponent
    ],
    exports: [
        SidebarComponent
    ]
})
export class SidebarModule { }