import { Injectable } from '@angular/core';
import * as Elmahio from 'elmah.io.javascript';

import { environment } from 'environments/environment';
import { ErrorHandlerBase } from './error.handler.base';

@Injectable({ providedIn: 'root' })
export class ElmahIoErrorHandler implements ErrorHandlerBase {

    private readonly logger: Elmahio;

    constructor() {
        this.logger = new Elmahio({
            apiKey: '4fda4397d4a34e25ba8b734d00462754',
            logId: environment.production
                ? '06f9a127-2b37-4bf8-b497-0ea12cb1d48e'
                : '2c87739c-c4c3-4757-958a-8ac88c69b22d'
        });

        this.logger.on('message', message => {
            if (localStorage != null && localStorage.tokens != null) {
                message.data.push({ key: 'X-ELMAHIO-SEARCH-Token', value: localStorage.tokens });
            }
        });
    }

    public isApplicable(error: Error): boolean {
        return error != null && error.message != null;
    }

    public handleError(error: Error): void {
        if (this.isApplicable(error)) {
            this.logger.error(error.message, error);
        } else {
            this.logger.error('Application error occured.', error);
        }
    }
}