<div class="default-image-wrap" [ngClass]="{'default-background-image' : useBackgroundImage || enableZoom }">

    <img *ngIf="catchedError" class="broken-image" [src]="defaultImageUrl" alt="">

    <img *ngIf="imageSrc != null && !catchedError" class="main-image" [ngClass]="{ 'main-image--default' : isDummy }"
        [src]="imageSrc" alt="" rpcOnLoad (loaded)="onImageLoaded()" (error)="onError($event)">

    <div *ngIf="catchedError && useBackgroundImage" class="broken-background-image"
        [ngStyle]="{ 'background-image': 'url(' + defaultImageUrl + ');', 'height': '100%' }">
    </div>

    <div *ngIf="!enableZoom && image != null && useBackgroundImage && !catchedError" class="main-background-image"
        [ngClass]="{ 'main-background-image--default' : isDummy }"
        [ngStyle]="{ 'background-image': 'url(' + image + ');', 'height': '100%' }">
    </div>

    <pinch-zoom class="main-zoom-img-wrap"
        *ngIf="useBackgroundImage && enableZoom && image != null && !catchedError && !isDummy" [disablePan]="true">
        <div class="main-background-image" [ngStyle]="{ 'background-image': 'url(' + image + ');' }"></div>
    </pinch-zoom>

    <pinch-zoom class="main-zoom-img-wrap"
        *ngIf="!useBackgroundImage && enableZoom && imageSrc != null && !catchedError && !isDummy" [disablePan]="true">
        <img class="main-zoom-img" [src]="imageSrc" alt="">
    </pinch-zoom>

    <custom-spinner *ngIf="enableSpinner && isImageLoading" class="default-image-spinner" [showImage]="false">
    </custom-spinner>
</div>