import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';

export const COUNTRIES = [
    new RpcSelectOption('AD', 'Andorra'),
    new RpcSelectOption('AE', 'United Arab Emirates'),
    new RpcSelectOption('AF', 'Afghanistan'),
    new RpcSelectOption('AG', 'Antigua and Barbuda'),
    new RpcSelectOption('AI', 'Anguilla'),
    new RpcSelectOption('AL', 'Albania'),
    new RpcSelectOption('AM', 'Armenia'),
    new RpcSelectOption('AO', 'Angola'),
    new RpcSelectOption('AQ', 'Antarctica'),
    new RpcSelectOption('AR', 'Argentina'),
    new RpcSelectOption('AS', 'American Samoa'),
    new RpcSelectOption('AT', 'Austria'),
    new RpcSelectOption('AU', 'Australia'),
    new RpcSelectOption('AW', 'Aruba'),
    new RpcSelectOption('AZ', 'Azerbaijan'),
    new RpcSelectOption('BA', 'Bosnia and Herzegovina'),
    new RpcSelectOption('BB', 'Barbados'),
    new RpcSelectOption('BD', 'Bangladesh'),
    new RpcSelectOption('BE', 'Belgium'),
    new RpcSelectOption('BF', 'Burkina Faso'),
    new RpcSelectOption('BG', 'Bulgaria'),
    new RpcSelectOption('BH', 'Bahrain'),
    new RpcSelectOption('BI', 'Burundi'),
    new RpcSelectOption('BJ', 'Benin'),
    new RpcSelectOption('BL', 'Saint Barthelemy'),
    new RpcSelectOption('BM', 'Bermuda'),
    new RpcSelectOption('BN', 'Brunei'),
    new RpcSelectOption('BO', 'Bolivia'),
    new RpcSelectOption('BR', 'Brazil'),
    new RpcSelectOption('BS', 'Bahamas, The'),
    new RpcSelectOption('BT', 'Bhutan'),
    new RpcSelectOption('BV', 'Bouvet Island'),
    new RpcSelectOption('BW', 'Botswana'),
    new RpcSelectOption('BY', 'Belarus'),
    new RpcSelectOption('BZ', 'Belize'),
    new RpcSelectOption('CA', 'Canada'),
    new RpcSelectOption('CC', 'Cocos (Keeling) Islands'),
    new RpcSelectOption('CD', 'Congo, Democratic Republic of the'),
    new RpcSelectOption('CF', 'Central African Republic'),
    new RpcSelectOption('CG', 'Congo, Republic of the'),
    new RpcSelectOption('CH', 'Switzerland'),
    new RpcSelectOption('CI', 'Cote d\'Ivoire'),
    new RpcSelectOption('CK', 'Cook Islands'),
    new RpcSelectOption('CL', 'Chile'),
    new RpcSelectOption('CM', 'Cameroon'),
    new RpcSelectOption('CN', 'China'),
    new RpcSelectOption('CO', 'Colombia'),
    new RpcSelectOption('CR', 'Costa Rica'),
    new RpcSelectOption('CU', 'Cuba'),
    new RpcSelectOption('CV', 'Cape Verde'),
    new RpcSelectOption('CW', 'Curacao'),
    new RpcSelectOption('CX', 'Christmas Island'),
    new RpcSelectOption('CY', 'Cyprus'),
    new RpcSelectOption('CZ', 'Czech Republic'),
    new RpcSelectOption('DE', 'Germany'),
    new RpcSelectOption('DJ', 'Djibouti'),
    new RpcSelectOption('DK', 'Denmark'),
    new RpcSelectOption('DM', 'Dominica'),
    new RpcSelectOption('DO', 'Dominican Republic'),
    new RpcSelectOption('DZ', 'Algeria'),
    new RpcSelectOption('EC', 'Ecuador'),
    new RpcSelectOption('EE', 'Estonia'),
    new RpcSelectOption('EG', 'Egypt'),
    new RpcSelectOption('EH', 'Western Sahara'),
    new RpcSelectOption('ER', 'Eritrea'),
    new RpcSelectOption('ES', 'Spain'),
    new RpcSelectOption('ET', 'Ethiopia'),
    new RpcSelectOption('FI', 'Finland'),
    new RpcSelectOption('FJ', 'Fiji'),
    new RpcSelectOption('FK', 'Falkland Islands (Islas Malvinas)'),
    new RpcSelectOption('FM', 'Micronesia, Federated States of'),
    new RpcSelectOption('FO', 'Faroe Islands'),
    new RpcSelectOption('FR', 'France'),
    new RpcSelectOption('FX', 'France, Metropolitan'),
    new RpcSelectOption('GA', 'Gabon'),
    new RpcSelectOption('GB', 'United Kingdom'),
    new RpcSelectOption('GD', 'Grenada'),
    new RpcSelectOption('GE', 'Georgia'),
    new RpcSelectOption('GF', 'French Guiana'),
    new RpcSelectOption('GG', 'Guernsey'),
    new RpcSelectOption('GH', 'Ghana'),
    new RpcSelectOption('GI', 'Gibraltar'),
    new RpcSelectOption('GL', 'Greenland'),
    new RpcSelectOption('GM', 'Gambia, The'),
    new RpcSelectOption('GN', 'Guinea'),
    new RpcSelectOption('GP', 'Guadeloupe'),
    new RpcSelectOption('GQ', 'Equatorial Guinea'),
    new RpcSelectOption('GR', 'Greece'),
    new RpcSelectOption('GS', 'South Georgia and the Islands'),
    new RpcSelectOption('GT', 'Guatemala'),
    new RpcSelectOption('GU', 'Guam'),
    new RpcSelectOption('GW', 'Guinea-Bissau'),
    new RpcSelectOption('GY', 'Guyana'),
    new RpcSelectOption('HK', 'Hong Kong'),
    new RpcSelectOption('HM', 'Heard Island and McDonald Islands'),
    new RpcSelectOption('HN', 'Honduras'),
    new RpcSelectOption('HR', 'Croatia'),
    new RpcSelectOption('HT', 'Haiti'),
    new RpcSelectOption('HU', 'Hungary'),
    new RpcSelectOption('ID', 'Indonesia'),
    new RpcSelectOption('IE', 'Ireland'),
    new RpcSelectOption('IL', 'Israel'),
    new RpcSelectOption('IM', 'Isle of Man'),
    new RpcSelectOption('IN', 'India'),
    new RpcSelectOption('IO', 'British Indian Ocean Territory'),
    new RpcSelectOption('IQ', 'Iraq'),
    new RpcSelectOption('IR', 'Iran'),
    new RpcSelectOption('IS', 'Iceland'),
    new RpcSelectOption('IT', 'Italy'),
    new RpcSelectOption('JE', 'Jersey'),
    new RpcSelectOption('JM', 'Jamaica'),
    new RpcSelectOption('JO', 'Jordan'),
    new RpcSelectOption('JP', 'Japan'),
    new RpcSelectOption('KE', 'Kenya'),
    new RpcSelectOption('KG', 'Kyrgyzstan'),
    new RpcSelectOption('KH', 'Cambodia'),
    new RpcSelectOption('KI', 'Kiribati'),
    new RpcSelectOption('KM', 'Comoros'),
    new RpcSelectOption('KN', 'Saint Kitts and Nevis'),
    new RpcSelectOption('KP', 'Korea, North'),
    new RpcSelectOption('KR', 'Korea, South'),
    new RpcSelectOption('KW', 'Kuwait'),
    new RpcSelectOption('KY', 'Cayman Islands'),
    new RpcSelectOption('KZ', 'Kazakhstan'),
    new RpcSelectOption('LA', 'Laos'),
    new RpcSelectOption('LB', 'Lebanon'),
    new RpcSelectOption('LC', 'Saint Lucia'),
    new RpcSelectOption('LI', 'Liechtenstein'),
    new RpcSelectOption('LK', 'Sri Lanka'),
    new RpcSelectOption('LR', 'Liberia'),
    new RpcSelectOption('LS', 'Lesotho'),
    new RpcSelectOption('LT', 'Lithuania'),
    new RpcSelectOption('LU', 'Luxembourg'),
    new RpcSelectOption('LV', 'Latvia'),
    new RpcSelectOption('LY', 'Libya'),
    new RpcSelectOption('MA', 'Morocco'),
    new RpcSelectOption('MC', 'Monaco'),
    new RpcSelectOption('MD', 'Moldova'),
    new RpcSelectOption('ME', 'Montenegro'),
    new RpcSelectOption('MF', 'Saint Martin'),
    new RpcSelectOption('MG', 'Madagascar'),
    new RpcSelectOption('MH', 'Marshall Islands'),
    new RpcSelectOption('MK', 'Macedonia'),
    new RpcSelectOption('ML', 'Mali'),
    new RpcSelectOption('MM', 'Burma'),
    new RpcSelectOption('MN', 'Mongolia'),
    new RpcSelectOption('MO', 'Macau'),
    new RpcSelectOption('MP', 'Northern Mariana Islands'),
    new RpcSelectOption('MQ', 'Martinique'),
    new RpcSelectOption('MR', 'Mauritania'),
    new RpcSelectOption('MS', 'Montserrat'),
    new RpcSelectOption('MT', 'Malta'),
    new RpcSelectOption('MU', 'Mauritius'),
    new RpcSelectOption('MV', 'Maldives'),
    new RpcSelectOption('MW', 'Malawi'),
    new RpcSelectOption('MX', 'Mexico'),
    new RpcSelectOption('MY', 'Malaysia'),
    new RpcSelectOption('MZ', 'Mozambique'),
    new RpcSelectOption('NA', 'Namibia'),
    new RpcSelectOption('NC', 'New Caledonia'),
    new RpcSelectOption('NE', 'Niger'),
    new RpcSelectOption('NF', 'Norfolk Island'),
    new RpcSelectOption('NG', 'Nigeria'),
    new RpcSelectOption('NI', 'Nicaragua'),
    new RpcSelectOption('NL', 'Netherlands'),
    new RpcSelectOption('NO', 'Norway'),
    new RpcSelectOption('NP', 'Nepal'),
    new RpcSelectOption('NR', 'Nauru'),
    new RpcSelectOption('NU', 'Niue'),
    new RpcSelectOption('NZ', 'New Zealand'),
    new RpcSelectOption('OM', 'Oman'),
    new RpcSelectOption('PA', 'Panama'),
    new RpcSelectOption('PE', 'Peru'),
    new RpcSelectOption('PF', 'French Polynesia'),
    new RpcSelectOption('PG', 'Papua New Guinea'),
    new RpcSelectOption('PH', 'Philippines'),
    new RpcSelectOption('PK', 'Pakistan'),
    new RpcSelectOption('PL', 'Poland'),
    new RpcSelectOption('PM', 'Saint Pierre and Miquelon'),
    new RpcSelectOption('PN', 'Pitcairn Islands'),
    new RpcSelectOption('PR', 'Puerto Rico'),
    new RpcSelectOption('PS', 'Gaza Strip'),
    new RpcSelectOption('PS', 'West Bank'),
    new RpcSelectOption('PT', 'Portugal'),
    new RpcSelectOption('PW', 'Palau'),
    new RpcSelectOption('PY', 'Paraguay'),
    new RpcSelectOption('QA', 'Qatar'),
    new RpcSelectOption('RE', 'Reunion'),
    new RpcSelectOption('RO', 'Romania'),
    new RpcSelectOption('RS', 'Serbia'),
    new RpcSelectOption('RU', 'Russia'),
    new RpcSelectOption('RW', 'Rwanda'),
    new RpcSelectOption('SA', 'Saudi Arabia'),
    new RpcSelectOption('SB', 'Solomon Islands'),
    new RpcSelectOption('SC', 'Seychelles'),
    new RpcSelectOption('SD', 'Sudan'),
    new RpcSelectOption('SE', 'Sweden'),
    new RpcSelectOption('SG', 'Singapore'),
    new RpcSelectOption('SH', 'Saint Helena, Ascension, and Tristan'),
    new RpcSelectOption('SI', 'Slovenia'),
    new RpcSelectOption('SJ', 'Svalbard'),
    new RpcSelectOption('SK', 'Slovakia'),
    new RpcSelectOption('SL', 'Sierra Leone'),
    new RpcSelectOption('SM', 'San Marino'),
    new RpcSelectOption('SN', 'Senegal'),
    new RpcSelectOption('SO', 'Somalia'),
    new RpcSelectOption('SR', 'Suriname'),
    new RpcSelectOption('SS', 'South Sudan'),
    new RpcSelectOption('ST', 'Sao Tome and Principe'),
    new RpcSelectOption('SV', 'El Salvador'),
    new RpcSelectOption('SX', 'Sint Maarten'),
    new RpcSelectOption('SY', 'Syria'),
    new RpcSelectOption('SZ', 'Swaziland'),
    new RpcSelectOption('TC', 'Turks and Caicos Islands'),
    new RpcSelectOption('TD', 'Chad'),
    new RpcSelectOption('TF', 'French Southern and Antarctic Lands'),
    new RpcSelectOption('TG', 'Togo'),
    new RpcSelectOption('TH', 'Thailand'),
    new RpcSelectOption('TJ', 'Tajikistan'),
    new RpcSelectOption('TK', 'Tokelau'),
    new RpcSelectOption('TL', 'Timor-Leste'),
    new RpcSelectOption('TM', 'Turkmenistan'),
    new RpcSelectOption('TN', 'Tunisia'),
    new RpcSelectOption('TO', 'Tonga'),
    new RpcSelectOption('TR', 'Turkey'),
    new RpcSelectOption('TT', 'Trinidad and Tobago'),
    new RpcSelectOption('TV', 'Tuvalu'),
    new RpcSelectOption('TW', 'Taiwan'),
    new RpcSelectOption('TZ', 'Tanzania'),
    new RpcSelectOption('UA', 'Ukraine'),
    new RpcSelectOption('UG', 'Uganda'),
    new RpcSelectOption('UM', 'United States Minor Outlying Islands'),
    new RpcSelectOption('US', 'United States'),
    new RpcSelectOption('UY', 'Uruguay'),
    new RpcSelectOption('UZ', 'Uzbekistan'),
    new RpcSelectOption('VA', 'Holy See (Vatican City)'),
    new RpcSelectOption('VC', 'Saint Vincent and the Grenadines'),
    new RpcSelectOption('VE', 'Venezuela'),
    new RpcSelectOption('VG', 'British Virgin Islands'),
    new RpcSelectOption('VI', 'Virgin Islands'),
    new RpcSelectOption('VN', 'Vietnam'),
    new RpcSelectOption('VU', 'Vanuatu'),
    new RpcSelectOption('WF', 'Wallis and Futuna'),
    new RpcSelectOption('WS', 'Samoa'),
    new RpcSelectOption('XK', 'Kosovo'),
    new RpcSelectOption('YE', 'Yemen'),
    new RpcSelectOption('YT', 'Mayotte'),
    new RpcSelectOption('ZA', 'South Africa'),
    new RpcSelectOption('ZM', 'Zambia')
];