export const locale = {
    lang: 'en',
    data: {
        PROMPT: {
            TITLES: {
                ANDROID: 'Add to Home screen',
                CLOSE: 'Close',
                IOS_1: 'To install this web app on your device tap the Menu button',
                IOS_2: 'and then \'Add to Home screen\'',
                IOS_3: 'button.'
            }
        }
    }
};