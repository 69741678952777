import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DateConstants } from '@core-constants/dates/date.constants';
import { NewMatchInfo } from '@listings/models/listing/new-match';
import { ListingActivityContribution } from '@portfolio/models/listing-activity-contribution';

@Component({
    selector: 'new-match-tooltip',
    templateUrl: './new-match-tooltip.component.html',
    styleUrls: ['./new-match-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewMatchTooltipComponent {

    @Input() public listingActivityContribution: ListingActivityContribution;

    public readonly dateConstants = DateConstants;

    public trackByNewMatchId(index: number, item: NewMatchInfo): number {
        return item.id;
    }
}
