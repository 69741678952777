import { Directive, HostBinding, Input } from '@angular/core';

import { ScrollConstants } from '@core-layout/scroll-to-top/scroll.constants';

@Directive({ selector: '[scrollToTopTarget]' })
export class ScrollToTopTargetDirective {

    @Input('scrollToTopTargetMobile') isMobileScroll: boolean;
    @HostBinding(`class.${ScrollConstants.ScrollToTopClasses.Target}`) get hostClass(): boolean {
        return !this.isMobileScroll || (this.isMobileScroll && window.matchMedia('screen and (max-width: 959.99px)').matches);
    }
}