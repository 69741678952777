export const locale = {
    lang: 'en',
    data: {
        SIMPLE_DIALOG: {
            BUTTONS: {
                OK: 'Ok',
                CANCEL: 'Cancel'
            },
            TITLES: {
                CONFIRM: 'Confirm'
            }
        }
    }
};