export const locale = {
    lang: 'en',
    data: {
        LAST_PRICE_CHANGE: {
            TITLES: {
                SHOW_PRICE_CHANGE: 'View Price Change',
                PRICE: 'Price',
                INCREASE: 'Increase',
                DECREASE: 'Decrease',
                FROM: 'From',
                BY: 'By'
            }
        }
    }
};