export const locale = {
    lang: 'en',
    data: {
        PHONE_FORM_INPUT: {
            VALIDATION: {
                PHONE_REQUIRED: 'Phone is required',
                WRONG_PHONE: 'Phone number format is wrong'
            },
            FIELDS: {
                PHONE: 'Phone',
                CODE: 'Code'
            },
            PLACEHOLDERS: {
                SEARCH: 'Select a country',
                NOT_FOUND: 'No matching country found'
            }
        }
    }
};