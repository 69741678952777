import { createSelector } from "@ngrx/store";

import { RpcRoute } from "@core-layout/app/models/rpc-route";
import { SYSTEM_FOLDERS_INFO, SYSTEM_FOLDERS_ID_TO_URL_NAME_MAP } from "@folders/constants/folder.constants";
import * as settingsSelector from "@settings/store/selectors/settings.selector";

export const selectPortfolioRouterLink = createSelector(
    settingsSelector.selectDefaultFolderId,
    defaultFolderId => {
        return defaultFolderId == null
            ? RpcRoute.Portfolio
            : defaultFolderId === SYSTEM_FOLDERS_INFO.ExternalListings.id
                ? RpcRoute.ExrernalListingsFullPath
                : [RpcRoute.Portfolio, SYSTEM_FOLDERS_ID_TO_URL_NAME_MAP.get(defaultFolderId) ?? defaultFolderId];
    }
);