import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Agent } from '@agents/models/agent';
import { Credential } from '@auth/models/credential';
import { EncryptedCredential } from '@auth/models/encrypted-credential';
import { EncryptedExactCustomerCredential } from '@auth/models/encrypted-exact-customer-credential';
import { Invitation } from '@auth/models/invitation';
import { User } from '@auth/models/user';
import * as loginActions from '@auth/store/actions/login.actions';
import * as invitationSelectors from '@auth/store/selectors/invitation.selector';
import * as userSelectors from '@auth/store/selectors/user.selector';

@Injectable({ providedIn: 'root' })
export class UserStoreService {

    constructor(
        private readonly store: Store<{}>
    ) { }

    public readonly isPrimaryCustomer$ = this.store.select(userSelectors.selectIsPrimaryCustomer);

    public readonly canSeeAdditionalProfiles$ = this.store.select(userSelectors.selectCanSeeAdditionalProfiles);

    public readonly customerProfilePictureUrl$ = this.store.select(userSelectors.selectCustomerProfilePictureUrl);

    public readonly customerId$ = this.store.select(userSelectors.selectCustomerId);

    public readonly customerCollaborationId$ = this.store.select(userSelectors.selectCustomerCollaborationId);

    public readonly customerFullName$ = this.store.select(userSelectors.selectCustomerFullName);

    public readonly selectLoginProcessData$ = this.store.select(userSelectors.selectLoginProcessData);

    public tryLogin(credential: Credential): void {
        this.store.dispatch(loginActions.tryLogin({ credential: credential }));
    }

    public login(credential: EncryptedExactCustomerCredential): void {
        this.store.dispatch(loginActions.login({ credential: credential }));
    }

    public setLoginProcessData(credentials: EncryptedCredential): void {
        this.store.dispatch(loginActions.setLoginProcessData({ credentials }));
    }

    public removeProcessLoginAction(): void {
        this.store.dispatch(loginActions.removeLoginProcessData());
    }

    public confirmLogin(user: User, duringLoginSync?: boolean): void {
        this.store.dispatch(loginActions.loginSuccessful(user, duringLoginSync));
    }

    public logout(forced: boolean = true, duringLoginSync: boolean = false, redirect: boolean = true): void {
        this.store.dispatch(loginActions.logout(forced, duringLoginSync, redirect));
    }

    public refresh(): void {
        this.store.dispatch(loginActions.refresh());
    }

    public getUser(): Observable<User> {
        return this.store.select(userSelectors.selectUser);
    }

    public getCustomerFullName(): Observable<string> {
        return this.store.pipe(select(userSelectors.selectCustomerFullName));
    }

    public getCustomerProfileImage(): Observable<string> {
        return this.store.pipe(select(userSelectors.selectCustomerProfileImage));
    }

    public getAgent(): Observable<Agent> {
        return this.store.pipe(select(userSelectors.selectAgent));
    }

    public getCompanyId(): Observable<number> {
        return this.store.pipe(select(userSelectors.selectCompanyId));
    }

    public getAgentFullName(): Observable<string> {
        return this.store.pipe(select(userSelectors.selectAgentFullName));
    }

    public getAgentProfileImage(): Observable<string> {
        return this.store.pipe(select(userSelectors.selectAgentProfileImage));
    }

    public getAccessToken(): Observable<string> {
        return this.store.pipe(select(userSelectors.selectAccessToken));
    }

    public getIsAuthorized(): Observable<boolean> {
        return this.store.pipe(select(userSelectors.isAuthorized));
    }

    public getUserInvitation(): Observable<Invitation> {
        return this.store.pipe(select(invitationSelectors.selectInvitation), filter(invitation => invitation != null));
    }
}