<div class="mat-custom-header">
  <div class="mat-custom-controls">

    <div class="mat-custom-date" *ngIf="calendar.type === 'year'">
      <button mat-button type="button" class="mat-custom-date-year mat-custom-control"
      (click)="switchToView('multi-year')"
      [@controlActive]="isControlActive(['multi-year']) ? 'active' : ''"
      [class.mat-custom-control-active]="isControlActive(['multi-year'])"
      [attr.aria-label]="_intl.switchToMultiYearViewLabel">
        <span>{{ _yearButtonText }}</span>
      </button>
    </div>

    <div class="mat-custom-date" *ngIf="calendar.type === 'month'">
      <button mat-button type="button" class="mat-custom-date-month mat-custom-control"
      (click)="switchToView('year')"
      [@controlActive]="isControlActive(['year']) ? 'active' : ''"
      [class.mat-custom-control-active]="isControlActive(['year'])"
      [attr.aria-label]="_intl.switchToYearViewLabel">
        <span>{{ _monthButtonText }}</span>
      </button>

      <button mat-button type="button" class="mat-custom-date-year mat-custom-control"
      (click)="switchToView('multi-year')"
      [@controlActive]="isControlActive(['multi-year']) ? 'active' : ''"
      [class.mat-custom-control-active]="isControlActive(['multi-year'])"
      [attr.aria-label]="_intl.switchToMultiYearViewLabel">
        <span>{{ _yearButtonText }}</span>
      </button>
    </div>

    <div class="mat-custom-date" *ngIf="calendar.hasOutput('date')">
      <button mat-button type="button" class="mat-custom-date-year mat-custom-control"
      (click)="switchToView('multi-year')"
      [@controlActive]="isControlActive(['multi-year']) ? 'active' : ''"
      [class.mat-custom-control-active]="isControlActive(['multi-year'])"
      [attr.aria-label]="_intl.switchToMultiYearViewLabel">
        <span>{{ _yearButtonText }}</span>
      </button>

      <button mat-button type="button" class="mat-custom-date-monthday mat-custom-control"
      (click)="monthdayClicked()"
      [@controlActive]="isControlActive(['month', 'year']) ? 'active' : ''"
      [class.mat-custom-control-active]="isControlActive(['month', 'year'])"
      [attr.aria-label]="monthdayButtonLabel">
        <span class="mat-custom-date-year-dayname">{{ _dayButtonText }}&nbsp;</span>
        <span class="mat-custom-date-year-monthday">{{ _monthdayButtonText }}</span>
      </button>
    </div>

    <div class="mat-custom-time" *ngIf="calendar.hasOutput('time')">
      <div class="mat-custom-time-hour">
        <button mat-button type="button" class="mat-custom-time-hour mat-custom-control"
        (click)="switchToView('hour')"
        [@controlActive]="isControlActive(['hour']) ? 'active' : ''"
        [class.mat-custom-control-active]="isControlActive(['hour'])"
        [attr.aria-label]="_intl.switchToHourViewLabel">
          <span>{{ _hourButtonText }}</span>
        </button>

        <span class="mat-custom-separator">:</span>

        <button mat-button type="button" class="mat-custom-time-minute mat-custom-control"
        [@controlActive]="isControlActive(['minute']) ? 'active' : ''"
        [class.mat-custom-control-active]="isControlActive(['minute'])"
        (click)="switchToView('minute')"
        [attr.aria-label]="_intl.switchToMinuteViewLabel">
          <span>{{ _minuteButtonText }}</span>
        </button>

      </div>
      <div class="mat-custom-time-ampm" *ngIf="calendar.twelveHour">
        <button mat-button type="button" class="mat-calendar-control"
        [@controlActive]="_isAM ? 'active' : ''"
        [class.mat-custom-control-active]="_isAM"
        [attr.aria-label]="_intl.setToAMLabel"
        (click)="toggleAmPm(true)">
          AM
        </button>
        <button mat-button type="button" class="mat-calendar-control"
        [@controlActive]="!_isAM ? 'active' : ''"
        [class.mat-custom-control-active]="!_isAM"
        [attr.aria-label]="_intl.setToPMLabel"
        (click)="toggleAmPm(false)">
          PM
        </button>
      </div>
    </div>

  </div>
  <div class="mat-custom-prev-next" *ngIf="hasPrevNextBlock()">

    <button mat-icon-button type="button"
            class="mat-calendar-previous-button"
            [disabled]="!previousEnabled()"
            (click)="previousClicked()"
            [attr.aria-label]="prevButtonLabel">
    </button>

    <button mat-button type="button" class="mat-custom-period mat-custom-control"
    disableRipple="true"
    (click)="currentPeriodClicked()"
    [disabled]="currentPeriodDisabled()"
    [attr.aria-label]="periodButtonLabel">
      <strong>{{ periodButtonText }}</strong>
    </button>

    <button mat-icon-button type="button"
            class="mat-calendar-next-button"
            [disabled]="!nextEnabled()"
            (click)="nextClicked()"
            [attr.aria-label]="nextButtonLabel">
    </button>

  </div>
</div>
