export const locale = {
    lang: 'en',
    data: {
        APPOINTMENT_FORM: {
            PLACEHOLDERS: {
                DAY: 'Day',
                START_TIME: 'Start Time',
                END_TIME: 'End Time',
                COMMENT: 'Comment'
            },
            VALIDATION_ERRORS: {
                DATE_TIME_REQUIRED: 'Appointment date and time must be selected',
                PAST_TIME: 'You cannot request an appointment that takes place in past'
            },
            BUTTONS: {
                SEND: 'SEND',
                UPDATE: 'UPDATE',
                CANCEL: 'CANCEL'
            },
            TITLES: {
                SCHEDULE: 'Schedule Appointment',
                CANCEL: 'Cancel'
            }
        }
    }
};
