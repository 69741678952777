export const locale = {
    lang: 'en',
    data: {
        REMOVED_ACTIVITY_CONTROLS: {
            TITLES: {
                RESTORE: 'Restore',
                DELETE: 'Delete'
            }
        }
    }
};