import { AppointmentStatus } from "@appointments/enums/appointment-status.enum";
import { ListingActivityConstants } from "@listings/constants/listing-activity-constants";
import { GraphqlAppointmentStatus } from "@notifications/models/notification-api-item";
import { ListingActivityAction } from "app/graphql/graphql";

export const ACTIVITY_NOTIFICATION_ACTION_MAP: { [key: number]: ListingActivityAction } = {
    [ListingActivityConstants.PickListed.id]: ListingActivityAction.Picked,
    [ListingActivityConstants.Liked.id]: ListingActivityAction.Liked,
    [ListingActivityConstants.Disliked.id]: ListingActivityAction.Disliked,
    [ListingActivityConstants.Shown.id]: ListingActivityAction.Shown
};

export const APPOINTMENT_STATUS_MAP = new Map<GraphqlAppointmentStatus, AppointmentStatus>([
    ['PENDING', AppointmentStatus.Pending],
    ['CONFIRMED', AppointmentStatus.Confirmed],
    ['DECLINED', AppointmentStatus.Declined],
    ['SHOWN', AppointmentStatus.Shown],
]);
