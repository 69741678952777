import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
    selector: 'prompt',
    templateUrl: './prompt.component.html',
    styleUrls: ['./prompt.component.scss']
})
export class PromptComponent {

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: 'ios' | 'android', promptEvent?: any },
        private readonly bottomSheetRef: MatBottomSheetRef<PromptComponent>
    ) { }

    public installPwa(): void {
        this.data.promptEvent.prompt();
        this.close();
    }

    public close(): void {
        this.bottomSheetRef.dismiss();
    }
}