import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AvatarInfo } from '@users/models/avatar-info';

@Component({
    selector: 'avatars-icon',
    templateUrl: './avatars-icon.component.html',
    styleUrls: ['./avatars-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarsIconComponent {
    @Input() public front: AvatarInfo;
    @Input() public back: AvatarInfo | null;
    @Input() public displayIcon = false;
    @Input() public iconName: 'star' | 'shared-space' = 'star';
}
