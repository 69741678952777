import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export const getMoreThanTimeValidator = (moreThanControlName: string): ((control: AbstractControl) => ValidationErrors | null) => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        if (currentControl == null || currentControl.parent == null) {
            return null;
        }

        const moreThanControl = currentControl.parent.get(moreThanControlName);
        if (moreThanControl == null) {
            return null;
        }

        if (currentControl.value == null || moreThanControl.value == null) {
            return null;
        }

        if (moment(currentControl.value).isAfter(moreThanControl.value, 'minute')) {
            return null;
        }

        return { moreThanTime: true };
    };
};
