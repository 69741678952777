import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidatorsMapItem } from '@core-controls/models/validators-map-item';
import { DropZoneDataTransfer } from '@core-directives/drop-zone/drop-zone-data-transfer';
import { ToastService } from '@core-services/toast.service';
import { ValidationService } from '@core-validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ByteFormatPipe } from 'ngx-material-file-input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RpcFileUploadOptions } from './models/rpc-file-upload-options';
import { UploadFile } from './models/upload-file';
import { UploadedFileState } from './models/uploaded-file-state';

@Component({
    selector: 'rpc-file-upload',
    templateUrl: './rpc-file-upload.component.html',
    styleUrls: ['./rpc-file-upload.component.scss']
})
export class RpcFileUploadComponent implements OnInit, OnDestroy {

    @Input() public options: RpcFileUploadOptions;
    @Input() public title: string;

    @Output() public fileStateChanged = new EventEmitter<UploadedFileState>();

    private readonly unsubscribe$ = new Subject<void>();
    private translatedFailToDownloadFileMessage: string;
    private translatedFileTypeValidationMessage: string;
    private translatedMaxSizeValidationMessage: string;

    public fileData: string;
    public formControl: FormControl;
    public validatorsMap: ValidatorsMapItem[];
    public accept: string;
    public uploadError = false;

    constructor(
        private readonly validationService: ValidationService,
        private readonly translateService: TranslateService,
        private readonly toaster: ToastService,
    ) {
        this.translateService.get('PRC_FILE_UPLOAD.ERRORS.FILE_FETCH_ERROR')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((translation: string) => this.translatedFailToDownloadFileMessage = translation);

        this.translateService.get('PRC_FILE_UPLOAD.VALIDATION.WRONG_FILE_FORMAT')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((translation: string) => this.translatedFileTypeValidationMessage = translation);

        this.translateService.get('PRC_FILE_UPLOAD.VALIDATION.MAX_SIZE')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((translation: string) => this.translatedMaxSizeValidationMessage = translation);
    }

    public ngOnInit(): void {
        if (this.options == null || this.options.formGroup == null || this.options.controlName == null || this.options.file == null) {
            throw new Error('Wrong file upload control configuration. Please, provide proper options configuration.');
        }

        this.validatorsMap = this.options.validatorsMap == null
            ? this.getValidators(this.options.file)
            : [...this.options.validatorsMap, ...this.getValidators(this.options.file)];

        const validators = this.validatorsMap?.filter(validationItem => validationItem.validator != null).map(validationItem => validationItem.validator);

        this.formControl = new FormControl(this.options.file.fileUrl, validators);
        this.options.formGroup.addControl(this.options.controlName, this.formControl);
        this.accept = this.getAccept(this.options.file);

        if (this.options.file.fileUrl != null) {
            this.fileData = this.options.file.fileUrl;
        }

        if (this.options.fileUrlInput != null) {
            this.options.fileUrlInput
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(url => this.setFileDataAsUrl(url, true));
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public uploadFile(files: FileList): void {
        const file = files[0];
        this.setFileDataAsFileBlob(file);
    }

    public deleteImage(event: Event): void {
        event.stopPropagation();
        this.fileData = null;
        this.formControl.setValue(null);
        this.fileStateChanged.emit({ kind: 'deleted' });
    }

    public onDrop(data: DropZoneDataTransfer): void {
        if (data.fileContent != null && data.fileContent.length > 0) {
            this.setFileDataAsFileBlob(data.fileContent[0]);
        } else if (data.stringContent != null && data.stringContent.length > 0) {
            this.setFileDataAsUrl(data.stringContent[0], false);
        }
    }

    private getAccept(file: UploadFile): string {
        switch (file.kind) {
            case 'image':
                return file.supportedFileTypes.map(t => 'image/' + t).join(',');
        }
        return '*/*';
    }

    private getValidators(file: UploadFile): ValidatorsMapItem[] {
        const validators: ValidatorsMapItem[] = [];

        if (file.supportedFileTypes != null && file.supportedFileTypes.length > 0) {
            validators.push({
                message: this.translatedFileTypeValidationMessage + ': ' + file.supportedFileTypes.join(', '),
                showError: (control: FormControl) => control.hasError('fileType'),
                validator: this.validationService.fileTypeValidator(file.supportedFileTypes, true)
            });
        }
        if (file.sizeLimit != null) {
            validators.push({
                message: this.translatedMaxSizeValidationMessage + ' ' + (new ByteFormatPipe({ sizeUnit: 'Bytes' }).transform(file.sizeLimit) as string),
                showError: (control: FormControl) => control.hasError('maxFileSize'),
                validator: this.validationService.maxFileSizeValidator(file.sizeLimit)
            });
        }
        return validators;
    }

    private setFileDataAsUrl(url: string, fromUrlInput: boolean): void {
        if (url == null) {
            return;
        }
        this.formControl.setValue(url);
        if (!this.formControl.valid) {
            return;
        }

        if (this.options.file.kind === 'image') {
            this.isImageExists(url)
                .then(() => this.fileExists(url, fromUrlInput), _ => this.fileDoesNotExist());
        }
    }

    private setFileDataAsFileBlob(file: File): void {
        if (file == null) {
            return;
        }
        this.formControl.setValue(file);
        if (!this.formControl.valid) {
            return;
        }
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            this.fileData = reader.result.toString();
            this.uploadError = false;
            this.fileStateChanged.emit({ kind: 'file', file: file, base64EncodedDataUrl: this.fileData });
        });

        reader.addEventListener('error', error => {
            this.uploadError = true;
        });

        reader.readAsDataURL(file);
    }

    private isImageExists(imageUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(true);
            image.onerror = () => reject(false);
            image.src = imageUrl;
        });
    }

    private fileExists(url: string, fromUrlInput: boolean): void {
        this.fileData = url;
        this.fileStateChanged.emit({ kind: 'url', fileUrl: url, fromUrlInput: fromUrlInput });
    }

    private fileDoesNotExist(): void {
        this.toaster.showClientError(this.translatedFailToDownloadFileMessage);
    }
}