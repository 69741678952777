import { Injectable } from '@angular/core';

import { DELETED_USER_BASE_INFO } from '@users/constants/deleted-user.constants';
import { UserBaseInfo } from '@users/models/user-base-info';

@Injectable({ providedIn: 'root' })
export class UsersService {
    public static getUserInfoOrRemovedAccount(users: UserBaseInfo[], userId: number): UserBaseInfo {
        return users.find(user => user.customerId === userId) ?? DELETED_USER_BASE_INFO;
    }
}
