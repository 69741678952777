<ul *ngIf="listing" class="listing-labels-wrap" [ngClass]="{'listing-labels-wrap--include-text' : includeText}">
    <li *ngIf="showNewlyAddedTag && !listing.isNewViewed"
        title="{{ 'LISTING_LABELS.NEWLY_ADDED_BY_AGENT.LONG_TITLE' | translate }}">
        <div class="listing-labels-item nl">
            <rpc-icon class="listing-labels-item-icon" [iconName]="'na-label'"></rpc-icon>
            <div *ngIf="includeText" class="listing-labels-item-text">
                {{ 'LISTING_LABELS.NEWLY_ADDED_BY_AGENT.LONG_TITLE' | translate }}
            </div>
        </div>
    </li>
    <li *ngIf="listing.isNewMatch">
        <div class="listing-labels-item nm">

            <rpc-icon class="listing-labels-item-icon" *ngIf="isMobile$ | async;else desktopNewMatchLabel"
                [iconName]="'star'" rpcTooltip [customClass]="'rpc-nm-short-tooltip'" [trigger]="customTriggerName"
                [component]="NewMatchTooltipComponent" [componentScopeData]="newMatchTooltipData" rpcClickHandler
                (click)="onMobileClick()"></rpc-icon>

            <ng-template #desktopNewMatchLabel>
                <rpc-icon class="listing-labels-item-icon" [iconName]="'star'" rpcTooltip
                    [customClass]="'rpc-nm-short-tooltip'" [component]="NewMatchTooltipComponent"
                    [componentScopeData]="newMatchTooltipData"></rpc-icon>
            </ng-template>
        </div>
    </li>
    <li *ngIf="listing.openHouses?.length > 0" title="{{ 'LISTING_LABELS.OPEN_HOUSE.LONG_TITLE' | translate }}">
        <div mat-button class="listing-labels-item oh" [matMenuTriggerFor]="openHouse" (click)="menuOpened()">
            <rpc-icon class="listing-labels-item-icon" [iconName]="'oh-label'"></rpc-icon>
            <div *ngIf="includeText" class="listing-labels-item-text">
                {{ 'LISTING_LABELS.OPEN_HOUSE.LONG_TITLE' | translate }}
            </div>
        </div>
        <mat-menu #openHouse="matMenu" [overlapTrigger]="false" class="listing-labels-dropdown">
            <div class="listing-labels-menu-oh" (click)="$event.stopPropagation()">
                <div class="listing-labels-menu-oh-main">
                    <span>{{ 'LISTING_LABELS.OPEN_HOUSE.NEXT' | translate }} {{ 'LISTING_LABELS.OPEN_HOUSE.LONG_TITLE' | translate }}:</span>
                </div>
                <div>
                    {{ listing.openHouses[0].startDate | date : DateConstants.Formats.AngularPipeShortDateSlash }}
                </div>
                <div>
                    <span>
                        {{ listing.openHouses[0].startDate | date: 'h:mm' }} -
                        {{ listing.openHouses[0].endDate | date: 'h:mm' }}
                    </span>
                    <span>
                        {{listing.openHouses[0].comment}}
                    </span>
                </div>
            </div>
        </mat-menu>
    </li>
    <li class="listing-labels-item-wrap--type"
        title="{{ 'LISTING_LABELS.CATEGORY.LONG.' + listing.category.toUpperCase() | translate }}">
        <div class="listing-labels-item"
            [ngClass]="listing.category.toUpperCase() === ListingCategory.Sales ? 's' : 'r'">
            <rpc-icon class="listing-labels-item-icon"
                [iconName]="listing.category === 'sales' ? 's-label' : 'r-label'"></rpc-icon>
            <div *ngIf="includeText" class="listing-labels-item-text">
                {{ 'LISTING_LABELS.CATEGORY.LONG.' + listing.category.toUpperCase() | translate }}
            </div>
        </div>
    </li>
</ul>