import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SplashScreenService {

    private static readonly SplashScreenElementId = '#splash-screen';
    private static readonly ForceHideDelayDelay = 4000;

    private splashScreenElement: HTMLElement;
    private animationPlayer: AnimationPlayer;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly animationBuilder: AnimationBuilder,
        private readonly router: Router
    ) {
        this.initialize();
    }

    public show(): void {
        this.animationPlayer = this.animationBuilder
            .build([
                style({ opacity: '0', zIndex: '99999' }),
                animate('400ms ease', style({ opacity: '1' }))
            ])
            .create(this.splashScreenElement);

        setTimeout(() => { this.animationPlayer.play(); });
    }

    public hide(): void {
        this.animationPlayer = this.animationBuilder
            .build([
                style({ opacity: '1' }),
                animate('400ms ease', style({ opacity: '0', zIndex: '-10' }))
            ]).create(this.splashScreenElement);

        setTimeout(() => { this.animationPlayer.play(); });
    }

    private initialize(): void {
        if (this.document.readyState === 'loading') {
            this.document.onreadystatechange = () => {
                if (this.document.readyState === 'interactive') {
                    this.initializeSplashScreenHideEvent();
                }
            };
        } else {
            this.initializeSplashScreenHideEvent();
        }
    }

    // TODO : Implemented in scope of RPC-463. Infinite splash screen should be deeply investigated to avoid force hide.
    private initializeSplashScreenHideEvent(): void {
        let isSplashScreenHidden = false;
        this.splashScreenElement = this.document.body.querySelector(SplashScreenService.SplashScreenElementId);

        if (this.splashScreenElement != null) {
            const splashScreenHideTrigger = () => {
                if (!isSplashScreenHidden) {
                    isSplashScreenHidden = true;
                    this.hide();
                }
            };

            this.router.events
                .pipe(filter((event => event instanceof NavigationEnd)), take(1))
                .subscribe(() => setTimeout(splashScreenHideTrigger));

            setTimeout(splashScreenHideTrigger, SplashScreenService.ForceHideDelayDelay);
        }
    }
}