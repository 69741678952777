export const locale = {
    lang: 'en',
    data: {
        MULTI_ACTIVITY_CONTROLS: {
            TITLES: {
                PICK_LISTED: 'Pick Listed',
                LIKED: 'Like',
                SHOWN: 'Shown',
                DISLIKED: 'Dislike',
                CURRENT_LISTING: 'Current listing',
                ALL_SELECTED_LISTINGS: 'All selected listings'
            }
        }
    }
};