import { AgentCandidate } from '@agents/models/agent-candidate';
import { EncryptedCredential } from '@auth/models/encrypted-credential';
import { Invitation } from '@auth/models/invitation';
import { User } from '@auth/models/user';
import { TokenService } from '@auth/services/token.service';

export class AuthState {
    user: User;
    invitation: Invitation;
    inviteCode: string;
    restoreCode: string;
    loginProcessData: EncryptedCredential;
    collaborationSpaces: AgentCandidate[] = [];
    hasMultipleCollaborationSpaces = false;

    constructor() {
        this.user = TokenService.restoreCurrentUser();
        this.invitation = null;
        this.inviteCode = '';
        this.restoreCode = '';
        this.loginProcessData = null;
    }
}
