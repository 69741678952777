import uniqBy from 'lodash-es/uniqBy';

import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { Creator } from '@appointments/enums/creator.enum';
import { ListingsAppointments } from '@appointments/models/appointments/listings-appointments';
import { AppointmentStatusService } from '@appointments/store/services/appointment-status.service';
import { CreateOperationListingComment } from '@comments/models/comments/listing-comment';
import { ListingsComments } from '@comments/models/comments/listings-comments';
import { SYSTEM_FOLDERS_INFO, SYSTEM_FOLDERS_WITH_ACTIVITY_PANEL } from '@folders/constants/folder.constants';
import { FolderActivityPanelInfo } from '@folders/models/folder-activity-panel-info';
import { FolderDetails } from '@folders/models/folder-details';
import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { CommonListing } from '@listings/models/listing/common-listing';

export class FolderActivityPanelBuilder {
    public static getFoldersActivityPanelInfoMap(
        allFolders: FolderDetails[],
        foldersListings: Map<number, CommonListing[]>,
        externalListingsCount: number,
        newExternalListingsCount: number,
        listingsComments: ListingsComments,
        listingsAppointments: ListingsAppointments,
        currentUserId: number
    ): Map<number, FolderActivityPanelInfo> {
        return allFolders.reduce(
            (map, { id, isSystemFolder }) => {
                const folderListings = foldersListings.has(id) ? foldersListings.get(id) : [];
                const hasActivityPanel = FolderActivityPanelBuilder.hasActivityPanel(id, isSystemFolder);
                const listingsCount = FolderActivityPanelBuilder.getTotalListingsCount(id, folderListings, externalListingsCount);

                if (!hasActivityPanel) {
                    return map.set(id, {
                        hasActivityPanel,
                        listingsCount,
                        hasNewListings: SYSTEM_FOLDERS_INFO.ExternalListings.id === id && newExternalListingsCount > 0
                    });
                }

                return map.set(id, {
                    hasActivityPanel,
                    listingsCount,
                    hasNewListings: FolderActivityPanelBuilder.hasNewListings(folderListings),
                    commentsCount: FolderActivityPanelBuilder.getCommentsCount(folderListings, listingsComments),
                    hasNewComments: FolderActivityPanelBuilder.hasNewComments(folderListings, listingsComments, currentUserId),
                    upcomingAppointmentCount: FolderActivityPanelBuilder.getUpcomingAppointmentsCount(folderListings, listingsAppointments),
                    hasNewAppointments: FolderActivityPanelBuilder.hasNewAppointments(folderListings, listingsAppointments),
                    likedListingsCount: FolderActivityPanelBuilder.getLikedListingsCount(folderListings),
                    hasNewLikedListings: FolderActivityPanelBuilder.hasNewLikedListings(folderListings),
                    newMatchesCount: FolderActivityPanelBuilder.getNewMatchesCount(folderListings)
                });
            },
            new Map<number, FolderActivityPanelInfo>()
        );
    }

    private static getTotalListingsCount(
        folderId: number,
        listings: CommonListing[],
        externallListingsCount: number
    ): number {
        if (folderId === SYSTEM_FOLDERS_INFO.ExternalListings.id) {
            return externallListingsCount;
        }

        return listings.filter(listing => listing.isNewMatch || listing.activities.length > 0).length;
    }

    private static getCommentsCount(listings: CommonListing[], listingsComments: ListingsComments): number {
        return listings.reduce((sum, { hashCode }) => sum + (listingsComments[hashCode]?.length ?? 0), 0);
    }

    private static getUpcomingAppointmentsCount(listings: CommonListing[], listingsAppointments: ListingsAppointments): number {
        return listings.reduce(
            (sum, { hashCode }) => {
                const upcomingAppointments = listingsAppointments[hashCode]?.filter(appointment => {
                    const status = AppointmentStatusService.calculateTimeDependentStatus(appointment);
                    return status !== AppointmentStatus.Declined && status !== AppointmentStatus.Shown;
                }) ?? [];

                return sum + upcomingAppointments.length;
            },
            0
        );
    }

    private static getLikedListingsCount(listings: CommonListing[]): number {
        return listings.filter(({ activities }) => activities.some(({ id }) => id === ListingActivityConstants.Liked.id)).length;
    }

    private static getNewMatchesCount(listings: CommonListing[]): number {
        return listings.filter(({ isNewMatch }) => isNewMatch).length;
    }

    private static hasNewListings(listings: CommonListing[]): boolean {
        return listings.some(({ isNewMatch, activities, isNewViewed }) => (isNewMatch || activities.length > 0) && !isNewViewed);
    }

    private static hasNewComments(
        listings: CommonListing[],
        listingsComments: ListingsComments,
        currentUserId: number
    ): boolean {
        return listings.some(({ hashCode }) => {
            const comments = listingsComments[hashCode] ?? [];

            return uniqBy(comments, x => x.id || (x as CreateOperationListingComment).operationId)
                .some(comment => comment.createId !== currentUserId && !comment.viewed);
        });
    }

    private static hasNewAppointments(listings: CommonListing[], listingsAppointments: ListingsAppointments): boolean {
        return listings.some(({ hashCode }) => AppointmentStatusService
            .filterByStatus(listingsAppointments[hashCode], AppointmentStatus.Pending)
            .some(appointment => appointment.creator === Creator.Agent));
    }

    private static hasNewLikedListings(listings: CommonListing[]): boolean {
        return listings.some(({ activities, isNewViewed }) => activities.some(({ id }) => id === ListingActivityConstants.Liked.id) && !isNewViewed);
    }

    private static hasActivityPanel(id: number, isSystemFolder: boolean): boolean {
        return !isSystemFolder || SYSTEM_FOLDERS_WITH_ACTIVITY_PANEL.includes(id);
    }
}