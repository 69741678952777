import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ActionError } from '@core-models/errors/action-error';
import { NewMatchSettingsService } from '@profile/services/new-match-settings.service';
import { FeatureCollection } from '@settings/models/map/feature-collection';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { OnMarketSettings } from '@settings/models/settings/on-market-settings';
import * as settingsActions from '@settings/store/actions/settings.actions';
import * as settingsSelectors from '@settings/store/selectors/settings.selector';
import { SettingsState } from '@settings/store/states/settings.state';
import { NewMatchSettingsModel } from 'app/modules/user-modules/profile-modules/profile-base/interfaces/new-match-settings/new-match-settings.model';
import { SettingsActionErrors } from '../enums/settings-action-errors';

@Injectable({ providedIn: 'root' })
export class SettingsStoreService {

    constructor(
        private readonly store: Store<SettingsState>,
        private readonly newMatchSettingsService: NewMatchSettingsService
    ) { }

    public readonly isDarkMode$ = this.store.select(settingsSelectors.selectModeInfo);
    public readonly defaultFolderId$ = this.store.select(settingsSelectors.selectDefaultFolderId);
    public readonly permissionSettings$ = this.store.select(settingsSelectors.selectPermissionSettings);
    public readonly isShowSystemFolders$ = this.store.select(settingsSelectors.selectIsShowSystemFolders);
    public readonly canSearchForListings$ = this.store.select(settingsSelectors.selectCanSearchForListings);

    public getSettingsError(): Observable<ActionError<SettingsActionErrors>> {
        return this.store.select(settingsSelectors.selectSettingsError).pipe(filter(error => error != null));
    }

    public removeSettingsError(): void {
        this.store.dispatch(settingsActions.removeSettingsError());
    }

    public getSettings(): Observable<CustomerSettings> {
        return this.store.select(settingsSelectors.selectSettings)
            .pipe(filter(settings => settings != null));
    }

    public getNewMatchSettings(): Observable<NewMatchSettingsModel> {
        return this.store
            .select(settingsSelectors.selectNewMatchSettings)
            .pipe(map(settingsValue => this.newMatchSettingsService.createSettings(settingsValue)));
    }

    public getOnMarketSettings(): Observable<OnMarketSettings> {
        return this.store.select(settingsSelectors.selectOnMarketSettings);
    }

    public getNeighborhoodsMapping(): Observable<FeatureCollection> {
        return this.store.select(settingsSelectors.selectNeighborhoodsMapping);
    }

    public loadSettings(): void {
        this.store.dispatch(settingsActions.loadSettings());
    }

    public loadNewMatchSettings(): void {
        this.store.dispatch(settingsActions.loadNewMatchSettings());
    }

    public loadNeighborhoodsMapping(): void {
        this.store.dispatch(settingsActions.loadNeighborhoodsMapping());
    }

    public updateSettings(newSettings: CustomerSettings, oldSettings: CustomerSettings): void {
        this.store.dispatch(settingsActions.updateSettings({ newSettings, oldSettings }));
    }

    public saveSettings(settings: CustomerSettings, isInitialSave: boolean): void {
        this.store.dispatch(settingsActions.saveLoadedSettings(settings, isInitialSave));
    }

    public updateNewMatchSettings(settings: NewMatchSettingsModel): void {
        const request = this.newMatchSettingsService.formatRequest(settings);
        this.store.dispatch(settingsActions.updateNewMatchSettings({ request }));
    }

    public changeSystemFoldersVisibility(): void {
        this.store.dispatch(settingsActions.changeSystemFoldersVisibility());
    }
}