<div class="mat-clock" [style.width.px]="_size" [style.height.px]="_size">
  <div class="mat-clock-center"></div>
  <div class="mat-clock-hand" [ngStyle]="_hand"></div>

  <div class="mat-clock-hours" [class.active]="inHourView">
    <div *ngFor="let item of _hours"
      class="mat-clock-cell"
      [ngClass]="item.cssClasses"
      [class.mat-clock-cell-selected]="_selectedHour == item.value"
      [class.mat-clock-cell-disabled]="!item.enabled"
      [style.top.%]="item.top"
      [style.left.%]="item.left"
      [style.fontSize]="item.fontSize">
      {{ item.displayValue }}
    </div>
  </div>

  <div class="mat-clock-minutes" [class.active]="!inHourView">
    <div *ngFor="let item of _minutes"
      class="mat-clock-cell"
      [ngClass]="item.cssClasses"
      [class.mat-clock-cell-selected]="_selectedMinute == item.value"
      [class.mat-clock-cell-disabled]="!item.enabled"
      [style.top.%]="item.top"
      [style.left.%]="item.left">
      {{ item.displayValue }}
    </div>
  </div>
</div>
