export enum ListingStatusTexts {
    Active = 'ACTIVE',
    ContractSigned = 'CONTRACT SIGNED',
    PermanentlyOffMarket = 'PERMANENTLY OFF MARKET',
    Sold = 'SOLD',
    LeaseSigned = 'LEASE SIGNED',
    Rented = 'RENTED',
    OffMarket = 'OFF MARKET',
    Expired = 'EXPIRED',
    Future = 'FUTURE',
    Unknown = 'UNKNOWN'
}