import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CommonListing } from '@listings/models/listing/common-listing';
import { ListingInfo } from '@listings/models/listing/listing-info';
import { Listings } from '@listings/models/listing/listings';
import { ListingsMedia } from '@listings/models/listing/listings-media';
import * as listingActions from '@listings/store/actions/listings.actions';
import * as listingSelectors from '@listings/store/selectors/listings.selector';
import { FormattedMedia } from '@media/models/formatted-media';

@Injectable({ providedIn: 'root' })
export class ListingsStoreService {

    constructor(
        private readonly store: Store<{}>
    ) { }

    public readonly isListingsLoading$ = this.store.select(listingSelectors.selectIsListingsLoading);
    public readonly isListingLoading$ = this.store.select(listingSelectors.selectIsListingLoading);
    public readonly hasAnySelectedNewMatchListing$ = this.store.select(listingSelectors.hasAnySelectedNewMatchListing);
    public readonly selectedPortfolioListingsInfo$ = this.store.select(listingSelectors.selectPortfolioSelectedListingsInfo);
    public readonly isAllSelectedListingsAreRemoved$ = this.store.select(listingSelectors.selectIsAllSelectedListingsAreRemoved);
    public readonly isAnySelectedListingRemoved$ = this.store.select(listingSelectors.selectIsAnySelectedListingRemoved);
    public readonly hasAnySelectedPortfolioListings$ = this.store.select(listingSelectors.hasAnySelectedPortfolioListings);
    public readonly hasNotRemovedSelectedPortfolioListings$ = this.store.select(listingSelectors.hasNotRemovedSelectedPortfolioListings);
    public readonly selectedListings$ = this.store.select(listingSelectors.selectSelectedListings);
    public readonly selectedListingsWithoutOpenRentals$ = this.store.select(listingSelectors.selectSelectedListingsWithoutOpenRentals);
    public readonly isActiveListingIsInPortfolio$ = this.store.select(listingSelectors.selectIsActiveListingIsInPortfolio);
    public readonly portfolioListingsCountWithoutRemoved$ = this.store.select(listingSelectors.selectPortfolioListingsCountWithoutRemoved);
    public readonly unviewedListingsCount$ = this.store.select(listingSelectors.selectUnviewedListingsCount);

    public getListings(): Observable<Listings> {
        return this.store.pipe(select(listingSelectors.selectListings));
    }

    public getCustomerListings(): Observable<CommonListing[]> {
        return this.store.select(listingSelectors.selectCustomerListings);
    }

    public getAllListingsMedia(): Observable<ListingsMedia> {
        return this.store
            .pipe(select(listingSelectors.selectAllListingsMedia));
    }

    public getListingMedia(listingId: string): Observable<FormattedMedia> {
        return this.store.select(listingSelectors.selectListingMedia(listingId));
    }

    public isMediaLoadedForListing(listingId: string): Observable<boolean> {
        return this.store.select(listingSelectors.selectIsMediaLoadedForListing(listingId));
    }

    public getSelectedCustomerListings(): Observable<CommonListing[]> {
        return combineLatest([this.getCustomerListings(), this.getSelectedListingIds()])
            .pipe(
                map(([listings, selectedLstingids]) => listings.filter(listing => selectedLstingids.includes(listing.id)))
            );
    }

    public getMarketListings(): Observable<CommonListing[]> {
        return this.store.select(listingSelectors.selectMarketListings);
    }

    public getSelectedMarketListingsAvailableForActivity(activityId: number): Observable<CommonListing[]> {
        return this.store.select(listingSelectors.selectSelectedMarketListingsAvailableForActivity(activityId));
    }

    public getListing(listingId: string): Observable<CommonListing> {
        return this.store.select(listingSelectors.selectListing(listingId));
    }

    public getSelectedListingIds(): Observable<string[]> {
        return this.store.pipe(select(listingSelectors.selectSelectedListingIds));
    }

    public getSelectedListingIdsWithoutOpenRentals(): Observable<string[]> {
        return this.store.select(listingSelectors.selectSelectedListingIdsWithoutOpenRentals);
    }

    public areCustomerListingsLoaded(): Observable<boolean> {
        return this.store.pipe(select(listingSelectors.selectCustomerListingsLodaded));
    }

    public areMarketListingsLoading(): Observable<boolean> {
        return this.store.pipe(select(listingSelectors.selectMarketListingsLoading));
    }

    public getListingInfoById(listingId: string): Observable<ListingInfo> {
        return this.store.select(listingSelectors.selectListingFolderManagerInfoById(listingId));
    }

    public loadListing(listingId: string): void {
        this.store.dispatch(listingActions.loadListing({ id: listingId }));
    }

    public loadListingsMedia(listingIds: string[]): void {
        this.store.dispatch(listingActions.loadListingsMedia({ listingIds }));
    }

    public loadCustomerListings(shouldSetLoading: boolean = true): void {
        this.store.dispatch(listingActions.loadCustomerListings({ shouldSetLoading }));
    }

    public markAsViewed(listingId: string, listingHashCode: number): void {
        this.store.dispatch(listingActions.markAsViewed({ listingId, listingHashCode }));
    }

    public selectListing(listingId: string): void {
        this.store.dispatch(listingActions.selectListing({ id: listingId }));
    }

    public unselectListing(listingId: string): void {
        this.store.dispatch(listingActions.unselectListing({ id: listingId }));
    }

    public selectListings(listingIds: string[]): void {
        this.store.dispatch(listingActions.selectListings({ listingIds: listingIds }));
    }

    public unselectAllListings(): void {
        this.store.dispatch(listingActions.unselectAllListings());
    }

    public setMarketListingsLoaded(loaded: boolean): void {
        this.store.dispatch(listingActions.setMarketListingsLoaded({ isLoaded: loaded }));
    }

    public clearMarketListings(): void {
        this.store.dispatch(listingActions.clearMarketListings());
    }

    public hardDelete(listingIds: string[], isFromStateOnly: boolean = false): void {
        this.store.dispatch(listingActions.hardDelete({ listingIds, isFromStateOnly }));
    }

    public softDelete(listingIds: string[], isFromStateOnly: boolean = false): void {
        this.getListings()
            .pipe(take(1))
            .subscribe(((listings) => this.store.dispatch(listingActions.softDelete({ listingIds, listings, isFromStateOnly }))));
    }

    public restore(listingIds: string[]): void {
        this.store.dispatch(listingActions.restoreRequeted({ listingIds }));
    }

    public showDirections(): void {
        this.store.dispatch(listingActions.showDirections());
    }
}