import * as moment from 'moment';

import { ChareEventDateFormatConstant } from '@appointments/constants/share-event-date-format-constant';
import { ShareEventUrlsConstatns } from '@appointments/constants/share-event-urls.constants';
import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { ShareEventBase } from '@appointments/models/share-event/share-event-base';

export class OutlookShareEvent extends ShareEventBase {
    constructor(location: string, appointment: ListingAppointment) {
        super(location, appointment);
    }

    public getURL(): string {
        const paramerets = {
            startdt: moment(this.start).format(ChareEventDateFormatConstant.OutlookDateFormat),
            enddt: moment(this.end).format(ChareEventDateFormatConstant.OutlookDateFormat),
            subject: this.title,
            body: this.description,
            location: this.location
        };

        const queryString = Object.keys(paramerets)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(paramerets[key] as string))
            .join('&');

        return ShareEventUrlsConstatns.OutlookURL + '&' + queryString;
    }
}