import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { AgentBaseInfo } from '@agents/models/agent-base-info';
import { AgentsStoreService } from '@agents/store/services/agents-store.service';
import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { AppointmentsStoreService } from '@appointments/store/services/appointments-store.service';
import { CollaborationSpaceStoreService } from '@auth/store/services/collaboration-space-store.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcMenuIconButtonComponent } from '@core-controls/components/rpc-menu-icon-button/rpc-menu-icon-button.component';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { RouterStoreService } from '@core-layout/app/store/services/router-store.service';
import { SidebarService } from '@core-layout/sidebar/sidebar.service';
import { FoldersStoreWriteService } from '@folders/store/services/folders-store-write.service';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { MatchMediaService } from '@media/services/match-media.service';
import { NotificationsComponent } from '@notifications/components/notifications/notifications.component';
import { NotificationsStoreService } from '@notifications/store/services/notifications-store.service';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { AvatarInfo } from '@users/models/avatar-info';
import { PROFILE_TABS_NAMES } from 'app/modules/user-modules/profile-modules/profile-base/constants/profile-tabs.constants';
import { ProfileBaseStoreService } from 'app/modules/user-modules/profile-modules/profile-base/store/services/profile-base-store.service';
import { PortfolioStoreService } from '@portfolio/store/services/portfolio-store.service';
import { BADGE_ALERT_ANIMATION_CONFIG } from './toolbar.constants';
import { BADGE_COUNT_ALERT_LIMIT, NOTIFICATION_TYPE_NOTIFICATION_BELL_TAB_INDEX_MAP } from '@notifications/constants/notifications.constants';
import { NotificationNavigateParams } from '@notifications/models/notification-navigate-params';
import { ListingCommunicationsService } from 'app/modules/listing-modules/listing-communications/listing-communications-base/services/listing-communications.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {

    @ViewChild('rpcMenuIconButtonApi') private readonly rpcMenuIconButtonApi: RpcMenuIconButtonComponent;

    private readonly unsubscribe$ = new Subject<void>();
    public readonly RpcRoute = RpcRoute;
    public readonly isUserAuthorized$ = this.userStoreService.getIsAuthorized();
    public readonly badgeCountAlertLimit = BADGE_COUNT_ALERT_LIMIT;
    public isMobileDevice: boolean;
    public defaultFolderId: number | null;
    public isMobileScreen: boolean;

    public readonly companyConfiguration$ = this.configurationService.configuration$.pipe(map(configuration => configuration.company));
    public readonly canSearchForListings$ = this.settingsStoreService.canSearchForListings$;
    public readonly listingsCount$ = this.listingsStoreService.portfolioListingsCountWithoutRemoved$;
    public readonly appointmentsCount$ = this.appointmentsStoreService.getFlatListingsAppointmentsCount();
    public readonly unviewedAppointmentsCount$ = this.appointmentsStoreService.unviewedAppointmentsCount$;
    public readonly unviewedListingsCount$ = this.listingsStoreService.unviewedListingsCount$;
    public readonly customerFullName$ = this.userStoreService.getCustomerFullName();
    public readonly customerProfileImage$ = this.userStoreService.getCustomerProfileImage();
    public readonly hideLoginRedirect$ = this.routeService.getCurrentRouteDataProperty<boolean>('hideToolbarLoginRedirect');
    public readonly canSeeAdditionalProfiles$ = this.userStoreService.canSeeAdditionalProfiles$;
    public readonly canAddAdditionalProfile$ = this.profileBaseStoreService.canAddAdditionalProfile$;
    public readonly isDarkMode$ = this.settingsStoreService.isDarkMode$;
    public readonly hasMultipleCollaborationSpaces$ = this.collaborationSpaceStoreService.hasMultipleCollaborationSpaces$;
    public readonly agents$ = this.agentsStoreService.getAgents();
    public readonly isKnowledgeBaseOpened$ = this.routerStoreService.url$.pipe(map(url => url != null && url.includes(RpcRoute.KnowledgeBase)));
    public readonly portfolioRouterLink$ = this.portfolioStoreService.portfolioRouterLink$;
    public readonly unviewedNotificationTotalCount$ = this.notificationsStoreService.unviewedNotificationTotalCount$;
    public readonly badgeAlertAnimationConfig = BADGE_ALERT_ANIMATION_CONFIG;

    constructor(
        private readonly sidebarService: SidebarService,
        private readonly configurationService: AppConfigurationService,
        private readonly routeService: RouteService,
        private readonly bottomSheet: MatBottomSheet,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly appointmentsStoreService: AppointmentsStoreService,
        private readonly userStoreService: UserStoreService,
        private readonly notificationsStoreService: NotificationsStoreService,
        private readonly matchMediaService: MatchMediaService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly profileBaseStoreService: ProfileBaseStoreService,
        private readonly collaborationSpaceStoreService: CollaborationSpaceStoreService,
        private readonly foldersStoreWriteService: FoldersStoreWriteService,
        private readonly agentsStoreService: AgentsStoreService,
        private readonly routerStoreService: RouterStoreService,
        private readonly portfolioStoreService: PortfolioStoreService,
        private readonly listingCommunicationsService: ListingCommunicationsService,
    ) { }

    public ngOnInit(): void {
        this.matchMediaService.onMediaChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mediaQuery => {
                this.isMobileScreen = mediaQuery === 'xs';

                if (mediaQuery === 'xs' && this.rpcMenuIconButtonApi != null) {
                    this.rpcMenuIconButtonApi.closeMenu();
                }
            });

        this.settingsStoreService.defaultFolderId$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(defaultFolderId => this.defaultFolderId = defaultFolderId);

        this.listingCommunicationsService.openNotificationBellTab$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ notificationType }) => {
                const activeTabIndex = NOTIFICATION_TYPE_NOTIFICATION_BELL_TAB_INDEX_MAP.get(notificationType);
                this.notificationsStoreService.setActiveTabIndex(activeTabIndex);

                this.isMobileScreen ? this.openNotificationsBottomSheet() : this.rpcMenuIconButtonApi.openMenu();
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public openNotificationsBottomSheet(): void {
        if (this.bottomSheet._openedBottomSheetRef != null) {
            return;
        }

        const config = { panelClass: ['bottom-panel-wrap', 'rpc-notifications-dropdown-mobile-wrap'] };

        const bottomSheetRef = this.bottomSheet.open(NotificationsComponent, config);

        bottomSheetRef.instance.notificationSourceNavigated
            .pipe(take(1))
            .subscribe((notificationNavigateParams) => this.onNotificationSourceNavigated(notificationNavigateParams));

        bottomSheetRef.afterDismissed()
            .pipe(take(1))
            .subscribe(() => this.onNotificationBellClosed());
    }

    public toggleSidebarOpen(key: string): void {
        this.sidebarService.getSidebar(key).toggleOpen();
    }

    public onLogoClicked(): void {
        this.defaultFolderId != null
            ? this.foldersStoreWriteService.navigateToFolder(this.defaultFolderId)
            : this.routeService.navigate(RpcRoute.Portfolio).catch(() => { });
    }

    public onMyProfileClicked(): void {
        this.routeService.navigate(RpcRoute.Profile, { state: { tab: PROFILE_TABS_NAMES.GENERAL } }).catch(() => { });
    }

    public onSettingsClicked(): void {
        this.routeService.navigate(RpcRoute.Profile, { state: { tab: PROFILE_TABS_NAMES.SETTINGS } }).catch(() => { });
    }

    public onInviteUser(): void {
        this.profileBaseStoreService.showInviteUserDialog();
    }

    public onLogoutClicked(): void {
        this.userStoreService.logout(false);
    }

    public onChangeAgentClicked(): void {
        this.collaborationSpaceStoreService.loadCollaborationSpaces();
    }

    public onKnowledgeBaseClicked(): void {
        this.routeService.navigate(RpcRoute.KnowledgeBase).catch(() => { });
    }

    public getAgentAvatarInfo(agent: AgentBaseInfo): AvatarInfo {
        return { fullName: agent.fullName, profileImage: agent.profilePictureUrl };
    }

    public onNotificationSourceNavigated(notificationNavigateParams: NotificationNavigateParams): void {
        this.isMobileDevice ? this.bottomSheet.dismiss() : this.rpcMenuIconButtonApi.closeMenu();

        this.notificationsStoreService.setActiveTabIndex(null);
        this.notificationsStoreService.redirectToNotificationEntity(notificationNavigateParams);
    }

    public onNotificationBellClosed(): void {
        this.notificationsStoreService.setActiveTabIndex(null);

        this.notificationsStoreService.showOnlyUnviewedFilterChanged(false);
    }
}