import { RpcRoute } from "@core-layout/app/models/rpc-route";
import { InitialFolderStateMode } from "@folders/enums/initial-folder-state-mode.enum";
import { ListingActivityConstants } from "@listings/constants/listing-activity-constants";
import { ListingSearchOptions } from "@listings/models/search/listing-search-options";
import { NotificationEventEntity } from "@notifications/enums/notification-event-entity";

export const URL_NAME_MAP = new Map<RpcRoute, string>([
    [RpcRoute.Appointments, 'appointments'],
    [RpcRoute.SearchListings, 'search'],
    [RpcRoute.Portfolio, 'portfolio']
]);

export const FOLDER_COVER_FILTER_NAME_MAP = new Map<number, string>([
    [InitialFolderStateMode.All, 'all'],
    [InitialFolderStateMode.Appointments, 'appointments'],
    [InitialFolderStateMode.Comments, 'messages'],
    [InitialFolderStateMode.Default, 'all'],
    [InitialFolderStateMode.Liked, 'liked'],
    [InitialFolderStateMode.NewMatches, 'new-match'],
]);

export const SEARCH_FILTER_NAME_MAP = new Map<keyof ListingSearchOptions, string | null>([
    ['priceSearchOption', 'price'],
    ['bedSearchOption', 'bed'],
    ['bathSearchOption', 'bath'],
    ['ownershipTypeSearchOptions', 'ownership_type'],
    ['roomSearchOption', 'room'],
    ['squareFootageSearchOption', 'square_footage'],
    ['monthlyExpenceSearchOption', 'monthly_expence'],
    ['buildingPeriodSearchOption', 'building_period'],
    ['miscellaneousDetailsSearchOption', 'miscellaneous_details'],
    ['privateOutdoorSpaceSearchOption', 'private_outdoor_space'],
    ['buildingTypeSearchOption', 'building_type'],
    ['attendedLobbySearchOption', 'attended_lobby'],
    ['commonOutdoorSpaceSearchOption', 'common_outdoor_space'],
    ['featuresSearchOption', 'features'],
]);

export const LISTING_REACTION_NAME_MAP = new Map<number, string>([
    [ListingActivityConstants.Liked.id, 'like'],
    [ListingActivityConstants.Disliked.id, 'dislike'],
    [ListingActivityConstants.Shown.id, 'shown'],
]);

export const NOTIFICATION_BELL_TABS =  ['all', 'messages', 'appointments', 'listings'];

export const NOTIFICATION_BELL_ENTITY_MAP = new Map<NotificationEventEntity, string>([
    [NotificationEventEntity.Appointment, 'appointment'],
    [NotificationEventEntity.Comment, 'comment'],
    [NotificationEventEntity.ListingActivity, 'listing'],
    [NotificationEventEntity.ExternalListing, 'external-listing']
]);