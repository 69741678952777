import { Injectable } from '@angular/core';

import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { GoogleShareEvent } from '@appointments/models/share-event/google-share-event';
import { ICSShareEvent } from '@appointments/models/share-event/ics-share-event';
import { OutlookShareEvent } from '@appointments/models/share-event/outlook-share-event';
import { AppointmentLinks } from '../models/appointment-links';

@Injectable({ providedIn: 'root' })
export class AppointmentLinkService {

    public static getAppointmentLinks(appointment: ListingAppointment, listingAddress: string, canAddToCalendar: boolean): AppointmentLinks | null {
        return !canAddToCalendar
            ? null
            : {
                google: new GoogleShareEvent(listingAddress, appointment).getURL(),
                outlook: new OutlookShareEvent(listingAddress, appointment).getURL(),
                ics: new ICSShareEvent(listingAddress, appointment).getURL(),
            };
    }
}
