import { animate, animateChild, animation, query, sequence, stagger, state, style, transition, trigger, useAnimation } from '@angular/animations';

const customAnimation = animation(
    [
        style({
            opacity: '{{opacity}}',
            transform: 'scale({{scale}}) translate3d({{x}}, {{y}}, {{z}})'
        }),
        animate('{{duration}} {{delay}} cubic-bezier(0.0, 0.0, 0.2, 1)', style('*'))
    ],
    {
        params: {
            duration: '200ms',
            delay: '0ms',
            opacity: '0',
            scale: '1',
            x: '0',
            y: '0',
            z: '0'
        }
    });

export const generalAnimate = trigger('animate', [transition('void => *', [useAnimation(customAnimation)])]);

export const iteratorParent = trigger('iteratorParent', [
    transition(':enter', [
        query('@iteratorItem', stagger(70, animateChild()), { optional: true })
    ])
]);

export const iteratorItem = trigger('iteratorItem', [
    transition(':enter', [
        style({ transform: 'scale(0.3)', opacity: 0 }),
        animate('400ms 100ms linear', style({ transform: 'scale(1)', opacity: 1 }))
    ]),
    transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1 }),
        animate('300ms 0ms linear', style({ transform: 'scale(0.3)', opacity: 0 }))
    ])
]);

export const fadeInScaleAnimation = trigger('fadeIn', [
    transition(':enter', [
        style({ transform: 'scale(0.9)', opacity: 0 }),
        animate('200ms 100ms linear', style({ transform: 'scale(1)', opacity: 1 }))
    ])
]);

export const animateStagger = trigger('animateStagger', [
    state('50', style('*')),
    state('100', style('*')),
    state('200', style('*')),

    transition('void => 50', query('@*', [stagger('50ms', [animateChild()])], { optional: true })),
    transition('void => 100', query('@*', [stagger('100ms', [animateChild()])], { optional: true })),
    transition('void => 200', query('@*', [stagger('200ms', [animateChild()])], { optional: true }))
]);

export const fadeInOut = trigger('fadeInOut', [
    state('0, void', style({ opacity: 0 })),
    state('1, *', style({ opacity: 1 })),
    transition('1 => 0', animate('300ms ease-out')),
    transition('0 => 1', animate('300ms ease-in')),
    transition('void <=> *', animate('300ms ease-in'))
]);

export const slideInOut = trigger('slideInOut', [
    state('0', style({ height: '0px' })),
    state('1', style({ height: '*' })),
    transition('1 => 0', animate('300ms ease-out')),
    transition('0 => 1', animate('300ms ease-in'))
]);

export const slideIn = trigger('slideIn', [
    transition(
        'void => left',
        [style({ transform: 'translateX(100%)' }), animate('300ms ease-in', style({ transform: 'translateX(0)' }))]
    ),
    transition(
        'left => void',
        [style({ transform: 'translateX(0)' }), animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))]
    ),
    transition(
        'void => right',
        [style({ transform: 'translateX(-100%)' }), animate('300ms ease-in', style({ transform: 'translateX(0)' }))]
    ),
    transition(
        'right => void',
        [style({ transform: 'translateX(0)' }), animate('300ms ease-in', style({ transform: 'translateX(100%)' }))]
    )
]);

export const slideInLeft = trigger('slideInLeft', [
    state('void', style({ transform: 'translateX(-100%)' })),
    state('*', style({ transform: 'translateX(0)' })),
    transition('void => *', animate('300ms')),
    transition('* => void', animate('300ms'))
]);

export const slideInRight = trigger('slideInRight', [
    state('void', style({ transform: 'translateX(100%)' })),
    state('*', style({ transform: 'translateX(0)' })),
    transition('void => *', animate('300ms')),
    transition('* => void', animate('300ms'))
]);

export const slideInTop = trigger('slideInTop', [
    state('void', style({ transform: 'translateY(-100%)' })),
    state('*', style({ transform: 'translateY(0)' })),
    transition('void => *', animate('300ms')),
    transition('* => void', animate('300ms'))
]);

export const slideInBottom = trigger('slideInBottom', [
    state('void', style({ transform: 'translateY(100%)' })),
    state('*', style({ transform: 'translateY(0)' })),
    transition('void => *', animate('300ms')),
    transition('* => void', animate('300ms'))
]);

export const expandCollapse = trigger('expandCollapse', [
    state('void', style({ height: '0px' })),
    state('*', style({ height: '*' })),
    transition('void => *', animate('300ms ease-out')),
    transition('* => void', animate('300ms ease-in'))
]);

export const smoothAppearance = (name: string) => trigger(name, [
    transition('* => *', [
        query(
            ':enter',
            [style({ opacity: 0 }), stagger(1000, [animate('0.5s', style({ opacity: 1 }))])],
            { optional: true }
        )
    ])
]);

export const bounce = () => sequence([
    style({ transform: 'translateY(0)' }),
    animate("400ms cubic-bezier(0,0,0,1)", style({ transform: 'translateY(-5px)' })),
    animate("300ms cubic-bezier(1,0,1,1)", style({ transform: 'translateY(0)' })),
    animate("200ms cubic-bezier(0,0,0,1)", style({ transform: 'translateY(-2px)' })),
    animate("150ms cubic-bezier(1,0,1,1)", style({ transform: 'translateY(0)' })),
]);