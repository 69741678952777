export const locale = {
    lang: 'en',
    data: {
        ADDRESS_EDIT_FORM: {
            TITLES: {
                ADDRESS: 'Address',
                DELETE: 'Delete',
                TYPE: 'Type',
                ADDRESS_LINE_1: 'Address Line 1',
                ADDRESS_LINE_2: 'Address Line 2',
                CITY: 'City',
                STATE: 'State',
                ZIP_CODE: 'Zip Code',
                COUNTRY: 'Country',
                ADD_NEW: 'Add New'
            },
            ERRORS: {
                CLIENT: {
                    ADDRESS_TYPE_REQUIRED: 'Address type is required',
                    ADDRESS_LINE_1_REQUIRED: 'Address Line 1 is required',
                    CITY_REQUIRED: 'City is required',
                    STATE_REQUIRED: 'State is required',
                    STATE_LENGTH_REQUIRED: 'State must contain at least 2 characters',
                    ZIP_CODE_REQUIRED: 'Zip Code is required'
                }
            }
        }
    }
};