<div class="mp-splash mp-splash-inner">
    <div class="mp-splash-backdrop" *ngIf="hasBackdrop"></div>
    <div class="logo">
        <img *ngIf="showImage" src="assets/images/logos/mp-logo.svg" alt="myPlaceNYC">
        <rpc-icon [iconName]="'spinner-item'" class="logo-spinner first"></rpc-icon>
        <rpc-icon [iconName]="'spinner-item'" class="logo-spinner second"></rpc-icon>
        <rpc-icon [iconName]="'spinner-item'" class="logo-spinner third"></rpc-icon>
        <rpc-icon [iconName]="'spinner-item'" class="logo-spinner fourth"></rpc-icon>
        <rpc-icon [iconName]="'spinner-item'" class="logo-spinner fifth"></rpc-icon>
        <rpc-icon [iconName]="'spinner-item'" class="logo-spinner sixth"></rpc-icon>
    </div>
</div>