import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of } from 'rxjs';

import { AgentCandidate } from '@agents/models/agent-candidate';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginData } from '@auth/models/login-data';
import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import * as switchCollaborationSpaceActions from '../actions/switch-collaboration-space.actions';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
    constructor(
        private readonly http: ApiHttpClient
    ) { }

    public loadCollaborationSpaces(): Observable<Action> {
        return this.http.get('auth/collaboration-spaces').pipe(
            map((response: ApiDataResult<AgentCandidate[]>) => switchCollaborationSpaceActions.loadCollaborationSpacesSuccessful({ collaborationSpaces: response.result })),
            catchError((errorResponse: HttpErrorResponse) => of(switchCollaborationSpaceActions.loadCollaborationSpacesFailed(errorResponse.error as ApiError)))
        );
    }

    public loadHasMultipleCollaborationSpaces(): Observable<Action> {
        return this.http.get('auth/has-multiple-spaces').pipe(
            map((response: ApiDataResult<boolean>) => {
                return switchCollaborationSpaceActions.loadHasMultipleCollaborationSpacesSuccessful({ hasMultipleCollaborationSpaces: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) => of(switchCollaborationSpaceActions.loadHasMultipleCollaborationSpacesFailed(errorResponse.error as ApiError)))
        );
    }

    public getCollaborationSpaceToken(customerId: number): Observable<Action> {
        return this.http.get(`auth/token/${customerId}`).pipe(
            map((result: ApiDataResult<LoginData>) => {
                return switchCollaborationSpaceActions.switchCollaborationSpaceSuccessful({ loginResponse: result.result, selectedCustomerId: customerId });
            }),
            catchError((errorResponse: HttpErrorResponse) => of(switchCollaborationSpaceActions.switchCollaborationSpaceFailed(errorResponse.error as ApiError)))
        );
    }
}