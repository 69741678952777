<div class="auth-wrap" [ngClass]="!(isAuthorized$ | async) ? 'register' : 'logout'" @fadeIn>
    <svg class="auth-decoration" *ngIf="!(isAuthorized$ | async)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 487.1 660">
        <linearGradient id="gradientTop" gradientUnits="userSpaceOnUse" x1="973.2902" y1="1028.4862" x2="973.2902"
            y2="1027.5936" gradientTransform="matrix(702.4512 0 0 -739.3792 -681999.25 760721.3125)">
            <stop class="bg-top-start-color" offset="0" />
            <stop class="bg-top-stop-color" offset="1" />
        </linearGradient>
        <linearGradient id="gradientBottom" gradientUnits="userSpaceOnUse" x1="342.7586" y1="1028.3552" x2="342.7586"
            y2="1027.3552" gradientTransform="matrix(297.1582 0 0 -325.7151 -100701.7969 335230.8125)">
            <stop class="bg-bottom-start-color" offset="0" />
            <stop class="bg-bottom-stop-color" offset="1" />
        </linearGradient>
        <g transform="translate(-1444.5 -280)">
            <path class="bg-top"
                d="M1931.5,280.2l-483.9-0.2c0,0,35.8,45.6,34.8,97.9s-12.9,119.3,42.2,161.2s73.7,84.3,74.9,164.2s30.8,98.2,85.2,111.6s166.7,1.5,246.8,125.1L1931.5,280.2z" />
        </g>
        <g transform="translate(-813.217 -280)">
            <path class="bg-bottom"
                d="M1003.1,280c0,0,72.3,10.5,90.1,75c22.6,81.7,156.3,27,152.8,136.3c-3,93.9,54.2,114.4,54.2,114.4V280H1003.1z" />
        </g>
    </svg>
    <svg class="auth-decoration" *ngIf="isAuthorized$ | async" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.75 209.47">
        <linearGradient id="gradientTop" x1="250.41" y1="54.95" x2="250.41" y2="53.97"
            gradientTransform="matrix(200.73, 0, 0, -211.28, -50177.63, 11609.09)" gradientUnits="userSpaceOnUse">
            <stop class="bg-top-start-color" offset="0" stop-color="#344a73" />
            <stop class="bg-top-stop-color" offset="1" stop-color="#8c4d98" />
        </linearGradient>
        <linearGradient id="gradientBottom" x1="250.6" y1="53.88" x2="250.6" y2="52.88"
            gradientTransform="matrix(119.26, 0, 0, -130.72, -29775.09, 7043.47)" gradientUnits="userSpaceOnUse">
            <stop class="bg-bottom-start-color" offset="0" stop-color="#466196" />
            <stop class="bg-bottom-stop-color" offset="1" stop-color="#a25aaf" />
        </linearGradient>
        <path class="bg-top"
            d="M0,0S9,13.54,8.68,28.5,5,62.58,20.75,74.56s21.07,24.1,21.4,46.92S51,149.54,66.49,153.37s49.75.36,71.38,36.47c11.76,19.63,32.88,15.71,32.88,15.71L170.78,0Z"
            fill="url(#gradienttop)" />
        <path class="bg-bottom"
            d="M51.52,0s29,4.2,36.18,30.11C96.78,62.91,150.41,41,149,84.8c-1.19,37.7,21.75,45.92,21.75,45.92V0Z"
            fill="url(#gradientBottom)" />
    </svg>
    <div class="auth-inner" style="--bg-image: url('/assets/images/decoration/registration.png');">
        <div class="auth-form-wrap">
            <div class="auth-form">
                <div class="auth-title">
                    {{ (invitation?.isWorkspaceAddition ? 'REGISTRATION.WORKSPACE_TITLE' : 'REGISTRATION.TITLE') |
                    translate }}
                </div>
                <div class="auth-description">
                    <div class="auth-description-item">
                        {{ (invitation?.isWorkspaceAddition ? 'REGISTRATION.WORKSPACE_WELCOME' : 'REGISTRATION.WELCOME')
                        | translate }} {{companyConfiguration.productNameAlias}}.
                    </div>
                    <div class="auth-description-item" *ngIf="!(isAuthorized$ | async)">
                        {{ (invitation?.isWorkspaceAddition ? 'REGISTRATION.WORKSPACE_INVITE' : 'REGISTRATION.INVITE') |
                        translate }}:
                    </div>
                </div>
                <agent-short-info *ngIf="!(isAuthorized$ | async)" class="auth-agent-info" [agent]="invitation?.invitatingAgent != null ? invitation?.invitatingAgent : invitation?.agent">
                </agent-short-info>
                <registration-form *ngIf="!(isAuthorized$ | async)"
                    [isWorkspaceAddition]="invitation?.isWorkspaceAddition" [customerData]="invitation?.customer"
                    (formSubmited)="onRegistration($event)">
                </registration-form>
                <div class="logout-description" *ngIf="isAuthorized$ | async">
                    <rpc-icon class="logout-description-icon" [iconName]="'info-circle'"></rpc-icon>
                    <div class="logout-description-text">
                        <div>{{ 'REGISTRATION.LOGOUT_WARNING_1' | translate }}</div>
                        <div>{{ 'REGISTRATION.LOGOUT_WARNING_2' | translate }}</div>
                    </div>
                </div>
                <div class="logout-action-wrap" *ngIf="isAuthorized$ | async">
                    <rpc-button class="logout-action" [mainButton]="true" [color]="'accent'" [appearanceType]="'stroke'"
                        [title]="'REGISTRATION.BUTTONS.CANCEL'" (clicked)="cancel()">
                    </rpc-button>
                    <rpc-button class="logout-action" [mainButton]="true" [color]="'accent'" [appearanceType]="'flat'"
                        [title]="'REGISTRATION.BUTTONS.CONTINUE'" (clicked)="continue()">
                    </rpc-button>
                </div>
            </div>
        </div>
    </div>
</div>