import { Pipe, PipeTransform } from '@angular/core';

import { IBaseFilterOptions } from '@core-models/filter/base-filter-options-interface';

@Pipe({ name: 'baseFilterCollection' })
export class BaseFilterCollectionPipe<TCollection> implements PipeTransform {
    transform(collection: TCollection[], selectedFilter: IBaseFilterOptions<TCollection>): TCollection[] {
        if (collection == null) {
            return [];
        }

        return selectedFilter.filter(collection);
    }
}