import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import * as appointmentsActions from '@appointments/store/actions/appointments.actions';
import * as commentsActions from '@comments/store/actions/comments.actions';
import * as foldersActions from '@folders/store/actions/folders.actions';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingApiService } from '@listings/store/services/listing-api.service';
import { ListingsStoreService } from '../services/listings-store.service';

@Injectable()
export class RemoveListingEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly listingApiService: ListingApiService,
        private readonly listingsStoreService: ListingsStoreService
    ) { }

    public readonly softDelete$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.softDelete),
        switchMap(({ listingIds, listings, isFromStateOnly }) => {
            return isFromStateOnly ? EMPTY : this.listingApiService.softDelete(listingIds, listings);
        })
    ));

    public readonly softDeleteSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.softDeleteSuccess),
        switchMap(({ listingIds, listings }) => {
            const getNewMatchIdsWithoutActivities = (newMatchIds: number[], id: string) => {
                const { isNewMatch, activities, newMatches } = listings[id];
                return isNewMatch && activities.length === 0 ? [...newMatchIds, ...newMatches.map(({ id }) => id)] : newMatchIds;
            };
            const newMatchIdsWithoutActivities = listingIds.reduce(getNewMatchIdsWithoutActivities, new Array<number>());

            return [
                appointmentsActions.loadListingsAppointments(),
                ...(newMatchIdsWithoutActivities.length > 0 ? [foldersActions.updateFolderMappings({ newMatchIds: newMatchIdsWithoutActivities })] : [])
            ];
        })
    ));

    public readonly hardDeleteListings$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.hardDelete),
        concatLatestFrom(() => this.listingsStoreService.getListings()),
        switchMap(([{ listingIds, isFromStateOnly }, listings]) => {
            return isFromStateOnly ? EMPTY : this.listingApiService.hardDelete(listingIds, listings);
        })
    ));

    public readonly hardDeleteSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.hardDeleteSuccess),
        switchMap(() => [commentsActions.loadListingsComments(), appointmentsActions.loadListingsAppointments(), foldersActions.loadListingFolderIdsMappings()])
    ));
}
