import { INITIAL_DEFAULT_FOLDER_ID } from '@folders/constants/folder.constants';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SettingsState } from '@settings/store/states/settings.state';

const settingsStateSelector = createFeatureSelector<SettingsState>('settings');

export const selectSettingsError = createSelector(
    settingsStateSelector,
    (state) => state?.error
);

export const selectSettings = createSelector(
    settingsStateSelector,
    state => state == null || state.settings == null ? null : state.settings
);

export const selectOnMarketSettings = createSelector(
    settingsStateSelector,
    state => state?.settings?.layoutSettings?.onMarketSettings
);

export const selectNeighborhoodsMapping = createSelector(
    settingsStateSelector,
    state => state == null || state.neighborhoodsMapping == null ? null : state.neighborhoodsMapping
);

export const selectNewMatchSettings = createSelector(
    settingsStateSelector,
    state => state == null || state.newMatchSettings == null ? null : state.newMatchSettings
);

export const selectModeInfo = createSelector(
    selectSettings,
    state => state?.layoutSettings?.darkMode
);

export const selectDefaultFolderId = createSelector(
    selectSettings,
    settings => settings == null ? INITIAL_DEFAULT_FOLDER_ID : settings.layoutSettings?.defaultFolderId
);

export const selectPermissionSettings = createSelector(
    selectSettings,
    settings => settings?.permissionSettings
);

export const selectHideOpenRentals = createSelector(
    selectSettings,
    settings => settings?.permissionSettings?.hideOpenRentals ?? true
);

export const selectCanSearchForListings = createSelector(
    selectSettings,
    settings => settings?.permissionSettings?.canSearchForListings ?? false
);

export const selectIsShowSystemFolders = createSelector(
    selectSettings,
    settings => settings?.layoutSettings?.showSystemFolders
);