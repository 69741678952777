import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingsState } from '../states/listings.state';

export const listingSelectionReducerTypes: ReducerTypes<ListingsState, readonly ActionCreator[]>[] = [
    on(listingActions.selectListing, (state, action): ListingsState => {
        const updatedSelectedListingIds = state.selectedListingIds.indexOf(action.id) < 0
            ? [...state.selectedListingIds, action.id]
            : [...state.selectedListingIds];
        return {
            ...state,
            selectedListingIds: updatedSelectedListingIds
        };
    }),
    on(listingActions.unselectListing, (state, action): ListingsState => {
        const listingIdIndex = state.selectedListingIds.indexOf(action.id);
        const updatedSelectedListingIds = [...state.selectedListingIds];
        if (listingIdIndex >= 0) {
            updatedSelectedListingIds.splice(listingIdIndex, 1);
        }
        return {
            ...state,
            selectedListingIds: updatedSelectedListingIds
        };
    }),
    on(listingActions.unselectAllListings, (state): ListingsState => {
        return {
            ...state,
            selectedListingIds: []
        };
    }),
    on(listingActions.selectListings, (state, action): ListingsState => {
        const updatedSelectedListingIds = [...state.selectedListingIds];
        action.listingIds
            .filter((listingId) => !state.selectedListingIds.includes(listingId))
            .forEach((listingId) => updatedSelectedListingIds.push(listingId));
        return {
            ...state,
            selectedListingIds: updatedSelectedListingIds
        };
    }),
];