import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ImportantDialogOptions } from '@core-utils/important-dialog/models/important-dialog-options';

@Component({
    selector: 'important-dialog',
    templateUrl: './important-dialog.component.html',
    styleUrls: ['./important-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportantDialogComponent {

    public options: ImportantDialogOptions;

    constructor(
        public dialogRef: MatDialogRef<ImportantDialogComponent>) {
    }

}
