import { ListingHelperService } from '@listings/services/listing-helper.service';
import { MediaConstants } from '@media/constants/media.constants';
import { FormattedMedia } from '@media/models/formatted-media';
import { Image } from '@media/models/image';
import { CommonListing } from './common-listing';
import { ListingPrices } from './listing-prices';

export class ListingInfo {
    public readonly listingId: string;
    public readonly hashCode: number;
    public readonly listingAddress: string;
    public readonly prices: ListingPrices;
    public readonly image: Image;
    public readonly isMarketListing: boolean;
    public readonly isDeleted: boolean;
    public readonly isNewMatch: boolean;
    public readonly hasActivity: boolean;

    constructor(listing: CommonListing, listingMedia: FormattedMedia, isHideOpenRentals: boolean) {
        this.listingId = listing.id;
        this.hashCode = listing.hashCode;
        this.listingAddress = ListingHelperService.getListingFullAddress(listing, isHideOpenRentals);
        this.prices = listing.prices;
        this.image = listingMedia?.extraSmallImages != null ? listingMedia?.extraSmallImages[0] : MediaConstants.UnavailableImage;
        this.isMarketListing = listing.activities.length === 0 && !listing.isNewMatch;
        this.isDeleted = listing.isDeleted;
        this.isNewMatch = listing.isNewMatch;
        this.hasActivity = listing.activities.length > 0;
    }
}