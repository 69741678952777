import { FormGroup } from '@angular/forms';
import { ValidatorsMapItem } from '@core-controls/models/validators-map-item';

export class RpcInputOptions {
    formGroup: FormGroup;
    controlName: string;
    defaultValue?: any;
    validatorsMap?: ValidatorsMapItem[];

    constructor(formGroup: FormGroup, controlName: string, defaultValue: any = null, validatorsMap: ValidatorsMapItem[] = null) {
        this.formGroup = formGroup;
        this.controlName = controlName;
        this.defaultValue = defaultValue;
        this.validatorsMap = validatorsMap;
    }
}