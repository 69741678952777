<form [formGroup]="changePasswordForm" class="change-password-wrap">
    <div *ngIf="!isAdditionalProfile" class="change-password-item">
        <rpc-input type="password" appearance="outline" label="PASSWORD.FIELDS.OLD_PASSWORD"
            [options]="oldPasswordControlOptions"></rpc-input>
    </div>
    <div class="change-password-item">
        <rpc-input type="password" appearance="outline" label="PASSWORD.FIELDS.NEW_PASSWORD"
            [options]="newPasswordControlOptions"></rpc-input>
    </div>
    <div class="change-password-item">
        <rpc-input type="password" appearance="outline" label="PASSWORD.FIELDS.PASSWORD_CONFIRM"
            [options]="confirmPasswordControlOptions"></rpc-input>
    </div>
    <div class="change-password-btn-wrap">
        <rpc-button class="change-password-btn" [mainButton]="true" [color]="'accent'" [appearanceType]="'flat'"
            [disabled]="isUpdateButtonDisabled" (clicked)="save()" [title]="passwordButtonTitle">
        </rpc-button>
    </div>
</form>