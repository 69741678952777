import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { ActivityHelpersService } from '@listings/store/services/activity-helpers.service';
import { ListingsState } from '@listings/store/states/listings.state';
import * as listingActivityActions from '../actions/listing-activity.actions';
import { ListingsReducerHelpers } from './helpers/listings.reducer.helper';

export const listingActivityReducerTypes: ReducerTypes<ListingsState, readonly ActionCreator[]>[] = [
    on(listingActivityActions.setListingsActivity, (state, { request, customerId }) => {
        const listingsWithoutRemoved = request.listingCandidates.filter(x => !state.listings[x.id].isDeleted);

        return ListingsReducerHelpers.setListingsActivities(
            state,
            { ...request, listingCandidates: listingsWithoutRemoved, },
            customerId,
            false,
            ActivityHelpersService.setListingActivity,
        );
    }),
    on(listingActivityActions.setListingsActivityFailed, (state, { request, customerId }) =>
        ListingsReducerHelpers.setListingsActivities(
            state,
            request,
            customerId,
            true,
            ActivityHelpersService.restoreListingActivity
        )
    ),
    on(listingActivityActions.addListingsToPickedList, (state, { listingCandidates, customerId }) =>
        ListingsReducerHelpers.setListingsActivities(
            state,
            {
                listingCandidates,
                activity: ListingActivityConstants.PickListed
            },
            customerId,
            false,
            ActivityHelpersService.setListingActivity,
        )
    ),
    on(listingActivityActions.removeListingsFromPickedList, (state, { listingCandidates, customerId }) =>
        ListingsReducerHelpers.setListingsActivities(
            state,
            {
                listingCandidates,
                activity: ListingActivityConstants.PickListed
            },
            customerId,
            true,
            ActivityHelpersService.restoreListingActivity
        )
    ),
];