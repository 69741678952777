import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { SavedSearch } from '@listings/models/saved-search/saved-search';
import { SavedSearchCreationRequest } from '@listings/models/saved-search/saved-search-creation-request';
import { SavedSearchModification } from '@on-market/models/saved-search-modification';
import { CustomerSettings } from '@settings/models/settings/customer-settings';

export const loadSavedSearches = createAction(
    '[Saved Search] Load Saved Searches',
    props<CustomerSettings>()
);
export const loadSavedSearchesSuccess = createAction(
    '[Saved Search] Load Saved Searches Success',
    props<{ savedSearches: SavedSearch[], customerSettings: CustomerSettings }>()
);
export const loadSavedSearchesFailed = createAction(
    '[Saved Search] Load Saved Searches Failed',
    props<{ error: ApiError }>()
);

export const createSavedSearchRequested = createAction(
    '[Saved Search] Create Saved Search Requested',
    props<{ savedSearchModification: SavedSearchModification }>()
);
export const createSavedSearch = createAction(
    '[Saved Search] Create Saved Search',
    props<{ savedSearch: SavedSearchCreationRequest }>()
);
export const createSavedSearchSuccess = createAction(
    '[Saved Search] Create Saved Search Success',
    props<{ tempId: number, savedSearchId: number, savedSearchName: string, folderId?: number }>()
);
export const createSavedSearchFailed = createAction(
    '[Saved Search] Create Saved Search Failed',
    props<{ error: ApiError, savedSearch: SavedSearchCreationRequest }>()
);

export const updateSavedSearchRequested = createAction(
    '[Saved Search] Update Saved Search Requested',
    props<{ savedSearchModification: SavedSearchModification }>()
);
export const updateSavedSearch = createAction(
    '[Saved Search] Update Saved Search',
    props<{ previousSavedSearch: SavedSearchCreationRequest, currentSavedSearch: SavedSearchCreationRequest, newMatchesIds: number[], updateCriteria: boolean }>()
);
export const updateSavedSearchSuccess = createAction(
    '[Saved Search] Update Saved Search Success'
);
export const updateSavedSearchFailed = createAction(
    '[Saved Search] Update Saved Search Failed',
    props<{ error: ApiError, savedSearch: SavedSearchCreationRequest, folderId?: number, includePreviousMatches: boolean, newMatchesIds: number[], updateCriteria: boolean }>()
);

export const deleteSavedSearch = createAction(
    '[Saved Search] Delete Saved Search',
    props<{ savedSearch: SavedSearch, removeDefaultSavedSearchId: boolean, removeActiveSavedSearchId: boolean }>()
);
export const deleteSavedSearchSuccess = createAction(
    '[Saved Search] Delete Saved Search Success'
);
export const deleteSavedSearchFailed = createAction(
    '[Saved Search] Delete Saved Search Failed',
    props<{ error: ApiError, savedSearch: SavedSearch, removeDefaultSavedSearchId: boolean, removeActiveSavedSearchId: boolean }>()
);

export const setActiveSavedSearchId = createAction(
    '[Saved Search] Set Active Saved Search Id',
    props<{ activeSavedSearchId: number, reloadListings: boolean }>()
);

export const addCustomerSavedSearchActivity = createAction(
    '[Saved Search] Add Customer Saved Search Activity',
    props<{ savedSearchId: number, isNew: boolean, categoryId: number }>()
);