import { Injectable } from '@angular/core';

import { LocalStorageConstants } from '@auth/constants/local-storage-constants';
import { LoginData } from '@auth/models/login-data';
import { User } from '@auth/models/user';

@Injectable({ providedIn: 'root' })
export class TokenService {
    private static readonly timeCorrection = 1000;

    public static saveUserTokens(tokens: LoginData): void {
        localStorage.setItem(LocalStorageConstants.TokenKey, JSON.stringify(tokens));
    }

    public static restoreCurrentUser(): User {
        const tokensStr = localStorage.getItem(LocalStorageConstants.TokenKey);
        if (tokensStr?.length > 0) {
            return TokenService.parseUserFromToken(JSON.parse(tokensStr) as LoginData);
        }

        return null;
    }

    public static parseUserFromToken(data: LoginData): User {
        const { exp, ...user } = JSON.parse(window.atob(data.accessToken.split('.')[1])) as (User & { exp: number });

        user.loginData = data;
        user.expiration = new Date(exp * TokenService.timeCorrection);
        user.primaryCustomerId = +user.primaryCustomerId > 0 ? +user.primaryCustomerId : null;
        user.customerId = +user.customerId;
        user.agentId = +user.agentId;

        return user;
    }

    public static removeUserTokens(): void {
        localStorage.removeItem(LocalStorageConstants.TokenKey);
    }

    public static processUserData(loginData: LoginData): User {
        TokenService.saveUserTokens(loginData);

        return TokenService.parseUserFromToken(loginData);
    }
}