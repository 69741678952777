import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ListingActivityConstants, ListingActivityDescription } from '@listings/constants/listing-activity-constants';
import { ListingActivities } from '@listings/enums/listing-activities.enum';
import { ListingActivity } from '@listings/models/listing/listing-activity';

@Component({
    selector: 'single-activity-controls',
    templateUrl: './single-activity-controls.component.html',
    styleUrls: ['../activity-controls-base/activity-controls/activity-controls.component.scss', './single-activity-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleActivityControlsComponent implements OnChanges {

    @Input() public activities: ListingActivity[];
    @Input() public baseColor?: boolean = null;
    @Input() public enableCustomBackground?: boolean = null;
    @Input() public isCombined = true;
    @Input() public disabled = false;
    @Input() public titlesMap?: Map<ListingActivities, string> = null;

    @Output() public activityClicked = new EventEmitter<ListingActivityDescription>();

    @ContentChild('extraActions') public extraActions: TemplateRef<HTMLElement>;

    public calculatedActivities: {
        isPickListed: boolean,
        isLiked: boolean,
        isDisliked: boolean,
        isShown: boolean,
        pickDate: Date | null,
        likedDate: Date | null,
        shownDate: Date | null,
        dislikedDate: Date | null
    };

    public iconName: string = null;
    public activityClass: string;
    public iconTitle: string;
    public tooltipText: string;
    public listingActivities = ListingActivities;

    private readonly defaultActivityTitles = new Map<ListingActivities, string>([
        [ListingActivities.Liked, 'SIINGLE_ACTIVITY_CONTROLS.TITLES.SINGLE.LIKED'],
        [ListingActivities.Shown, 'SIINGLE_ACTIVITY_CONTROLS.TITLES.SINGLE.SHOWN'],
        [ListingActivities.Disliked, 'SIINGLE_ACTIVITY_CONTROLS.TITLES.SINGLE.DISLIKED'],
    ]);

    public ngOnChanges(changes: SimpleChanges<SingleActivityControlsComponent>): void {
        if (changes.activities != null && changes.activities.currentValue != null) {
            const activities = changes.activities.currentValue;
            this.calculatedActivities = {
                isPickListed: activities != null
                    ? activities.some(listingActivity => listingActivity.id === ListingActivityConstants.PickListed.id)
                    : false,
                isLiked: activities != null
                    ? activities.some(listingActivity => listingActivity.id === ListingActivityConstants.Liked.id)
                    : false,
                isDisliked: activities != null
                    ? activities.some(listingActivity => listingActivity.id === ListingActivityConstants.Disliked.id)
                    : false,
                isShown: activities != null
                    ? activities.some(listingActivity => listingActivity.id === ListingActivityConstants.Shown.id)
                    : false,
                pickDate: this.getActivityDate(activities, ListingActivityConstants.PickListed.id),
                likedDate: this.getActivityDate(activities, ListingActivityConstants.Liked.id),
                shownDate: this.getActivityDate(activities, ListingActivityConstants.Shown.id),
                dislikedDate: this.getActivityDate(activities, ListingActivityConstants.Disliked.id)
            };

            this.iconName = this.getIconName();
            this.activityClass = this.getActivityClass();
            this.iconTitle = this.hasAnyIntersectingActivity() || this.calculatedActivities?.isShown
                ? this.getIconTitle()
                : '';
            this.tooltipText = this.getIconTitle();
        }
    }

    public onPickListedClicked(): void {
        this.activityClicked.emit(ListingActivityConstants.PickListed);
    }

    public onLikedClicked(): void {
        this.activityClicked.emit(ListingActivityConstants.Liked);
    }

    public onDislikedClicked(): void {
        this.activityClicked.emit(ListingActivityConstants.Disliked);
    }

    public onShownClicked(): void {
        this.activityClicked.emit(ListingActivityConstants.Shown);
    }

    public getActivityTitle(activity: ListingActivities): string {
        return this.titlesMap?.get(activity) ?? this.defaultActivityTitles.get(activity);
    }

    private getActivityDate(activities: ListingActivity[], activityId: number): Date {
        if (activities == null) {
            return null;
        }
        const foundActivity = activities.find(listingActivity => listingActivity.id === activityId);
        return foundActivity != null ? foundActivity.date : null;
    }

    private getIconName(): string {
        return this.calculatedActivities?.isLiked
            ? 'like'
            : this.calculatedActivities?.isDisliked
                ? 'dislike'
                : this.calculatedActivities?.isShown && !this.hasAnyIntersectingActivity()
                    ? 'eye'
                    : 'like';
    }

    private getActivityClass(): string {
        return this.calculatedActivities?.isDisliked
            ? 'disliked'
            : this.calculatedActivities?.isLiked
                ? 'liked'
                : this.calculatedActivities?.isShown && !this.hasAnyIntersectingActivity()
                    ? 'shown'
                    : 'activity';
    }

    private getIconTitle(): string {
        const userActivity = this.calculatedActivities?.isLiked
            ? 'LIKED'
            : this.calculatedActivities?.isDisliked
                ? 'DISLIKED'
                : this.calculatedActivities?.isShown
                    ? 'SHOWN'
                    : 'ZERO';

        return this.calculatedActivities?.isShown && this.hasAnyIntersectingActivity()
            ? 'SIINGLE_ACTIVITY_CONTROLS.TITLES.MULTIPLE.' + userActivity
            : 'SIINGLE_ACTIVITY_CONTROLS.TITLES.SINGLE.' + userActivity;
    }

    private hasAnyIntersectingActivity(): boolean {
        return this.calculatedActivities?.isLiked || this.calculatedActivities?.isDisliked;
    }
}