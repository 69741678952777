import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { cloneDeep, isEqual, merge } from 'lodash-es';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { AppConfiguration } from '@app-config/models/app-configuration';
import { CompanyConfiguration } from '@app-config/models/company-configuration';
import { WelcomeVideoLinkModel } from '@app-config/models/welcome-video-link-model';
import * as appActions from '@core-layout/app/store/actions/app.actions';
import { AppEffects } from '@core-layout/app/store/effects/app.effects';

export const APP_CONFIG = new InjectionToken('applicationConfiguration');

@Injectable({ providedIn: 'root' })
/**
 * Service provides access to application layout and company styles configuration.
 */
export class AppConfigurationService {

    private currentConfiguration: BehaviorSubject<AppConfiguration>;
    private readonly configurationDefault: AppConfiguration;

    constructor(
        private readonly appEffects: AppEffects,
        @Inject(APP_CONFIG) storedDefaultConfiguration: AppConfiguration
    ) {
        this.configurationDefault = storedDefaultConfiguration;

        this.initialize();
    }

    /**
     * Merges current configuration with new one.
     * Usage of any type allows to pass as an argument only a part of configuration.
     * @param configuration Configuration to be merged into current.
     * @type AppConfiguration
     */
    set configuration(configuration: any) {
        this.setConfiguration(configuration);
    }

    /**
     * Gets current configuration observable.
     */
    get configuration$(): Observable<AppConfiguration> {
        return this.getConfiguration();
    }

    /**
     * Gets current welcome video link feature observable.
     */
    public get welcomeVideoLinkFeature$(): Observable<WelcomeVideoLinkModel> {
        return this.getConfiguration().pipe(map((x): WelcomeVideoLinkModel => ({
            enabled: x.company.features.welcomeVideoLink.enabled,
            link: x.company.features.welcomeVideoLink.link,
            productNameAlias: x.company.productNameAlias
        })));
    }

    /**
     * Gets whether current welcome video link feature exists observable.
     */
    public get isWelcomeVideoLinkExisted$(): Observable<boolean> {
        return this.getConfiguration().pipe(map(x =>
            x.company.features?.welcomeVideoLink != null &&
            x.company.features.welcomeVideoLink.enabled &&
            x.company.features.welcomeVideoLink.link?.length > 0 &&
            x.company.productNameAlias?.length > 0));
    }

    /**
     * Merges current configuration with new one.
     * Usage of any type allows to pass as an argument only a part of configuration.
     * @param configuration Configuration to be merged into current.
     * @type AppConfiguration
     */
    public setConfiguration(configuration: any): void {
        // Get the value from the behavior subject
        let config = this.currentConfiguration.getValue();

        // Merge the new config
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        config = merge({}, config, configuration);

        // Notify the observers
        this.currentConfiguration.next(config);
    }

    /**
     * Gets current configuration observable.
     * @type Observable<AppConfiguration>
     */
    public getConfiguration(): Observable<AppConfiguration> {
        return this.currentConfiguration.asObservable();
    }

    public resetLayout(applyDefault: boolean): void {
        if (applyDefault && isEqual(this.currentConfiguration.getValue().layout, this.configurationDefault.layout)) {
            // Clone the current config
            const config = cloneDeep(this.currentConfiguration.getValue());

            // Reset the layout from the default config
            config.layout = cloneDeep(this.configurationDefault.layout);

            // Set the config
            this.currentConfiguration.next(config);
        } else {
            const config = cloneDeep(this.currentConfiguration.getValue());
            this.currentConfiguration.next(config);
        }
    }

    private initialize(): void {
        this.currentConfiguration = new BehaviorSubject(cloneDeep(this.configurationDefault));

        this.appEffects
            .loadDomainCompanyConfiguration$
            .pipe(ofType(appActions.loadCompanyConfigurationSuccess))
            .subscribe(companyConfiguration => this.setCompany(companyConfiguration));

        this.appEffects
            .loadCompanyConfiguration$
            .pipe(ofType(appActions.loadCompanyConfigurationSuccess))
            .subscribe(companyConfiguration => this.setCompany(companyConfiguration));

        this.appEffects
            .loadCompanyConfigurationByInternalCompanyId$
            .pipe(ofType(appActions.loadCompanyConfigurationSuccess))
            .subscribe(companyConfiguration => this.setCompany(companyConfiguration));
    }

    private setCompany(companyConfiguration: CompanyConfiguration): void {
        this.configuration = {
            company: {
                ...companyConfiguration,
                logoPath: 'assets/images/company/' + companyConfiguration.id + '/logo.svg',
                darkModeLogoPath: 'assets/images/company/' + companyConfiguration.id + '/logo-dark.svg',
            }
        };
    }
}