import { Component, EventEmitter } from '@angular/core';
import { ListingActivityConstants, ListingActivityDescription } from '@listings/constants/listing-activity-constants';

@Component({
    selector: 'activities-tooltip',
    templateUrl: './activities-tooltip.component.html',
    styleUrls: ['./activities-tooltip.component.scss']
})
export class ActivitiesTooltipComponent {
    public isLiked: boolean;
    public isDisliked: boolean;
    public isShown: boolean;

    public readonly listingActivities = ListingActivityConstants;
    public readonly activityClicked = new EventEmitter<ListingActivityDescription>();

    public onActivityClicked(activity: ListingActivityDescription): void {
        this.activityClicked.next(activity);
    }
}