import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';

import { environment } from 'environments/environment';

const createDefaultApolloClient = (httpLink: HttpLink): ApolloClientOptions<NormalizedCacheObject> => {
    return {
        link: httpLink.create({ uri: environment.baseGraphQlUrl }),
        cache: new InMemoryCache(),
    };
};
@NgModule({
    exports: [ApolloModule],
    providers: [
        { provide: 'BASE_GRAPHQL_API_URL', useValue: environment.baseGraphQlUrl },
        {
            provide: APOLLO_OPTIONS,
            useFactory: createDefaultApolloClient,
            deps: [HttpLink],
        }
    ],
})
export class GraphQLModule { }
