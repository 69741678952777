import { ListingsComments } from '@comments/models/comments/listings-comments';
import { ActionError } from '@core-models/errors/action-error';
import { CommentActionErrors } from '../enums/comment-action-errors';

export class CommentsState {
    listingsComments: ListingsComments;
    error: ActionError<CommentActionErrors>;

    isSingleCommentLoaded: boolean;
    commentsLoaded: boolean;

    constructor() {
        this.listingsComments = {};

        this.error = null;

        this.isSingleCommentLoaded = false;

        this.commentsLoaded = false;
    }
}