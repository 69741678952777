import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { GoogleAnalyticsEventName } from "../../../../enums/google-analytics-event-name";
import { InitialFolderStateMode } from "@folders/enums/initial-folder-state-mode.enum";
import { ViewModes } from "@view-base/models/view-modes.enum";
import { ListingSearchOptions } from "@listings/models/search/listing-search-options";
import { ListingCategories } from "@listings/enums/listing-categories.enum";
import { addEvent, addPageEvent, setCustomerAttributes } from "@core-layout/app/store/actions/google-analytics.actions";
import { FOLDER_COVER_FILTER_NAME_MAP, LISTING_REACTION_NAME_MAP, NOTIFICATION_BELL_ENTITY_MAP, NOTIFICATION_BELL_TABS, SEARCH_FILTER_NAME_MAP } from "@core-constants/google-analytics.constants";
import { NotificationEventEntity } from "@notifications/enums/notification-event-entity";

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsStoreService {

    constructor(private readonly store: Store<{}>) { }

    public addChangeSystemFoldersVisibilityEvent(state: 'hidden' | 'shown'): void {
        this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.Portfolio_ChangeSystemFoldersVisibility, payload: { state } }));
    }

    public addDarkModeChangedEvent(state: 'on' | 'off'): void {
        this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.Settings_DarkMode_Changed, payload: { state } }));
    }

    public addCreateFolderEvent(source?: 'saved_search' | 'folder_manager' | 'fab'): void {
        this.store.dispatch(addPageEvent({ name: GoogleAnalyticsEventName.Folder_Create, payload: { source } }));
    }

    public addViewProfileTabEvent(tab: 'general_info' | 'settings' | 'additional_profiles' | 'new_match_settings'): void {
        this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.Profile_ViewTab, payload: { tab } }));
    }

    public addViewChangedEvent(type: ViewModes): void {
        this.store.dispatch(addPageEvent({ name: GoogleAnalyticsEventName.ViewChanged, payload: { type } }));
    }

    public addShareEvent(method: 'email' | 'link', contentType: 'listing' | 'mortgage_calculator'): void {
        this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.Share, payload: { method, content_type: contentType } }));
    }

    public addMapVisibilityChangedEvent(state: 'hidden' | 'shown'): void {
        this.store.dispatch(addPageEvent({ name: GoogleAnalyticsEventName.Map_VisibilityChanged, payload: { state } }));
    }

    public addMapSizeChangedEvent(size: number): void {
        const newLocal = new Map<boolean, number>([
            [size === 0, 0],
            [size > 0 && size <= 25, 25],
            [size > 25 && size < 40, 30],
            [size >= 40 && size <= 60, 50],
            [size >= 60 && size < 85, 75],
            [size >= 85 && size <= 100, 100]
        ]).get(true);
        this.store.dispatch(addPageEvent({ name: GoogleAnalyticsEventName.Map_SizeChanged, payload: { size: newLocal } }));
    }

    public addFolderManagementAttachDetachEvent(action: 'attach' | 'detach'): void {
        this.store.dispatch(addPageEvent({ name: GoogleAnalyticsEventName.FolderManagement, payload: { action } }));
    }

    public addListingReactionEvent(activityId: number): void {
        const reaction = LISTING_REACTION_NAME_MAP.get(activityId);
        if (reaction != null) {
            this.store.dispatch(addPageEvent({ name: GoogleAnalyticsEventName.ListingAddReaction, payload: { reaction } }));
        }
    }

    public addSearchOmniBoxEvent(type: string): void {
        const category = type.toLowerCase().replace(' ', '_');
        this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.Search_OmniBox, payload: { category } }));
    }

    public addSearchApplyFilterEvent(categoryId: ListingCategories, filterKey: keyof ListingSearchOptions): void {
        const category = categoryId === ListingCategories.Sales ? 'sales' : 'rentals';
        const filter = SEARCH_FILTER_NAME_MAP.get(filterKey) ?? null;

        if (filter != null || filterKey === 'categoryId') {
            this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.Search_ApplyFilter, payload: { category, filter } }));
        }
    }

    public addOpenFolderEvent(folderState?: InitialFolderStateMode): void {
        const filter = FOLDER_COVER_FILTER_NAME_MAP.get(folderState) ?? null;

        this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.Folder_Open, payload: { filter } }));
    }

    public addNotificationBellToggleEvent(checked: boolean) {
        const state = checked ? 'unread' : 'all';
        this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.NotificationBell_Toggle, payload: { state } }));
    }

    public addNotificationBellViewEvent(tabIndex: number) {
        const tab = NOTIFICATION_BELL_TABS[tabIndex];
        this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.NotificationBell_View, payload: { tab } }));
    }

    public addNotificationBellRedirectEvent(eventEntity: NotificationEventEntity) {
        const entity = NOTIFICATION_BELL_ENTITY_MAP.get(eventEntity);
        this.store.dispatch(addEvent({ name: GoogleAnalyticsEventName.NotificationBell_Redirect, payload: { entity } }));
    }

    public addEvent(name: GoogleAnalyticsEventName): void {
        this.store.dispatch(addEvent({ name }));
    }

    public addPageEvent(name: GoogleAnalyticsEventName): void {
        this.store.dispatch(addPageEvent({ name }));
    }

    public setCustomerAttributes(): void {
        this.store.dispatch(setCustomerAttributes());
    }
}