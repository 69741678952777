export const locale = {
    lang: 'en',
    data: {
        SERVER: {
            ERRORS: {
                LINK_IS_CORRUPTED: 'Unexpected error occured.',
                REQUEST_IS_CORRUPTED: 'Request\'s data is corrupted.'
            }
        }
    }
};