export const locale = {
    lang: 'en',
    data: {
        LISTING_LABELS: {
            NEW_MATCH: {
                LONG_TITLE: 'New Match'
            },
            NEWLY_ADDED_BY_AGENT: {
                LONG_TITLE: 'Newly Added by Agent'
            },
            OPEN_HOUSE: {
                LONG_TITLE: 'Open House',
                NEXT: 'Next',
                PUBLIC: 'Public',
                BROKER_ONLY: 'Broker Only'
            },
            CATEGORY: {
                SHORT: {
                    SALES: 'S',
                    RENTAL: 'R'
                },
                LONG: {
                    SALES: 'Sale',
                    RENTAL: 'Rental'
                }
            }
        }
    }
};