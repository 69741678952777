import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { AppointmentStatus } from '../../enums/appointment-status.enum';

export class AppointmentUpdateStatusActionModel {
    public readonly listingHashCode: number;
    public readonly appointmentId: number;
    public readonly customerStatus: AppointmentStatus;
    public readonly previousCustomerStatus: AppointmentStatus;
    public readonly oldListingAppointment: ListingAppointment;
    public readonly updateId: number;

    constructor(
        listingHashCode: number,
        appointmentId: number,
        customerStatus: AppointmentStatus,
        oldListingAppointment: ListingAppointment,
        previousCustomerStatus: AppointmentStatus,
        updateId: number
    ) {
        this.listingHashCode = listingHashCode;
        this.appointmentId = appointmentId;
        this.customerStatus = customerStatus;
        this.oldListingAppointment = oldListingAppointment;
        this.previousCustomerStatus = previousCustomerStatus;
        this.updateId = updateId;
    }
}