import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Params, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Invitation } from '@auth/models/invitation';
import { User } from '@auth/models/user';
import { RegistrationStoreService } from '@auth/store/services/registration-store.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ToastService } from '@core-services/toast.service';
import { TranslationLoaderService } from '@core-services/translation-loader.service';

@Injectable({ providedIn: 'root' })
export class RegistrationGuard implements CanActivateChild, CanActivate {

    constructor(
        private readonly routeService: RouteService,
        private readonly userStoreService: UserStoreService,
        private readonly registrationStoreService: RegistrationStoreService,
        private readonly toastService: ToastService,
        private readonly translationLoaderService: TranslationLoaderService,
    ) {
        this.translationLoaderService.loadTranslations({
            lang: 'en',
            data: {
                REGISTRATION_GUARD: {
                    WRONG_USER: 'Cannot proceed with another workspace addition because it is intended for another user.'
                }
            }
        });
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.canActivateRoute(childRoute.params);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivateRoute(route.params);
    }

    private canActivateRoute(params: Params): Observable<boolean> {
        this.registrationStoreService.loadInvitation(params.code as string);

        return combineLatest([
            this.userStoreService.getUserInvitation(),
            this.userStoreService.getUser()
        ]).pipe(
            take(1),
            switchMap(([invitation, user]) => {
                const forbidAction = (): Observable<boolean> => {
                    this.routeService.navigate(RpcRoute.Portfolio).catch(() => { });
                    return of(false);
                };

                if (this.hasLoggedInUserData(user) && this.isSimpleRegistration(invitation)) {
                    return forbidAction();
                } 
                    if (!this.hasLoggedInUserData(user) || this.isInvitationForLoggedInUser(user, invitation)) {
                        return of(true);
                    }

                    this.toastService.showClientError('REGISTRATION_GUARD.WRONG_USER');
                    return forbidAction();
                
            })
        );
    }

    private hasLoggedInUserData(user: User): boolean {
        return user != null && user.loginData != null;
    }

    private isSimpleRegistration(invitation: Invitation): boolean {
        return !invitation.isWorkspaceAddition;
    }

    private isInvitationForLoggedInUser(user: User, invitation: Invitation): boolean {
        return user != null && invitation != null && invitation.isWorkspaceAddition && invitation.customer.email === user.customerUserName;
    }
}