import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { GalleryModule } from '@ngx-gallery/core';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { NgLetModule } from 'ng-let';
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { FlexLayoutModule } from '@angular/flex-layout';
import { RpcControlsModule } from '@core-controls/controls.module';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { CustomSpinnerModule } from '@core-layout/custom-spinner/custom-spinner.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { DefaultIFrameComponent } from '@media/components/default-iframe/default-iframe.component';
import { DefaultImageComponent } from '@media/components/default-image/default-image.component';
import { DefaultVideoComponent } from '@media/components/default-video/default-video.component';
import { MediaGalleryComponent } from '@media/components/media-gallery/media-gallery.component';
import { MediaSliderComponent } from '@media/components/media-slider/media-slider.component';
import { MediaViewerComponent } from '@media/components/media-viewer/media-viewer.component';
import { MediaViewerBreakPointsProvider } from '@media/components/media-viewer/models/media-viewer-breakpoints';
import { locale as english } from '@media/i18n/en';
import { MediaLinkPipe } from '@media/pipes/media-link.pipe';
import { RpcPipesModule } from '@core-pipes/pipes.module';

@NgModule({
    declarations: [
        MediaLinkPipe,
        MediaGalleryComponent,
        MediaSliderComponent,
        MediaViewerComponent,
        DefaultImageComponent,
        DefaultIFrameComponent,
        DefaultVideoComponent,
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        GalleryModule,
        PinchZoomModule,
        MatButtonModule,
        FlexLayoutModule,
        MatDialogModule,
        MatRippleModule,
        NgLetModule,
        InViewportModule,

        // Application Modules
        RpcIconModule,
        RpcControlsModule,
        CustomSpinnerModule,
        RpcDirectivesModule,
        RpcPipesModule
    ],
    exports: [
        MediaGalleryComponent,
        MediaSliderComponent,
        MediaViewerComponent,
        DefaultImageComponent,
        DefaultIFrameComponent,
        DefaultVideoComponent,
    ],
    providers: [
        MediaViewerBreakPointsProvider,
    ]
})

export class MediaModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}