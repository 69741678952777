import { AbstractControl, ValidationErrors } from '@angular/forms';

export const emailAvailableForInvitation = (unavailableEmails: string[]) : ((control: AbstractControl) => ValidationErrors | null) => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        const controlValue = currentControl.value as string;

        if (!unavailableEmails.includes(controlValue))
        {
            return null;
        }

        return { unavailable: true };
    };
};