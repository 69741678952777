import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { RpcSelectControltOptions } from '@core-controls/components/rpc-select/models/rpc-select-controlt-options';
import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';
import { NewAccountDataModel, OldAccountDataModel } from '../../interfaces/customer-info/account-data-model';
import { Email } from '../../interfaces/customer-info/email';
import { ITopProfileInfo } from '../../interfaces/customer-info/top-profile-info';

@Component({
    selector: 'profile-top',
    templateUrl: './profile-top.component.html',
    styleUrls: ['./profile-top.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileTopComponent {

    private emails: Email[] = [];

    // tslint:disable-next-line: no-unsafe-any
    @Input()
    public set profileInfo(profileInfo: ITopProfileInfo) {
        if (profileInfo == null) {
            return;
        }

        const { firstName, lastName, userName, emails, isAdditionalProfile } = profileInfo;

        this.firstName = firstName;
        this.lastName = lastName;
        this.userName = userName;
        this.emails = emails;
        this.isAdditionalProfile = isAdditionalProfile;
        this.fullName = `${firstName} ${lastName}`;

        this.buildForm(firstName, lastName, emails);
    }

    @Output() updateAccountData = new EventEmitter<{ newData: NewAccountDataModel, oldData: OldAccountDataModel }>();

    public accountDataForm: FormGroup;
    public firstNameControlOptions: RpcInputOptions;
    public lastNameControlOptions: RpcInputOptions;
    public mainEmailSelectOptions: RpcSelectControltOptions;
    public mainAdditionalProfileEmailOptions: RpcInputOptions;
    public firstName: string;
    public lastName: string;
    public userName: string;
    public isEdit = false;

    public isAdditionalProfile: boolean;
    public fullName: string;

    constructor(private readonly formBuilder: FormBuilder) { }

    public onEdit(): void {
        this.setEditMode(true);
    }

    public onCancel(): void {
        this.setEditMode(false);
    }

    public onSave(): void {

        // tslint:disable: no-unsafe-any
        const { firstName, lastName, accountName } = this.accountDataForm.value;

        const email = this.isAdditionalProfile ? this.userName : accountName;
        const newAccountEmail = this.emails.find(({ emailAddress }) => emailAddress === email);

        const newData: NewAccountDataModel = { firstName, lastName, newAccountName: email, oldAccountName: this.userName };
        const oldData: OldAccountDataModel = {
            firstName: this.firstName,
            lastName: this.lastName,
            oldAccountName: this.userName,
            newAccountName: newAccountEmail.emailAddress,
            newAccountEmailPreviousCarbonCopy: newAccountEmail.isCarbonCopy
        };

        this.updateAccountData.emit({ newData, oldData });
        this.setEditMode(false);
    }

    private buildForm(firstName: string, lastName: string, emails: Email[]): void {
        if (this.accountDataForm == null) {
            this.accountDataForm = this.formBuilder.group({});
        }

        this.firstNameControlOptions = {
            formGroup: this.accountDataForm,
            controlName: 'firstName',
            defaultValue: firstName,
            validatorsMap: [
                {
                    message: 'PROFILE_TOP.VALIDATION_ERRORS.FIRST_NAME_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };

        this.lastNameControlOptions = {
            formGroup: this.accountDataForm,
            controlName: 'lastName',
            defaultValue: lastName,
            validatorsMap: [
                {
                    message: 'PROFILE_TOP.VALIDATION_ERRORS.LAST_NAME_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };

        const loginEmail = emails.find(x => x.isForLogin)?.emailAddress;

        this.mainEmailSelectOptions = new RpcSelectControltOptions(
            this.accountDataForm,
            'accountName',
            emails.map(email => new RpcSelectOption(email.emailAddress, email.emailAddress, 'user')),
            loginEmail
        );

        this.mainAdditionalProfileEmailOptions = {
            formGroup: this.accountDataForm,
            controlName: 'additionalProfileAccountName',
            defaultValue: loginEmail
        };
    }

    private setEditMode(isEditMode: boolean): void {
        this.isEdit = isEditMode;
    }
}