export const locale = {
    lang: 'en',
    data: {
        INVITE_USER_FORM: {
            FIELDS: {
                RECIPIENT_EMAIL: 'Recipient\'s Email',
                MESSAGE: 'Message (optional)'
            },
            ERRORS: {
                EMAIL_REQUIRED: 'Email is required',
                WRONG_EMAIL: 'Please enter a valid email address'
            }
        }
    }
};
