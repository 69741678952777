export enum RpcRoute {
    Login = 'login',
    ForgotPassword = 'forgot-password',
    RestorePassword = 'restore-password/:code',
    Registration = 'registration',
    TermsAndConditions = 'terms-and-conditions',
    Portfolio = 'portfolio',
    SearchListings = 'search-listings',
    Appointments = 'appointments',
    Listing = 'listing/:id',
    SharedListing = 'shared-listing',
    SharedListingWithCode = 'shared-listing/:code',
    Profile = 'profile',
    NewMatch = 'new-match/:listingId',
    NotFound = 'not-found',
    MortgageReport = 'mortgage-report',
    AdditionalProfile = 'additional-profile',
    AgentSelection = 'agent-selection',
    Folder = ':folder',
    ExrernalListings = 'external-listings',
    ExrernalListingsFullPath = '/portfolio/external-listings',
    FolderFullPath = '/portfolio/:folder',
    KnowledgeBase = 'knowledge-base'
}