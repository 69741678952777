import { Component, Input } from '@angular/core';

import { AgentBaseInfo } from '@agents/models/agent-base-info';

@Component({
    selector: 'portfolio-agent-info',
    templateUrl: './portfolio-agent-info.component.html',
    styleUrls: ['./portfolio-agent-info.component.scss']
})
export class PortfolioAgentInfoComponent {
    @Input() agent: AgentBaseInfo;
    @Input() showCompany: boolean;

    public isDefined(value: string): boolean {
        return value != null && value !== '';
    }
}
