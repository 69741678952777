import { SortOptionBase } from '@core-models/sort/sort-option';
import { ListingCategories } from '@listings/enums/listing-categories.enum';
import { ViewModes } from '@view-base/models/view-modes.enum';

export class OnMarketSettings {

    constructor(
        public sortSettings: SortOptionBase,
        public selectedSearchCategory: ListingCategories,
        public viewMode: ViewModes,
        public mapSizePercent: number,
        public defaultSavedSearchId: number
    ) {
    }

}