import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UserBaseInfo } from '@users/models/user-base-info';
import * as usersActions from '../actions/users.actions';
import * as usersSelectors from '../selectors/users.selectors';
import { UsersState } from '../states/users.state';

@Injectable({ providedIn: 'root' })
export class UsersStoreService {
    constructor(private readonly store: Store<UsersState>) { }

    public readonly customersAndAgents$ = this.store.select(usersSelectors.selectCustomersAndAgents);
    public readonly customersAndAgentsCount$ = this.store.select(usersSelectors.selectCustomersAndAgentsCount);
    public readonly customers$ = this.store.select(usersSelectors.selectCustomers);
    public readonly customersAndAgentsIds$ = this.store.select(usersSelectors.selectCustomersAndAgentsIds);

    public getUserInfoOrRemovedAccount(customerId: number): Observable<UserBaseInfo> {
        return this.store.select(usersSelectors.selectUserInfoOrRemovedAccount(customerId));
    }

    public loadCustomers(): void {
        this.store.dispatch(usersActions.loadCustomers());
    }

}