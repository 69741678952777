<div class="multi-selection" *ngIf="listingIds != null && listingIds.length > 0"
    [ngClass]="{'mobile' : compact, 'multi-selection--active' : someListingsSelected || allListingsSelected}">
    <mat-checkbox class="multi-selection-checkbox" [checked]="allListingsSelected"
        [indeterminate]="someListingsSelected" (change)="onSelectionChanged($event.checked)">
        {{ compact && selectedListingIds.length !== 0 ? selectedListingIds.length + ' selected' : 'Select All' }}
    </mat-checkbox>
    <mat-divider class="multi-selection-divider multi-selection-checkbox-divider" [vertical]="true"
        *ngIf="!compact && (someListingsSelected || allListingsSelected)"></mat-divider>

    <div class="multi-selection-counter" *ngIf="!compact && (someListingsSelected || allListingsSelected)">
        {{ selectedListingIds.length + ' selected' }}
    </div>

    <ng-container *ngIf="selectedListingIds.length > 0">
        <ng-container *ngTemplateOutlet="selectionSubMenu"></ng-container>
    </ng-container>
</div>