import { locale as comments } from '@comments/components/comments/i18n/en';

const commentsCommon = {
    COMMENTS_COMMON: {
        ERRORS: {
            LISTINGS_COMMENTS_LOADING: 'Failed to load listings comments.',
            LISTING_COMMENTS_LOADING: 'Failed to load listing comments.',
            COMMENT_CREATION: 'Failed to create comment. Please, try again.',
            COMMENTS_MARK_AS_VIEWED: 'Failed to mark comment as viewed.',
            COMMENT_UPDATE: 'Failed to update comment. Please, try again.',
            LISTING_REMOVED_ERROR: 'The process is aborted due to listing removal.'
        },
        DIALOGS: {
            INSERT_TO_NEW_LISTING: 'Creating a comment for this listing will also add it to your Pick List.',
            BUTTONS: {
                CREATE_COMMENT: 'Create Comment'
            }
        }
    }
};

export const locale = {
    lang: 'en',
    data: {
        ...commentsCommon,
        ...comments.data
    }
};