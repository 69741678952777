import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { RpcControlsModule } from '@core-controls/controls.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { SimpleDialogComponent } from '@core-utils/simple-dialog/components/simple-dialog/simple-dialog.component';
import { locale as english } from '@core-utils/simple-dialog/i18n/en';

@NgModule({
    declarations: [
        SimpleDialogComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        MatButtonModule,
        FlexLayoutModule,

        RpcIconModule,
        RpcControlsModule
    ]
})
export class SimpleDialogModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}