<div class="ium-wrap">
    <div class="ium-title">{{ 'INVITE_USER_DIALOG.TITLES.TITLE' | translate }}</div>
    <rpc-icon-button class="ium-close" [iconName]="'cross'" [title]="'INVITE_USER_DIALOG.BUTTONS.CLOSE'"
        [mat-dialog-close]="false" (click)='close()'>
    </rpc-icon-button>
    <div class="ium-text">{{ 'INVITE_USER_DIALOG.TITLES.DESCRIPTION' | translate }}</div>
    <invite-user-form class="ium-form-wrap" [email]="inviteDialogData?.email"
        [portfolioEmails]="inviteDialogData?.portfolioEmails" (sendEmailClicked)="sendEmail($event)"
        (canceled)="close()">
    </invite-user-form>
</div>