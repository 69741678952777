import { AbstractControl, ValidationErrors } from '@angular/forms';

export const getPatternValidator = (regex: RegExp, error: ValidationErrors): (control: AbstractControl) => ValidationErrors | null => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        if (currentControl == null || currentControl.parent == null || currentControl.value == null || currentControl.value === '') {
            return null;
        }

        const valid = regex.test(currentControl.value as string);

        return valid ? null : error;
    };
};