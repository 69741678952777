import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { Listings } from '@listings/models/listing/listings';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingsState } from '../states/listings.state';
import { ListingsReducerHelpers } from './helpers/listings.reducer.helper';

export const marketListingReducerTypes: ReducerTypes<ListingsState, readonly ActionCreator[]>[] = [
    on(listingActions.loadMarketListings, (state) => ({ 
        ...state,
        marketListingsLoaded: false,
        marketListingsLoading: true
    })),
    on(listingActions.loadMarketListingsSuccess, (state, action) => {
        const listings = Object.values(action.listings)
            .map(listing => ({
                ...listing
            }))
            .reduce((acc, curr) => (acc[curr.id] = curr, acc), {});

        return {
            ...state,
            listings: ListingsReducerHelpers.updateListings({ ...state.listings }, listings, false),
            marketListingsLoaded: true,
            marketListingsLoading: false
        };
    }),
    on(listingActions.loadMarketListingsFailed, (state) => ({
        ...state,
        marketListingsLoaded: false,
        marketListingsLoading: false
        
    })),
    on(listingActions.setMarketListingsLoaded, (state, { isLoaded }) => ({ ...state, marketListingsLoaded: isLoaded })),
    on(listingActions.clearMarketListings, (state) => {
        const filteredListings = Object.keys(state.listings)
            .reduce(
                (listings, key) => {
                    const stateListing = state.listings[key];
                    if (!stateListing.isMarketListing || stateListing.activities.length > 0 || stateListing.isNewMatch) {
                        stateListing.isMarketListing = false;
                        listings[key] = stateListing;
                    }
                    return listings;
                },
                new Listings());

        return {
            ...state,
            listings: filteredListings
        };
    }),
];