import { Telemetry } from '@customer-activity/models/telemetry';

export class AddUserActivityRequest {
    constructor(
        public telemetry: Telemetry
    ) { }
}

export class AddUserActivityWithActivityRequest {
    constructor(
        public activity: string,
        public telemetry: Telemetry
    ) { }
}