import { createAction, props } from '@ngrx/store';

import { ListingComment } from '@comments/models/comments/listing-comment';
import { ListingsComments } from '@comments/models/comments/listings-comments';
import { CommentCreateActionModel } from '@comments/store/models/comment-create-action-model';
import { CommentUpdateActionModel } from '@comments/store/models/comment-update-action-model';
import { MarkAsViewedActionModel } from '@comments/store/models/mark-as-viewed-action-model';
import { ApiError } from '@error/models/api-error';

export const loadListingsComments = createAction(
    '[Comments] Load Listings Comments'
);
export const loadListingsCommentsSuccess = createAction(
    '[Comments] Load Listings Comments Success',
    props<{ comments: ListingsComments }>()
);
export const loadListingsCommentsFailed = createAction(
    '[Comments] Load Listings Comments Failed',
    (error: ApiError) => error
);

export const loadListingComments = createAction(
    '[Comments] Load Listing Comments',
    props<{ listingId: string }>()
);
export const loadListingCommentsSuccess = createAction(
    '[Comments] Load Listing Comments Success',
    props<{ comments: ListingComment[]; }>()
);
export const loadListingCommentsFailed = createAction(
    '[Comments] Load Listing Comments Failed',
    (error: ApiError) => error
);

export const loadComment = createAction('[Comments] Load Comment', props<{ id: number }>());
export const loadCommentSuccess = createAction('[Comments] Load Comment Success', props<{ comment: ListingComment }>());
export const loadCommentFailed = createAction('[Comments] Load Comment Failed');

export const createListingComment = createAction(
    '[Comments] Create Listing Comment',
    props<CommentCreateActionModel>()
);
export const createListingCommentSuccess = createAction(
    '[Comments] Create Listing Comment Success',
    props<{ comment: ListingComment, request: CommentCreateActionModel }>()
);
export const createListingCommentFailed = createAction(
    '[Comments] Create Listing Comment Failed',
    (error: ApiError, request: CommentCreateActionModel) => {
        return { error: error, request: request };
    }
);

export const markCommentAsViewed = createAction(
    '[Comments] Mark Comment As Viewed',
    props<MarkAsViewedActionModel>()
);
export const markCommentAsViewedSuccess = createAction(
    '[Comments] Mark Comment As Viewed Success',
    props<MarkAsViewedActionModel>()
);
export const markCommentAsViewedFailed = createAction(
    '[Comments] Mark Comment As Viewed Failed',
    (error: ApiError, request: MarkAsViewedActionModel) => {
        return { error: error, request: request };
    }
);

export const updateListingComment = createAction(
    '[Comments] Update Listing Comment',
    props<CommentUpdateActionModel>()
);
export const updateListingCommentSuccess = createAction(
    '[Comments] Update Listing Comment Success',
    props<CommentUpdateActionModel>()
);
export const updateListingCommentFailed = createAction(
    '[Comments] Update Listing Comment Failed',
    (error: ApiError, request: CommentUpdateActionModel) => {
        return { error: error, request: request };
    }
);

export const resetState = createAction('[Comments] Reset State');