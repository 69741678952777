/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { SortDirection } from '@core-models/sort/sort-direction';
import { SortOptionBase } from '@core-models/sort/sort-option';

export class SortCollectionHelper {
    public static sort<T>(collection: T[], selectedSort: SortOptionBase, alternativeSort?: SortOptionBase, caseSensitivity = false): T[] {
        if (collection == null || collection.length === 0) {
            return [];
        }
        if (selectedSort == null) {
            return collection;
        }

        collection.sort((firstItem: T, secondItem: T) => {
            const firstItemValue = SortCollectionHelper.getValue(firstItem, selectedSort.propertyName, caseSensitivity, alternativeSort?.propertyName);
            const secondItemValue = SortCollectionHelper.getValue(secondItem, selectedSort.propertyName, caseSensitivity, alternativeSort?.propertyName);
            if (firstItemValue < secondItemValue) {
                return selectedSort.direction === SortDirection.Asc ? -1 : 1;
            } else if (firstItemValue > secondItemValue) {
                return selectedSort.direction === SortDirection.Asc ? 1 : -1;
            } 
                return 0;
            
        });

        return collection;
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    private static getValue(item: any, propertyName: string, caseSensitivity: boolean, alternativePropertyName?: string): any {
        if (propertyName == null || propertyName === '') {
            return null;
        }

        let propertyValue = SortCollectionHelper.getPropertyValue(item, propertyName, caseSensitivity);

        if (propertyValue == null && alternativePropertyName != null && alternativePropertyName !== '') {
            propertyValue = SortCollectionHelper.getPropertyValue(item, alternativePropertyName, caseSensitivity);
        }
        return caseSensitivity ? propertyValue?.toLowerCase() : propertyValue;
    }

    private static getPropertyValue(item: any, propertyName: string, caseSensitivity: boolean): any {
        const itemProperies = propertyName.split('.');
        return itemProperies.length > 1
            ? SortCollectionHelper.getValue(item[itemProperies[0]], itemProperies.slice(1, itemProperies.length).join(), caseSensitivity)
            : item[itemProperies[0]];
    }
}