import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Observer, Subject, timer } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { User } from '@auth/models/user';
import { TokenService } from '@auth/services/token.service';
import { loginSuccessful, logout, logoutFailed, refreshFailed, refreshSuccessful } from '@auth/store/actions/login.actions';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { ApiError } from '@error/models/api-error';
import { CommunicationSignalRService } from '@notifications/services/communication-signalr.service';

@Injectable({ providedIn: 'root' })
export class TokenInitializerService implements OnDestroy {

    private readonly MinimumMillisecondsUntilExpiration = 5000;
    private readonly CommunicationHubListenerCreationPostponeTime = 5000;
    private readonly ExpirationMultiplier = 0.9;

    private readonly unsubscribe$ = new Subject<void>();
    private refreshTokenUnsubscribe$ = new Subject<void>();
    private timerUnsubscribe$ = new Subject<void>();

    constructor(
        private readonly actions$: Actions,
        private readonly communicationSignalRService: CommunicationSignalRService,
        private readonly userStoreService: UserStoreService
    ) {
        this.actions$
            .pipe(ofType(loginSuccessful), takeUntil(this.unsubscribe$))
            .subscribe((loginData) => {
                this.startTimerTokenRefreshing(loginData.user);
            });

        this.actions$
            .pipe(ofType(logout), takeUntil(this.unsubscribe$))
            .subscribe(this.logout);

        this.actions$
            .pipe(ofType(logoutFailed), takeUntil(this.unsubscribe$))
            .subscribe(this.logout);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

        this.unsubscribeTemporarySubscriptions();
    }

    public initialize(): Promise<void> {
        return new Promise<void>(resolve => {
            this.userStoreService.getUser()
                .pipe(take(1))
                .subscribe((storedUser: User) => {
                    if (storedUser != null) {
                        this.startTimerTokenRefreshing(storedUser);

                        const millisecondsUntilExpiration = storedUser.expiration.getTime() - Date.now();
                        if (millisecondsUntilExpiration <= this.MinimumMillisecondsUntilExpiration) {
                            this.userStoreService.refresh();
                        }
                    }

                    resolve();
                });
        });
    }

    public refreshToken(): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            this.actions$
                .pipe(ofType(refreshSuccessful), take(1), map(x => x.loginData.accessToken))
                .subscribe((token) => {
                    observer.next(token);
                });

            this.actions$
                .pipe(ofType(refreshFailed), take(1))
                .subscribe((error: ApiError) => {
                    observer.error(error);
                });

            this.userStoreService.refresh();
        });
    }

    public readonly initializeNotifications = (): void => {
        this.communicationSignalRService.createNotificationHub(this.userStoreService.getAccessToken());
        setTimeout(() => this.communicationSignalRService.createHubListeners(), this.CommunicationHubListenerCreationPostponeTime);
    }

    private startTimerTokenRefreshing(user: User): void {
        this.unsubscribeRefreshToken();
        this.refreshTokenUnsubscribe$ = new Subject<void>();

        this.startTimer(user);
        this.initializeNotifications();

        this.actions$
            .pipe(ofType(refreshSuccessful), takeUntil(this.refreshTokenUnsubscribe$))
            .subscribe((refreshedUser: User) => {
                this.startTimer(refreshedUser);
            });

        this.actions$
            .pipe(ofType(refreshFailed), take(1))
            .subscribe(() => {
                this.unsubscribeRefreshToken();
                this.refreshTokenUnsubscribe$ = new Subject<void>();
                this.userStoreService.logout();
            });
    }

    private readonly startTimer = (user: User): void => {
        const millisecondsUntilExpiration = user.expiration.getTime() - Date.now();
        if (millisecondsUntilExpiration <= this.MinimumMillisecondsUntilExpiration) {
            return;
        }

        this.unsubscribeTimer();
        this.timerUnsubscribe$ = new Subject<void>();

        const delay = millisecondsUntilExpiration * this.ExpirationMultiplier;
        timer(delay).pipe(takeUntil(this.timerUnsubscribe$)).subscribe(() => this.userStoreService.refresh());
    }

    private readonly logout = (): void => {
        this.unsubscribeTemporarySubscriptions();
        this.communicationSignalRService.removeNotificationHub();
        TokenService.removeUserTokens();
    }

    private readonly unsubscribeTemporarySubscriptions = (): void => {
        this.unsubscribeTimer();
        this.unsubscribeRefreshToken();
    }

    private readonly unsubscribeTimer = (): void => {
        if (this.timerUnsubscribe$ != null) {
            this.timerUnsubscribe$.next();
            this.timerUnsubscribe$.complete();
        }
    }

    private readonly unsubscribeRefreshToken = (): void => {
        if (this.refreshTokenUnsubscribe$ != null) {
            this.refreshTokenUnsubscribe$.next();
            this.refreshTokenUnsubscribe$.complete();
        }
    }
}