import { Injectable } from '@angular/core';

import { SidebarComponent } from '@core-layout/sidebar/sidebar.component';

@Injectable({ providedIn: 'root' })
export class SidebarService {

    private readonly registry: { [key: string]: SidebarComponent } = {};

    /**
     * Add the sidebar to the registry
     *
     * @param key
     * @param sidebar
     */
    public register(key: string, sidebar: SidebarComponent): void {
        if (this.registry[key] != null) {
            console.error(`The sidebar with the key '${key}' already exists. Either unregister it first or use a unique key.`);
            return;
        }

        this.registry[key] = sidebar;
    }

    /**
     * Remove the sidebar from the registry
     *
     * @param key
     */
    public unregister(key: string): void {
        if (this.registry[key] == null) {
            console.warn(`The sidebar with the key '${key}' doesn't exist in the registry.`);
        }

        delete this.registry[key];
    }

    /**
     * Return the sidebar with the given key
     *
     * @param key
     * @returns {SidebarComponent}
     */
    public getSidebar(key: string): SidebarComponent {
        // Check if the sidebar exists
        if (this.registry[key] == null) {
            console.warn(`The sidebar with the key '${key}' doesn't exist in the registry.`);
            return;
        }

        return this.registry[key];
    }

    /**
     * Close all sidebars.
     */
    public closeAll(): void {
        Object.keys(this.registry).forEach(key => {
            if (this.registry[key] != null && this.registry[key].opened) {
                this.registry[key].close();
            }
        });
    }
}