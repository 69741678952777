// TODO : Split store (usage in profile state in Phone model of user) and forms models.

export class PhoneInfo {
    phoneNumber: string;
    countryCode: string;
    isValid: boolean;
    phoneId: number;

    constructor(phoneNumber?: string, countryCode?: string, isValid?: boolean, phoneId?: number) {
        this.phoneNumber = phoneNumber;
        this.countryCode = countryCode;
        this.isValid = isValid;
        this.phoneId = phoneId;
    }
}