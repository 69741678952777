import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({ selector: '[rpcOnLoad]' })
export class RpcOnLoadDirective implements OnInit, OnDestroy {

    @Input() public rpcOnLoad: string;
    @Input() public loadedParentClassName = 'loaded';

    @Output() public loaded = new EventEmitter<void>();

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>
    ) { }

    public ngOnInit(): void {
        this.rpcOnLoad = this.rpcOnLoad == null || this.rpcOnLoad === '' ? 'load' : this.rpcOnLoad;

        this.elementRef.nativeElement.addEventListener(this.rpcOnLoad, this.onLoad.bind(this));
    }

    public ngOnDestroy(): void {
        this.elementRef.nativeElement.removeEventListener(this.rpcOnLoad, this.onLoad.bind(this));
    }

    public onLoad(): void {
        if (this.elementRef != null && this.elementRef.nativeElement != null) {
            const {parentElement} = this.elementRef.nativeElement;
            parentElement.className += ` ${this.loadedParentClassName}`;
            this.loaded.emit();
        }
    }
}