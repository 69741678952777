import { locale as mediaGallery } from '@media/components/media-gallery/i18n/en';
import { locale as mediaSlider } from '@media/components/media-slider/i18n/en';
import { locale as mediaViewer } from '@media/components/media-viewer/i18n/en';

const localesData = {
    ...mediaViewer.data,
    ...mediaSlider.data,
    ...mediaGallery.data
};

export const locale = {
    lang: 'en',
    data: localesData
};