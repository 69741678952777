/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Injectable } from '@angular/core';
import { NewMatchSettingsRequest } from '@settings/models/settings/new-match-settings-request';

import { NewMatchCheckingFrequency } from '../../profile-base/interfaces/enums/new-match-checking-frequency.enum';
import { NewMatchDailyFrequency } from '../../profile-base/interfaces/enums/new-match-daily-frequency.enum';
import { NewMatchEmailTemplate } from '../../profile-base/interfaces/enums/new-match-email-template.enum';
import { NewMatchMonthlyFrequency } from '../../profile-base/interfaces/enums/new-match-monthly-frequency.enum';
import { NewMatchWeekDays } from '../../profile-base/interfaces/enums/new-match-weekdays.enum';
import { NewMatchWeeklyFrequency } from '../../profile-base/interfaces/enums/new-match-weekly-frequency.enum';
import { NewMatchNotificationFrequency } from '../../profile-base/interfaces/new-match-settings/new-match-notification-frequency';
import { NewMatchSettingsModel } from '../../profile-base/interfaces/new-match-settings/new-match-settings.model';

const realTime = 237;
const hotProspect = 3729;
const weekly = 3730;
const monthly = 3730;
const dayOfWeekCodes = [232, 230, 234, 235, 233, 229, 231];

const weeklyFrequencyMap = [
    [3535, 3529, 3530, 3531, 3532, 3533, 3534],
    [3542, 3536, 3537, 3538, 3539, 3540, 3541],
    [3549, 3543, 3544, 3545, 3546, 3547, 3548],
    [3556, 3550, 3551, 3552, 3553, 3554, 3555]
];

const monthlyFrequencies = Object
    .values(NewMatchMonthlyFrequency)
    .filter(val => !isNaN(Number(val)))
    .map(Number);

@Injectable({ providedIn: 'root' })
export class NewMatchSettingsService {
    public createSettings(settingsValues: number[]): NewMatchSettingsModel {
        if (settingsValues == null || settingsValues.length === 0) {
            return null;
        }
        const checkingFrequency = settingsValues.includes(NewMatchCheckingFrequency.daily)
            ? NewMatchCheckingFrequency.daily
            : NewMatchCheckingFrequency.realtime;

        const emailTemplate = settingsValues.includes(NewMatchEmailTemplate.detail)
            ? NewMatchEmailTemplate.detail
            : NewMatchEmailTemplate.summary;

        const notificationFrequency = this.createNotificationFrequency(settingsValues);
        return new NewMatchSettingsModel(checkingFrequency, emailTemplate, notificationFrequency);
    }

    public formatRequest(settingsModel: NewMatchSettingsModel): NewMatchSettingsRequest {
        const { checkingFrequency, emailTemplate, emailNotificationFrequency } = settingsModel;
        const personalSettings = this.getEmailNotificationFrequencyValues(emailNotificationFrequency);
        const sharedSettings = [checkingFrequency, emailTemplate];

        return new NewMatchSettingsRequest(personalSettings, sharedSettings);
    }

    private dailyFrequencyToCodes(days: NewMatchWeekDays[]): number[] {
        return days.map(day => dayOfWeekCodes[day]);
    }

    private weeklyFrequencyToCode(week: NewMatchWeeklyFrequency, day: NewMatchWeekDays): number {
        return weeklyFrequencyMap[week][day];
    }

    private createNotificationFrequency(settingsValues: number[]): NewMatchNotificationFrequency {
        if (settingsValues.includes(realTime)) {
            return { kind: 'realtime' };
        }

        if (settingsValues.some(val => dayOfWeekCodes.includes(val))) {
            const daysOfWeek = dayOfWeekCodes
                .reduce((result, code, index) => {
                    if (settingsValues.includes(code)) {
                        result.push(index);
                    }
                    return result;
                }, new Array<NewMatchWeekDays>());

            const frequency = this.getDailyFrequency(settingsValues);
            return { kind: 'daily', frequency: frequency, days: daysOfWeek };
        }

        if (this.isWeekly(settingsValues)) {
            const [frequency, day] = this.getWeeklyFrequency(settingsValues);
            return { kind: 'weekly', frequency: frequency, day: day };
        }

        const monthlyFrequency = monthlyFrequencies.filter(val => settingsValues.includes(val))[0];
        return { kind: 'monthly', frequency: monthlyFrequency };
    }

    private getDailyFrequency(settingsValues: number[]): NewMatchDailyFrequency {
        if (settingsValues.includes(NewMatchDailyFrequency.OncePerDay)) {
            return NewMatchDailyFrequency.OncePerDay;
        }

        if (settingsValues.includes(NewMatchDailyFrequency.TwoTimesPerDay)) {
            return NewMatchDailyFrequency.TwoTimesPerDay;
        }

        return NewMatchDailyFrequency.FourTimesPerDay;
    }

    private isWeekly(settingsValues: number[]): boolean {
        for (const week of weeklyFrequencyMap) {
            for (const day of week) {
                if (settingsValues.includes(day)) {
                    return true;
                }
            }
        }
        return false;
    }

    private getWeeklyFrequency(settingsValues: number[]): [NewMatchWeeklyFrequency, NewMatchWeekDays] {
        for (let week = 0; week < weeklyFrequencyMap.length; week++) {
            for (let day = 0; day < weeklyFrequencyMap[week].length; day++) {
                if (settingsValues.includes(weeklyFrequencyMap[week][day])) {
                    return [week, day];
                }
            }
        }
        throw new Error('Incorrect weekly settings: [' + settingsValues.toString() + ']');
    }

    private getEmailNotificationFrequencyValues(emailNotificationFrequency: NewMatchNotificationFrequency): number[] {
        switch (emailNotificationFrequency.kind) {
            case 'realtime':
                return [realTime, hotProspect];
            case 'daily':
                return [
                    ...this.dailyFrequencyToCodes(emailNotificationFrequency.days),
                    emailNotificationFrequency.frequency
                ];
            case 'weekly':
                return [
                    this.weeklyFrequencyToCode(emailNotificationFrequency.frequency, emailNotificationFrequency.day),
                    weekly
                ];
            case 'monthly':
                return [emailNotificationFrequency.frequency, monthly];
            default: return [];
        }
    }
}