import { locale as emailsList } from '@profile/components/emails-list/i18n/en';
import { locale as addressesEditForm } from '../components/addresses-edit-form/i18n/en';
import { locale as emailAlertSettings } from '../components/email-alert-settings/i18n/en';
import { locale as inviteUserDialog } from '../components/invite-user-dialog/i18n/en';
import { locale as inviteUserForm } from '../components/invite-user-form/i18n/en';
import { locale as newMatchSettings } from '../components/new-match-settings/i18n/en';
import { locale as password } from '../components/password/i18n/en';
import { locale as phonesEditForm } from '../components/phones-edit-form/i18n/en';
import { locale as profileTop } from '../components/profile-top/i18n/en';
import { locale as welcomeVideoLink } from '../components/welcome-video-link/i18n/en';

const profileCommon = {
    PROFILE_COMMON: {
        ENUM_TYPES: {
            home: 'Home',
            mobile: 'Mobile',
            office: 'Office',
            personal: 'Personal',
            work: 'Work',
            other: 'Other'
        },
        ERRORS: {
            EMAIL_USED_BY_SAME_COMPANY_CUSTOMER: 'This email address is already in use by another user.',
            EMAIL_USED_BY_ANOTHER_COMPANY_CUSTOMER: 'This email address is already in use by another user.',
            EMAIL_EXISTS_WITHIN_ANOTHER_ACCOUNT: 'That email address is already in use within another account.',
            INVITE_ONE_MORE_CHILD_ACCOUNT_FAILED: 'The system does not allow more users.'
        }
    },
    PROFILE: {
        TITLES: {
            PROFILE: 'Profile',
            AGENT_INFORMATION: 'Agent information',
            GENERAL_INFO: 'General Info',
            ADDITIONAL_PROFILES: 'Additional Profiles',
            EMAILS: 'Emails',
            PHONES: 'Phones',
            ADDRESSES: 'Addresses',
            EDIT_GENERAL_INFO: 'Edit General Info',
            CANCEL: 'Cancel',
            SAVE_CHANGES: 'Save Changes',
            SETTINGS: 'Settings',
            NOTIFICATIONS: 'Notifications',
            ACCESS: 'Access',
            CHANGE_PASSWORD: 'Change Password',
            UPDATE_PASSWORD: 'Update Password',
            ACCOUNT: 'Account',
            DEACTIVATE_ACCOUNT: 'Deactivate account',
            DEACTIVATE: 'Deactivate',
            NEW_MATCH_SETTINGS: 'New Matches',
            OTHER: 'Other'
        },
        TEXT: {
            DARK_MODE: 'Dark Mode',
            DARK_MODE_NOTE: 'Colors of interface will be inverted'
        },
        NOTIFICATIONS: {
            PASSWORD_UPDATE_SUCCESS: 'Password updated successfully'
        },
        DIALOGS: {
            DEACTIVATEION_MESSAGE_PRIMARY_CUSTOMER: 'You are going to deactivate your account. This will de-activate the entire collaboration portfolio for you and all other accounts that may be linked to it.',
            DEACTIVATEION_MESSAGE_CHILD_ACCOUNT: 'You are going to deactivate your account. You will no longer have an access to your account.',
            DEACTIVATEION_MESSAGE_CHILD_ACCOUNT_BY_PRIMARY: 'You are going to deactivate your child account.',
            MAIN_DEACTIVATEION_MESSAGE: 'Would you like to Deactivate your Account?'
        },
        ERRORS: {
            SERVER: {
                ACCOUNT_NAME_EXIST: 'Login already exists',
                EMAIL_EXIST: 'Email already exist',
                PHONE_EXIST: 'Phone already exist',
                UNHANDLED_ERROR: 'Update accidantely failed. Previous data restored',
                OLD_PASSWORD_WRONG: 'Old password is wrong',
                ACCOUNT_DEACTIVATION_FAILED: 'Failed to deactivate account'
            }
        }
    }
};

export const locale = {
    lang: 'en',
    data: {
        ...profileCommon,
        ...addressesEditForm.data,
        ...emailsList.data,
        ...emailAlertSettings.data,
        ...password.data,
        ...phonesEditForm.data,
        ...profileTop.data,
        ...newMatchSettings.data,
        ...inviteUserDialog.data,
        ...inviteUserForm.data,
        ...welcomeVideoLink.data
    }
};