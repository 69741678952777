import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { SetListingActivityRequest } from '@listings/models/api/set-listing-activity-request';
import { CommonListing } from '@listings/models/listing/common-listing';
import { Listings } from '@listings/models/listing/listings';
import { ListingsFromApi } from '@listings/models/listing/listings-from-api';
import { ListingsMediaFromApi } from '@listings/models/listing/listings-media-from-api';
import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import * as listingActions from '@listings/store/actions/listings.actions';
import * as onMarketActions from '@on-market/store/actions/on-market.actions';
import * as listingActivityActions from '../actions/listing-activity.actions';
import { ListingActivityId } from '@listings/models/listing/listing-activity-id';

@Injectable({ providedIn: 'root' })
export class ListingApiService {

    constructor(private readonly http: ApiHttpClient) { }

    public loadListing(listingId: string): Observable<Action> {
        return this.http
            .get('listing/getListing', { params: { listingId: listingId } })
            .pipe(
                map((commonListing: CommonListing) => listingActions.loadListingSuccess({ listing: commonListing, listingId })),
                catchError((errorResponse: HttpErrorResponse) => of(listingActions.loadListingFailed(errorResponse.error as ApiError)))
            );
    }

    public loadListingsByHashCodes(hashCodes: number[]): Observable<Action> {
        return this.http.post('listing/listings-by-hash-codes', { hashCodes }).pipe(
            switchMap((response: ApiDataResult<CommonListing[]>) => response.error != null
                ? [listingActions.reloadListingsByIdsFailed(response.error)]
                : [
                    listingActions.reloadListingsByIdsSuccess({ loadedListings: response.result, listingsToDeleteIds: [] }),
                    listingActions.loadListingsMedia({ listingIds: response.result.map(x => x.id) })
                ]
            ),
            catchError((errorResponse: HttpErrorResponse) => of(listingActions.loadListingFailed(errorResponse.error as ApiError)))
        );
    }

    public loadCustomerListings(): Observable<Action> {
        return this.http
            .get('listing/all')
            .pipe(
                switchMap((response: ApiDataResult<ListingsFromApi>) => [
                    listingActions.loadCustomerListingsSuccess({ listings: response.result }),
                    listingActions.loadListingsMedia({ listingIds: Object.values(response.result).map(o => o.id) })
                ])
            );
    }

    public loadListingsMedia(ids: string[]): Observable<Action> {
        return this.http
            .post('listing/listings-media', { listingIds: ids })
            .pipe(
                map((response: ApiDataResult<ListingsMediaFromApi>) => {
                    return response.error != null
                        ? listingActions.loadListingsMediaFailed(response.error)
                        : listingActions.loadListingsMediaSuccess({ media: response.result });
                }),
                catchError((errorResponse: HttpErrorResponse) => of(listingActions.loadListingsMediaFailed(errorResponse.error as ApiError)))
            );
    }

    public loadMarketListings(searchOptions: ListingSearchOptions): Observable<Action> {
        return this.http
            .post('listing/searchListings', searchOptions)
            .pipe(
                switchMap((commonListings: ListingsFromApi) => [
                    listingActions.loadMarketListingsSuccess({ listings: commonListings }),
                    onMarketActions.loadSuccess({ searchOptions })
                ]),
                catchError((errorResponse: HttpErrorResponse) => of(listingActions.loadMarketListingsFailed(errorResponse.error as ApiError)))
            );
    }

    public setListingsActivity(params: SetListingActivityRequest, customerId: number): Observable<Action> {
        const listingIdPinOwnerIdMap = {};
        params.listingCandidates.forEach(candidate => listingIdPinOwnerIdMap[candidate.id] = candidate.pinOwnerAgentId);
        return this.http.post('listing/set-listing-activity', {
            listingIdPinOwnerIdMap: listingIdPinOwnerIdMap,
            listingIdsWithChangedActivity: params.listingCandidates.filter(candidate => candidate.activities.length > 0).map(candidate => candidate.id),
            activityId: params.activity.id,
            notifyRequired: params.notifyRequired,
        }).pipe(
            map((response: ApiDataResult<ListingActivityId[]>) => {
                return response.error != null
                    ? listingActivityActions.setListingsActivityFailed(response.error, params, customerId)
                    : listingActivityActions.setListingsActivitySuccess({ request: params, activitiesSet: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) => of(listingActivityActions.setListingsActivityFailed(errorResponse.error as ApiError, params, customerId)))
        );
    }

    public markAsViewed(listingId: string, listingHashCode: number): Observable<Action> {
        return this.http
            .post('listing/markAsViewed', { listingId })
            .pipe(
                map(listingActions.markAsViewedSuccess),
                catchError((errorResponse: HttpErrorResponse) => of(listingActions.markAsViewedFailed(errorResponse.error as ApiError, listingHashCode)))
            );
    }

    public reloadListingsByIds(listingsToLoadIds: string[], listingsToDeleteIds: string[]): Observable<Action> {
        return this.http.post('listing/listings-by-ids', { listingsIds: listingsToLoadIds }).pipe(
            map((response: ApiDataResult<CommonListing[]>) => {
                return response.error != null
                    ? listingActions.reloadListingsByIdsFailed(response.error)
                    : listingActions.reloadListingsByIdsSuccess({ loadedListings: response.result, listingsToDeleteIds });
            }),
            catchError((errorResponse: HttpErrorResponse) => of(listingActions.reloadListingsByIdsFailed(errorResponse.error as ApiError)))
        );
    }

    public softDelete(listingIds: string[], listings: Listings): Observable<Action> {
        return this.http
            .post('listing/soft-delete', { listingIds })
            .pipe(
                map((response: ApiDataResult<number>) => {
                    return response.error != null
                        ? listingActions.softDeleteFailed({ error: response.error, listings })
                        : listingActions.softDeleteSuccess({ listingIds, listings });
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    return of(listingActions.softDeleteFailed({ error: errorResponse.error as ApiError, listings }));
                })
            );
    }

    public restore(listingsIds: string[], listings: Listings): Observable<Action> {
        return this.http
            .post('listing/restore', { listingsIds })
            .pipe(
                map((response: ApiDataResult<number>) => {
                    return response.error != null
                        ? listingActions.restoreFailed({ error: response.error, listings })
                        : listingActions.restoreSuccess({ listingIds: listingsIds });
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    return of(listingActions.restoreFailed({ error: errorResponse.error as ApiError, listings }));
                })
            );
    }

    public hardDelete(listingsIds: string[], listings: Listings): Observable<Action> {
        return this.http
            .post('listing/hard-delete', { listingsIds })
            .pipe(
                map((response: ApiDataResult<number>) => {
                    const listingsHashCodes = listingsIds.map(id => listings[id].hashCode);

                    return response.error != null
                        ? listingActions.hardDeleteFailed({ error: response.error, listings })
                        : listingActions.hardDeleteSuccess({ listingsHashCodes: listingsHashCodes });
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    return of(listingActions.hardDeleteFailed({ error: errorResponse.error as ApiError, listings }));
                })
            );
    }
}