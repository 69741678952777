<div clas="customer-info-wrap">
    <ng-container *ngIf="isEdit; else isView">
        <div class="customer-info-form-wrap">
            <form [formGroup]="accountDataForm">
                <rpc-input class="customer-info-form-input" type="text" appearance="outline"
                    label="PROFILE_TOP.TITLES.FIRST_NAME" [options]="firstNameControlOptions">
                </rpc-input>
                <rpc-input class="customer-info-form-input" type="text" appearance="outline"
                    label="PROFILE_TOP.TITLES.LAST_NAME" [options]="lastNameControlOptions">
                </rpc-input>
                <ng-container *ngIf="isAdditionalProfile; else primaryProfile">
                    <rpc-input class="customer-info-form-input" type="text" appearance="outline"
                        label="PROFILE_TOP.TITLES.EMAIL" [options]="mainAdditionalProfileEmailOptions"
                        [disabled]="isAdditionalProfile">
                    </rpc-input>
                </ng-container>
                <ng-template #primaryProfile>
                    <rpc-select class="customer-info-form-select" [color]="'accent'"
                        [label]="'PROFILE_TOP.TITLES.MAIN_EMAIL'" [controlOptions]="mainEmailSelectOptions"
                        [appearance]="'outline'">
                    </rpc-select>
                </ng-template>

                <div class="customer-info-form-submit-wrap">
                    <rpc-button class="customer-info-form-submit fix" [color]="'accent'" [mainButton]="true"
                        [appearanceType]="'stroke'" title="PROFILE_TOP.TITLES.CANCEL" (clicked)="onCancel()">
                    </rpc-button>
                    <rpc-button class="customer-info-form-submit" [color]="'accent'" [iconName]="'export-layout'" [mainButton]="true"
                        [appearanceType]="'flat'" title="PROFILE_TOP.TITLES.SAVE" [disabled]="accountDataForm?.invalid"
                        (clicked)="onSave()">
                    </rpc-button>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-template #isView>
        <div class="customer-info-box">
            <div class="customer-item customer-item--name">
                <div class="item text-truncate">{{ fullName }}</div>
                <rpc-icon-button class="item-btn" [color]="'accent'" [title]="'PROFILE_TOP.TITLES.EDIT'"
                    [iconName]="'edit'" (clicked)="onEdit()">
                </rpc-icon-button>
            </div>
            <div class="customer-item  customer-item--email">
                <div class="item text-truncate">{{ userName }}</div>
            </div>
        </div>
    </ng-template>
</div>