export class ApiError {

    /**
     * Property exists only in case of handled failed backend validation.
     * Contains a key that must be used with ServerMessageService for error message translation receiving.
     */
    errorKey: string;

    /**
     * Property exists only in case of failed DataAnnotation backend validation.
     * Contains complete error message, stacktrace, etc.
     */
    errors: { [key: string]: string[] };

    /**
     * Property exists only in case of unhandled backend exception.
     * Contains complete error message, stacktrace, etc.
     */
    error: string;

    constructor(errorKey: string) {
        this.errorKey = errorKey;
    }
}