import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Phone } from '../../interfaces/customer-info/phone';
import { PhoneTypes } from '../../interfaces/enums/phone-types.enum';
import { PHONE_TYPES_TRANSLATION } from './constants/phone-types.constants';

@Component({
    selector: 'phones-list',
    templateUrl: './phones-list.component.html',
    styleUrls: ['../profile-info-base/profile-info-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PhonesListComponent {
    @Input() phones: Phone[] = [];

    public phoneTypes = PhoneTypes;

    public getEmailsType(phoneType: PhoneTypes): string {
        return PHONE_TYPES_TRANSLATION.has(phoneType) ? PHONE_TYPES_TRANSLATION.get(phoneType) : '';
    }
}