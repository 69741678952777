import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { RpcControlsModule } from '@core-controls/controls.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { PromptComponent } from '@rpc-pwa/components/promt-component/prompt.component';
import { locale as english } from '@rpc-pwa/i18n/en';

@NgModule({
    declarations: [
        PromptComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,

        // Application Modules
        RpcControlsModule,
        RpcIconModule
    ]
})
export class PwaModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english);
    }
}