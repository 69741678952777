import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, share, switchMap, tap } from 'rxjs/operators';

import { AgentsStoreService } from '@agents/store/services/agents-store.service';
import { Creator } from '@appointments/enums/creator.enum';
import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { ListingsAppointments } from '@appointments/models/appointments/listings-appointments';
import { AppointmentErrorsService } from '@appointments/services/appointment-errors.service';
import * as appointmentsActions from '@appointments/store/actions/appointments.actions';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { CustomerActivityService } from '@customer-activity/customer-activity.service';
import { AppointmentActivityType } from '@customer-activity/enums/appointment-activity-type.enum';
import { ApiError } from '@error/models/api-error';
import { ListingActivityHelper } from '@listings/services/listing-activity.helper';
import * as listingActivityActions from '@listings/store/actions/listing-activity.actions';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { OnMarketStoreService } from '@on-market/store/services/on-market-store.service';
import { AppointmentsStoreService } from '../services/appointments-store.service';
import { AppointmentApiService } from '../services/appointment-api.service';
import { AppointmentUpdateStatusActionModel } from '../models/appointment-update-status-action-model';
import { ListingHelperService } from '@listings/services/listing-helper.service';

@Injectable()
export class AppointmentsEffects {

    private static readonly SavedTimeFormat = 'HH:mm';
    private static readonly SavedDateFormat = 'YYYY-MM-DD';

    constructor(
        private readonly actions$: Actions,
        private readonly http: ApiHttpClient,
        private readonly customerActivityService: CustomerActivityService,
        private readonly onMarketStoreService: OnMarketStoreService,
        private readonly agentsStoreService: AgentsStoreService,
        private readonly appointmentsStoreService: AppointmentsStoreService,
        private readonly appointmentErrorService: AppointmentErrorsService,
        private readonly userStoreService: UserStoreService,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly appointmentApiService: AppointmentApiService
    ) { }

    loadListingsAppointments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appointmentsActions.loadListingsAppointments),
            switchMap(() =>
                this.http
                    .get('appointments/all')
                    .pipe(
                        map((response: ApiDataResult<ListingsAppointments>) => {
                            return response.error != null
                                ? appointmentsActions.loadListingsAppointmentsFailed(response.error)
                                : appointmentsActions.loadListingsAppointmentsSuccess({ appointments: response.result });
                        }),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(appointmentsActions.loadListingsAppointmentsFailed(errorResponse.error as ApiError)))
                    )
            ),
            share()
        )
    );

    loadListingAppointments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appointmentsActions.loadListingAppointments),
            switchMap(requestParams =>
                this.http
                    .get('appointments/getListingAppointments', { params: { listingId: requestParams.listingId } })
                    .pipe(
                        map((appointments: ListingAppointment[]) => appointments),
                        concatLatestFrom(() => this.agentsStoreService.getAgentIds()),
                        map(([appointments, agentIds]) => {
                            return appointmentsActions.loadListingAppointmentsSuccsess({
                                appointments: appointments.map(app => ({
                                    ...app,
                                    creator: agentIds.includes(app.createId) ? Creator.Agent : Creator.Customer
                                }))
                            });
                        }),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(appointmentsActions.loadListingAppointmentsFailed(errorResponse.error as ApiError)))
                    )
            ),
            share()
        )
    );

    public readonly loadAppointment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(appointmentsActions.loadAppointment),
            switchMap(({ id }) => this.appointmentApiService.loadAppointment(id)));
    });

    createListingAppointment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appointmentsActions.createListingAppointment),
            mergeMap(requestParams =>
                this.http
                    .post('appointments/createAppointment', {
                        listingId: requestParams.listingId,
                        listingAddress: requestParams.listingAddress,
                        comment: requestParams.createOperationListingAppointment.comment,
                        startDate:
                            `${this.formatDateTime(requestParams.createOperationListingAppointment.startDateTime, AppointmentsEffects.SavedDateFormat)} ` +
                            `${this.formatDateTime(requestParams.createOperationListingAppointment.startDateTime, AppointmentsEffects.SavedTimeFormat)}`,
                        endDate:
                            `${this.formatDateTime(requestParams.createOperationListingAppointment.endDateTime, AppointmentsEffects.SavedDateFormat)} ` +
                            `${this.formatDateTime(requestParams.createOperationListingAppointment.endDateTime, AppointmentsEffects.SavedTimeFormat)}`,
                        isNewMatch: requestParams.isNewMatch
                    })
                    .pipe(
                        map((appointment: ListingAppointment) => appointment),
                        concatLatestFrom(() => this.agentsStoreService.getAgentIds()),
                        map(([appointment, agentIds]) =>
                            appointmentsActions.createListingAppointmentSuccess({
                                appointment: {
                                    ...appointment,
                                    creator: agentIds.includes(appointment.createId) ? Creator.Agent : Creator.Customer
                                },
                                request: requestParams
                            })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(appointmentsActions.createListingAppointmentFailed(errorResponse.error as ApiError, requestParams)))
                    )
            ),
            share()
        )
    );

    updateListingAppointment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appointmentsActions.updateListingAppointment),
            mergeMap((requestParams) =>
                this.http
                    .post('appointments/updateAppointment', {
                        listingId: requestParams.listingId,
                        listingAddress: requestParams.listingAddress,
                        comment: requestParams.listingAppointment.comment,
                        appointmentId: requestParams.listingAppointment.id,
                        startDate:
                            `${this.formatDateTime(requestParams.listingAppointment.startDateTime, AppointmentsEffects.SavedDateFormat)} ` +
                            `${this.formatDateTime(requestParams.listingAppointment.startDateTime, AppointmentsEffects.SavedTimeFormat)}`,
                        endDate:
                            `${this.formatDateTime(requestParams.listingAppointment.endDateTime, AppointmentsEffects.SavedDateFormat)} ` +
                            `${this.formatDateTime(requestParams.listingAppointment.endDateTime, AppointmentsEffects.SavedTimeFormat)}`
                    })
                    .pipe(
                        map(() => appointmentsActions.updateListingAppointmentSuccess({ request: requestParams })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(appointmentsActions.updateListingAppointmentFailed(errorResponse.error as ApiError, requestParams)))
                    )
            ),
            share()
        )
    );

    changeAppointmentStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appointmentsActions.changeAppointmentStatus),
            mergeMap(({ request }) =>
                this.http
                    .post('appointments/changeAppointmentStatus', {
                        listingHashcode: request.listingHashCode,
                        appointmentId: request.appointmentId,
                        customerStatus: request.customerStatus
                    })
                    .pipe(
                        map(() => appointmentsActions.changeAppointmentStatusSuccess({ request })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(appointmentsActions.changeAppointmentStatusFailed(errorResponse.error as ApiError, request)))
                    )
            ),
            share()
        )
    );

    public readonly changeStatusById$ = createEffect(() => this.actions$.pipe(
        ofType(appointmentsActions.changeStatusById),
        concatLatestFrom(() => [
            this.userStoreService.getUser(),
            this.appointmentsStoreService.getFlatListingsAppointments()
        ]),
        map(([{ appointmentId, listingHashcode, previousCustomerStatus, status }, user, appointments]) => {
            const appointment = appointments.find(x => x.id === appointmentId);
            const updateStatusModel = new AppointmentUpdateStatusActionModel(
                listingHashcode,
                appointmentId,
                status,
                appointment,
                previousCustomerStatus,
                user.customerId
            );

            return appointmentsActions.changeAppointmentStatus({ request: updateStatusModel });
        })
    ));

    markAppointmentsAsViewed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appointmentsActions.markAppointmentsAsViewed),
            mergeMap((requestParams) =>
                this.http
                    .post('appointments/markAsViewed', {
                        ids: requestParams.listingsAppointments.flatMap(appointment => appointment.appointmentIds)
                    })
                    .pipe(
                        map(() => appointmentsActions.markAppointmentsAsViewedSuccess(requestParams)),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(appointmentsActions.markAppointmentsAsViewedFailed(errorResponse.error as ApiError, requestParams))
                        )
                    )
            ),
            share()
        )
    );

    deleteListingAppointment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appointmentsActions.deleteListingAppointment),
            mergeMap(({ model, isFromStateOnly }) => isFromStateOnly
                ? EMPTY
                : this.http
                    .post('appointments/deleteAppointment', {
                        listingId: model.listingId,
                        listingAddress: model.listingAddress,
                        appointmentId: model.appointmentId
                    })
                    .pipe(
                        map(() => appointmentsActions.deleteListingAppointmentSuccess({ request: model })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(appointmentsActions.deleteListingAppointmentFailed(errorResponse.error as ApiError, model)))
                    )
            ),
            share()
        )
    );

    public readonly createListingAppointmentSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(appointmentsActions.createListingAppointmentSuccess),
                concatLatestFrom(() => [
                    this.onMarketStoreService.getActiveSavedSearchIdIfIsNotDefault(),
                    this.listingsStoreService.getListings(),
                    this.userStoreService.getUser()
                ]),
                switchMap(([{ appointment, request: { listingId, listingCategory, isNewMatch } }, activeSavedSearchId, allListings, { customerId }]) => {
                    this.customerActivityService.addAppointmentSessionActivity(
                        listingId,
                        listingCategory,
                        appointment.id,
                        AppointmentActivityType.Requested,
                        activeSavedSearchId).subscribe();

                    if (!isNewMatch) {
                        return [];
                    }

                    return [listingActivityActions.addListingsToPickedList({
                        listingCandidates: ListingActivityHelper.getActivityListingsCandidates([listingId], allListings),
                        customerId,
                    })];
                })
            )
    );

    public readonly showAppointmentError$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                appointmentsActions.loadListingsAppointmentsFailed,
                appointmentsActions.loadListingAppointmentsFailed,
                appointmentsActions.markAppointmentsAsViewedFailed,
                appointmentsActions.createListingAppointmentFailed,
                appointmentsActions.updateListingAppointmentFailed,
                appointmentsActions.changeAppointmentStatusFailed,
                appointmentsActions.deleteListingAppointmentFailed
            ),
            concatLatestFrom(() => this.appointmentsStoreService.getAppointmentError()),
            tap(([_, error]) => this.appointmentErrorService.showError(error))
        ),
        { dispatch: false }
    );

    private readonly formatDateTime = (time: Date, format: string): string => moment(time).format(format);
}