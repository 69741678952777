import { createAction, props } from '@ngrx/store';

import { SortOptionBase } from '@core-models/sort/sort-option';
import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { MapShapesSearchOptions } from '@listings/models/search/map-shapes-search-options';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { PatternSearchResult } from '@toolbars/on-market-toolbar/models/pattern-search-result';
import { ViewModes } from '@view-base/models/view-modes.enum';

export const setSort = createAction(
    '[Search Options] Set Sort',
    props<{ sort: SortOptionBase }>()
);

export const changeSearchOptions = createAction(
    '[Search Options] Change Search Options',
    props<{ searchOptions: ListingSearchOptions }>()
);

export const setSearchOptions = createAction(
    '[Search Options] Set Search Options',
    props<{ searchOptions: ListingSearchOptions, activeSavedSearchId?: number }>()
);

export const setViewMode = createAction(
    '[Search Options] Set View Mode',
    props<{ viewMode: ViewModes }>()
);

export const setMapSizePercent = createAction(
    '[Search Options] Set Map Size Percent',
    props<{ mapSizePercent: number }>()
);

export const resetSavedView = createAction(
    '[Search Options] Reset Saved View',
    props<CustomerSettings>()
);

export const setPreviousSearchOptionsValue = createAction(
    '[Search Options] Set Previous Search Options Value',
    props<{ searchOptions: ListingSearchOptions }>()
);
export const addCustomerSearchActivities = createAction(
    '[Search Options] Add Customer Search Actions',
    props<{ categoryId: number, fields: { fieldName: string, fieldValue: string, optionName?: string }[] }>()
);

export const setDefaultSearchOptions = createAction(
    '[Search Options] Set Default Search Options'
);

export const clearSelectedSearch = createAction(
    '[Search Options] Clear Selected Search',
    props<{ categoryId?: number }>()
);

export const setMapShapesSearchOptions = createAction(
    '[Search Options] Set Map Shapes Search Options',
    props<{ mapOptions: MapShapesSearchOptions }>()
);

export const setSelectedMapNeighborhoods = createAction(
    '[Search Options] Set Selected Neighborhoods',
    props<{ selectedNeighborhoodIds: number[] }>()
);

export const patternResultChanged = createAction(
    '[Search Options] Pattern Result Changed',
    props<{ patternResults: PatternSearchResult[] }>()
);