export const locale = {
    lang: 'en',
    data: {
        RESTORE_PASSWORD: {
            VALIDATION_ERRORS: {
                PASSWORD_REQUIRED: 'Password is required',
                PASSWORD_CONFIRMATION_REQUIRED: 'Password confirmation is required',
                PASSWORDS_NOT_MATCH: 'Passwords must match',
                NUMBER_REQIRED: 'At least 1 digit is required',
                CAPITAL_REQUIRED: 'At least 1 capital character is required',
                SMALL_REQUIRED: 'At least 1 small character is required',
                SPECIAL_REQIRED: 'At least 1 special character is required',
                MINIMUM_LENGTH: 'Password must be at least 6 characters',
                MAXIMUM_LENGTH: 'Password must be not more than 16 characters.'
            },
            FIELDS: {
                PASSWORD: 'Password',
                PASSWORD_CONFIPMATION: 'Password confirmation'
            },
            MESSAGES: {
                PASSWORD_RESTORED: 'Password was restored successfully!'
            },
            TITLE: 'Restore Password?',
            RESTORE_EXPLANATION: 'You can always reset your password here.',
            UPDATE_BUTTON: 'RESET PASSWORD',
            ERRORS: {
                SERVER: {
                    CUSTOMER_NOT_FOUND: 'Account for password restore is not found.',
                    INVALID_RESTORE_CODE: 'Invalid restore link.',
                    RESTORE_CODE_EXPIRED: 'Current link is expired. Resent email to restore password.',
                    PASSWORD_CHANGE_ERROR: 'Password restore is failed.',
                    RESTORE_LINK_IS_STALE: 'Restore password link is stale.',
                    SEND_RESTORE_LINK_ERROR: 'Restore password email wasn\'t sent.'
                }
            }
        }
    }
};