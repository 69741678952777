import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { CreateOperationListingComment, ListingComment } from '@comments/models/comments/listing-comment';
import { ListingsComments } from '@comments/models/comments/listings-comments';
import * as commentsActions from '@comments/store/actions/comments.actions';
import * as commentsSelectors from '@comments/store/selectors/comments.selectors';
import { CommentsState } from '@comments/store/states/comments.state';
import { ActionError } from '@core-models/errors/action-error';
import { CommonListing } from '@listings/models/listing/common-listing';
import { ListingActivityStoreService } from '@listings/store/services/listing-activity-store.service';
import { CommentActionErrors } from '../enums/comment-action-errors';
import { CommentCreateActionModel } from '../models/comment-create-action-model';
import { CommentUpdateActionModel } from '../models/comment-update-action-model';
import { MarkAsViewedActionModel } from '../models/mark-as-viewed-action-model';

@Injectable({ providedIn: 'root' })
export class CommentsStoreService {

    constructor(
        private readonly store: Store<CommentsState>,
        private readonly userStoreService: UserStoreService,
        protected readonly listingActivityStoreService: ListingActivityStoreService,
    ) { }

    public getCommentError(): Observable<ActionError<CommentActionErrors>> {
        return this.store.select(commentsSelectors.selectCommentError).pipe(filter(error => error != null));
    }

    public getIsSingleCommentLoaded(): Observable<boolean> {
        return this.store.select(commentsSelectors.selectIsSingleCommentLoaded);
    }

    public areCommentsLoaded(): Observable<boolean> {
        return this.store.pipe(select(commentsSelectors.selectCommentsLodaded));
    }

    public getListingsComments(): Observable<ListingsComments> {
        return this.store.select(commentsSelectors.selectListingsComments);
    }

    public getListingComments(listingIdHashCode: number): Observable<ListingComment[]> {
        return this.store.select(commentsSelectors.selectListingComments(listingIdHashCode));
    }

    public getListingCommentsCount(listingIdHashCode: number): Observable<number> {
        return this.store.select(commentsSelectors.selectListingCommentsCount(listingIdHashCode));
    }

    public getListingNewCommentsCount(listingIdHashCode: number): Observable<number> {
        return this.store.select(commentsSelectors.selectListingNewCommentsCount(listingIdHashCode));
    }
    public getLastComment(listingHashCode: number): Observable<ListingComment> {
        return this.store.select(commentsSelectors.selectLastComment(listingHashCode));
    }

    public loadListingComments(listingId: string): void {
        this.store.dispatch(commentsActions.loadListingComments({ listingId }));
    }

    public loadListingsComments(): void {
        this.store.dispatch(commentsActions.loadListingsComments());
    }

    public createComment(listing: CommonListing, listingAddress: string, comment: string, isNewMatch: boolean = false): void {
        this.userStoreService.getUser()
            .pipe(take(1))
            .subscribe(currentUser => {
                const createName = `${currentUser.customerFirstName} ${currentUser.customerLastName}`;
                const createOperationListingComment = new CreateOperationListingComment(listing.hashCode, comment, new Date(), currentUser.customerId, createName);
                const commentCreateActionModel = new CommentCreateActionModel(listing, listingAddress, createOperationListingComment, isNewMatch);
                this.store.dispatch(commentsActions.createListingComment(commentCreateActionModel));
            });
    }

    public markCommentsAsViewed(listingId: string, listingIdHashCode: number, commentIds: number[]): void {
        this.store.dispatch(commentsActions.markCommentAsViewed(new MarkAsViewedActionModel(listingId, listingIdHashCode, commentIds)));
    }

    public updateComment(listingId: string, listingIdHashCode: number, listingAddress: string, newComment: string, oldListingComment: ListingComment): void {
        this.store.dispatch(
            commentsActions.updateListingComment(
                new CommentUpdateActionModel(listingId, listingIdHashCode, listingAddress, newComment, oldListingComment)
            )
        );
    }
}