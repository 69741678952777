<ng-container *ngIf="appearanceType === 'normal' && !avatarVisible">
    <button mat-button #rpcMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="rpcMenu" class="rpc-menu-btn-link"
        title="{{shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
        actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
        menuOpened ? 'rpc-menu-btn-link--opened' : '',
        badgeIcon ? 'rpc-menu-btn-link--badge-icon' : '',
        enableCustomBackground ? 'rpc-menu-btn-link--custom' : ''
    ]" (click)="onClick()" [disabled]="disabled" [type]="type">

        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>

    </button>
</ng-container>

<ng-container *ngIf="appearanceType === 'normal' && avatarVisible">
    <button mat-button #rpcMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="rpcMenu" class="rpc-menu-btn-link"
        title="{{shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
        actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
        menuOpened ? 'rpc-menu-btn-link--opened' : ''
    ]" (click)="onClick()" [disabled]="disabled" [type]="type">
        <ng-container *ngTemplateOutlet="buttonContentAvatar"> </ng-container>
    </button>
</ng-container>

<ng-container *ngIf="appearanceType === 'stroke' && !avatarVisible">
    <button mat-stroked-button #rpcMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="rpcMenu" class="rpc-menu-btn-link"
        title="{{shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
        actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
        menuOpened ? 'rpc-menu-btn-link--opened' : '',
        badgeIcon ? 'rpc-menu-icon-btn--badge-icon' : '',
        enableCustomBackground ? 'rpc-menu-btn-link--custom' : ''
    ]" (click)="onClick()" [disabled]="disabled" [type]="type">

        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>

    </button>
</ng-container>

<ng-container *ngIf="appearanceType === 'stroke' && avatarVisible">
    <button mat-stroked-button #rpcMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="rpcMenu" class="rpc-menu-btn-link"
        title="{{shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
        actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
        menuOpened ? 'rpc-menu-btn-link--opened' : ''
    ]" (click)="onClick()" [disabled]="disabled" [type]="type">

        <ng-container *ngTemplateOutlet="buttonContentAvatar"> </ng-container>

    </button>
</ng-container>

<mat-menu #rpcMenu="matMenu" [overlapTrigger]="false" class="rpc-menu-wrap {{menuActionClass}}" [ngClass]="[
        menuActionClass != null && menuActionClass !== '' && (menuActionTrigger == null || menuActionTrigger) ? menuActionClass : ''
    ]" (closed)="onClosed()">
    <div (click)="$event.stopPropagation()">
        <ng-content></ng-content>
    </div>
</mat-menu>

<ng-template #buttonContent>
    <rpc-icon *ngIf="iconName != null && iconName !== ''" [iconName]="iconName" class="rpc-menu-btn-link-icon">
    </rpc-icon>

    <div *ngIf="badgeIcon" class="rpc-menu-btn-link-badge">
        <rpc-icon class="rpc-menu-btn-link-badge-icon" [iconName]="badgeIconName"></rpc-icon>
    </div>

    <span class="rpc-menu-btn-link-text" *ngIf="title != null && title !== ''">
        {{shouldTranslate ? (title | translate) : title }}
    </span>

    <rpc-icon *ngIf="menuIconVisible" [iconName]="menuOpened ? 'arrow-up' : 'arrow-down'"
        class="rpc-menu-btn-link-trigger">
    </rpc-icon>

    <span class="rpc-menu-btn-link-bg" *ngIf="enableCustomBackground"></span>
</ng-template>

<ng-template #buttonContentAvatar>
    <ng-content select="[avatarContent]"></ng-content>
    <span class="rpc-menu-btn-link-text" *ngIf="title != null && title !== ''">
        {{shouldTranslate ? (title | translate) : title }}
    </span>

    <rpc-icon *ngIf="menuIconVisible" [iconName]="menuOpened ? 'arrow-up' : 'arrow-down'"
        class="rpc-menu-btn-link-trigger">
    </rpc-icon>
</ng-template>