<mat-toolbar class="toolbar" *ngLet="isUserAuthorized$ | async as isUserAuthorized">
    <div class="toolbar-box" [ngClass]="{'not-authorized' : !isUserAuthorized}">
        <div class="toolbar-logo" *ngIf="(companyConfiguration$ | async) as companyConfiguration" (click)="onLogoClicked()">
            <img [src]="(isDarkMode$ | async) ? companyConfiguration.darkModeLogoPath : companyConfiguration.logoPath"
                alt="{{companyConfiguration.productNameAlias}}">
        </div>

        <ng-container *ngIf="isUserAuthorized">
            <mat-divider [vertical]="true" class="divider-first"></mat-divider>
            <!-- Mobile Menu -->
            <div class="toolbar-m-menu">
                <rpc-icon-button [title]="'TOOLBAR.TITLES.SHOW_MENU'" iconName="burger" class="toolbar-m-menu-item"
                    (clicked)="toggleSidebarOpen('mobileMenu')"></rpc-icon-button>
            </div>

            <!-- Desktop Menu -->
            <div class="toolbar-menu" *ngLet="{listingsCount: listingsCount$ | async, unviewedListingsCount: unviewedListingsCount$ | async} as listingCounts">
                <rpc-button class="toolbar-menu-item" [link]="portfolioRouterLink$ | async" [routerLinkActive]="'active'"
                    [title]="'TOOLBAR.TITLES.MY_LISTINGS'" [badgeCount]="listingCounts.listingsCount"
                    [badgeCountAlert]="listingCounts.unviewedListingsCount"
                    [alt]="listingCounts.unviewedListingsCount > 0 
                        ? ('TOOLBAR.TOOLTIPS.NEW_LISTINGS_IN_PORTFOLIO'  | translate : {count: listingCounts.unviewedListingsCount}) 
                        : listingCounts.listingsCount > 0 
                            ? ('TOOLBAR.TOOLTIPS.LISTINGS_IN_PORTFOLIO'  | translate : {count: listingCounts.listingsCount})
                            : 'TOOLBAR.TITLES.MY_LISTINGS'">
                </rpc-button>
                <rpc-button class="toolbar-menu-item" *ngIf="canSearchForListings$ | async"
                    [link]="RpcRoute.SearchListings" [routerLinkActive]="'active'"
                    [title]="'TOOLBAR.TITLES.SEARCH_LISTINGS'">
                </rpc-button>
                <rpc-button *ngLet="{unviewedAppointmentsCount: unviewedAppointmentsCount$ | async, appointmentsCount: appointmentsCount$ | async } as appointmentCounts"
                    class="toolbar-menu-item" [link]="RpcRoute.Appointments" [routerLinkActive]="'active'"
                    [title]="'TOOLBAR.TITLES.MY_APPOINTMENTS'" [badgeCount]="appointmentCounts.appointmentsCount"
                    [badgeCountAlert]="appointmentCounts.unviewedAppointmentsCount"
                    [alt]="appointmentCounts.unviewedAppointmentsCount > 0 
                        ? ('TOOLBAR.TOOLTIPS.NEW_UPCOMING_APPOINTMENTS_IN_PORTFOLIO'  | translate : {count: (appointmentCounts.unviewedAppointmentsCount)})
                        : appointmentCounts.appointmentsCount > 0 
                            ? ('TOOLBAR.TOOLTIPS.APPOINTMENTS_IN_PORTFOLIO'  | translate : {count: appointmentCounts.appointmentsCount})
                            : 'TOOLBAR.TITLES.MY_APPOINTMENTS'">
                </rpc-button>
            </div>

            <rpc-icon-button class="toolbar-knowledge"
                             [actionClass]="'active'"
                             [actionTrigger]="isKnowledgeBaseOpened$ | async"
                             [iconName]="'question'"
                             [title]="'TOOLBAR.TITLES.KNOWLEDGE_BASE'"
                             (clicked)="onKnowledgeBaseClicked()">
            </rpc-icon-button>

            <ng-container *ngLet="agents$ | async as agents">
                <div class="toolbar-user-agent" *ngIf="agents.length > 0">
                    <div class="toolbar-user-btn" [title]="'TOOLBAR.TITLES.CONTACT_AGENT' | translate" [matMenuTriggerFor]="agentsList"
                        matRipple>
                        <avatar-initials *ngIf="agents.length === 1; else multipleAgents"
                            class="toolbar-user-btn-avatar" mat-icon-button [userFullName]="agents[0].fullName"
                            [userProfileImageUrl]="agents[0].profilePictureUrl">
                        </avatar-initials>
                        <ng-template #multipleAgents>
                            <avatars-icon class="toolbar-user-btn-avatar" mat-icon-button
                                [front]="getAgentAvatarInfo(agents[0])" [back]="getAgentAvatarInfo(agents[1])">
                            </avatars-icon>
                        </ng-template>
                        <div class="toolbar-user-btn-text">{{'TOOLBAR.TITLES.CONTACT_AGENT' | translate}}</div>
                    </div>

                    <mat-menu #agentsList="matMenu" [overlapTrigger]="false">
                        <portfolio-agents-list class="toolbar-user-agent-info" [agents]="agents">
                        </portfolio-agents-list>
                    </mat-menu>
                </div>
            </ng-container>

            <div class="toolbar-notification" *ngLet="unviewedNotificationTotalCount$ | async as unviewedNotificationTotalCount">
                <!-- Mobile Notifications -->
                <rpc-icon-button [iconName]="'bell'" class="toolbar-notification-item" [animationConfig]="badgeAlertAnimationConfig"
                    (clicked)="openNotificationsBottomSheet()" [title]="'TOOLBAR.TITLES.SHOW_NOTIFICATIONS'"
                    [badgeCountAlert]="unviewedNotificationTotalCount" [badgeCountAlertLimit]="badgeCountAlertLimit"></rpc-icon-button>

                <!-- Desktop Notifications -->
                <rpc-menu-icon-button class="toolbar-notification-item d-view"
                    #rpcMenuIconButtonApi="rpcMenuIconButtonApi" [alt]="'TOOLBAR.TITLES.SHOW_NOTIFICATIONS'"
                    [iconName]="'bell'" [badgeCountAlert]="unviewedNotificationTotalCount" [badgeCountAlertLimit]="badgeCountAlertLimit"
                    [menuActionClass]="'rpc-notifications-dropdown'" [animationConfig]="badgeAlertAnimationConfig" (closed)="onNotificationBellClosed()">
                    <notifications *ngIf="rpcMenuIconButtonApi.menuOpened"  (notificationSourceNavigated)="onNotificationSourceNavigated($event)"></notifications>
                </rpc-menu-icon-button>
            </div>
            <mat-divider [vertical]="true" class="divider-second"></mat-divider>

            <div class="toolbar-user">
                <ng-container *ngLet="{customerFullName: customerFullName$ | async, customerProfileImage: customerProfileImage$ | async} as customer">
                    <div class="toolbar-user-link" *ngIf="customer.customerFullName != null" title="{{ customer.customerFullName }}"
                        #userRippleTrigger [matMenuTriggerFor]="userMenu">
                        <avatar-initials mat-icon-button matRipple [matRippleCentered]="true"
                            [matRippleTrigger]="userRippleTrigger" class="username-avatar" [userFullName]="customer.customerFullName"
                            [userProfileImageUrl]="customer.customerProfileImage">
                        </avatar-initials>
                    </div>
                </ng-container>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="rpc-dropdown-menu">
                    <div class="rpc-dropdown">
                        <rpc-button class="rpc-dropdown-btn" [title]="'TOOLBAR.TITLES.PROFILE'" iconName="user"
                            (clicked)="onMyProfileClicked()"></rpc-button>
                        <rpc-button class="rpc-dropdown-btn" *ngIf="canSeeAdditionalProfiles$ | async as canSeeAdditionalProfiles"
                            [title]="'TOOLBAR.TITLES.INVITE_USER'" iconName="invite-user" (clicked)="onInviteUser()"
                            [disabled]="!canSeeAdditionalProfiles">
                        </rpc-button>
                        <rpc-button class="rpc-dropdown-btn" [title]="'TOOLBAR.TITLES.SETTINGS'" iconName="cog"
                            (clicked)="onSettingsClicked()"></rpc-button>
                        <rpc-button *ngIf="hasMultipleCollaborationSpaces$ | async" class="rpc-dropdown-btn"
                            [title]="'TOOLBAR.TITLES.CHANGE_AGENT'" iconName="agent-switch"
                            [routerLinkActive]="'active'" (clicked)="onChangeAgentClicked()"></rpc-button>
                        <rpc-button class="rpc-dropdown-btn" [title]="'TOOLBAR.TITLES.LOGOUT'" iconName="sign-out-square-fill"
                            (clicked)="onLogoutClicked()"></rpc-button>
                    </div>
                </mat-menu>
            </div>
        </ng-container>

        <div class="toolbar-auth-wrap" *ngIf="!isUserAuthorized && !(hideLoginRedirect$ | async)">
            <rpc-button [link]="RpcRoute.Login" title="Login"></rpc-button>
        </div>
    </div>
</mat-toolbar>