<div class="combined-phone-wrap" [formGroup]="phoneForm">
    <div class="combined-phone-select-wrap">
        <mat-form-field color="accent" [appearance]="appearance" class="form-item">
            <mat-label>{{'PHONE_FORM_INPUT.FIELDS.CODE' | translate}}</mat-label>
            <mat-select formControlName="countryCode" (blur)="onBlur()" #singleSelect>
                <mat-select-trigger *ngIf="phoneForm.get('countryCode').value != null">
                    {{phoneForm.get('countryCode').value.dialCode}}
                </mat-select-trigger>
                <mat-option>
                    <ngx-mat-select-search formControlName="countryCodeFilter" [placeholderLabel]="searchPlaceHolder"
                        [noEntriesFoundLabel]="notFoundSearchPlaceHolder"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let countryPhoneInfo of filteredCountriesPhoneInfo | async"
                    [value]="countryPhoneInfo">
                    {{countryPhoneInfo.name}} <b style="font-weight:500;">{{countryPhoneInfo.dialCode}}</b>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="combined-phone-input-wrap">
        <mat-form-field color="accent" [appearance]="appearance" class="form-item">
            <mat-label>{{'PHONE_FORM_INPUT.FIELDS.PHONE' | translate}}</mat-label>
            <input formControlName="phoneNumber" matInput (blur)="onBlur()"
                placeholder="{{phoneForm.get('countryCode').value != null ? phoneForm.get('countryCode').value.examplePhoneNumber : ''}}">
        </mat-form-field>
    </div>
    <div class="combined-phone-error" *ngIf="showRequiredErrorMessage()">
        {{'PHONE_FORM_INPUT.VALIDATION.PHONE_REQUIRED' | translate}}
    </div>
    <div class="combined-phone-error" *ngIf="showWrongFormatErrorMessage()">
        {{'PHONE_FORM_INPUT.VALIDATION.WRONG_PHONE' | translate}}
    </div>
</div>