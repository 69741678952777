import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export const emailWithDotValidator = (control: AbstractControl): ValidationErrors | null => {

    const result = Validators.email(control);
    const isStandartValidationPassed = result == null;

    if (!isStandartValidationPassed) {
        return result;
    }

    const dotAtTheEndPattern = /^.*\.[a-z]{1,}$/i;

    return dotAtTheEndPattern.test(control.value as string) ? null : { 'email': true };

};