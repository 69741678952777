import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
    lang: string;
    data: object;
}

@Injectable({ providedIn: 'root' })
export class TranslationLoaderService {

    constructor(
        private readonly translateService: TranslateService
    ) { }

    /**
     * Load translations. Locale data will be merged into single translation object.
     * Be careful and provide unique translation string names.
     *
     * @param {Locale} args
     */
    public loadTranslations(...args: Locale[]): void {
        const locales = [...args];

        locales.forEach((locale) => {
            this.translateService.setTranslation(locale.lang, locale.data, true);
        });
    }
}