import { createAction, props } from '@ngrx/store';

import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { NeighborhoodProperty } from '@on-market/models/neighborhood-property';

export const loadSuccess = createAction(
    '[On Market Page] Load Success',
    props<{ searchOptions: ListingSearchOptions }>()
);

export const loadNeighborhoodProperties = createAction(
    '[On Market Page] Load Neighborhood Properties'
);
export const loadNeighborhoodPropertiesSuccess = createAction(
    '[On Market Page] Load Neighborhood Properties Success',
    props<{ neighborhoodProperties: NeighborhoodProperty[] }>()
);
export const loadNeighborhoodPropertiesFailed = createAction(
    '[On Market Page] Load Neighborhood Properties Failed'
);

export const resetState = createAction('[On Market Page] Reset State');