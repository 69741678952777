import { AssetType } from '@media/enums/asset-type.enum';
import { RecordType } from '@media/enums/record-type.enum';
import { Image } from '@media/models/image';
import { Thumbnail } from '@media/models/thumbnail';

const fillFit = 'fill';
const cropFit = 'crop';

export class StoredMedia {
    public mediaId: number;
    public width: number;
    public height: number;
    public assetType: AssetType;
    public recordType: RecordType;
    public orientation: string;
    public url: string;
    public description: string;
}

class ImagePreferences {

    public constructor(public fit: string, public width: number, public height: number) {
    }

    public static mobileLargeImagePreferences = () => new ImagePreferences(fillFit, 1024, 768);
    public static mobileMediumImagePreferences = () => new ImagePreferences(fillFit, 1024, 768);
    public static mobileSmallImagePreferences = () => new ImagePreferences(fillFit, 500, 375);
    public static mobileExtraSmallImagePreferences = () => new ImagePreferences(fillFit, 368, 200);

    public static desktopLargeImagePreferences = () => new ImagePreferences(fillFit, 1920, 1080);
    public static desktopMediumImagePreferences = () => new ImagePreferences(fillFit, 1126, 600);
    public static desktopSmallImagePreferences = () => new ImagePreferences(fillFit, 500, 500);
    public static desktopExtraSmallImagePreferences = () => new ImagePreferences(fillFit, 500, 500);

    public static thumbnailImagePreferences = () => new ImagePreferences(fillFit, 300, 300);

}

const getSizeAdjustedUrl = (url: string, sizePreferences: ImagePreferences) =>
    url.indexOf('?') !== -1 ? url : `${url}?fit=${sizePreferences.fit}&w=${sizePreferences.width}&h=${sizePreferences.height}`;

const createImage = (media: StoredMedia, mobileImagePreferences: ImagePreferences, desktopImagePreferences: ImagePreferences): Image => (
    {
        mediaId: media.mediaId,
        mobileUrl: mobileImagePreferences != null ? getSizeAdjustedUrl(media.url, mobileImagePreferences) : media.url,
        desktopUrl: desktopImagePreferences != null ? getSizeAdjustedUrl(media.url, desktopImagePreferences) : media.url,
        assetType: media.assetType,
        recordType: media.recordType,
        description: media.description,
        isPortrait: media.orientation !== 'L',
        isDummy: false
    });

export const formatLargeImage = (media: StoredMedia): Image =>
    createImage(media, ImagePreferences.mobileLargeImagePreferences(), ImagePreferences.desktopLargeImagePreferences());

export const formatMediumImage = (media: StoredMedia): Image =>
    createImage(media, ImagePreferences.mobileMediumImagePreferences(), ImagePreferences.desktopMediumImagePreferences());

export const formatSmallImage = (media: StoredMedia): Image =>
    createImage(media, ImagePreferences.mobileSmallImagePreferences(), ImagePreferences.desktopSmallImagePreferences());

export const formatExtraSmallImage = (media: StoredMedia): Image =>
    createImage(media, ImagePreferences.mobileExtraSmallImagePreferences(), ImagePreferences.desktopExtraSmallImagePreferences());

export const formatOriginalImage = (media: StoredMedia): Image =>
    createImage(media, null, null);

export const formatThumbnail = (media: StoredMedia): Thumbnail => (
    {
        mediaId: media.mediaId,
        url: getSizeAdjustedUrl(media.url, ImagePreferences.thumbnailImagePreferences()),
        assetType: media.assetType,
        recordType: media.recordType,
        description: media.description,
        isPortrait: media.orientation !== 'L',
        isDummy: false
    });
