import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';

@Component({
    selector: 'rpc-button',
    templateUrl: './rpc-button.component.html',
    styleUrls: ['./rpc-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpcButtonComponent implements OnInit, OnChanges {

    @Input() public title?: string;
    @Input() public alt?: string;
    @Input() public iconName?: string;
    @Input() public actionClass?: string;
    @Input() public actionTrigger?: boolean = null;
    @Input() public mainButton?: boolean = null;
    @Input() public badgeCount?: number;
    @Input() public badgeCountAlert?: number;
    @Input() public badgeCountAlertLimit?: number;
    @Input() public link?: string | any[];
    @Input() public routerLinkActive?: string | string[] = '';
    @Input() public appearanceType?: 'normal' | 'stroke' | 'flat' = 'normal';
    @Input() public enableCustomBackground?: boolean = null;
    @Input() public disabled?: boolean;
    @Input() public color?: ThemePalette;
    @Input() public target?: '_blank' | '_self' = '_self';
    @Input() public download?: string;
    @Input() public hasAlt = true;
    @Input() public type?: 'submit' | 'reset' | 'button' = 'button';
    @Input() public shouldTranslate? = true;

    @Output() public clicked = new EventEmitter<MouseEvent>();

    public ngOnInit(): void {
        if (this.alt == null && this.hasAlt) {
            this.alt = this.title;
        }
    }

    public ngOnChanges(changes: SimpleChanges<RpcButtonComponent>): void {
        if (changes.title != null && this.hasAlt && changes.alt == null) {
            this.alt = changes.title.currentValue;
        }
    }
}