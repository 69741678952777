export const locale = {
    lang: 'en',
    data: {
        EMAILS_LIST: {
            TITLES: {
                CC_ED: 'CC\'ed',
                LOGIN_EMAIL: 'Login Email'
            }
        }
    }
};