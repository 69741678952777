import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { RegistrationData } from '@auth/models/registration-data';
import * as invitationActions from '@auth/store/actions/invitation.actions';
import { AuthState } from '@auth/store/states/auth.state';

@Injectable({ providedIn: 'root' })
export class RegistrationStoreService {

    constructor(
        private readonly store: Store<AuthState>
    ) { }

    public loadInvitation(inviteCode: string): void {
        this.store.dispatch(invitationActions.getInvitation({ inviteCode: inviteCode }));
    }

    public register(registrationData: RegistrationData): void {
        this.store.dispatch(invitationActions.registration({ registrationData: registrationData }));
    }
}