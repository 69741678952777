import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import * as appointmentsActions from '../actions/appointments.actions';
import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';

@Injectable({ providedIn: 'root' })
export class AppointmentApiService {
    constructor(private readonly http: ApiHttpClient) { }

    public loadAppointment(id: number) {
        return this.http.get(`appointments/${id}`).pipe(
            map((response: ApiDataResult<ListingAppointment>) => {
                return response.error != null
                    ? appointmentsActions.loadAppointmentFailed()
                    : appointmentsActions.loadAppointmentSuccess({ appointment: response.result });
            }),
            catchError(() => of(appointmentsActions.loadAppointmentFailed()))
        );
    }
}