export const locale = {
    lang: 'en',
    data: {
        NOTIFICATION_LIST: {
            TITLES: {
                ALL_READ_NOTIFICATIONS: 'That\'s all your notifications from the last 30 days.',
                ALL_UNREAD_NOTIFICATIONS: 'That\'s all your **unread** notifications from the last 30 days.',
                UNREAD_NOTIFICATIONS_MESSAGE: 'You have read all your notifications.',
            }
        }
    }
};