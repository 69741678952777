export const locale = {
    lang: 'en',
    data: {
        AGENT_BOX: {
            TITLES: {
                LAST_LOGIN: 'Last Login:',
                CURRENT_AGENT: 'Current Agent'
            }
        }
    }
};