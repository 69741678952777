import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { User } from '@auth/models/user';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';

@Injectable({ providedIn: 'root' })
export class AuthorizedOnlyGuard implements CanActivateChild, CanActivate {

    constructor(
        protected readonly routeService: RouteService,
        protected readonly userStoreService: UserStoreService
    ) { }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(route, state);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(route, state);
    }

    private canActivateRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userStoreService.getUser()
            .pipe(
                take(1),
                switchMap((user: User) => {
                    if (user != null && user.loginData != null) {
                        return of(true);
                    }

                    return this.onUnauthorizedUser(route, state);
                }));
    }

    protected onUnauthorizedUser(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.routeService.navigate(RpcRoute.Login, this.routeService.formReturnUrlNavigationExtras(state)).catch(() => { });
        return of(false);
    }
}