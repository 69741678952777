import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Agent } from '@agents/models/agent';

@Component({
    selector: 'agent-short-info',
    templateUrl: './agent-short-info.component.html',
    styleUrls: ['./agent-short-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentShortInfoComponent {

    @Input() agent: Agent;
    @Input() showTitles = true;

}