import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { CreateNotificationApiService } from '../services/create-notification-api.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import * as externallistingsActions from '@external-listings/store/actions/external-listings.actions';
import * as listingsActions from '@listings/store/actions/listings.actions';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { NotificationsApiService } from '../services/notifications-api.service';
import { NotificationEventEntity } from '@notifications/enums/notification-event-entity';
import * as notificationsActions from '../actions/notifications.actions';

@Injectable()
export class CreateListingNotificationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly createNotificationApiService: CreateNotificationApiService,
        private readonly notificationsApiService: NotificationsApiService,
        private readonly userStoreService: UserStoreService,
        private readonly listingsStoreService: ListingsStoreService,
    ) { }

    public readonly createExternalListingAddedNotification$ = createEffect(
        () => this.actions$.pipe(
            ofType(externallistingsActions.createListingSuccess),
            concatLatestFrom(() => this.userStoreService.customerCollaborationId$),
            switchMap(([{ listing }, collaborationId]) => {

                const externalListingAddedNotification = {
                    externalListingId: listing.id,
                    collaborationId: collaborationId,
                    createId: listing.createId
                };

                return this.createNotificationApiService.createExternalListingAddedNotification(externalListingAddedNotification);
            })
        )
    );

    public readonly removeExternalListingNotification$ = createEffect(
        () => this.actions$.pipe(
            ofType(externallistingsActions.deleteListingSuccess),
            map(({ deletedListingId }) => {
                const removeExternalListingNotificationInput = {
                    entityType: NotificationEventEntity.ExternalListing,
                    entitiesIds: [deletedListingId]
                };

                return notificationsActions.removeEntitiesNotifications(removeExternalListingNotificationInput);
            })
        )
    );

    public readonly createListingHardDeletedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(listingsActions.hardDeleteSuccess),
        concatLatestFrom(() => [this.userStoreService.customerCollaborationId$, this.userStoreService.customerId$]),
        switchMap(([{ listingsHashCodes }, collaborationId, customerId]) => {
            const input = { listingsIds: listingsHashCodes, collaborationId, createId: customerId };

            return this.createNotificationApiService.createListingHardDeletedNotification(input);
        })
    ));

    public readonly createListingSoftDeletedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(listingsActions.softDeleteSuccess),
        concatLatestFrom(() => [
            this.listingsStoreService.getCustomerListings(),
            this.userStoreService.customerCollaborationId$,
            this.userStoreService.customerId$
        ]),
        switchMap(([{ listingIds }, listings, collaborationId, customerId]) => {
            const ids = new Set(listingIds);
            const hashCodes = listings.reduce((hashCodes, x) => ids.has(x.id) ? [...hashCodes, x.hashCode] : hashCodes, new Array<number>())
            const input = { listingsIds: hashCodes, collaborationId, createId: customerId };

            return this.createNotificationApiService.createListingSoftDeletedNotification(input);
        })
    ));

    public readonly createListingRestoreNotification$ = createEffect(() => this.actions$.pipe(
        ofType(listingsActions.restoreSuccess),
        concatLatestFrom(() => [
            this.listingsStoreService.getCustomerListings(),
            this.userStoreService.customerCollaborationId$,
            this.userStoreService.customerId$
        ]),
        switchMap(([{ listingIds }, listings, collaborationId, customerId]) => {
            const ids = new Set(listingIds);
            const hashCodes = listings.reduce((hashCodes, x) => ids.has(x.id) ? [...hashCodes, x.hashCode] : hashCodes, new Array<number>())
            const input = { listingsIds: hashCodes, collaborationId, createId: customerId };

            return this.createNotificationApiService.createListingRestoredNotification(input);
        })
    ));
}