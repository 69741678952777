import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { PROFILE_TABS_NAMES } from 'app/modules/user-modules/profile-modules/profile-base/constants/profile-tabs.constants';

@Injectable({ providedIn: 'root' })
export class ProfilesGuard implements CanActivate, CanActivateChild {

    constructor(
        private readonly routeService: RouteService,
        private readonly userStoreService: UserStoreService
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(childRoute, state);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(route, state);
    }

    private canActivateRoute(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> {
        const params = route.queryParams;

        if (params?.tab == null) {
            return of(true);
        }

        return combineLatest([
            this.userStoreService.getUser(),
            this.userStoreService.canSeeAdditionalProfiles$
        ]).pipe(
            filter(([user, canSeeAdditionalProfiles]) => [user, canSeeAdditionalProfiles].every(x => x != null)),
            take(1),
            switchMap(([, canSeeAdditionalProfiles]) => {
                const tab = params.tab === PROFILE_TABS_NAMES.ADDITIONAL_PROFILES && canSeeAdditionalProfiles
                    ? PROFILE_TABS_NAMES.ADDITIONAL_PROFILES
                    : PROFILE_TABS_NAMES.GENERAL;

                this.routeService.navigate(RpcRoute.Profile, { state: { tab } }).catch(() => { });
                return of(false);
            }));
    }
}