import { AnimationBuilder, AnimationMetadata } from '@angular/animations';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AnimationService {
    constructor(private builder: AnimationBuilder) { }

    public playAnimation(animateElement: HTMLElement, animations: AnimationMetadata | AnimationMetadata[], repeatTimes: number = 1): void {
        if (animateElement == null || repeatTimes === 0) {
            return;
        }

        const factory = this.builder.build(animations);
        const player = factory.create(animateElement);

        player.onDone(() => setTimeout(() => player.destroy()));
        player.onDestroy(() => this.playAnimation(animateElement, animations, --repeatTimes));

        player.play();
    }
}
