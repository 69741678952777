import { Injectable } from '@angular/core';

import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { Creator } from '@appointments/enums/creator.enum';
import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { AppointmentActionOptions } from '../models/appointment-action-options';
import { AppointmentStatusDetailsParams, AppointmentStatusService } from './appointment-status.service';

type AppointmentActionOptionsParams = Pick<ListingAppointment, 'customerStatus' | 'agentStatus' | 'creator' | 'createId' | 'updateId' | 'viewed'>;

@Injectable({ providedIn: 'root' })
export class AppointmentActionService {
    public static getAppointmentActionOptions(
        appointmentActionOptionsParams: AppointmentActionOptionsParams,
        customerId: number,
        agentId: number,
        isRemovedListing: boolean,
        status: AppointmentStatus
    ): AppointmentActionOptions {
        const { createId, updateId, viewed } = appointmentActionOptionsParams;

        return {
            canAccept: AppointmentActionService.canAcceptAppointment(appointmentActionOptionsParams, status, isRemovedListing),
            canDecline: AppointmentActionService.canDeclineAppointment(appointmentActionOptionsParams, status, isRemovedListing),
            canCancel: AppointmentActionService.canCancelAppointment(appointmentActionOptionsParams, customerId, status, isRemovedListing),
            canEdit: AppointmentActionService.canEditAppointment(appointmentActionOptionsParams, customerId, agentId, status, isRemovedListing),
            canDelete: AppointmentActionService.canDeleteAppointment(appointmentActionOptionsParams, customerId, status, isRemovedListing),
            canAddToCalendar: AppointmentActionService.canAddAppointmentToCalendar(appointmentActionOptionsParams, status),
            isChanged: !viewed && ((createId !== customerId) && (updateId != null && updateId !== customerId))
        };
    }

    private static canAcceptAppointment(
        appointmentStatusDetailsParams: AppointmentStatusDetailsParams,
        status: AppointmentStatus,
        isRemovedListing = false,
        adjustStatusUsingCurrentTime = true
    ): boolean {
        return !isRemovedListing &&
            AppointmentStatusService.isAgentRequestedAppointmentIsPending(appointmentStatusDetailsParams, status, adjustStatusUsingCurrentTime);
    }

    private static canDeclineAppointment(
        { customerStatus, creator }: AppointmentStatusDetailsParams,
        status: AppointmentStatus,
        isRemovedListing = false,
        adjustStatusUsingCurrentTime = true
    ): boolean {
        if (adjustStatusUsingCurrentTime) {
            return !isRemovedListing && status === AppointmentStatus.Pending && creator === Creator.Agent;
        }
        return !isRemovedListing && customerStatus === AppointmentStatus.Pending && creator === Creator.Agent;
    }

    private static canCancelAppointment(
        { customerStatus, createId, updateId }: AppointmentActionOptionsParams,
        userId: number,
        status: AppointmentStatus,
        isRemovedListing = false,
        adjustStatusUsingCurrentTime = true
    ): boolean {
        if (adjustStatusUsingCurrentTime) {
            return !isRemovedListing && status === AppointmentStatus.Confirmed && (createId === userId || updateId === userId);
        }
        return !isRemovedListing && customerStatus === AppointmentStatus.Confirmed && (createId === userId || updateId === userId);
    }

    // eslint-disable-next-line complexity
    private static canEditAppointment(
        appointmentActionOptionsParams: AppointmentActionOptionsParams,
        userId: number,
        agentId: number,
        status: AppointmentStatus,
        isRemovedListing = false,
        adjustStatusUsingCurrentTime = true
    ): boolean {
        const notEditableStatuses = [AppointmentStatus.Declined, AppointmentStatus.Shown];
        if (isRemovedListing) {
            return false;
        }

        const { customerStatus, agentStatus, createId, updateId } = appointmentActionOptionsParams;

        if (adjustStatusUsingCurrentTime) {
            if (AppointmentActionService.isConfirmedAppointment(appointmentActionOptionsParams, status)) {
                return (createId === userId || updateId === userId) && !notEditableStatuses.includes(status);
            }
            return (createId === agentId || createId === userId) && !notEditableStatuses.includes(status);
        }
        if (AppointmentActionService.isConfirmedAppointment(appointmentActionOptionsParams, status, false)) {
            return (createId === userId || updateId === userId)
                && !notEditableStatuses.includes(customerStatus) && !notEditableStatuses.includes(agentStatus);
        }
        return (createId === agentId || createId === userId)
            && !notEditableStatuses.includes(customerStatus) && !notEditableStatuses.includes(agentStatus);
    }

    private static canDeleteAppointment(
        appointmentActionOptionsParams: AppointmentActionOptionsParams,
        userId: number,
        status: AppointmentStatus,
        isRemovedListing = false,
        adjustStatusUsingCurrentTime = true
    ): boolean {
        return !isRemovedListing && appointmentActionOptionsParams.createId === userId &&
            AppointmentStatusService.isCustomerRequestedAppointmentIsPending(appointmentActionOptionsParams, status, adjustStatusUsingCurrentTime);
    }

    private static canAddAppointmentToCalendar(
        { customerStatus, agentStatus }: AppointmentActionOptionsParams,
        status: AppointmentStatus,
        adjustStatusUsingCurrentTime = true
    ): boolean {
        if (adjustStatusUsingCurrentTime) {
            return status === AppointmentStatus.Confirmed || status === AppointmentStatus.Pending;
        }
        return (agentStatus === AppointmentStatus.Confirmed && customerStatus === AppointmentStatus.Confirmed)
            || customerStatus === AppointmentStatus.Pending
            || agentStatus === AppointmentStatus.Pending;
    }

    private static isConfirmedAppointment(
        { customerStatus, agentStatus }: AppointmentActionOptionsParams,
        status: AppointmentStatus, adjustStatusUsingCurrentTime = true): boolean {
        if (adjustStatusUsingCurrentTime) {
            return status === AppointmentStatus.Confirmed;
        }
        return agentStatus === AppointmentStatus.Confirmed || customerStatus === AppointmentStatus.Confirmed;
    }
}
