import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { SimpleDialogOptions } from '@core-utils/simple-dialog/models/simple-dialog-options';

@Component({
    selector: 'simple-dialog',
    templateUrl: './simple-dialog.component.html',
    styleUrls: ['./simple-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDialogComponent {

    public options: SimpleDialogOptions;

    constructor(
        public dialogRef: MatDialogRef<SimpleDialogComponent>
    ) { }

}