<div class="confirm-wrap" [ngClass]="options.panelClass">
    <div class="confirm-top" *ngIf="options.iconName">
        <rpc-icon [iconName]="options.iconName" class="confirm-top-icon"></rpc-icon>
    </div>
    <div class="confirm-title">
        {{ options.mainMessage | translate }}
    </div>
    <div mat-dialog-content class="confirm-message" *ngIf="options.additionalMessage != null">
        {{ options.additionalMessage | translate }}
    </div>
    <div mat-dialog-actions class="confirm-actions">
        <rpc-button class="confirm-btn confirm-btn--cancel" [title]="'IMPORTANT_DIALOG.BUTTONS.CANCEL'"
            [mainButton]="true" [color]="'accent'" (clicked)="dialogRef.close(false)">
        </rpc-button>
        <rpc-button class="confirm-btn confirm-btn--submit"
            [title]="options.confirmButtonTitle == null ? 'IMPORTANT_DIALOG.BUTTONS.OK' : options.confirmButtonTitle"
            [mainButton]="true" [color]="'accent'" [appearanceType]="'flat'" [iconName]="options.iconName"
            (clicked)="dialogRef.close(true)">
        </rpc-button>
    </div>
</div>