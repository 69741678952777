import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

import * as usersActions from '../actions/users.actions';
import { UsersApiService } from '../services/users-api.service';

@Injectable()
export class UsersEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly usersApiService: UsersApiService
    ) { }

    public readonly loadCustomers$ = createEffect(() => this.actions$.pipe(
        ofType(usersActions.loadCustomers),
        switchMap(() => this.usersApiService.loadCustomers())
    ));
}