import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'rpc-checkbox',
    templateUrl: './rpc-checkbox.component.html'
})
export class RpcCheckboxComponent {
    @Input() public state: RpcCheckboxState = 'unchecked';
    @Input() public canBeChangedToIndeterminate: boolean;
    @Input() public color?: ThemePalette = 'accent';
    @Input() public label?: string;

    @Output() public stateChange = new EventEmitter<RpcCheckboxState>();

    public onCheckboxChange(): void {
        this.updateCheckboxState();
        this.stateChange.emit(this.state);
    }

    private updateCheckboxState(): void {
        if (this.state === 'indeterminate') {
            this.state = 'checked';
            return;
        }

        if (this.state === 'checked') {
            this.state = 'unchecked';
            return;
        }

        this.state = this.canBeChangedToIndeterminate ? 'indeterminate' : 'checked';
    }
}
