import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ActionError } from '@core-models/errors/action-error';
import { ProfileActionErrors } from '@profile/enums/profile-action-errors';
import { ChildAccount } from '../../interfaces/child-account/child-account';
import { IInviteUserModel } from '../../invite-user/invite-user-model';
import { IResendInvitationModel } from '../../invite-user/resend-invitation-model';
import * as profileBaseActions from '../actions/profile-base.actions';
import * as profileBaseSelectors from '../selectors/profile-base.selector';
import { ProfileBaseState } from '../states/profile-base.state';

@Injectable({ providedIn: 'root' })
export class ProfileBaseStoreService {

    constructor(
        private readonly store: Store<ProfileBaseState>,
    ) { }

    public readonly additionalProfilesEmails$ = this.store.select(profileBaseSelectors.selectAdditionalProfilesEmails);
    public readonly canAddAdditionalProfile$ = this.store.select(profileBaseSelectors.selectCanAddAdditionalProfile);
    public readonly activeAdditionalProfiles$ = this.store.select(profileBaseSelectors.selectActiveAdditionalProfiles);

    public getAdditionalProfiles(): Observable<ChildAccount[]> {
        return this.store.select(profileBaseSelectors.selectAdditionalProfiles);
    }

    public getAdditionalProfile(customerId: number): Observable<ChildAccount> {
        return this.store.select(profileBaseSelectors.selectAdditionalProfiles).pipe(
            map(profiles => profiles.find(profile => profile.customerId === customerId))
        );
    }

    public getAdditionalProfilesCount(): Observable<number> {
        return this.store.select(profileBaseSelectors.selectAdditionalProfilesCount);
    }

    public getProfileError(): Observable<ActionError<ProfileActionErrors>> {
        return this.store.select(profileBaseSelectors.selectProfileError).pipe(filter(error => error != null));
    }

    public loadAdditionalProfiles(filters: { isActiveOnly: boolean }): void {
        this.store.dispatch(profileBaseActions.loadAdditionalProfiles({ isActiveOnly: filters.isActiveOnly }));
    }

    public inviteUser(inviteUserModel: IInviteUserModel): void {
        this.store.dispatch(profileBaseActions.inviteUser({ inviteUserModel }));
    }

    public deactivateChildAccountByPrimary(childCustomerId: number): void {
        this.store.dispatch(profileBaseActions.deactivateChildAccountByPrimary({ childCustomerId }));
    }
    public reSendInvite(resendInvitationModel: IResendInvitationModel): void {
        this.store.dispatch(profileBaseActions.reSendInvite({ resendInvitationModel }));
    }

    public cancelInvitation(childEmail: string): void {
        this.store.dispatch(profileBaseActions.cancelInvitation({ childEmail }));
    }

    public showInviteUserDialog(): void {
        return this.store.dispatch(profileBaseActions.showInviteUserDialog());
    }
}