import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { SharedListing } from '@shared-listing-details/models/shared-listing';

export const loadSharedListing = createAction(
    '[Shared Listing Details] Load Shared Listing',
    props<{ sharedListingCode: string }>());
export const loadSharedListingSuccess = createAction(
    '[Shared Listing Details] Load Shared Listing Success',
    (sharedListing: SharedListing) => sharedListing);
export const loadSharedListingFailed = createAction(
    '[Shared Listing Details] Load Shared Listing Failed',
    (error: ApiError) => error);