export class ListingActivityConstants {
    public static PickListed: ListingActivityDescription = {
        id: 1, title: 'PICKLISTED_TITLE', field: 'pickListedDate', icon: 'pick', iconClass: 'picklisted'
    };
    public static Liked: ListingActivityDescription = {
        id: 2, title: 'LIKED_TITLE', field: 'likedDate', icon: 'like', iconClass: 'liked'
    };
    public static Disliked: ListingActivityDescription = {
        id: 3, title: 'DISLIKED_TITLE', field: 'dislikedDate', icon: 'dislike', iconClass: 'disliked'
    };
    public static Shown: ListingActivityDescription = {
        id: 4, title: 'SHOWN_TITLE', field: 'shownDate', icon: 'eye', iconClass: 'shown'
    };
    public static NewMatch: ListingActivityDescription = {
        id: 5, title: 'NEW_MATCH', field: '', icon: 'gallery', iconClass: 'nm'
    };

    public static AvailableActivities: ListingActivityDescription[] = [
        ListingActivityConstants.PickListed,
        ListingActivityConstants.Liked,
        ListingActivityConstants.Disliked,
        ListingActivityConstants.Shown
    ];
}

export class ListingActivityDescription {
    id: number;
    title: string;
    field: string;
    icon: string;
    iconClass: string;
}