import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharingType } from '@agents/enums/sharing-type';
import { AgentBaseInfo } from '@agents/models/agent-base-info';
import { AgentCandidate } from '@agents/models/agent-candidate';
import { DateConstants } from '@core-constants/dates/date.constants';
import { AvatarInfo } from '@users/models/avatar-info';

@Component({
    selector: 'agent-box',
    templateUrl: './agent-box.component.html',
    styleUrls: ['./agent-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentBoxComponent {

    public readonly DateConstants = DateConstants;

    @Input() agentCandidate: AgentCandidate;
    @Input() isSelected: boolean;
    @Input() isCurrent: boolean;

    public get front(): AvatarInfo {
        return {
            fullName: this.agentOwner.fullName,
            profileImage: this.agentOwner.profilePictureUrl
        };
    }

    public get back(): AvatarInfo | null {
        if (!this.isSharedSpace) {
            return null;
        }

        const agentNotOwner = this.agentCandidate.agents.find(agent => agent.sharingTypeId !== SharingType.Owner);

        return {
            fullName: agentNotOwner.fullName,
            profileImage: agentNotOwner.profilePictureUrl
        };
    }

    public get agentOwner(): AgentBaseInfo {
        return this.agentCandidate.agents.find(agent => agent.sharingTypeId === SharingType.Owner);
    }

    public get isSharedSpace(): boolean {
        return this.agentCandidate.agents.length > 1;
    }

    public get fullNames(): string {
        return this.agentCandidate.agents.map(x => x.fullName).join(', ');
    }
}