import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { AppStoreService } from '@core-layout/app/store/services/app-store.service';
import { SidebarService } from '@core-layout/sidebar/sidebar.service';
import { ApiHttpClient } from '@core-services/api-http-client.service';

@Component({
    selector: 'rpc-theme-options',
    templateUrl: './theme-options.component.html',
    styleUrls: ['./theme-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RpcThemeOptionsComponent implements OnInit, OnDestroy {

    private readonly unsubscribe$ = new Subject<void>();

    public selectedCompanyId: string;
    public companies: string[] = ['halstead', 'bhs', 'aanyc', 'corcoran', 'elliman', 'serhant', 'sothebys', 'keller-williams'];

    constructor(
        private readonly configurationService: AppConfigurationService,
        private readonly sidebarService: SidebarService,
        private readonly http: ApiHttpClient,
        private readonly appStoreService: AppStoreService
    ) { }

    public ngOnInit(): void {
        this.configurationService.configuration$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(configuration => {
                if (configuration != null && configuration.company != null) {
                    this.selectedCompanyId = configuration.company.id;
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onModelChanged(companyId: string): void {
        this.appStoreService.loadConfiguration(companyId);
    }

    public toggleSidebarOpen(key): void {
        this.sidebarService.getSidebar(key).toggleOpen();
    }
}