<div class="avatar-initials-wrap" [ngClass]="{ 'avatar-initials-wrap--with-hover' : showHoverIcon }"
    [title]="userFullName">
    <img class="main-image" [src]="imageSrc" [alt]="userFullName" *ngIf="imageSrc != null && !catchedError" rpcOnLoad
        (error)="onError()">
    <div class="text" *ngIf="imageSrc == null || catchedError">
        {{ initials }}
    </div>
    <div class="hover-wrap" *ngIf="showHoverIcon" title="{{ 'AVATAR_INITIALS.TITLES.CHANGE_PHOTO' | translate }}">
        <div class="hover-wrap-icon-wrap">
            <rpc-icon class="hover-wrap-icon" iconName="edit" title="{{ 'AVATAR_INITIALS.TITLES.EDIT' | translate }}">
            </rpc-icon>
        </div>
        <div class="hover-wrap-icon-wrap" *ngIf="imageSrc != null">
            <rpc-icon class="hover-wrap-icon" iconName="cross" (click)="remove($event)"
                title="{{ 'AVATAR_INITIALS.TITLES.DELETE' | translate }}">
            </rpc-icon>
        </div>
    </div>
</div>