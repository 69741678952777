<form *ngIf="settings != null" name="newMatchSettingsForm" [formGroup]="newMatchSettingsForm">
    <div class="nm-wrap">
        <div class="nm-item">
            <div class="nm-item-title">{{ 'NM.TITLES.CHECK_FOR_NEW_MATCHES' | translate }}</div>
            <div class="nm-item-subtitle">{{ 'NM.TITLES.HOW_OFTEN_SHOULD_WE_CHECK' | translate }}?</div>
            <div class="nm-item-btn-wrap">
                <rpc-button class="nm-item-btn" [title]="'NM.TITLES.EVERY_FIVE_MIN' | translate"
                    [appearanceType]="getCheckFrequencyButtonAppearance(NewMatchCheckingFrequency.realtime)"
                    [color]="getCheckFrequencyButtonColor(NewMatchCheckingFrequency.realtime)"
                    (clicked)="toggleCheckFrequency(NewMatchCheckingFrequency.realtime)">
                </rpc-button>
                <rpc-button class="nm-item-btn" [title]="'NM.TITLES.FOUR_TIMES_A_DAY' | translate"
                    [appearanceType]="getCheckFrequencyButtonAppearance(NewMatchCheckingFrequency.daily)"
                    [color]="getCheckFrequencyButtonColor(NewMatchCheckingFrequency.daily)"
                    (clicked)="toggleCheckFrequency(NewMatchCheckingFrequency.daily)">
                </rpc-button>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div class="nm-item">
            <div class="nm-item-title">{{ 'NM.TITLES.EMAIL_TEMPLATE' | translate }}</div>
            <div class="nm-item-subtitle">{{ 'NM.TITLES.HOW_WOULD_YOU_LIKE_TO_FORMAT_INFORMATION' | translate }}?</div>
            <div class="nm-item-btn-wrap">
                <rpc-button class="nm-item-btn nm-item-btn--icon" [title]="'NM.TITLES.DETAILED_VIEW' | translate"
                    [appearanceType]="getEmailTemplateButtonAppearance(NewMatchEmailTemplate.detail)"
                    [iconName]="'master'" [color]="getEmailTemplateButtonColor(NewMatchEmailTemplate.detail)"
                    (clicked)="toggleEmailTemplate(NewMatchEmailTemplate.detail)">
                </rpc-button>
                <rpc-button class="nm-item-btn nm-item-btn--icon" [title]="'NM.TITLES.SUMMARY_VIEW' | translate"
                    [appearanceType]="getEmailTemplateButtonAppearance(NewMatchEmailTemplate.summary)"
                    [iconName]="'summary-lines'" [color]="getEmailTemplateButtonColor(NewMatchEmailTemplate.summary)"
                    (clicked)="toggleEmailTemplate(NewMatchEmailTemplate.summary)">
                </rpc-button>
            </div>
        </div>

        <div class="nm-item">
            <div class="nm-item-title">{{ 'NM.TITLES.MY_NOTIFICATIONS' | translate }}</div>
            <div class="nm-item-subtitle">{{ 'NM.TITLES.HOW_OFTEN_TO_RECEIVE' | translate }}?</div>
            <div class="nm-item-btn-wrap">
                <rpc-button *ngFor="let option of notificationFrequencyOptions" class="nm-item-btn"
                    [title]="option.titleTranslationKey | translate"
                    [appearanceType]="getEmailNotificationFrequencyButtonAppearance(option.frequency)"
                    [color]="getEmailNotificationFrequencyButtonColor(option.frequency)"
                    (clicked)="toggleEmailNotificationFrequency(option.frequency)">
                </rpc-button>
            </div>

            <div class="nm-item-box">

                <div class="nm-item-title">{{'NM.TITLES.DELIVERY_SCHEDULE' | translate}}</div>

                <div *ngIf="isEmailNotificationFrequencySelected('realtime')" class="nm-item-box-text">
                    {{ 'NM.TEXT.REALTIME' | translate }}
                </div>

                <ng-container *ngIf="isEmailNotificationFrequencySelected('daily')">
                    <div class="nm-item-btn-wrap">
                        <rpc-button *ngFor="let option of weekDayOptions" class="nm-item-btn"
                            [title]="option.titleTranslationKey| translate"
                            [appearanceType]="getDailyFrequencyButtonAppearance(option.day)"
                            [color]="getDailyFrequencyButtonColor(option.day)"
                            (clicked)="toggleDailyFrequencyDay(option.day)">
                        </rpc-button>
                    </div>
                    <mat-error *ngIf="newMatchSettingsForm.controls?.selectedDays?.value.length === 0">
                        {{ 'NM.ERROR_MESSAGE.SELECT_ONE_DAY' | translate }}
                    </mat-error>
                    <div class="nm-item-box-text">
                        {{ 'NM.TEXT.EMAILS_ARE_SENT' | translate }}
                        <rpc-select class="nm-item-box-select nm-item-box-select--sm" [color]="'accent'"
                            [label]="'NM.TITLES.FREQUENCY_PER_DAY' | translate" [appearance]="'outline'"
                            [controlOptions]="frequencyPerDay" (changed)="frequencyPerDayChanged($event)">
                        </rpc-select>
                        {{ 'NM.TEXT.PER_DAY' | translate }}
                    </div>
                </ng-container>

                <ng-container *ngIf="isEmailNotificationFrequencySelected('weekly')">
                    <div class="nm-item-box-text">
                        {{ 'NM.TEXT.EVERY' | translate }}
                        <rpc-select class="nm-item-box-select nm-item-box-select--xs" [color]="'accent'"
                            [label]="'NM.TITLES.FREQUENCY_PER_DAY' | translate" [appearance]="'outline'"
                            [controlOptions]="weeklyFrequency" (changed)="weeklyFrequencyChanged($event)">
                        </rpc-select>
                        {{ 'NM.TEXT.WEEKS' | translate }}
                        <rpc-select class="nm-item-box-select nm-item-box-select--sm" [color]="'accent'"
                            [label]="'NM.TITLES.FREQUENCY_PER_DAY' | translate" [appearance]="'outline'"
                            [controlOptions]="weeklyFrequencyDay" (changed)="weeklyFrequencyDayChanged($event)">
                        </rpc-select>
                        <br />
                        {{ 'NM.TEXT.WEEKLY_EMAIL_SENDING' | translate }}
                    </div>
                </ng-container>

                <ng-container *ngIf="isEmailNotificationFrequencySelected('monthly')">
                    <div class="nm-item-box-text">
                        {{ 'NM.TEXT.EVERY' | translate }}
                        <rpc-select class="nm-item-box-select" [color]="'accent'"
                            [label]="'NM.TITLES.FREQUENCY_PER_DAY' | translate" [appearance]="'outline'"
                            [controlOptions]="monthlyFrequency" (changed)="monthlyFrequencyChanged($event)">
                        </rpc-select>
                        {{ 'NM.TEXT.MONTHLY_EMAIL_SENDING' | translate }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</form>