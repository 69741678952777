import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ListingsSelectionEventParams } from '@listings/models/events/listings-selection-event-params';

@Component({
    selector: 'multi-selection-menu',
    templateUrl: './multi-selection-menu.component.html',
    styleUrls: ['./multi-selection-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectionMenuComponent implements OnChanges {

    @Input() public listingIds: string[];
    @Input() public selectedListingIds: string[];
    @Input() public compact: boolean;

    @Output() public selectionChanged = new EventEmitter<ListingsSelectionEventParams>();

    @ContentChild('selectionSubMenu') public selectionSubMenu: TemplateRef<any>;

    public allListingsSelected: boolean;
    public someListingsSelected: boolean;

    public ngOnChanges(changes: SimpleChanges<MultiSelectionMenuComponent>): void {
        if (changes.listingIds != null && changes.listingIds.currentValue != null) {
            this.listingIds = changes.listingIds.currentValue;
        }

        if (changes.selectedListingIds != null && changes.selectedListingIds.currentValue != null) {
            this.selectedListingIds = changes.selectedListingIds.currentValue;
        }

        if (this.listingIds != null && this.selectedListingIds != null) {
            this.allListingsSelected = this.listingIds.every(listingId => this.selectedListingIds.includes(listingId));
            this.someListingsSelected = this.selectedListingIds.length > 0 && !this.allListingsSelected;
        }
    }

    public onSelectionChanged(checked: boolean): void {
        this.selectionChanged.emit({ listingIds: this.listingIds, areSelected: checked });
    }
}