import { DEFAULT_FOLDER_SETTINGS } from './constants/settings.constants';
import { CustomerSettings } from './models/settings/customer-settings';
import { FoldersSettings } from './models/settings/folders-settings';

export class SettingsHelper {

    public static createDefaultFolderSettings(folderId: number): FoldersSettings {
        return { ...DEFAULT_FOLDER_SETTINGS, folderId };
    }

    public static updateWithFoldersSettings(settings: CustomerSettings, foldersSettings: FoldersSettings[], isUnselectDefaultFolder: boolean = false): CustomerSettings {
        return {
            ...settings,
            layoutSettings: {
                ...settings.layoutSettings,
                foldersSettings: foldersSettings,
                defaultFolderId: isUnselectDefaultFolder ? null : settings.layoutSettings.defaultFolderId
            }
        };
    }

    public static getDefaultFolderSettings(settings: CustomerSettings): FoldersSettings {
        const { defaultFolderId, foldersSettings } = settings.layoutSettings;

        return defaultFolderId != null
            ? foldersSettings.find(({ folderId }) => folderId === defaultFolderId)
            : null;
    }

    public static updateWithDefaultFolderSettings(settings: CustomerSettings): CustomerSettings {
        const { defaultFolderId, foldersSettings } = settings.layoutSettings;

        return SettingsHelper.getDefaultFolderSettings(settings) == null
            ? SettingsHelper.updateWithFoldersSettings(settings, [...(foldersSettings ?? []), SettingsHelper.createDefaultFolderSettings(defaultFolderId)])
            : settings;
    }
}