import { AbstractControl, ValidationErrors } from '@angular/forms';

export const uniqueValuesValidatior = <TValue>(values: TValue[], hasCaseCompare: boolean): ((control: AbstractControl) => ValidationErrors | null) => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        if (
            currentControl == null ||
            currentControl.parent == null ||
            currentControl.value == null ||
            currentControl.value === '' ||
            values == null
        ) {
            return null;
        }

        const match = values.some(value => {
            return hasCaseCompare
                // eslint-disable-next-line no-undefined
                ? value.toString().localeCompare(currentControl.value, undefined, { sensitivity: 'base' }) === 0
                : value === currentControl.value;
        });

        return match
            ? { uniqueValue: true }
            : null;
    };
};