<div class="rpc-file-wrap">
    <div class="rpc-file-box" dropZone (dataTransfer)="onDrop($event)" (click)="file.click()">
        <input type="file" #file (change)="uploadFile(file.files)" class="rpc-file-input" [accept]="accept" />
        <div class="rpc-file-panel">
            <div class="rpc-file-title-wrap" *ngIf="fileData == null">
                <rpc-icon [iconName]="'photo'" class="rpc-file-title-icon"></rpc-icon>
                <div class="rpc-file-title">{{ title | translate }}</div>
            </div>
            <div class="rpc-file-img-wrap" *ngIf="fileData != null">
                <div class="rpc-file-img" [ngStyle]="{'background-image' : 'url(' + fileData + ')'}"></div>
                <rpc-icon [iconName]="'cross'" title="{{ 'PRC_FILE_UPLOAD.BUTTONS.DELETE' | translate }}" class="rpc-file-img-btn" (click)="deleteImage($event)"></rpc-icon>
            </div>
        </div>
    </div>

    <mat-error class="rpc-file-error-wrap" *ngIf="uploadError">
        <div class="rpc-file-error">{{ 'PRC_FILE_UPLOAD.ERRORS.FILE_UPLOAD_ERROR' | translate }}</div>
    </mat-error>

    <mat-hint class="rpc-file-hint-wrap" *ngFor="let validationItem of validatorsMap">
        <ng-container *ngTemplateOutlet="showErrorsTemplate, context: {
            $implicit: {
                showError: validationItem.showError,
                message: validationItem.message
            }
        }">
        </ng-container>
    </mat-hint>
</div>

<ng-template #showErrorsTemplate let-validationItem>
    <div class="rpc-file-hint" *ngIf="validationItem.showError(formControl)">
        {{ validationItem.message | translate }}
    </div>
</ng-template>