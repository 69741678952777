import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProgressBarService {

    private bufferValue: BehaviorSubject<number>;
    private mode: BehaviorSubject<'determinate' | 'indeterminate' | 'buffer' | 'query'>;
    private value: BehaviorSubject<number>;
    private visible: BehaviorSubject<boolean>;

    constructor(
        private readonly router: Router
    ) {
        this.initialize();
    }

    public get bufferValue$(): Observable<number> {
        return this.bufferValue.asObservable();
    }

    public setBufferValue(value: number): void {
        this.bufferValue.next(value);
    }

    public get mode$(): Observable<'determinate' | 'indeterminate' | 'buffer' | 'query'> {
        return this.mode.asObservable();
    }

    public setMode(value: 'determinate' | 'indeterminate' | 'buffer' | 'query'): void {
        this.mode.next(value);
    }

    public get value$(): Observable<number> {
        return this.value.asObservable();
    }

    public setValue(value: number): void {
        this.value.next(value);
    }

    public get visible$(): Observable<boolean> {
        return this.visible.asObservable();
    }

    public show(): void {
        this.visible.next(true);
    }

    public hide(): void {
        this.visible.next(false);
    }

    private initialize(): void {
        this.bufferValue = new BehaviorSubject(0);
        this.mode = new BehaviorSubject('indeterminate');
        this.value = new BehaviorSubject(0);
        this.visible = new BehaviorSubject(false);

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe(() => {
                this.show();
            });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel))
            .subscribe(() => {
                this.hide();
            });
    }
}