export const locale = {
    lang: 'en',
    data: {
        COMMENTS: {
            PLACEHOLDERS: {
                NEW_MESSAGE: 'Please, leave your message here'
            },
            BUTTONS: {
                SEND: 'Send',
                CANCEL: 'Cancel',
                EDIT: 'Edit Message'
            },
            TITLES: {
                VIEWED: 'Read',
                CREATOR: 'Creator',
                CREATION_TIME: 'Creation Time',
                CREATION_DATE: 'Creation Date',
                NO_MESSAGES: 'There are no messages yet',
                SHOW_OTHER: 'Show other',
                SHOW_OTHER_MESSAGES: 'messages',
                SHOW_OTHER_MESSAGE: 'message',
                EDITED: 'Edited',
                TODAY: 'Today'
            }
        }
    }
};