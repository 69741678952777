export class ListingStatusConstants {

    public static Sales = {
        Active: ['ACTIVE', 'BACK ON MARKET', 'OFFER OUT', 'OFFER', 'OFFER ACCEPTED', 'CONTRACT OUT'],
        Signed: ['CONTRACT SIGNED', 'BOARD APPROVED'],
        Closed: ['SOLD'],
        OffMarket: ['PERMANENTLY OFF MARKET', 'TEMPORARILY OFF MARKET'],
        Expired: ['EXPIRED'],
        Future: ['FUTURE']
    };

    public static Rentals = {
        Active: ['ACTIVE', 'BACK ON MARKET', 'APPLICATION IN', 'APPLICATION ACCEPTED', 'LEASE OUT', 'APP ACCEPTED', 'APP IN'],
        Signed: ['LEASE SIGNED', 'BOARD APPROVED'],
        Closed: ['RENTED'],
        OffMarket: ['PERMANENTLY OFF MARKET', 'TEMPORARILY OFF MARKET'],
        Expired: ['EXPIRED'],
        Future: ['FUTURE']
    };
}