import * as moment from 'moment';

import { ActivityListingCandidate, SetListingActivityRequest } from '@listings/models/api/set-listing-activity-request';
import { CommonListing } from '@listings/models/listing/common-listing';
import { ListingActivity } from '@listings/models/listing/listing-activity';
import { Listings } from '@listings/models/listing/listings';
import { ListingsState } from '@listings/store/states/listings.state';

export class ListingsReducerHelpers {

    public static setListingsActivities(
        state: ListingsState,
        request: SetListingActivityRequest,
        customerId: number,
        isRestore: boolean,
        changeActivityAction: (activityListingCandidate: ActivityListingCandidate, activityId: number, customerId: number) => ListingActivity[],
    ): ListingsState {

        const modifiedListings = JSON.parse(JSON.stringify(state.listings)) as Listings;
        const modifiedListingsCollection = Object.values(modifiedListings);
        const removalCandidateIds: string[] = [];

        request.listingCandidates.forEach(listingActivityCandidate => {
            const activities = changeActivityAction(listingActivityCandidate, request.activity.id, customerId);
            const candidateListing = modifiedListingsCollection.find(modifiedListing => modifiedListing.hashCode === listingActivityCandidate.hashCode);

            if (activities.length > 0) {

                if (listingActivityCandidate.isMarketListing) {
                    candidateListing.customerAssignedDate = new Date();
                }

                ListingsReducerHelpers.setActivity(
                    candidateListing,
                    listingActivityCandidate,
                    customerId,
                    isRestore,
                    activities
                );
            } else {
                modifiedListingsCollection
                    .filter(modifiedListing => modifiedListing.hashCode === listingActivityCandidate.hashCode)
                    .forEach(modifiedListing => delete modifiedListings[modifiedListing.id]);
            }
        });

        return {
            ...state,
            listings: modifiedListings,
            removalCandidateIds: state.removalCandidateIds.concat(removalCandidateIds),
            selectedListingIds: state.selectedListingIds.filter(selectedListingId => modifiedListings[selectedListingId] != null)
        };
    }

    public static updateListings(currentListings: Listings, newListings: Listings, isCustomerListings: boolean): Listings {

        const updatedListings: CommonListing[] = [];

        const newListingsArray = Object.values(newListings);

        // On each update keep loaded customer listings and remove market marker (it will be set in case of is it specified in new listing source)
        Object.values(currentListings).forEach(listing => {
            if ((!isCustomerListings && (listing.isNewMatch || listing.activities?.length > 0)) ||
                (isCustomerListings && newListingsArray.some(newListing => newListing.hashCode === listing.hashCode))) {

                updatedListings.push({ ...listing, isMarketListing: false });
            }
        });

        newListingsArray.forEach(listing => {
            const matchingListingIndex = updatedListings.findIndex((exsistingListing: CommonListing) => exsistingListing.hashCode === listing.hashCode);

            if (matchingListingIndex > -1) {
                updatedListings[matchingListingIndex] = { ...listing, id: updatedListings[matchingListingIndex].id };
            } else {
                updatedListings.push(listing);
            }
        });

        const listings = new Listings();
        updatedListings.forEach(listing => listings[listing.id] = listing);
        return listings;
    }

    private static setActivity(
        modifiedListing: CommonListing,
        listingCandidate: ActivityListingCandidate,
        customerId: number,
        isRestore: boolean,
        activities: ListingActivity[]
    ): void {
        if (listingCandidate.activities.length === 0) {
            modifiedListing.listingOwnerId = customerId;
        }

        modifiedListing.activities = activities;
        modifiedListing.activitiesUpdateDate = moment().toISOString(true);

        modifiedListing.isNewMatch = isRestore ? listingCandidate.isNewMatch : false;
        modifiedListing.newMatches = [];
    }
}