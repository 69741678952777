<ng-container *ngIf="appearanceType === 'normal' && link == null || link === '' && target !== '_blank'">
    <button mat-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit"
        (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
    ]" [disabled]="disabled" [type]="type">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </button>
</ng-container>

<ng-container *ngIf="appearanceType === 'normal' && link != null && link !== '' && target !== '_blank'">
    <a mat-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit"
        [routerLink]="link" [routerLinkActive]="routerLinkActive" [target]="target" (click)="clicked.emit($event)"
        title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
    ]" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </a>
</ng-container>

<ng-container *ngIf="appearanceType === 'normal' && link != null && link !== '' && target === '_blank' && download == null">
    <a mat-button [color]="color" class="rpc-btn-link"  [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit"
        [href]="link" [target]="target" (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
    ]" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </a>
</ng-container>

<ng-container *ngIf="appearanceType === 'normal' && link != null && link !== '' && target === '_blank' && download != null">
    <a mat-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" 
        [rpcBadgeCountAlertLimit]="badgeCountAlertLimit" [href]="link" [download]="download" [target]="target" 
        (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
    ]" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </a>
</ng-container>


<ng-container *ngIf="appearanceType === 'stroke' && link == null || link === '' && target !== '_blank'">
    <button mat-stroked-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" 
        [rpcBadgeCountAlertLimit]="badgeCountAlertLimit" (click)="clicked.emit($event)" 
        title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
    ]" [disabled]="disabled" [type]="type">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </button>
</ng-container>

<ng-container *ngIf="appearanceType === 'stroke' && link != null && link !== '' && target !== '_blank'">
    <a mat-stroked-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert"
        [rpcBadgeCountAlertLimit]="badgeCountAlertLimit" [routerLink]="link" [routerLinkActive]="routerLinkActive" [target]="target" 
        (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
    ]" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </a>
</ng-container>

<ng-container *ngIf="appearanceType === 'stroke' && link != null && link !== '' && target === '_blank' && download == null">
    <a mat-stroked-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit"
        [href]="link" [target]="target" (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
    ]" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </a>
</ng-container>

<ng-container *ngIf="appearanceType === 'stroke' && link != null && link !== '' && target === '_blank' && download != null">
    <a mat-stroked-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit"
        [href]="link" [download]="download" [target]="target" (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
    ]" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </a>
</ng-container>


<ng-container *ngIf="appearanceType === 'flat' && link == null || link === '' && target !== '_blank'">
    <button mat-flat-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount"
        [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit" 
        (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
        ]" [disabled]="disabled" [type]="type">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </button>
</ng-container>

<ng-container *ngIf="appearanceType === 'flat' && link != null && link !== '' && target !== '_blank'">
    <a mat-flat-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" 
        [rpcBadgeCountAlertLimit]="badgeCountAlertLimit" [routerLink]="link" [routerLinkActive]="routerLinkActive" [target]="target" 
        (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
        ]" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </a>
</ng-container>

<ng-container *ngIf="appearanceType === 'flat' && link != null && link !== '' && target === '_blank' && download == null">
    <a mat-flat-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit"
        [href]="link" [target]="target" (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
        ]" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </a>
</ng-container>

<ng-container *ngIf="appearanceType === 'flat' && link != null && link !== '' && target === '_blank' && download != null">
    <a mat-flat-button [color]="color" class="rpc-btn-link" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit"
        [href]="link" [download]="download" [target]="target" (click)="clicked.emit($event)" title="{{ shouldTranslate ? (alt | translate) : alt }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            mainButton ? 'rpc-btn-link--main' : '',
            enableCustomBackground ? 'rpc-btn-link--custom' : ''
        ]" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
    </a>
</ng-container>


<ng-template #buttonContent>

    <rpc-icon *ngIf="iconName != null && iconName !== ''" [iconName]="iconName" class="rpc-btn-link-icon">
    </rpc-icon>

    <span class="rpc-btn-link-text" *ngIf="title != null && title !== ''">
        {{ shouldTranslate ? (title | translate) : title }}
    </span>

    <span class="rpc-btn-link-bg" *ngIf="enableCustomBackground"></span>

</ng-template>