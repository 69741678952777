import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'removed-listing-activity-controls',
    templateUrl: './removed-listing-activity-controls.component.html',
    styleUrls: ['../activity-controls-base/activity-controls/activity-controls.component.scss', './removed-listing-activity-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemovedListingActionControlsComponent {

    @Input() public baseColor?: boolean = null;
    @Input() public isColored?: boolean = null;
    @Input() public isIconButtons = true;
    @Input() public enableCustomBackground?: boolean = null;

    @Output() public deleteListingClicked = new EventEmitter();
    @Output() public restoreClicked = new EventEmitter();

    @ContentChild('extraActions') public extraActions: TemplateRef<HTMLElement>;

    public onDeleteListingClicked(): void {
        this.deleteListingClicked.emit();
    }

    public onRestoreClicked(): void {
        this.restoreClicked.emit();
    }
}