<a class="welcome-video" [href]="welcomeVideoLinkFeature.link" target="_blank" rel="nofollow">
    <div class="welcome-video-title">
        {{ 'WELCOME_VIDEO_LINK.TITLES.TITLE' | translate }} {{ welcomeVideoLinkFeature.productNameAlias }}?
    </div>
    <div class="welcome-video-button">
        <rpc-icon [iconName]="'triangle'" class="welcome-video-button-icon"></rpc-icon>
        <div class="welcome-video-button-text">{{ 'WELCOME_VIDEO_LINK.TITLES.WATCH_VIDEO' | translate }}</div>
    </div>
    <div class="welcome-video-img-wrap">
        <svg class="welcome-video-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160.62 92.5">
            <path
                d="M160.62,85V45.85c-8.46-2.54-28.37-1.05-69-14C13.45,6.82,0,92.39,0,92.39l11.23.11H151.67A9.35,9.35,0,0,0,159,89.14,6.58,6.58,0,0,0,160.62,85Z"
                fill="#efefef" />
            <rect x="33.28" y="62.5" width="109" height="30" fill="#f5f8fb" style="isolation:isolate" />
            <path d="M38.28,52h99a5,5,0,0,1,5,5v7h-109V57A5,5,0,0,1,38.28,52Z" fill="#283237"
                style="isolation:isolate" />
            <line x1="42.78" y1="72.52" x2="65.78" y2="72.52" fill="none" stroke="#bcb8b8" stroke-width="4" />
            <line x1="42.78" y1="81.03" x2="83.78" y2="81.03" fill="none" stroke="#bcb8b8" />
            <line x1="42.78" y1="88.12" x2="83.78" y2="88.12" fill="none" stroke="#bcb8b8" />
            <circle cx="39.28" cy="58" r="2" fill="#fff" />
            <circle cx="45.28" cy="58" r="2" fill="#fff" />
            <circle cx="51.28" cy="58" r="2" fill="#fff" />
            <path
                d="M79.15,22.93a10.37,10.37,0,0,1-2.44-5.23,4,4,0,0,1,.4-2.85c1.69-2.79,3.12-5.76,5.54-8,1.72-1.62,3.63-2.71,6.08-1.95.7.21.6-.27.69-.57,1-3.41,4-5,7.86-3.94,1.45.41,3.08,3.58,2.9,5.56A10,10,0,0,1,99,9.5a2.23,2.23,0,0,1-2.3,1.12,12.89,12.89,0,0,1,2,9.24,7.81,7.81,0,0,1-1.23,3.25c-.36.53-.83,1.15-1.49,1.06-1.62-.2-2.21.92-2.89,2a2.51,2.51,0,0,1-.18-2c.43-1.36,0-2.6-.35-3.88-.13-.53-.35-.73-.9-.67a21.17,21.17,0,0,1-4.64,0L90.5,22a7.84,7.84,0,0,1-3.23-1.18A18.53,18.53,0,0,1,81.36,15c-.48-.71-.75-.74-1.16,0a7.29,7.29,0,0,0-.72,4C79.56,20.35,79.9,21.69,79.15,22.93Z"
                fill="#283237" />
            <path
                d="M79.15,22.93c.75-1.24.41-2.58.33-3.87a7.29,7.29,0,0,1,.72-4c.41-.79.68-.75,1.16,0a18.53,18.53,0,0,0,5.91,5.81A8,8,0,0,0,90.5,22L87,19.65a20.57,20.57,0,0,0,4.64,0c.55-.05.77.15.9.68.32,1.28.78,2.52.35,3.88a2.51,2.51,0,0,0,.18,2l.09,1.16c.16.76.31,1.52.49,2.27.6,2.53,1.54,4.73,4.75,4.54a3.33,3.33,0,0,1-.94,1.62c-2.87,3-8.65,4-12.3,1.93C84,37,82.72,36.45,82.21,35c.32-.11.63-.24,1-.32.68-.17,1.69.06,1.91-.7a5.14,5.14,0,0,0-.21-3.15c-.05-.12-.15-.3-.25-.31-2.07-.33-2.66-2.23-3.8-3.55C80.38,25.6,79.37,24.43,79.15,22.93Z"
                fill="#f0af7c" />
            <path
                d="M82.2,35c.52,1.41,1.83,2,3,2.64,3.65,2.06,9.43,1.1,12.3-1.93a3.24,3.24,0,0,0,.93-1.62,18.2,18.2,0,0,1,9.46,3.12c1.43,1,2.06,2.58,2.8,4.07,1.83,3.67,3.16,7.56,4.75,11.33,1,3.5,2.32,6.91,3.06,10.5A3.77,3.77,0,0,1,117,67.64c-.85.65-1.68,1.32-2.52,2l-.49.25-3,1.36a11.71,11.71,0,0,1-2.24.65c-1.48.16-2,.86-1.67,2.31a6.19,6.19,0,0,0,.07,2.31l0,.09.06.08c.13,1.48.26,3,.38,4.43l.15,1.49.24,3.2a2.8,2.8,0,0,0,.11,1.49l-.19,1.24-.35.2a3.52,3.52,0,0,0-1.55-.07c-.55.06-1-.11-1-.9a45.56,45.56,0,0,1,.91-8.48,28.33,28.33,0,0,0,.39-5.36c.28-1.14-.09-1.61-1.29-1.3a15.66,15.66,0,0,1-1.55.26c-1.84,0-3.7-.06-5.54,0a1.24,1.24,0,0,1-1.47-1.2c-.14-.85-.23-1.72-.34-2.58a6.74,6.74,0,0,1,1.78-4.51,14.31,14.31,0,0,1,3.66-.84c2.27-.34,4.4-.73,4.7-3.72.14-1.41-.12-1.95-1.54-1.74-.38.05-.76.14-1.15.17-3.08.25-6,3.55-6.83,6.08l-7.07-.2c-2-3.76-4.47-7.2-6.6-10.86a2.15,2.15,0,0,0-2.2-1.25c-4,.15-8,.23-12,.33.5-2.63,1.58-5.09,2.23-7.67l1.18-3C74.09,37.48,77.65,35.53,82.2,35Zm24.72,24.64c0,.9,0,1.43,0,2,0,.23-.09.55.19.62a1.1,1.1,0,0,0,.68-.19c.36-.2.19-.47,0-.73Z"
                fill="#ed7a65" />
            <path
                d="M60.6,60.4l-5.05-7.82c-.75-1.65-2.12-2.94-2.7-4.69h0c2.55,1,4.81,2.52,7.25,3.72a7.44,7.44,0,0,0,2.23,1l.76.58c2.21,4.13,4.81,8,7.22,12a48.55,48.55,0,0,1,3.39,5.89l-1.1-.37-.94-.53-4.23-2.21c-1.53-.79-3.23-1.36-4-3.12a18.51,18.51,0,0,0,2-3.89c.39-1.31-.13-1.87-1.46-1.63a2.12,2.12,0,0,0-1.09.37c-.82.8-1.38.47-1.88-.48Z"
                fill="#4e565b" />
            <path
                d="M63.09,53.23l-.76-.57,6.48-.1c4-.1,8-.17,12-.32A2.17,2.17,0,0,1,83,53.48c2.13,3.67,4.62,7.11,6.6,10.87-.56,0-.78-.5-1.19-.72-1.82-3.62-4.21-6.9-6.26-10.38-.28-.48-.76-.37-1.19-.36Z"
                fill="#f9e9d8" />
            <path d="M52.85,47.89l-.21-.08.13-.12.08.2Z" fill="#253862" />
            <path
                d="M103.54,73.89c.13,3.67.68,7.34.16,11a6.26,6.26,0,0,1-.62,2.36,1.87,1.87,0,0,1-1.25-.35,16.07,16.07,0,0,1-6.68-10.28,28,28,0,0,1-.25-7.54h1.15c.11.86.2,1.72.35,2.58a1.24,1.24,0,0,0,1.27,1.22l.19,0c1.85-.08,3.7,0,5.55,0C103.45,73.21,103.49,73.55,103.54,73.89Z"
                fill="#a45446" />
            <path
                d="M96.05,69.1H94.9l-2.34-.48-.88-.13c-2.59-.72-5.13-1.06-7.68.26a7.64,7.64,0,0,1-3.86.64c-.65,0-1.33-.08-1.63-.76s.2-1.34.72-1.76c.94-.76,2-1.44,3-2.13.53-.37,1-.68.14-1.22a.64.64,0,0,1-.36-.73c.13-.36.49-.25.76-.23a13.76,13.76,0,0,1,5.7,1.07c.4.22.63.74,1.18.72l7.07.19,1.15.06A6.76,6.76,0,0,0,96.05,69.1Z"
                fill="#f0af7c" />
            <path
                d="M97.83,64.6l-1.15-.06c.82-2.52,3.74-5.82,6.83-6.07.38,0,.76-.12,1.15-.18,1.42-.21,1.68.33,1.53,1.75-.3,3-2.43,3.37-4.7,3.72A13.61,13.61,0,0,0,97.83,64.6Z"
                fill="#a45446" />
            <path
                d="M106.92,59.68c.41.74.65,1.2.91,1.66.15.26.33.53,0,.73a1.07,1.07,0,0,1-.67.19c-.29-.07-.19-.4-.2-.62C106.91,61.11,106.92,60.57,106.92,59.68Z"
                fill="#ed7a65" />
            <path d="M107.81,88.52l-.35.2Z" fill="#dfeaf9" />
            <path d="M107.1,76.5a.17.17,0,0,1,0,.17l-.07-.08Z" fill="#adcaf9" />
            <path
                d="M71.65,70.26c-2.73,2.38-4.4,2.57-7.32.84a12.28,12.28,0,0,1-3.4-5.65,2.54,2.54,0,0,0,2.5-.52c.77,1.76,2.47,2.33,4,3.13Z"
                fill="#ee9445" />
            <path
                d="M63.43,64.93a2.54,2.54,0,0,1-2.5.52c-.55-.69-.39-1.54-.55-2.32l.21-2.73L61,59.31c.5.94,1.05,1.28,1.87.47a2.19,2.19,0,0,1,1.1-.37c1.32-.24,1.85.32,1.46,1.63A18.75,18.75,0,0,1,63.43,64.93Z"
                fill="#f0af7c" />
            <path
                d="M68.49,82.52c-1.69-2.07-1.43-4.11.06-6.15,1.36-1.86,2.7-3.72,4-5.58l1.1.37c.13.06.26.18.38.18,6.17,0,12.33,0,18.49,0,1.29,0,.5-.64.38-1.08.3-.66-.25-1.11-.39-1.66l2.34.48a27.93,27.93,0,0,0,.25,7.53,16,16,0,0,0,6.68,10.28,1.81,1.81,0,0,0,1.25.36l-.39.85a10.14,10.14,0,0,1-2.9.93c-3,.57-6,.34-9,.39a55.1,55.1,0,0,1-16.31-3.25A17.54,17.54,0,0,1,68.49,82.52Z"
                fill="#ed7a65" />
            <path
                d="M93,70.28c.12.44.91,1.09-.38,1.08-6.16,0-12.33,0-18.49,0-.12,0-.25-.12-.38-.18a47.68,47.68,0,0,0-3.39-5.88c-2.41-4-5-7.92-7.22-12.05L81,52.89c.43,0,.91-.12,1.19.36,2,3.48,4.44,6.76,6.26,10.38a13.73,13.73,0,0,0-5.7-1.07c-.26,0-.62-.13-.76.23a.65.65,0,0,0,.36.73c.89.54.4.85-.13,1.22-1,.69-2,1.37-3,2.13-.52.42-1.06,1-.72,1.76s1,.73,1.63.76A7.54,7.54,0,0,0,84,68.74c2.55-1.31,5.09-1,7.68-.25A5,5,0,0,0,93,70.28Z"
                fill="#283237" />
            <path d="M93,70.28a5,5,0,0,1-1.27-1.8l.88.13C92.7,69.17,93.25,69.62,93,70.28Z" fill="#f9e9d8" />
            <path d="M103.44,90.8l.23.46A.42.42,0,0,1,103.44,90.8Z" fill="#f5f8fb" />
            <path
                d="M90.75,89.44a55.1,55.1,0,0,1-16.31-3.25,17.54,17.54,0,0,1-6-3.67c-.23,1.19-.45,2.38-.68,3.57q-.27,3.19-.53,6.41H90.44C90.54,91.48,90.65,90.46,90.75,89.44Z"
                fill="#35434a" />
            <path
                d="M103.67,91.26a.42.42,0,0,1-.23-.46l-.1-.57-.65-2.11a10.14,10.14,0,0,1-2.9.93c-3,.57-6,.34-9,.39-.1,1-.2,2-.31,3.06h13.44c-.08-.37-.14-.75-.2-1.12Z"
                fill="#283237" />
            <path d="M134.28,92.5V79a7,7,0,0,0-7-7h-24a7,7,0,0,0-7,7V92.5Z" fill="#bcb8b8" opacity="0.2"
                style="isolation:isolate" />
            <polygon
                points="113.29 92.5 116.11 84.72 118.94 92.5 121.14 92.5 117.09 81.94 115.13 81.94 111.09 92.5 113.29 92.5"
                fill="#bcb8b8" />
        </svg>
    </div>
</a>