import { SortDirection } from './sort-direction';
import { SortOptionBase } from './sort-option';

export class SortOptionSecondLevel<T, K> extends SortOptionBase {
    public propertyName: string;

    constructor(firstPropertyName: keyof T, secondPropertyName: keyof K, direction: SortDirection = SortDirection.Desc) {
        super(direction);
        this.propertyName = `${firstPropertyName}.${secondPropertyName}`;
    }
}