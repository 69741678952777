/* eslint-disable @typescript-eslint/no-floating-promises */
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { CompanyConfiguration } from '@app-config/models/company-configuration';
import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { environment } from 'environments/environment';
import { AppStoreService } from '../store/services/app-store.service';
import { GoogleAnalyticsStoreService } from '../store/services/google-analytics-store.service';

@Injectable({ providedIn: 'root' })
export class AppService {

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly appStoreService: AppStoreService,
        private readonly configurationService: AppConfigurationService,
        private readonly platform: Platform,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
    ) { }

    public initializeCompanyConfiguration(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.configurationService.configuration$
                .subscribe(configuration => {
                    if (configuration != null && configuration.company != null) {
                        this.initializeManifest(configuration.company);
                        this.initializeFavicon(configuration.company);
                        this.initializeAppleTouchIcon(configuration.company);
                        resolve();
                    }
                });

            this.appStoreService.loadDomainCompanyConfiguration();
        });
    }

    public initializeSvgSprite(): Promise<void> {
        return new Promise<void>((resolve) => {
            const insertIconsElement = (iconsElementId: string, icons: string) => {
                const sprite = document.getElementById(iconsElementId);
                if (sprite !== null) {
                    sprite.remove();
                }

                const tempIconsContainerElement = document.createElement('div');
                tempIconsContainerElement.innerHTML = icons;
                const iconsElement = tempIconsContainerElement.firstElementChild;
                iconsElement.id = iconsElementId;
                document.body.insertAdjacentElement('beforeend', iconsElement);
            };
            const insertIconsElementOnLoaded = (iconsElementId: string, icons: string) => {
                if (document.body !== null) {
                    insertIconsElement(iconsElementId, icons);
                }
                else {
                    document.addEventListener('DOMContentLoaded', () => insertIconsElement(iconsElementId, icons));
                }
            };

            try {
                fetch('assets/sprites/icons.svg').then(response => {
                    response.text().then(icons => {
                        insertIconsElementOnLoaded('icons-sprite', icons);
                        resolve();
                    }).catch();

                }).catch();
            }
            finally {
                resolve();
            }
        });
    }

    public initializeGoogleAnalytics(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (environment.production) {
                const googleAnalyticsMeasurementId = 'G-N4LY6GTM8Y'
                const googleAnalyticsLink = document.createElement('script');
                googleAnalyticsLink.async = true;
                googleAnalyticsLink.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsMeasurementId}`;

                const googleAnalyticsInitializerScript = document.createElement('script');
                googleAnalyticsInitializerScript.type = 'text/javascript';
                googleAnalyticsInitializerScript.innerHTML =
                    `window.dataLayer = window.dataLayer || [];
                        function gtag(){window.dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${googleAnalyticsMeasurementId}');`;

                document.head.appendChild(googleAnalyticsLink);
                document.head.appendChild(googleAnalyticsInitializerScript);

                this.googleAnalyticsStoreService.setCustomerAttributes();
            }

            resolve();
        });
    }

    public initializeNewRelic(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (environment.production) {
                const newRelicInitializerScript = document.createElement('script');
                newRelicInitializerScript.type = 'text/javascript';
                newRelicInitializerScript.src = './new-relic/new-relic.js';
                newRelicInitializerScript.crossOrigin = 'use-credentials';
                document.head.appendChild(newRelicInitializerScript);
            }

            resolve();
        });
    }

    public initializeDeviceInfo(): Promise<void> {
        // eslint-disable-next-line complexity
        return new Promise<void>((resolve) => {

            if (this.platform.ANDROID || this.platform.IOS) {
                this.document.body.classList.add('is-mobile');
            }

            const deviceInfo: string[] = [];

            if (this.platform.ANDROID) {
                deviceInfo.push('android');
            }
            if (this.platform.BLINK) {
                deviceInfo.push('blink');
            }
            if (this.platform.EDGE) {
                deviceInfo.push('edge');
            }
            if (this.platform.FIREFOX) {
                deviceInfo.push('firefox');
            }
            if (this.platform.IOS) {
                deviceInfo.push('ios');
            }
            if (this.platform.SAFARI) {
                deviceInfo.push('safari');
            }
            if (this.platform.TRIDENT) {
                deviceInfo.push('trident');
            }
            if (this.platform.WEBKIT) {
                deviceInfo.push('webkit');
            }

            this.document.documentElement.setAttribute('data-device', deviceInfo.join());

            resolve();
        });
    }

    private initializeManifest(configuration: CompanyConfiguration): void {
        const newManifestLink = document.createElement('link');
        newManifestLink.id = 'manifest-link';
        newManifestLink.href = './manifest/manifest-' + configuration.id + '.json';
        newManifestLink.rel = 'manifest';
        newManifestLink.crossOrigin = 'use-credentials';

        const existingManifestLink = document.getElementById('manifest-link');
        if (existingManifestLink != null) {
            const manifestLinkParent = existingManifestLink.parentNode;
            manifestLinkParent.removeChild(existingManifestLink);
        }
        document.head.appendChild(newManifestLink);
    }

    private initializeFavicon(configuration: CompanyConfiguration): void {
        const faviconLink = document.createElement('link');
        faviconLink.id = 'favicon-link';
        faviconLink.href = './assets/images/company/' + configuration.id + '/favicon.png';
        faviconLink.rel = 'icon';
        faviconLink.type = 'image/x-icon';

        const existingFaviconLink = document.getElementById('favicon-link');
        if (existingFaviconLink != null) {
            const faviconLinkParent = existingFaviconLink.parentNode;
            faviconLinkParent.removeChild(existingFaviconLink);
        }
        document.head.appendChild(faviconLink);
    }

    private initializeAppleTouchIcon(configuration: CompanyConfiguration): void {
        const appleTouchLink = document.createElement('link');
        appleTouchLink.id = 'apple-touch-link';
        appleTouchLink.href = './assets/images/company/' + configuration.id + '/logo-mobile-128x128.png';
        appleTouchLink.rel = 'apple-touch-icon';

        const existingAppleTouchLink = document.getElementById('apple-touch-link');
        if (existingAppleTouchLink != null) {
            const appleTouchLinkParent = existingAppleTouchLink.parentNode;
            appleTouchLinkParent.removeChild(existingAppleTouchLink);
        }
        document.head.appendChild(appleTouchLink);
    }
}