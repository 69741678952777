import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export const getLessThanTimeValidator = (lessThanControlName: string): ((control: AbstractControl) => ValidationErrors | null) => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        if (currentControl == null || currentControl.parent == null) {
            return null;
        }

        const lessThanControl = currentControl.parent.get(lessThanControlName);
        if (lessThanControl == null) {
            return null;
        }

        if (currentControl.value == null || lessThanControl.value == null) {
            return null;
        }

        if (moment(currentControl.value).isBefore(lessThanControl.value, 'minute')) {
            return null;
        }

        return { lessThanTime: true };
    };
};
