import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Input, OnChanges, OnDestroy, ViewContainerRef } from '@angular/core';

import { RpcIconComponent } from '@core-utils/rpc-icon/rpc-icon.component';

@Directive({ selector: '[hideToggle]' })
export class HideToggleDirective implements AfterViewInit, OnChanges, OnDestroy {

    @Input() iconClass = 'toggler-icon';
    @Input() openedIcon = 'arrow-up';
    @Input() closedIcon = 'arrow-down';
    @Input() collapsedByDefault = true;
    @Input() reverse = false;
    @Input('targetBlock') private readonly targetBlock: HTMLElement;

    private readonly clickHandler: () => void;
    private toggleIconRef: ComponentRef<RpcIconComponent>;
    private isCollapsed: boolean;
    private viewWasInit = false;

    constructor(
        private readonly element: ElementRef<HTMLElement>,
        private readonly target: ViewContainerRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly changeDetactorRef: ChangeDetectorRef
    ) {
        this.clickHandler = this.click.bind(this) as () => void;
    }

    public ngOnChanges(): void {
        if (this.viewWasInit && this.toggleIconRef != null && this.isCollapsed !== this.collapsedByDefault) {
            const iconElement: HTMLElement = this.toggleIconRef.location.nativeElement as HTMLElement;
            iconElement.classList.remove(this.iconClass);
            this.element.nativeElement.removeChild(this.toggleIconRef.location.nativeElement);
            this.isCollapsed = this.collapsedByDefault;
            this.targetBlock.hidden = this.isCollapsed;
            this.createIcon();
        }
    }

    public ngAfterViewInit(): void {
        this.isCollapsed = this.collapsedByDefault;
        this.targetBlock.hidden = this.isCollapsed;

        this.createIcon();

        this.element.nativeElement.addEventListener('click', this.clickHandler, false);

        this.changeDetactorRef.markForCheck();
        this.changeDetactorRef.detectChanges();
        this.viewWasInit = true;
    }

    public ngOnDestroy(): void {
        this.element.nativeElement.removeEventListener('click', this.clickHandler, false);
    }

    private createIcon(): void {
        const factory = this.componentFactoryResolver.resolveComponentFactory(RpcIconComponent);
        this.toggleIconRef = this.target.createComponent(factory);
        this.toggleIconRef.instance.iconName = this.isCollapsed
            ? this.reverse ? this.openedIcon : this.closedIcon
            : this.reverse ? this.closedIcon : this.openedIcon;
        const iconElement: HTMLElement = this.toggleIconRef.location.nativeElement as HTMLElement;
        iconElement.classList.add(this.iconClass);
        this.element.nativeElement.appendChild(this.toggleIconRef.location.nativeElement);
    }

    private click(): void {
        this.isCollapsed = !this.isCollapsed;
        this.toggleIconRef.instance.iconName = this.isCollapsed
            ? this.reverse ? this.openedIcon : this.closedIcon
            : this.reverse ? this.closedIcon : this.openedIcon;
        this.toggleIconRef.instance.changeDetactorRef.markForCheck();
        this.toggleIconRef.instance.changeDetactorRef.detectChanges();
        this.targetBlock.hidden = this.isCollapsed;
    }

}