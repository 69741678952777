import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { filter, Observable, of, switchMap, take } from 'rxjs';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { INITIAL_DEFAULT_FOLDER_ID } from '@folders/constants/folder.constants';
import { FoldersStoreWriteService } from '@folders/store/services/folders-store-write.service';
import { GalleryViewTypes } from '@gallery-view/enums/gallery-view-types';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';

@Injectable({ providedIn: 'root' })
export class MyListingsGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly routeService: RouteService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly foldersStoreWriteService: FoldersStoreWriteService,
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(childRoute, state);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(route, state);
    }

    private canActivateRoute(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> {
        const params = route.queryParams;
        const state = this.routeService.getNavigationState<{ processDefaultFolder: boolean | null }>();

        if (params?.tab != null) {
            this.handleObsoleteRoutes(params.tab);
            return of(false);
        } else if (route.firstChild?.routeConfig?.path !== '') {
            return of(true);
        }

        return state?.processDefaultFolder === false ? of(true) : this.handlePortfolioRoute();
    }

    private handleObsoleteRoutes(tab: string): void {
        const routeToNavigate = tab === GalleryViewTypes.ExternalListings
            ? RpcRoute.ExrernalListingsFullPath
            : RpcRoute.Portfolio;

        this.routeService.navigate(routeToNavigate).catch(()=>{});
    }

    private handlePortfolioRoute(): Observable<boolean> {
        return this.settingsStoreService.defaultFolderId$.pipe(
            filter(defaultFolderId => defaultFolderId !== INITIAL_DEFAULT_FOLDER_ID),
            take(1),
            switchMap(defaultFolderId => {
                if (defaultFolderId != null) {
                    this.foldersStoreWriteService.navigateToFolder(defaultFolderId);
                    return of(false);
                }

                return of(true);
            })
        );
    }
}