import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { CommonListing } from '@listings/models/listing/common-listing';
import { ListingInfo } from '@listings/models/listing/listing-info';
import { Listings } from '@listings/models/listing/listings';
import { ListingsMedia } from '@listings/models/listing/listings-media';
import { ListingHelperService } from '@listings/services/listing-helper.service';
import { ListingsState } from '@listings/store/states/listings.state';
import { FormattedMedia } from '@media/models/formatted-media';
import * as settingsSelector from '@settings/store/selectors/settings.selector';
import { selectRouteParams } from '@core-layout/app/store/selectors/router.selectors';

const listingsStateSelector = createFeatureSelector<ListingsState>('listings');

export const selectListings = createSelector(
    listingsStateSelector,
    listingState => listingState == null || listingState.listings == null ? new Listings() : listingState.listings
);

export const selectAllListingsMedia = createSelector(
    listingsStateSelector,
    listingState => listingState == null || listingState.media == null ? new ListingsMedia() : listingState.media
);

export const selectIsListingsLoading = createSelector(listingsStateSelector, listingState => listingState?.isListingsLoading);
export const selectIsListingLoading = createSelector(listingsStateSelector, listingState => listingState?.isListingLoading);

export const selectCustomerListings = createSelector(
    selectListings,
    listings => {
        return Object.values(listings)
            .filter((commonListing: CommonListing) => typeof commonListing !== 'string' && (commonListing.isNewMatch || commonListing.activities?.length > 0))
            .map((listing): CommonListing => ({ ...listing, customerAssignedDate: new Date(listing.customerAssignedDate) }));
    });

export const selectListingsMedia = (listingIds: string[]) => createSelector(
    selectAllListingsMedia,
    media => listingIds.map(listingId => {
        const listingMedia = media[listingId];

        if (listingMedia == null) {
            return FormattedMedia.notLoaded;
        }

        return listingMedia;
    })
);

export const selectListingMedia = (listingId: string) => createSelector(
    selectListingsMedia([listingId]),
    media => media[0]
);

export const selectIsMediaLoadedForListing = (listingId: string) => createSelector(
    selectListingMedia(listingId),
    ({ originalImages, videos, tours }) => [originalImages, videos, tours].some(x => x == null || x.length > 0)
);

export const selectMarketListings = createSelector(
    selectListings,
    commonListings => Object.values(commonListings).filter(commonListing => typeof commonListing !== 'string' && commonListing.isMarketListing)
);

export const selectListing = (listingId: string) => createSelector(
    listingsStateSelector,
    listingState => listingState == null || listingState.listings == null
        ? null
        : listingState.listings[listingId]
);

export const selectSelectedListingIds = createSelector(
    listingsStateSelector,
    listingState => listingState == null || listingState.selectedListingIds == null ? [] : listingState.selectedListingIds
);

export const selectSelectedListings = createSelector(
    listingsStateSelector,
    listingState => listingState == null || listingState.selectedListingIds == null || listingState.listings == null
        ? []
        : Object.values(listingState.listings).filter(listing => listingState.selectedListingIds.includes(listing.id))
);

export const selectCustomerListingsLodaded = createSelector(
    listingsStateSelector,
    listingState => listingState == null ? false : listingState.customerListingsLoaded
);

export const selectMarketListingsLoading = createSelector(
    listingsStateSelector,
    listingState => listingState == null ? false : listingState.marketListingsLoading
);

export const hasAnySelectedNewMatchListing = createSelector(
    selectSelectedListings,
    listings => Object.values(listings).some(listing => listing.isNewMatch)
);

export const selectSelectedListingsInfo = createSelector(
    selectListings,
    selectAllListingsMedia,
    selectSelectedListingIds,
    settingsSelector.selectPermissionSettings,
    (listings, listingsMedia, selectedListingsIds, permissionSettings) => selectedListingsIds.filter(listingId => listings[listingId] != null).map(
        listingId => new ListingInfo(listings[listingId], listingsMedia[listingId], permissionSettings.hideOpenRentals))
);

export const selectPortfolioSelectedListingsInfo = createSelector(
    selectListings,
    selectSelectedListingsInfo,
    (listings, selectedListingsInfo) => selectedListingsInfo.filter(listing =>
        !listing.isMarketListing && (listings[listing.listingId].activities?.length > 0 || listings[listing.listingId].isNewMatch))
);

export const hasAnySelectedPortfolioListings = createSelector(
    selectPortfolioSelectedListingsInfo,
    (listings) => listings.length !== 0
);

export const hasNotRemovedSelectedPortfolioListings = createSelector(
    selectPortfolioSelectedListingsInfo,
    (listings) => listings.filter(listing => !listing.isDeleted).length !== 0
);

export const selectListingFolderManagerInfoById = (listingId: string) => createSelector(
    selectListing(listingId),
    selectListingMedia(listingId),
    settingsSelector.selectPermissionSettings,
    (listing, listingMedia, permissionSettings) => {
        return listing != null ? new ListingInfo(listing, listingMedia, permissionSettings.hideOpenRentals) : null;
    }
);

export const selectIsAllSelectedListingsAreRemoved = createSelector(
    selectSelectedListingIds,
    selectListings,
    (selectedListingIds, listings) => {
        return Object.values(listings)
            .filter(({ id, isDeleted }) => isDeleted && selectedListingIds.includes(id)).length === selectedListingIds.length;
    }
);

export const selectIsAnySelectedListingRemoved = createSelector(
    selectSelectedListingIds,
    selectListings,
    (selectedListingIds, listings) => selectedListingIds.some(id => listings[id].isDeleted)
);

export const selectNewMatchListingsBySavedSearchId = (savedSearchId: number) => createSelector(
    selectCustomerListings,
    listings => listings.filter(listing => listing.isNewMatch && listing.newMatches.some(x => x.savedSearchId === savedSearchId))
);

export const selectSelectedMarketListings = createSelector(
    selectMarketListings,
    selectSelectedListingIds,
    (listings, selectedListingIds) => listings.filter(listing => selectedListingIds.includes(listing.id) && !listing.isDeleted)
);

export const selectSelectedMarketListingsAvailableForActivity = (activityId: number) => createSelector(
    selectSelectedMarketListings,
    listings => listings.filter(listing => activityId !== ListingActivityConstants.PickListed.id || listing.activities.length === 0)
);

export const selectSelectedListingsWithoutOpenRentals = createSelector(
    selectListings,
    selectSelectedListingIds,
    settingsSelector.selectPermissionSettings,
    (listings, selectedListingsIds, permissionSettings) => {
        if (listings == null || selectedListingsIds == null || permissionSettings == null) {
            return [];
        }

        return Object.values(listings).filter(listing => permissionSettings.hideOpenRentals
            ? !ListingHelperService.isOpenRental(listing) && selectedListingsIds.includes(listing.id)
            : selectedListingsIds.includes(listing.id));
    }
);

export const selectSelectedListingIdsWithoutOpenRentals = createSelector(
    selectSelectedListingsWithoutOpenRentals,
    listings => listings.map(listing => listing.id)
);

export const selectActiveListing = createSelector(
    selectRouteParams,
    selectListings,
    (routeParams, listings) => routeParams?.id != null && listings[routeParams.id] != null ? listings[routeParams.id] : null
);

export const selectIsActiveListingIsInPortfolio = createSelector(
    selectActiveListing,
    activeListing => activeListing?.activities?.length > 0 || activeListing?.isNewMatch
);

export const selectPortfolioListingsCountWithoutRemoved = createSelector(
    selectCustomerListings,
    listings => listings.reduce((sum, { isDeleted }) => !isDeleted ? sum + 1 : sum, 0)
);

export const selectUnviewedListingsCount = createSelector(
    selectCustomerListings,
    listings => listings.reduce((sum, { isNewViewed }) => !isNewViewed ? sum + 1 : sum, 0)
);