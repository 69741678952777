import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { emailWithDotValidator } from '@core-validation/validators/email-with-dot.validator';
import { getExternalDependencyValidator } from '@core-validation/validators/external-dependency.validator';
import { getLessThanTimeValidator } from '@core-validation/validators/less-time.validator';
import { getMatchValidator, getNotMatchValidator } from '@core-validation/validators/match.validator';
import { getMoreThanTimeValidator } from '@core-validation/validators/more-time.validator';
import { getPatternValidator } from '@core-validation/validators/pattern.validator';
import { uniqueByValidatior } from '@core-validation/validators/unique-by.validator';
import { fileTypeValidator } from './validators/file-type.validator';
import { maxFileSizeValidator } from './validators/max-file-size.validator';
import { uniqueValuesValidatior } from './validators/unique-values.validator';
import { urlValidator } from './validators/url.validator';
import { whitespacesValidator } from './validators/whitespaces.validator';

@Injectable({ providedIn: 'root' })
export class ValidationService {
    private readonly MinimumPasswordLength = 6;
    private readonly MaximumPasswordLength = 16;

    public getMatchValidator = getMatchValidator;

    public getNotMatchValidator = getNotMatchValidator;

    public getExternalDependencyValidator = getExternalDependencyValidator;

    public getPatternValidator = getPatternValidator;

    public getMoreThanTimeValidator = getMoreThanTimeValidator;

    public getLessThanTimeValidator = getLessThanTimeValidator;

    public uniqueByValidatior = uniqueByValidatior;

    public emailWithDotValidator = emailWithDotValidator;

    public fileTypeValidator = fileTypeValidator;

    public urlValidator = urlValidator;

    public maxFileSizeValidator = maxFileSizeValidator;

    public whitespacesValidator = whitespacesValidator;

    public uniqueValuesValidatior = uniqueValuesValidatior;

    public getPasswordValidator = (): ValidatorFn => Validators.compose([
        Validators.required,
        Validators.minLength(this.MinimumPasswordLength),
        Validators.maxLength(this.MaximumPasswordLength),
        getPatternValidator(/\d/, { noNumber: true }),
        getPatternValidator(/[A-Z]/, { noCapitalCase: true }),
        getPatternValidator(/[a-z]/, { noSmallCase: true }),
        getPatternValidator(/[\[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\]\{\}\;\'\:\"\|\,\.\<\>\/\?\\]/, { noSpecialCharacter: true })
    ])
}