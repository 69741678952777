import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ApplicationUpdateService } from '@notifications/services/application-update.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {

    private readonly unsubscribe$ = new Subject<void>();

    public version: string;
    public currentYear: number;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly applicationUpdateService: ApplicationUpdateService
    ) { }

    public ngOnInit(): void {
        this.applicationUpdateService
            .getCurrentVersion()
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
            .subscribe(formattedVersion => {
                this.version = formattedVersion;
                this.changeDetectorRef.detectChanges();
            });
        this.currentYear = new Date().getFullYear();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}