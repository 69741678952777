import { gql } from "app/graphql";


export const CREATE_NEW_LISTING_ACTIVITY_NOTIFICATION = gql(`
    mutation CreateNewListingActivityNotification($input: ListingActivityInput!){
        notifications {
            events {
                newListingActivity(input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_LISTING_ACTIVITY_CHANGED_NOTIFICATION = gql(`
    mutation CreateListingActivityChangedNotification($input: ListingActivityInput!){
        notifications {
            events {
                changedListingActivity(input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_LISTING_ACTIVITY_REMOVED_NOTIFICATION = gql(`
    mutation CreateListingActivityRemovedNotification($input: NotificationsGraphQL_RemoveListingActivityInput!) {
        notifications {
            events {
                removeListingActivity(input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_EXTERNAL_LISTING_ADDED_NOTIFICATION = gql(`
    mutation CreateExternalListingAddedNotification($input: ExternalListingEventInput!){
        notifications {
            events {
                externalListingAdded(input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_LISTING_SOFT_DELETED_NOTIFICATION = gql(`
    mutation CreateListingSoftDeletedNotification($input: ListingsEventInput!){
        notifications {
            events {
                listingSoftDeleted(input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_LISTING_HARD_DELETED_NOTIFICATION = gql(`
    mutation CreateListingHardDeletedNotification($input: ListingsEventInput!){
        notifications {
            events {
                listingHardDeleted(input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_LISTING_RESTORED_NOTIFICATION = gql(`
    mutation CreateListingRestoredNotification($input: ListingsEventInput!){
        notifications {
            events {
                listingRestored(input: $input) {
                    id
                }
            }
        }
    }
`);