
import { MAX_IMAGES_ON_FOLDER_COVER } from '@folders/constants/folder.constants';
import { FolderActivityPanelInfo } from '@folders/models/folder-activity-panel-info';
import { FolderCover } from '@folders/models/folder-cover';
import { FolderDetails } from '@folders/models/folder-details';
import { CommonListing } from '@listings/models/listing/common-listing';
import { ListingsMedia } from '@listings/models/listing/listings-media';
import { MediaConstants } from '@media/constants/media.constants';
import { Image } from '@media/models/image';

export class FolderCoverBuilder {
    public static getFolderCovers(folders: FolderDetails[], foldersActivityPanelInfoMap: Map<number, FolderActivityPanelInfo>): FolderCover[] {
        return folders.map((folder): FolderCover => ({
            ...folder,
            activityPanelInfo: foldersActivityPanelInfoMap.has(folder.id) ? foldersActivityPanelInfoMap.get(folder.id) : null
        }));
    }

    public static getFolderCoverImages(
        folderId: number,
        media: ListingsMedia,
        foldersListings: Map<number, CommonListing[]>,
        folderCoverMap: Map<number, Image[]>
    ): Map<number, Image[]> {
        if (Object.values(media).length === 0) {
            return folderCoverMap.set(folderId, []);
        }

        const listings = foldersListings.has(folderId) ? foldersListings.get(folderId) : [];
        const requiredImagesNumber = FolderCoverBuilder.getFolderCoverImagesCount(listings.length);

        const images: Image[] = [];

        for (const listing of listings) {
            if (images.length === requiredImagesNumber) {
                break;
            }

            if (media[listing.id]?.smallImages != null && media[listing.id].smallImages.length > 0) {
                images.push(media[listing.id].smallImages[0]);
            }
        }

        const folderCoverImages = images.length !== requiredImagesNumber
            ? images.concat(FolderCoverBuilder.getDefaultImages(requiredImagesNumber, images.length))
            : images;

        return folderCoverMap.set(folderId, folderCoverImages);
    }

    private static getFolderCoverImagesCount(listingsCount: number): number {
        return listingsCount < MAX_IMAGES_ON_FOLDER_COVER ? listingsCount : MAX_IMAGES_ON_FOLDER_COVER;
    }

    private static getDefaultImages(requiredImagesNumber: number, actualImagesNumber: number): Image[] {
        return Array.from(
            Array(requiredImagesNumber - actualImagesNumber),
            (_, index): Image => ({ ...MediaConstants.UnavailableImage, mediaId: MediaConstants.UnavailableImage.mediaId + index })
        );
    }
}