import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CompanyConfiguration } from '@app-config/models/company-configuration';
import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { Credential } from '@auth/models/credential';
import { serverMessagesMapping } from '@auth/pages/login/server-message-mapping/server-message-mapping';
import * as loginActions from '@auth/store/actions/login.actions';
import { AuthEffects } from '@auth/store/effects/auth.effects';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { fadeInScaleAnimation } from '@core-animations/element-animations';
import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ServerMessageService } from '@core-services/server-message.service';
import { ToastService } from '@core-services/toast.service';
import { ProgressBarService } from '@core-utils/progress-bar/progress-bar.service';
import { ApiError } from '@error/models/api-error';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['../../auth-base/auth-base.component.scss', './login.component.scss'],
    animations: [fadeInScaleAnimation]
})
export class LoginComponent implements OnInit, OnDestroy {

    private readonly unsubscribe$ = new Subject<void>();

    public readonly RpcRoute = RpcRoute;

    public loginForm: FormGroup;
    public userNameControlOptions: RpcInputOptions;
    public passwordControlOptions: RpcInputOptions;
    public companyConfiguration: CompanyConfiguration;

    constructor(
        private readonly configurationService: AppConfigurationService,
        private readonly authEffects: AuthEffects,
        private readonly routeService: RouteService,
        private readonly toaster: ToastService,
        private readonly progressBarService: ProgressBarService,
        private readonly serverMessageService: ServerMessageService,
        private readonly userStoreService: UserStoreService
    ) {
        configurationService.configuration = {
            layout: {
                secondaryToolbar: { hidden: true, component: null, layout: 'full', allowScrollLayoutChange: true },
                spinner: { hidden: true }
            }
        };

        this.serverMessageService.loadServerMessagesMapping(serverMessagesMapping);
    }

    public ngOnInit(): void {
        this.buildForm();

        this.authEffects.tryLogin$
            .pipe(ofType(loginActions.loginSuccessful), takeUntil(this.unsubscribe$))
            .subscribe(
                () => this.routeService.navigateToReturnUrl().finally(() => this.progressBarService.hide()),
                (error: ApiError) => this.showErrorMessage(error)
            );

        this.authEffects.tryLogin$
            .pipe(ofType(loginActions.loginWithinAgentsSelection), takeUntil(this.unsubscribe$))
            .subscribe(
                (loginResponse) => {
                    this.userStoreService.setLoginProcessData(loginResponse.credentials);
                    this.routeService.navigate(RpcRoute.AgentSelection, { queryParamsHandling: 'preserve' }).finally(() => this.progressBarService.hide());
                },
                (error: ApiError) => this.showErrorMessage(error)
            );

        this.authEffects.tryLogin$
            .pipe(ofType(loginActions.loginFailed), takeUntil(this.unsubscribe$))
            .subscribe((error: ApiError) => this.showErrorMessage(error));

        this.configurationService.configuration$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(configuration => this.companyConfiguration = configuration.company);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public login(): void {
        this.userStoreService.tryLogin(this.loginForm.getRawValue() as Credential);
        this.progressBarService.show();
    }

    private buildForm(): void {
        this.loginForm = new FormGroup({});

        this.userNameControlOptions = {
            formGroup: this.loginForm,
            controlName: 'userName',
            validatorsMap: [
                {
                    message: 'LOGIN.VALIDATION.USERNAME_REQUIRED_VALIDATION',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };

        this.passwordControlOptions = {
            formGroup: this.loginForm,
            controlName: 'password',
            validatorsMap: [
                {
                    message: 'LOGIN.VALIDATION.PASSWORD_REQUIRED_VALIDATION',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };
    }

    private showErrorMessage(error: ApiError): void {
        this.toaster.showServerError(error);
        this.progressBarService.hide();
    }
}