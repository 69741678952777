export class RestorePasswordRequest{
    password: string;
    confirmPassword: string;
    restoreCode: string;

    constructor(password: string, confirmPassword: string, code: string){
        this.password = password;
        this.confirmPassword = confirmPassword;
        this.restoreCode = code;
    }
}