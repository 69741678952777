import { selectUser } from '@auth/store/selectors/user.selector';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProfileState } from '@profile/store/states/profile.state';
import { ITopProfileInfo } from '../../../profile-base/interfaces/customer-info/top-profile-info';

const profileStateSelector = createFeatureSelector<ProfileState>('profile');

export const selectPhones = createSelector(profileStateSelector, state => state?.phones);

export const selectEmails = createSelector(profileStateSelector, state => state?.emails);

export const selectAddresses = createSelector(profileStateSelector, state => state?.addresses);

export const selectProfileError = createSelector(
    profileStateSelector,
    (state) => state?.error
);

export const selectSelectedAdditionalProfile = createSelector(profileStateSelector, (state) => state?.selectedAdditionalProfile);

export const selectIsAdditionalProfile = createSelector(profileStateSelector, _ => false);

export const selectTopProfileInfo = createSelector(
    selectUser,
    selectEmails,
    (user, emails) => {
        return {
            customerId: user?.customerId,
            firstName: user?.customerFirstName,
            lastName: user?.customerLastName,
            userName: user?.customerUserName,
            isAdditionalProfile: false,
            emails: emails,
        } as ITopProfileInfo;
    }
);
