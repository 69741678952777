import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import * as CountryService from 'country-state-picker';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { getPhoneFormatValidator } from '@core-controls/components/phone-form-input/validators/phone-number-format.validator';
import { PhoneInfo } from '@core-controls/models/phone-info';

import { CountryPhoneInfo } from './models/country-phone-info';

@Component({
    selector: 'phone-form-input',
    templateUrl: './phone-form-input.component.html',
    styleUrls: ['./phone-form-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneFormInputComponent implements OnInit, AfterViewInit, OnDestroy {
    private static readonly DefaultCountryCodeName = 'us';

    @Input() public phoneNumberInfo: PhoneInfo;
    @Input() public isPhoneRequired: boolean;
    @Input() public isFormatValidationRequired: boolean;
    @Input() appearance: MatFormFieldAppearance;
    @Output() public phoneNumberChanged = new EventEmitter<PhoneInfo>();

    @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

    public phoneForm: FormGroup;

    public filteredCountriesPhoneInfo: ReplaySubject<CountryPhoneInfo[]> = new ReplaySubject<CountryPhoneInfo[]>(1);
    public countriesPhoneInfo: CountryPhoneInfo[] = [];

    public searchPlaceHolder: string;
    public notFoundSearchPlaceHolder: string;

    private readonly phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    private readonly onDestroy = new Subject<void>();

    constructor(
        private readonly formBuilder: FormBuilder,
        translateService: TranslateService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        translateService.get('PHONE_FORM_INPUT.PLACEHOLDERS.SEARCH')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((translation: string) => this.searchPlaceHolder = translation);
        translateService.get('PHONE_FORM_INPUT.PLACEHOLDERS.NOT_FOUND')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((translation: string) => this.notFoundSearchPlaceHolder = translation);
    }

    public validate(): void {
        this.onBlur();
        this.changeDetectorRef.markForCheck();
    }

    public onBlur(): void {
        this.phoneForm.get('countryCode').markAsTouched();
        this.phoneForm.get('phoneNumber').markAsTouched();
    }

    public ngOnInit(): void {
        this.countriesPhoneInfo = this.getSupportedCountriesPhoneInfo();
        this.filteredCountriesPhoneInfo.next(this.countriesPhoneInfo.slice());

        this.configureForm();

        this.addSubscribtions();

        if (this.phoneNumberInfo != null && this.phoneNumberInfo.phoneNumber != null && this.phoneNumberInfo.phoneNumber !== '') {
            this.setInitialPhoneNumber(this.phoneNumberInfo);
        }
    }

    public ngAfterViewInit(): void {
        this.enableFiltering();
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    public showRequiredErrorMessage(): boolean {
        return this.phoneForm != null && this.phoneForm.touched && this.isPhoneRequired &&
            (this.phoneForm.get('countryCode').hasError('required') || this.phoneForm.get('phoneNumber').hasError('required'));
    }

    public showWrongFormatErrorMessage(): boolean {
        return this.phoneForm != null && this.phoneForm.touched && (this.phoneForm.get('countryCode').hasError('wrongFormat') || this.phoneForm.get('phoneNumber').hasError('wrongFormat'))
            && !this.phoneForm.get('countryCode').hasError('required') && !this.phoneForm.get('phoneNumber').hasError('required');
    }

    private configureForm(): void {
        const validators = [];
        if (this.isPhoneRequired) {
            validators.push(Validators.required);
        }
        if (this.isFormatValidationRequired) {
            validators.push(getPhoneFormatValidator(this.isPhoneRequired));
        }

        const defaultCountryCode = this.countriesPhoneInfo.find(countryPhoneInfo =>
            countryPhoneInfo.codeName.toLowerCase() === PhoneFormInputComponent.DefaultCountryCodeName);
        this.phoneForm = this.formBuilder.group({
            countryCode: [defaultCountryCode, validators],
            countryCodeFilter: [''],
            phoneNumber: ['', validators]
        });
    }

    private addSubscribtions(): void {
        this.phoneForm.get('countryCode')
            .valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => this.updatePhoneNumber());

        this.phoneForm.get('countryCodeFilter')
            .valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe((searchTeem: string) => this.filterCountriesPhoneInfo(searchTeem));

        this.phoneForm.get('phoneNumber')
            .valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => this.updatePhoneNumber());
    }

    private getSupportedCountriesPhoneInfo(): CountryPhoneInfo[] {
        const supportedCountriesPhoneInfo: CountryPhoneInfo[] = [];
        const supportedRegions: string[] = this.phoneNumberUtil.getSupportedRegions();
        const supportedRegionsData = CountryService.getFilteredCountries(supportedRegions) as { name: string; code: string }[];

        supportedRegionsData.forEach(regionData => {
            const exampleNumber = this.phoneNumberUtil.getExampleNumber(regionData.code);
            const phonePlaceholder = this.phoneNumberUtil.format(exampleNumber, PhoneNumberFormat.INTERNATIONAL);
            supportedCountriesPhoneInfo.push({
                name: regionData.name,
                codeName: regionData.code,
                dialCode: '+' + this.phoneNumberUtil.getCountryCodeForRegion(regionData.code),
                examplePhoneNumber: phonePlaceholder.substring(phonePlaceholder.indexOf(' ') + 1)
            });
        });

        return supportedCountriesPhoneInfo
            .sort((firstItem: CountryPhoneInfo, secondItem: CountryPhoneInfo) => firstItem.name.localeCompare(secondItem.name));
    }

    private enableFiltering(): void {
        this.filteredCountriesPhoneInfo
            .pipe(take(1), takeUntil(this.onDestroy))
            .subscribe(() => {
                this.singleSelect.compareWith = (firstCountryPhoneInfo: CountryPhoneInfo, secondCountryPhoneInfo: CountryPhoneInfo) =>
                    firstCountryPhoneInfo != null && secondCountryPhoneInfo != null && firstCountryPhoneInfo.name === secondCountryPhoneInfo.name;
            });
    }

    private filterCountriesPhoneInfo(searchTerm: string): void {
        if (this.countriesPhoneInfo == null) {
            return;
        }

        if (searchTerm == null || searchTerm === '') {
            this.filteredCountriesPhoneInfo.next(this.countriesPhoneInfo.slice());
            return;
        }

        searchTerm = searchTerm.toLowerCase();
        this.filteredCountriesPhoneInfo.next(
            this.countriesPhoneInfo.filter(country => (country.dialCode + country.name).toLowerCase().indexOf(searchTerm) > -1)
        );
    }

    private setInitialPhoneNumber(initialPhoneNumberInfo: PhoneInfo): void {
        if (initialPhoneNumberInfo.phoneNumber == null || initialPhoneNumberInfo.phoneNumber === '') {
            this.phoneForm.get('countryCode').setValue(
                this.countriesPhoneInfo.find(countryPhoneInfo =>
                    countryPhoneInfo.codeName.toLowerCase() === PhoneFormInputComponent.DefaultCountryCodeName));
            return;
        }

        if (initialPhoneNumberInfo.countryCode != null && initialPhoneNumberInfo.countryCode !== '') {
            const storedCountryPhoneInfo = this.countriesPhoneInfo.find(
                countryPhoneInfo => countryPhoneInfo.codeName === initialPhoneNumberInfo.countryCode);

            if (storedCountryPhoneInfo != null) {
                this.phoneForm.get('countryCode').setValue(storedCountryPhoneInfo);
                this.phoneForm.get('phoneNumber').setValue(
                    initialPhoneNumberInfo.phoneNumber.replace(storedCountryPhoneInfo.dialCode, '').replace(/\D/g, ''));
            }
        } else {
            let foundPhoneNumber = '';
            const possibleCountryCode = initialPhoneNumberInfo.phoneNumber.substring(0, initialPhoneNumberInfo.phoneNumber.indexOf(' '));
            if (possibleCountryCode !== '') {
                foundPhoneNumber = this.countriesPhoneInfo.some(countryPhoneInfo => countryPhoneInfo.dialCode === possibleCountryCode)
                    ? initialPhoneNumberInfo.phoneNumber.substring(initialPhoneNumberInfo.phoneNumber.indexOf(' ') + 1).replace(/\D/g, '')
                    : initialPhoneNumberInfo.phoneNumber.replace(/\D/g, '');
            } else {
                foundPhoneNumber = initialPhoneNumberInfo.phoneNumber.replace(/\D/g, '');
            }

            this.phoneForm.get('countryCode').setValue(
                this.countriesPhoneInfo.find(countryPhoneInfo =>
                    countryPhoneInfo.codeName.toLowerCase() === PhoneFormInputComponent.DefaultCountryCodeName));
            this.phoneForm.get('phoneNumber').setValue(foundPhoneNumber);
        }
    }

    private updatePhoneNumber(): void {
        let internationalPhoneNumber = '';

        const selectedCountryPhoneInfo: CountryPhoneInfo = this.phoneForm.get('countryCode').value as CountryPhoneInfo;
        const selectedPhoneNumber: string = this.phoneForm.get('phoneNumber').value as string;

        if (!/^(\d|\-|\(|\)|\s)+$/.test(selectedPhoneNumber) && selectedPhoneNumber !== '') {
            const phoneNumberWithoDigitsOnly = selectedPhoneNumber.replace(/\D/g, '');
            this.phoneForm.get('phoneNumber').setValue(phoneNumberWithoDigitsOnly);
            return;
        }

        if (selectedCountryPhoneInfo != null && selectedPhoneNumber != null && selectedPhoneNumber !== '') {
            try {
                const parsedPhoneNumber: PhoneNumber = this.phoneNumberUtil.parse(selectedCountryPhoneInfo.dialCode + selectedPhoneNumber);
                internationalPhoneNumber = this.phoneNumberUtil.format(parsedPhoneNumber, PhoneNumberFormat.INTERNATIONAL);
                const nationalPhoneNumber: string = internationalPhoneNumber.substring(internationalPhoneNumber.indexOf(' ') + 1);

                if (nationalPhoneNumber !== selectedPhoneNumber) {
                    this.phoneForm.get('phoneNumber').setValue(nationalPhoneNumber);
                    return;
                }

            } catch (error) {
                this.onPhoneChanged(internationalPhoneNumber, selectedCountryPhoneInfo.codeName, this.isPhoneNumberValid());
                return;
            }
        }
        this.onBlur();
        this.onPhoneChanged(
            internationalPhoneNumber,
            selectedCountryPhoneInfo == null ? null : selectedCountryPhoneInfo.codeName,
            this.isPhoneNumberValid());
    }

    private isPhoneNumberValid(): boolean {
        if (!this.isPhoneRequired && !this.isFormatValidationRequired) {
            return true;
        }

        if (this.isPhoneRequired && !this.isFormatValidationRequired) {
            return !this.phoneForm.get('countryCode').hasError('required') && !this.phoneForm.get('phoneNumber').hasError('required');
        }

        if (!this.isPhoneRequired && this.isFormatValidationRequired) {
            return !this.phoneForm.get('phoneNumber').hasError('wrongFormat');
        }

        return !this.phoneForm.get('countryCode').hasError('required')
            && !this.phoneForm.get('phoneNumber').hasError('required')
            && !this.phoneForm.get('phoneNumber').hasError('wrongFormat');
    }

    private onPhoneChanged(phoneNumber: string, countryCode: string, isValid: boolean): void {
        this.phoneNumberChanged.emit({
            phoneNumber: phoneNumber,
            countryCode: countryCode,
            isValid: isValid,
            phoneId: this.phoneNumberInfo != null && this.phoneNumberInfo.phoneId != null ? this.phoneNumberInfo.phoneId : 0
        });
    }
}