export const locale = {
    lang: 'en',
    data: {
        TOAST: {
            TITLE: {
                REVIEW: 'Let\'s review it',
                CLOSE: 'Close'
            }
        }
    }
};