import { Injectable } from "@angular/core";
import { tap } from "rxjs";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";

import { UserStoreService } from "@auth/store/services/user-store.service";
import * as notificationsActions from "../actions/notifications.actions";
import { NotificationEventAction } from "@notifications/enums/notification-event-action";
import { UsersStoreService } from "@users/store/services/users-store.service";
import { AppointmentStatus } from "@appointments/enums/appointment-status.enum";
import { PUSH_NOTIFICATION_ACTIONS, PushNotificationActions } from "@notifications/constants/notifications.constants";
import { ListingCommunicationsService } from "app/modules/listing-modules/listing-communications/listing-communications-base/services/listing-communications.service";

@Injectable()
export class PushNotificationsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly userStoreService: UserStoreService,
        private readonly usersStoreService: UsersStoreService,
        private readonly listingCommunicationsService: ListingCommunicationsService,
    ) { }

    public readonly showPushNotification$ = createEffect(
        () => this.actions$.pipe(
            ofType(notificationsActions.showPushNotification),
            concatLatestFrom(() => [this.userStoreService.getUser(), this.usersStoreService.customersAndAgents$, this.usersStoreService.customers$]),
            tap(([{ notification }, user, customersAndAgents, customers]) => {
                if (!PUSH_NOTIFICATION_ACTIONS.includes(notification.action as PushNotificationActions)) {
                    return;
                }

                const showAppointmentToast = (status: AppointmentStatus) => {
                    this.listingCommunicationsService.showAppointmentToast(notification, user, customersAndAgents, customers, status);
                };

                const showMessageToast = () => {
                    this.listingCommunicationsService.showCustomerCollaborationToast(notification.entityType, { key: 'NOTIFICATIONS_COMMON.ALERTS.NEW_COMMENTS', params: null });
                };

                const actionsMap = new Map<PushNotificationActions, () => void>([
                    [NotificationEventAction.AppointmentCreated, () => showAppointmentToast(AppointmentStatus.Pending)],
                    [NotificationEventAction.AppointmentConfirmed, () => showAppointmentToast(AppointmentStatus.Confirmed)],
                    [NotificationEventAction.AppointmentDeclined, () => showAppointmentToast(AppointmentStatus.Declined)],
                    [NotificationEventAction.CommentCreated, () => showMessageToast()],
                ]);

                actionsMap.get(notification.action as PushNotificationActions)();
            })
        ),
        { dispatch: false }
    );
}