import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import isEqual from 'lodash-es/isEqual';
import { map, share, switchMap } from 'rxjs/operators';

import { DEFAULT_FOLDER_SETTINGS } from '@settings/constants/settings.constants';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { SettingsHelper } from '@settings/settings.helper';
import * as settingsActions from '@settings/store/actions/settings.actions';
import { SettingsApiService } from '@settings/store/services/settings-api.service';
import { SettingsStoreService } from '../services/settings-store.service';

@Injectable()
export class SettingsEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly settingsApiService: SettingsApiService,
        private readonly settingsStoreService: SettingsStoreService,
    ) { }

    public loadSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(settingsActions.loadSettings),
            switchMap(() => this.settingsApiService.loadSettings()),
            share()
        )
    );

    public loadNewMatchSettings = createEffect(() =>
        this.actions$.pipe(
            ofType(settingsActions.loadNewMatchSettings),
            switchMap(() => this.settingsApiService.loadNewMatchSettings()),
            share()
        )
    );

    public updateSettings$ = createEffect(() => this.actions$.pipe(
        ofType(settingsActions.updateSettings),
        switchMap(({ newSettings, oldSettings }) => {
            const notDefaultFoldersSettings = newSettings.layoutSettings.foldersSettings.filter(({ folderId, ...folderSettings }) => {
                return !isEqual(folderSettings, DEFAULT_FOLDER_SETTINGS);
            });

            const settingsToUpdate = SettingsHelper.updateWithFoldersSettings(newSettings, notDefaultFoldersSettings);

            return this.settingsApiService.updateSettings(settingsToUpdate, oldSettings);
        }),
        share()
    ));

    public updateNewMatchSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(settingsActions.updateNewMatchSettings),
            switchMap(({ request }) => this.settingsApiService.updateNewMatchSettings(request)),
            share()
        )
    );

    public loadNeighborhoodsMapping$ = createEffect(() =>
        this.actions$.pipe(
            ofType(settingsActions.loadNeighborhoodsMapping),
            switchMap(() => this.settingsApiService.loadNeighborhoodsMapping()),
            share()
        )
    );

    public readonly changeSystemFoldersVisibility$ = createEffect(() => this.actions$.pipe(
        ofType(settingsActions.changeSystemFoldersVisibility),
        concatLatestFrom(() => [this.settingsStoreService.isShowSystemFolders$, this.settingsStoreService.getSettings()]),
        map(([, isShowSystemFolders, settings]) => {
            const newSettings: CustomerSettings = { ...settings, layoutSettings: { ...settings.layoutSettings, showSystemFolders: !isShowSystemFolders } };

            return settingsActions.updateSettings({ newSettings, oldSettings: settings });
        })
    ));
}