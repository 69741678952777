import { AbstractControl, ValidationErrors } from '@angular/forms';

export const getMatchValidator = (matchControlName: string): (control: AbstractControl) => ValidationErrors | null => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        if (currentControl == null || currentControl.parent == null) {
            return null;
        }

        const matchControl = currentControl.parent.get(matchControlName);

        if (matchControl == null) {
            return null;
        }

        if (currentControl.value === '') {
            return null;
        }

        if (matchControl.value === currentControl.value) {
            return null;
        }

        return { notMatching: true };
    };
};

export const getNotMatchValidator = (matchControlName: string): (control: AbstractControl) => ValidationErrors | null => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        if (currentControl == null || currentControl.parent == null) {
            return null;
        }

        const matchControl = currentControl.parent.get(matchControlName);

        if (matchControl == null) {
            return null;
        }

        if (currentControl.value === '') {
            return null;
        }

        if (matchControl.value !== currentControl.value) {
            return null;
        }

        return { matching: true };
    };
};