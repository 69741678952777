import { AppointmentActivityType } from '@customer-activity/enums/appointment-activity-type.enum';

export class AddAppointmentActivityRequest {
    constructor(
        public readonly listingId: string,
        public readonly listingCategory: string,
        public readonly appointmentId: number,
        public readonly activityType: AppointmentActivityType,
        public readonly savedSearchId?: number,
    ) { }
}