import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { PromptComponent } from '@rpc-pwa/components/promt-component/prompt.component';

@Injectable({ providedIn: 'root' })
export class PwaService {

    private promptEvent: any;
    private isFirstPrompt = true;

    constructor(
        private readonly bottomSheet: MatBottomSheet,
        private readonly platform: Platform,
        private readonly userStoreService: UserStoreService
    ) { }

    public initializePwaPrompt(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.platform.IOS && this.platform.SAFARI) {
                const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']) as boolean;
                if (!isInStandaloneMode) {
                    this.openPromptComponent('ios');
                }
            } else {
                window.addEventListener('beforeinstallprompt', (event: any) => {
                    event.preventDefault();
                    this.promptEvent = event;
                    if (this.platform.ANDROID) {
                        this.openPromptComponent('android');
                    }
                });
            }
            resolve();
        });
    }

    public isPromptAvailable(): boolean {
        return this.promptEvent != null;
    }

    public showPrompt(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.promptEvent.prompt();
            this.promptEvent.userChoice
                .then((choiceResult) => resolve(choiceResult.outcome === 'accepted'));
        });
    }

    private openPromptComponent(mobileType: 'ios' | 'android'): void {
        this.userStoreService.getIsAuthorized()
            .pipe(take(1))
            .subscribe(isUserAuthorized => {
                if (isUserAuthorized) {
                    timer(2500)
                        .pipe(take(1))
                        .subscribe(() => {
                            if (this.isFirstPrompt) {
                                this.bottomSheet.open(PromptComponent, { data: { mobileType, promptEvent: this.promptEvent } });
                                this.isFirstPrompt = false;
                            }
                        });
                }
            });
    }
}