import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { FolderUrlName } from '@folders/enums/folder-url-name';
import { ListingInfo } from '@listings/models/listing/listing-info';
import { RemoveListingData } from '@listings/models/listing/remove-listing-data';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { ImportantDialogService } from '@core-utils/important-dialog/services/important-dialog.service';
import { REMOVE_NEW_MATCHES_WITOUT_ACTIVITY_DIALOG_CONFIG } from '@listings/constants/listing-dialog-config.constants';

@Component({
    templateUrl: './remove-listing-dialog.component.html',
    styleUrls: ['./remove-listing-dialog.component.scss'],
})
export class RemoveListingDialogComponent implements OnInit {

    public listings$: Observable<ListingInfo[]>;
    public removedListingsCount$: Observable<number>;

    constructor(
        private readonly dialogRef: MatDialogRef<RemoveListingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RemoveListingData,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly routeService: RouteService,
        private readonly importantDialogService: ImportantDialogService,
    ) { }

    public ngOnInit(): void {
        this.listings$ = this.data?.listingId != null
            ? this.listingsStoreService.getListingInfoById(this.data.listingId).pipe(map(listing => [listing]))
            : this.listingsStoreService.selectedPortfolioListingsInfo$;

        this.removedListingsCount$ = this.listings$.pipe(map(listings => listings.filter(({ isDeleted }) => isDeleted).length));
    }

    public onUnselectListingClicked(listingId: string): void {
        this.listingsStoreService.unselectListing(listingId);
    }

    public removeListings(listings: ListingInfo[]): void {
        const newMatchListingsWithoutActivities = listings.filter(x => x.isNewMatch && !x.hasActivity);

        if (newMatchListingsWithoutActivities.length === 0) {
            this.closeDialogWithData(listings);
            return;
        }

        this.importantDialogService.show(
            REMOVE_NEW_MATCHES_WITOUT_ACTIVITY_DIALOG_CONFIG,
            (successful) => {
                if (successful) {
                    this.closeDialogWithData(listings);
                }
            }
        );
    }

    public close(): void {
        this.dialogRef.close();
    }

    public onNavigateToFolderClicked(): void {
        this.close();

        this.routeService.navigate(RpcRoute.Portfolio, { state: { folder: FolderUrlName.RemovedListings } }).catch(() => { });
    }

    private closeDialogWithData(listings: ListingInfo[]): void {
        const listingIdsToRemove = this.data?.isHardDelete
            ? listings.map(listing => listing.listingId)
            : listings.reduce(
                (result, { isDeleted, listingId }) => isDeleted ? result : result.concat(listingId),
                new Array<string>()
            );

        this.dialogRef.close(listingIdsToRemove);
    }
}
