import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { SimpleDialogService } from '@core-utils/simple-dialog/services/simple-dialog.service';

@Component({
    selector: 'avatar-initials',
    templateUrl: './avatar-initials.component.html',
    styleUrls: ['./avatar-initials.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarInitialsComponent implements OnChanges {

    @Input() public userFullName: string;
    @Input() public userProfileImageUrl: string;
    @Input() public showHoverIcon = false;
    @Output() public removeImage = new EventEmitter<void>();

    public initials: string;
    public imageSrc: SafeUrl;
    public catchedError = false;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly simpleDialogService: SimpleDialogService
    ) { }

    public ngOnChanges(changes: SimpleChanges<AvatarInitialsComponent>): void {
        this.catchedError = false;
        if (changes == null) {
            return;
        }

        if (changes.userFullName != null && changes.userFullName.currentValue != null) {
            const names = changes.userFullName.currentValue.split(' ');
            this.initials = names[0][0] + names.at(-1)[0];
        }

        if (changes.userProfileImageUrl != null) {
            this.imageSrc = changes.userProfileImageUrl.currentValue != null && changes.userProfileImageUrl.currentValue !== ''
                ? this.getSantizeUrl(changes.userProfileImageUrl.currentValue)
                : null;
        }
    }

    public onError(): void {
        this.catchedError = true;
    }

    public remove($event: MouseEvent): void {
        $event.stopPropagation();
        this.simpleDialogService.show(
            { showTitle: true, showCancel: true, message: 'Delete Profile Image' },
            (successful) => {
                if (successful) {
                    this.removeImage.emit();
                }
            }
        );
    }

    private getSantizeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}