import { AbstractControl, ValidationErrors } from '@angular/forms';

export const whitespacesValidator = (currentControl: AbstractControl): ValidationErrors | null => {
    if (currentControl == null || currentControl.parent == null) {
        return null;
    }

    if (currentControl.value != null && currentControl.value.trim() === '') {
        return { whitespaces: true };
    }

    return null;
};