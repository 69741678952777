import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { CountryPhoneInfo } from '../models/country-phone-info';

export const getPhoneFormatValidator = (isRequired: boolean): (control: AbstractControl) => ValidationErrors | null => {
    // eslint-disable-next-line complexity
    return (currentControl: AbstractControl): ValidationErrors | null => {
        if (currentControl == null || currentControl.parent == null) {
            return null;
        }

        let internationalPhoneNumber = '';
        const countryCodeControl = currentControl.parent.get('countryCode');
        const phoneNumberControl = currentControl.parent.get('phoneNumber');
        const countryPhoneInfo = currentControl.parent.get('countryCode').value as CountryPhoneInfo;
        const phoneNumber = currentControl.parent.get('phoneNumber').value as string;

        if (countryPhoneInfo != null && phoneNumber != null && phoneNumber !== '') {
            try {
                const phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
                const parsedPhoneNumber: PhoneNumber = phoneNumberUtil.parse(countryPhoneInfo.dialCode + phoneNumber);
                internationalPhoneNumber = phoneNumberUtil.format(parsedPhoneNumber, PhoneNumberFormat.INTERNATIONAL);

                const isPhoneNumberFormatWrong = !phoneNumberUtil.isValidNumberForRegion(parsedPhoneNumber, countryPhoneInfo.codeName);
                if (!isPhoneNumberFormatWrong) {
                    countryCodeControl.setErrors(null);
                    phoneNumberControl.setErrors(null);
                    return null;
                }

                countryCodeControl.setErrors({ wrongFormat: isPhoneNumberFormatWrong });
                phoneNumberControl.setErrors({ wrongFormat: isPhoneNumberFormatWrong });
                return { wrongFormat: isPhoneNumberFormatWrong };

            } catch (error) {
                countryCodeControl.setErrors({ wrongFormat: true });
                phoneNumberControl.setErrors({ wrongFormat: true });
                return { wrongFormat: true };
            }
        }

        if (countryPhoneInfo != null && (phoneNumber == null || phoneNumber === '')) {
            const error = isRequired ? { wrongFormat: true } : null;

            countryCodeControl.setErrors(error);
            phoneNumberControl.setErrors(error);
            return error;
        }

        if (countryPhoneInfo == null && phoneNumber != null && phoneNumber !== '') {
            countryCodeControl.setErrors({ wrongFormat: true });
            phoneNumberControl.setErrors({ wrongFormat: true });
            return { wrongFormat: true };
        }

        return null;
    };
};