import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { MatDatepicker, MatDatepickerInputEvent } from '@matheo/datepicker';
import * as moment from 'moment';

import { RpcDateTimeInputOptions } from './models/rpc-date-time-input-options';

@Component({
    selector: 'rpc-date-picker',
    templateUrl: './rpc-date-picker.component.html',
    styleUrls: ['./rpc-date-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpcDatePickerComponent implements OnInit, OnChanges {

    @Input() options?: RpcDateTimeInputOptions;
    @Input() appearance: MatFormFieldAppearance;
    @Input() label?: string;
    @Input() iconName?: string;
    @Input() hideInput = false;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() color?: ThemePalette = 'accent';
    @Input() dateClass?: (date: Date) => string;

    @Output() public dateChanged = new EventEmitter<Date>();

    @ViewChild('picker') picker: MatDatepicker<Date>;

    public formControl: FormControl;

    public ngOnInit(): void {
        if (this.options == null) {
            this.options = {
                formGroup: new FormGroup({}),
                controlName: 'rpc-date-picked-default-id',
                defaultValue: null,
                validatorsMap: [],
                type: 'date',
                startView: 'month',
                clockStep: 5,
                twelveHour: true
            };
        }

        this.formControl = new FormControl(
            this.options.defaultValue != null ? moment(this.options.defaultValue).toDate() : null,
            this.options.validatorsMap?.map(validationItem => validationItem.validator));
        this.options.formGroup.addControl(this.options.controlName, this.formControl);
    }

    public ngOnChanges(changes: SimpleChanges<RpcDatePickerComponent>): void {
        if (changes.options != null && changes.options.currentValue != null && !changes.options.firstChange) {
            this.formControl = new FormControl(
                changes.options.currentValue.defaultValue != null ? moment(changes.options.currentValue.defaultValue).toDate() : null,
                changes.options.currentValue.validatorsMap?.map(validationItem => validationItem.validator));
            changes.options.currentValue.formGroup.addControl(changes.options.currentValue.controlName, this.formControl);
        }
    }

    public onDateChanged(event: MatDatepickerInputEvent<Date, Date>): void {
        this.dateChanged.emit(event.value);
    }

    public openPicker(): void {
        this.picker.open();
    }

    public clearDate(): void {
        this.formControl.reset();
        this.dateChanged.emit(null);
    }
}