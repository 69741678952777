import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';

export const uniqueByValidatior = (uniqueControlName: string)
    : ((control: AbstractControl) => ValidationErrors | null) => {
    return (currentFormArray: FormArray): { [key: string]: boolean } => {
        const controls: AbstractControl[] = currentFormArray?.controls?.filter((formGroup: FormGroup) => {
            const control = formGroup.get(uniqueControlName);
            return control != null && control.value != null;
        });
        if (controls?.length > 1) {
            const uniqueObj = { uniqueBy: true };
            let find = false;
            controls.map(formGroup => formGroup.get(uniqueControlName)).forEach(control => {
                if (control.errors != null) {
                    delete control.errors['uniqueBy'];
                    if (Object.keys(control.errors).length === 0) {
                        control.setErrors(null);
                        control.updateValueAndValidity();
                    } else {
                        control.setErrors(control.errors);
                    }
                }
            });
            for (let i = 0; i < controls.length; i += 1) {
                const formGroup: FormGroup = controls[i] as FormGroup;
                const mainControl: AbstractControl = formGroup.get(uniqueControlName);

                const mainValue = (mainControl.value as string).toLowerCase();
                controls.forEach((group: FormGroup, index: number) => {
                    if (i === index) {
                        return;
                    }

                    const currControl = group.get(uniqueControlName);
                    const currValue = (currControl.value as string).toLowerCase();
                    const newErrors = currControl.errors == null ? uniqueObj : { ...currControl.errors, ...uniqueObj };
                    if (mainValue === currValue) {
                        currControl.setErrors(newErrors);
                        find = true;
                    }
                });
            }

            if (find) {
                return uniqueObj;
            }
        }
        return null;
    };
};