import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { ActivityListingCandidate } from '@listings/models/api/set-listing-activity-request';
import { ListingActivity } from '@listings/models/listing/listing-activity';

export class ActivityHelpersService {

    public static setListingActivity(activityListingCandidate: ActivityListingCandidate, newActivityId: number, customerId: number): ListingActivity[] {
        let newActivities: ListingActivity[] = [];
        switch (newActivityId) {
            case ListingActivityConstants.PickListed.id:
                newActivities = ActivityHelpersService.fillNewActivities(
                    activityListingCandidate.activities,
                    newActivityId,
                    customerId,
                    (activity) => activity.id === ListingActivityConstants.Shown.id);
                break;
            case ListingActivityConstants.Liked.id:
            case ListingActivityConstants.Disliked.id:
                newActivities = ActivityHelpersService.fillNewActivities(
                    activityListingCandidate.activities,
                    newActivityId,
                    customerId,
                    (activity) => activity.id === ListingActivityConstants.Shown.id);
                break;
            case ListingActivityConstants.Shown.id:
                newActivities = ActivityHelpersService.fillNewActivities(
                    activityListingCandidate.activities,
                    newActivityId,
                    customerId,
                    (activity) => activity.id !== ListingActivityConstants.Shown.id);
                break;
        }

        return newActivities;
    }

    public static restoreListingActivity(ativityListingCandidate: ActivityListingCandidate): ListingActivity[] {
        const oldActivities: ListingActivity[] = [];
        ativityListingCandidate.activities.forEach(activity => {
            oldActivities.push({ ...activity });
        });
        return oldActivities;
    }

    private static fillNewActivities(
        activities: ListingActivity[],
        newActivityId: number,
        customerId: number,
        addCondition: (activity: ListingActivity) => boolean): ListingActivity[] {

        const newActivities: ListingActivity[] = [];
        activities.forEach(activity => {
            if (addCondition(activity)) {
                newActivities.push({ ...activity });
            }
        });

        if (activities.every(activity => activity.id !== newActivityId)) {
            newActivities.push({
                id: newActivityId,
                date: new Date(),
                customerId,
                initiatorName: ''
            });
        }
        return newActivities;
    }
}