import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from '@core-layout/footer/footer.component';
import { locale as english } from '@core-layout/footer/i18n/en';
import { TranslationLoaderService } from '@core-services/translation-loader.service';

@NgModule({
    declarations: [
        FooterComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule
    ],
    exports: [
        FooterComponent
    ]
})
export class FooterModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english);
    }
}