import { createReducer, on } from '@ngrx/store';

import * as settingsActions from '@settings/store/actions/settings.actions';
import { SettingsState } from '@settings/store/states/settings.state';
import { SettingsHelper } from '../../settings.helper';
import { SettingsActionErrors } from '../enums/settings-action-errors';

const initialState = new SettingsState();
export const settingsReducer = createReducer(
    initialState,
    on(settingsActions.loadSettingsSuccessful, (state, settings) => {
        return { ...state, settings: SettingsHelper.updateWithDefaultFolderSettings(settings) };
    }),
    on(settingsActions.loadSettingsFailed, (state) => {
        return {
            ...state,
            error: { error: SettingsActionErrors.SettingsLoading }
        };
    }),
    on(settingsActions.loadNewMatchSettingsSuccessful, (state, { settings }) => {
        return {
            ...state,
            newMatchSettings: settings.settingsValues
        };
    }),
    on(settingsActions.loadNewMatchSettingsFailed, (state) => {
        return {
            ...state,
            error: { error: SettingsActionErrors.NewMatchSettingsLoading }
        };
    }),
    on(settingsActions.updateSettings, (state, action) => {
        return {
            ...state,
            settings: action.newSettings
        };
    }),
    on(settingsActions.updateSettingsFailed, (state, action) => {
        return {
            ...state,
            settings: action.settings,
            error: { error: SettingsActionErrors.SettingsUpdate }
        };
    }),
    on(settingsActions.updateNewMatchSettings, (state, { request: { personalSettings, sharedSettings } }) => {
        return {
            ...state,
            newMatchSettings: [...personalSettings, ...sharedSettings]
        };
    }),
    on(settingsActions.updateNewMatchSettingsFailed, (state, action) => {
        return {
            ...state,
            newMatchSettings: action.settings.settingsValues,
            error: { error: SettingsActionErrors.NewMatchSettingsUpdate }
        };
    }),
    on(settingsActions.removeSettingsError, (state, action) => {
        return {
            ...state,
            error: null
        };
    }),
    on(settingsActions.loadNeighborhoodsMappingSuccessful, (state, action) => {
        if (action.neighborhoods != null) {
            return {
                ...state,
                neighborhoodsMapping: {
                    type: 'FeatureCollection',
                    features: action.neighborhoods.map(item => item.features != null && item.features.length > 0 ? item.features[0] : null)
                }
            };
        }
        return {
            ...state,
            neighborhoodsMapping: null
        };
    }),
    on(settingsActions.loadNeighborhoodsMappingFailed, (state, action) => {
        return {
            ...state,
            error: { error: SettingsActionErrors.NeighborhoodsMappingLoading }
        };
    }),
    on(settingsActions.setSettings, (state, { settings }) => ({ ...state, settings })),
    on(settingsActions.resetState, () => ({ ...initialState }))
);