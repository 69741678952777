export const locale = {
    lang: 'en',
    data: {
        SIDE_MENU: {
            TITLES: {
                MY_LISTINGS: 'Portfolio',
                MY_APPOINTMENTS: 'Appointments',
                SEARCH_LISTINGS: 'Search for Listings',
                PROFILE: 'My Profile',
                SETTINGS: 'Settings',
                PROFILES: 'Profiles',
                LOGOUT: 'Logout',
                INSTALLATION: 'Install As Application',
                CHANGE_AGENT: 'Change Agent',
                KNOWLEDGE_BASE: 'Knowledge Base'
            }
        }
    }
};