import { SortDirection } from './sort-direction';
import { SortOptionBase } from './sort-option';

export class SortOptionFirstLevel<T> extends SortOptionBase {
    public propertyName: string;

    constructor(propertyName: keyof T, direction: SortDirection = SortDirection.Desc) {
        super(direction);
        this.propertyName = `${propertyName}`;
    }
}