import { ServerMessagesMapping } from '@core-models/messages/server-messages-mapping';

export const serverMessagesMapping: ServerMessagesMapping = {
    Registration_Invite_Expired: {
        code: 400,
        messageTranslationPath: 'REGISTRATION.ERRORS.SERVER.INVITE_EXPIRED',
        title: ''
    },
    Registration_Invite_Invalid: {
        code: 400,
        messageTranslationPath: 'REGISTRATION.ERRORS.SERVER.INVITE_INVALID',
        title: ''
    },
    Registration_AccountName_Registered: {
        code: 400,
        messageTranslationPath: 'REGISTRATION.ERRORS.SERVER.ACCOUNT_REGISTERED',
        title: ''
    }
};