import { locale as registrationForm } from '@auth/components/registration-form/i18n/en';
import { locale as forgotPassword } from '@auth/pages/forgot-password/i18n/en';
import { locale as login } from '@auth/pages/login/i18n/en';
import { locale as registration } from '@auth/pages/registration/i18n/en';
import { locale as restorePassword } from '@auth/pages/restore-password/i18n/en';
import { locale as termsAndConditions } from '@auth/pages/terms-and-conditions/i18n/en';

export const locale = {
    lang: 'en',
    data: {
        ...registrationForm.data,
        ...forgotPassword.data,
        ...login.data,
        ...registration.data,
        ...restorePassword.data,
        ...termsAndConditions.data
    }
};