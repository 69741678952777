import { createAction, props } from '@ngrx/store';

import { CompanyConfiguration } from '@app-config/models/company-configuration';
import { ApiError } from '@error/models/api-error';

export const loadCompanyConfiguration = createAction(
    '[App] Load Company Configuration', props<{ companyId: string }>());
export const loadCompanyConfigurationByInternalId = createAction(
    '[App] Load Company Configuration By Internal Id', props<{ internalCompanyId: number }>());
export const loadDomainCompanyConfiguration = createAction(
    '[App] Load Domain Company Configuration');
export const loadCompanyConfigurationSuccess = createAction(
    '[App] Load Company Configuration Success', (companyConfiguration: CompanyConfiguration) => companyConfiguration);
export const loadCompanyConfigurationFailed = createAction(
    '[App] Load Company Configuration Failed', (error: ApiError) => error);