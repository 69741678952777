import { PhoneInfo } from '@core-controls/models/phone-info';

export class CustomerRegistationFormData {
    id?: number;
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    phoneNumberInfo: PhoneInfo;
}