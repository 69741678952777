import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Subject } from 'rxjs';

import { ScrollConstants } from '@core-layout/scroll-to-top/scroll.constants';

@Injectable({ providedIn: 'root' })
export class ScrollService implements OnDestroy {

    public scrollToTopVisibility$ = new Subject<boolean>();

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly pageScrollService: PageScrollService
    ) { }

    public ngOnDestroy(): void {
        this.scrollToTopVisibility$.complete();
    }

    public scroll(scrollTarget: string, scrollFinishListener: EventEmitter<boolean> = null, duration: number = 0, scrollToBottom: boolean = false): void {
        const scrollContainers = this.getScrollableContainers();
        if (scrollContainers.length > 0) {
            const offset = scrollToBottom ? -Math.abs(scrollContainers[0].clientHeight) : 0;
            setTimeout(() => this.pageScrollService.scroll({
                document: this.document,
                scrollViews: this.getScrollableContainers(),
                scrollTarget: scrollTarget,
                scrollFinishListener: scrollFinishListener,
                duration: duration,
                scrollOffset: offset
            }));
        }
    }

    public scrollToTop(): void {
        this.scroll(`.${ScrollConstants.ScrollToTopClasses.Target}`);
    }

    public scrollToElementById(elementId: string, behavior: 'auto' | 'smooth' = 'auto'): void {
        const element = document.getElementById(elementId);

        if (element != null) {
            setTimeout(() => element.scrollIntoView({ behavior }));
        }
    }

    public scrollToContainerTop(elementSelector: string, behavior: 'auto' | 'smooth' = 'auto'): void {
        const element = document.querySelector(elementSelector);

        if (element != null) {
            setTimeout(() => element.scroll({ top: 0, behavior }));
        }
    }

    public isElementScrollable(elementSelector: string): boolean {
        const element = document.querySelector(elementSelector);
        return element == null
            ? false
            : element.scrollHeight > element.clientHeight;
    }

    private getScrollableContainers(): HTMLElement[] {
        const scrollContainers = this.document.getElementsByClassName(ScrollConstants.ScrollToTopClasses.Container);
        return Array.prototype.map.call(scrollContainers, (container: HTMLElement) => container) as HTMLElement[];
    }
}