export const locale = {
    lang: 'en',
    data: {
        FOOTER: {
            TITLES: {
                COPYRIGHT_1: 'Copyright ©',
                COPYRIGHT_2: 'RealPlus. All rights reserved.'
            }
        }
    }
};