import { Creator } from '@comments/enums/creator.enum';

export class ListingComment {
    public readonly id: number;
    public readonly listingIdHashCode: number;
    public readonly viewed: boolean;
    public readonly dateCreated: Date;
    public readonly dateUpdated: Date;
    public readonly comment: string;
    public readonly createId: number;
    public readonly createName: string;
    public readonly profilePictureUrl: string;

    public readonly isMine: boolean;

    constructor(
        listingIdHashCode: number,
        comment: string,
        dateCreated: Date,
        createId: number,
        creatorName: string,
        profilePictureUrl: string,
        viewed: boolean = false,
        dateUpdated: Date = null,
        commentId: number = null,
    ) {
        this.id = commentId;
        this.listingIdHashCode = listingIdHashCode;
        this.comment = comment;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.viewed = viewed;
        this.createId = createId;
        this.createName = creatorName;
        this.profilePictureUrl = profilePictureUrl;
    }
}
export class CreateOperationListingComment extends ListingComment {
    public readonly operationId: number;

    constructor(
        listingIdHashCode: number,
        comment: string,
        dateCreated: Date,
        createId: number,
        createName: string,
        operationId: number = Math.floor(Math.random() * -99999)
    ) {
        super(listingIdHashCode, comment, dateCreated, createId, createName, null);
        this.operationId = operationId;
    }
}