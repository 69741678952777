<div class="profile-item" *ngFor="let phoneControl of phoneControls; let i = index">
    <mat-divider class="profile-item-divider profile-item-divider--top"></mat-divider>
    <div class="profile-item-header">
        <div class="profile-item-header-title text-truncate">
            {{ 'PHONES_EDIT_FORM.TITLES.PHONE' | translate }} {{ i + 1 }}
        </div>
        <rpc-button class="profile-item-header-btn" [color]="'accent'" title="PHONES_EDIT_FORM.TITLES.DELETE"
            [iconName]="'decline'" (clicked)="onRemovePhone(i)">
        </rpc-button>
    </div>
    <div class="profile-item-box">
        <rpc-select class="profile-item-select" [color]="'accent'" label="PHONES_EDIT_FORM.TITLES.TYPE"
            [controlOptions]="phoneControl.selectOptions" appearance="outline">
        </rpc-select>
        <phone-form-input class="profile-item-input" [appearance]="'outline'" [phoneNumberInfo]="phoneControl.phoneInfo"
            [isPhoneRequired]="true" [isFormatValidationRequired]="true"
            (phoneNumberChanged)="onPhoneNumberChanged($event)">
        </phone-form-input>
    </div>
</div>
<mat-divider class="profile-item-divider profile-item-divider--bottom"></mat-divider>
<div class="profile-item-btn-wrap">
    <rpc-button class="profile-item-btn" [mainButton]="true" [color]="'accent'" [appearanceType]="'flat'"
        title="PHONES_EDIT_FORM.TITLES.ADD_NEW" (clicked)="onAddPhone()"></rpc-button>
    <rpc-icon-button class="profile-item-btn profile-item-btn--mobile" [appearanceType]="'fab'" [color]="'accent'"
        iconName="plus" title="PHONES_EDIT_FORM.TITLES.ADD_NEW" (clicked)="onAddPhone()">
    </rpc-icon-button>
</div>