import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FoldersState } from '../states/folders.state';

const foldersStateSelector = createFeatureSelector<FoldersState>('folders');

export const selectFoldersSavedSearches = createSelector(
    foldersStateSelector,
    state => state.foldersSavedSearches
);

export const selectFolderIdAttachedToSavedSearch = (savedSearchId: number) => createSelector(
    selectFoldersSavedSearches,
    folderSavedSearches => folderSavedSearches.find(x => x.savedSearchId === savedSearchId)?.folderId
);

export const selectSavedSearchesNamesAttachedToFolder = (folderId: number) => createSelector(
    selectFoldersSavedSearches,
    folderSavedSearches => folderSavedSearches.reduce(
        (savedSearchesNames, x) => {
            return x.folderId === folderId ? [...savedSearchesNames, x.savedSearchName] : savedSearchesNames;
        },
        new Array<string>()
    ),
);