import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NotificationsState } from '@notifications/store/states/notifications.state';
import { NotificationEventEntity } from "@notifications/enums/notification-event-entity";
import { selectCustomersAndAgents } from '@users/store/selectors/users.selectors';
import { NotificationsService } from '../services/notifications.service';
import { TABS_INDICES } from '@notifications/constants/notifications.constants';

const notificationsStateSelector = createFeatureSelector<NotificationsState>('notifications');

export const selectFlatNotifications = createSelector(notificationsStateSelector, state => state?.notifications ?? []);

export const selectActiveTabIndex = createSelector(notificationsStateSelector, state => state.activeTabIndex ?? TABS_INDICES.ALL);

export const selectIsShowOnlyUnviewedNotifications = createSelector(
    notificationsStateSelector,
    state => state?.isShowOnlyUnviewedNotifications
);

export const selectUnreadNotificationsCounters = createSelector(
    selectFlatNotifications,
    notifications => NotificationsService.getNotificationCounters(notifications)
);

export const selectUnviewedNotificationTotalCount = createSelector(
    selectUnreadNotificationsCounters,
    ({ appointments, messages, listings }) => appointments + messages + listings
);

export const selectAllNotitifications = createSelector(
    selectFlatNotifications,
    selectCustomersAndAgents,
    selectIsShowOnlyUnviewedNotifications,
    (notifications, customersAndAgents, isShowOnlyUnreadNotifications) => {
        return NotificationsService.getAllNotificationsItemsGroupedByTimePeriod(notifications, customersAndAgents, isShowOnlyUnreadNotifications);
    }
);

export const selectMessageNotitifications = createSelector(
    selectAllNotitifications,
    allNotitifications => NotificationsService.filterNotificationsByType(allNotitifications, NotificationEventEntity.Comment)
);

export const selectAppointmentNotitifications = createSelector(
    selectAllNotitifications,
    allNotitifications => NotificationsService.filterNotificationsByType(allNotitifications, NotificationEventEntity.Appointment)
);
export const selectListingNotitifications = createSelector(
    selectAllNotitifications,
    allNotitifications => {
        return NotificationsService.filterNotificationsByType(allNotitifications, NotificationEventEntity.ListingActivity, NotificationEventEntity.ExternalListing);
    }
);