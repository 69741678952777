export const locale = {
    lang: 'en',
    data: {
        LISTINGS_LIST: {
            TITLES: {
                LISTINGS: '{{count}} listings',
                REMOVE: 'Remove',
                REMOVED: 'Removed'
            }
        }
    }
};