import { Directive,  EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[rpcClickHandler]' })
export class RpcClickHandlerDirective {
    private static readonly DebounceTime = 250;

    @Output() public singleClicked = new EventEmitter<void>();
    @Output() public doubleClicked = new EventEmitter<void>();

    private clicksCount = 0;
    private timer?: NodeJS.Timeout = null;

    @HostListener('click')
    public click(): void {
        this.clicksCount++;
        if (this.timer == null) {
            this.timer = setTimeout(
                () => {
                    if (this.clicksCount === 1) {
                        this.singleClicked.emit();
                    }
                    else if (this.clicksCount === 2) {
                        this.doubleClicked.emit();
                    }
                    this.clicksCount = 0;
                    this.timer = null;
                },
                RpcClickHandlerDirective.DebounceTime);
        }
    }
}