import { ListingActivityType } from '@customer-activity/enums/listing-activity-type.enum';
import { ListingStatusConstants } from '@listings/constants//listing-status-constants';
import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { ListingCategoryTexts } from '@listings/enums/listing-category-texts.enum';
import { ListingOwnershipTexts } from '@listings/enums/listing-ownership-texts.enum';
import { ListingStatusTexts } from '@listings/enums/listing-status-texts.enum';
import { ListingTypeTexts } from '@listings/enums/listing-type-texts.enum';
import { CommonListing } from '@listings/models/listing/common-listing';
import { ListingProperties } from '@listings/models/listing/listing-properties';
import { ShowAddressOption } from '@shared-listing-details/enums/show-address-option.enums';

export class ListingHelperService {

    public static getListingProperties(listing: CommonListing): ListingProperties {
        return {
            category: ListingHelperService.getListingCategory(listing),
            ownership: ListingHelperService.getListingOwnershipType(listing),
            status: ListingHelperService.getListingStatus(listing)
        };
    }

    public static getListingFullAddress(listing: CommonListing, hideOpenRentals: boolean, showAddressOption: ShowAddressOption = null): string {
        if (hideOpenRentals && ListingHelperService.isOpenRental(listing)) {
            return listing.property.neighborhood;
        }

        if (showAddressOption != null && showAddressOption === ShowAddressOption.street) {
            return listing.property.address.slice(listing.property.address.indexOf(' '));
        }

        let address = listing.property.address;
        address += (listing.unit.number.toUpperCase() !== 'TOWNHOUSE' ? ', ' + listing.unit.number : '');
        return address;
    }

    public static mapActivityToListingActivityType(activityId: number): ListingActivityType {
        const map = new Map<number, ListingActivityType>([
            [ListingActivityConstants.PickListed.id, ListingActivityType.PickListed],
            [ListingActivityConstants.Liked.id, ListingActivityType.Liked],
            [ListingActivityConstants.Disliked.id, ListingActivityType.Disliked],
            [ListingActivityConstants.Shown.id, ListingActivityType.Shown],
        ]);

        return map.has(activityId) ? map.get(activityId) : null;
    }

    public static getListingOwnershipType(listing: CommonListing): ListingOwnershipTexts {
        if (listing.property == null || listing.property.ownershipType == null) {
            return null;
        }
        switch (listing.property.ownershipType.toUpperCase()) {
            case ListingOwnershipTexts.Condo:
                return ListingOwnershipTexts.Condo;
            case ListingOwnershipTexts.Condop:
                return ListingOwnershipTexts.Condop;
            case ListingOwnershipTexts.Coop:
                return ListingOwnershipTexts.Coop;
            case ListingOwnershipTexts.Rental:
                return ListingOwnershipTexts.Rental;
            case ListingOwnershipTexts.Townhouse:
                return ListingOwnershipTexts.Townhouse;
            default:
                return null;
        }
    }

    public static getListingCategory(listing: CommonListing): ListingCategoryTexts {
        switch (listing.category.toUpperCase()) {
            case ListingCategoryTexts.Rental:
                return ListingCategoryTexts.Rental;
            case ListingCategoryTexts.Sales:
                return ListingCategoryTexts.Sales;
            default:
                return null;
        }
    }

    public static isOpenRental(listing: CommonListing): boolean {
        return listing.category.toUpperCase() === ListingCategoryTexts.Rental && listing.type.toLowerCase() === ListingTypeTexts.Open;
    }

    public static getFurnishedValue(listing: CommonListing): string {
        if (!Boolean(listing.unit?.furnished)) {
            return '';
        }

        const value = listing.unit?.furnished?.toUpperCase();
        return value === 'Y'
            ? 'LISTING_FIELDS.ATTRIBUTE_VALUES.YES'
            : value === 'N'
                ? 'LISTING_FIELDS.ATTRIBUTE_VALUES.NO'
                : 'LISTING_FIELDS.ATTRIBUTE_VALUES.UNKNOWN';
    }

    private static getListingStatus(listing: CommonListing): ListingStatusTexts {
        const category = ListingHelperService.getListingCategory(listing);
        const statuses = category === ListingCategoryTexts.Sales ? ListingStatusConstants.Sales : ListingStatusConstants.Rentals;
        const stutus = listing.status.toUpperCase();
        if (statuses.Active.includes(stutus)) {
            return ListingStatusTexts.Active;
        } else if (statuses.Closed.includes(stutus)) {
            return category === ListingCategoryTexts.Sales ? ListingStatusTexts.Sold : ListingStatusTexts.Rented;
        } else if (statuses.OffMarket.includes(stutus)) {
            return ListingStatusTexts.OffMarket;
        } else if (statuses.Signed.includes(stutus)) {
            return category === ListingCategoryTexts.Sales ? ListingStatusTexts.ContractSigned : ListingStatusTexts.LeaseSigned;
        } else if (statuses.Expired.includes(stutus)) {
            return ListingStatusTexts.Expired;
        } else if (statuses.Future.includes(stutus)) {
            return ListingStatusTexts.Future;
        }
        return ListingStatusTexts.Unknown;
    }
}