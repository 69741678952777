import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { AgentCandidate } from '@agents/models/agent-candidate';
import { AgentListTexts } from '@agents/models/agent-list-texts';

@Component({
    selector: 'agent-list',
    templateUrl: './agent-list.component.html',
    styleUrls: ['./agent-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentListComponent {

    @Input() agentCandidates: AgentCandidate[];

    @Input() texts: AgentListTexts;

    @Input() currentCustomerId?: number;

    @Output() public readonly agentSeleectionConfirmed = new EventEmitter<number>();

    public selectedCustomerId: number;

    public onAgentSelected(selectedCustomerId: number): void {
        this.selectedCustomerId = selectedCustomerId;
    }

    public onAgentSelectionConfirmed(): void {
        if (this.canSubmit) {
            this.agentSeleectionConfirmed.emit(this.selectedCustomerId);
        }
    }

    public get canSubmit(): boolean {
        return this.selectedCustomerId != null && this.selectedCustomerId !== this.currentCustomerId;
    }
}