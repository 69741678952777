export const locale = {
    lang: 'en',
    data: {
        MEDIA_VIEWER: {
            TITLES: {
                ALL: 'All',
                LISTING: 'Listing',
                BUILDING: 'Building',
                VIDEO: 'Video',
                TOUR: '3D-tour',
                IMAGES: 'Images',
                FLOORPLANS: 'Floorplans',
                COLLAPSE_SECTION: 'Collapse Section',
                SHOW_SECTION: 'Show Section',
                CLOSE: 'Close',
            }
        }
    }
};