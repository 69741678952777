import * as moment from 'moment';

import { ChareEventDateFormatConstant } from '@appointments/constants/share-event-date-format-constant';
import { ShareEventUrlsConstatns } from '@appointments/constants/share-event-urls.constants';
import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { ShareEventBase } from '@appointments/models/share-event/share-event-base';

export class GoogleShareEvent extends ShareEventBase {

    constructor(location: string, appointment: ListingAppointment) {
        super(location, appointment);
    }

    public getURL(): string {
        const paramerets = {
            text: this.title,
            details: this.description,
            location: this.location,
            dates: [
                moment(this.start).format(ChareEventDateFormatConstant.GoogleDateFormat),
                moment(this.end).format(ChareEventDateFormatConstant.GoogleDateFormat)
            ].join('/')
        };

        const queryString = Object.keys(paramerets)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(paramerets[key] as string))
            .join('&');

        return ShareEventUrlsConstatns.GoogleURL + queryString;
    }
}