import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as routerSelectors from '../selectors/router.selectors';
import { AppState } from '../state/app.state';

@Injectable({ providedIn: 'root' })
export class RouterStoreService {

    constructor(private readonly store: Store<AppState>) { }

    public readonly routeParams$ = this.store.select(routerSelectors.selectRouteParams);
    public readonly url$ = this.store.select(routerSelectors.selectUrl);
}
