export const locale = {
    lang: 'en',
    data: {
        IMPORTANT_DIALOG: {
            BUTTONS: {
                CANCEL: 'Cancel',
                OK: 'Ok'
            }
        }
    }
};