import { Pipe, PipeTransform } from '@angular/core';
import { DateConstants } from '@core-constants/dates/date.constants';
import * as moment from 'moment';

@Pipe({ name: 'timePeriodLabel' })
export class TimePeriodLabelPipe implements PipeTransform {

    public transform(start: string | Date, end: string | Date): string {
        const startTime = this.formatTime(start);
        const endTime = this.formatTime(end);
        return startTime !== endTime ? `${startTime} - ${endTime}` : startTime;
    }

    private formatTime(date: Date | string): string {
        return moment(date).format(DateConstants.Formats.ShortTime);
    }
}