import { BREAKPOINT } from '@angular/flex-layout';

export const MEDIA_VIEWER_XS_BREAKPOINT = {
    alias: 'mv.xs',
    suffix: 'MvXs',
    mediaQuery: 'screen and (max-width: 799px)',
    overlapping: true,
};

export const MediaViewerBreakPointsProvider = {
    provide: BREAKPOINT,
    useValue: [MEDIA_VIEWER_XS_BREAKPOINT],
    multi: true
};