import { AbstractControl, ValidationErrors } from '@angular/forms';

export const getExternalDependencyValidator = (isRequired: boolean): (control: AbstractControl) => ValidationErrors | null => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        if (currentControl == null || currentControl.parent == null) {
            return null;
        }

        const validityObject = currentControl.value as { isValid: boolean };

        if (validityObject == null && !isRequired) {
            return null;
        }

        if (validityObject == null || validityObject.isValid == null || !validityObject.isValid) {
            return { externalDependencyNotValid: true };
        }

        return null;
    };
};