import { Injectable } from '@angular/core';

import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { GroupNotificationActionsToShow, PlainNotificationActionsToShow } from '@notifications/constants/notifications.constants';
import { NotificationEventAction } from "@notifications/enums/notification-event-action";

type NotificationDisplayAttributes = { title: string; params: Object; icon: string, iconClass: string };

@Injectable({ providedIn: 'root' })
export class NotificationAttributesService {
    public static getForSingle(action: NotificationEventAction, listingAddress: string, userFullName: string): NotificationDisplayAttributes | null {
        const actionDisplayAttributes: { [key in PlainNotificationActionsToShow]: NotificationDisplayAttributes } = {
            [NotificationEventAction.CommentCreated]: { title: 'NOTIFICATION_ITEM.TITLES.COMMENT_ADDED', params: { userFullName, listingAddress }, icon: null, iconClass: null },
            [NotificationEventAction.CommentUpdated]: { title: 'NOTIFICATION_ITEM.TITLES.COMMENT_UPDATED', params: { userFullName, listingAddress }, icon: null, iconClass: null },
            [NotificationEventAction.AppointmentCreated]: { title: 'NOTIFICATION_ITEM.TITLES.NEW_APPOINTMENT', params: { userFullName }, icon: 'appointment', iconClass: null },
            [NotificationEventAction.AppointmentUpdated]: { title: 'NOTIFICATION_ITEM.TITLES.APPOINTMENT_UPDATED', params: { userFullName }, icon: 'appointment', iconClass: null },
            [NotificationEventAction.AppointmentConfirmed]: { title: 'NOTIFICATION_ITEM.TITLES.APPOINTMENT_CONFIRMED', params: { userFullName }, icon: 'appointment', iconClass: null },
            [NotificationEventAction.AppointmentDeclined]: { title: 'NOTIFICATION_ITEM.TITLES.APPOINTMENT_DECLINED', params: { userFullName }, icon: 'appointment', iconClass: null },
            [NotificationEventAction.ListingActivityAddedToPortfolio]: { title: 'NOTIFICATION_ITEM.TITLES.LISTING_ADDED', params: { userFullName }, icon: ListingActivityConstants.PickListed.icon, iconClass: ListingActivityConstants.PickListed.iconClass },
            [NotificationEventAction.ListingActivityLiked]: { title: 'NOTIFICATION_ITEM.TITLES.LISTING_LIKED', params: { userFullName }, icon: ListingActivityConstants.Liked.icon, iconClass: ListingActivityConstants.Liked.iconClass },
            [NotificationEventAction.ListingActivityDisliked]: { title: 'NOTIFICATION_ITEM.TITLES.LISTING_DISLIKED', params: { userFullName }, icon: ListingActivityConstants.Disliked.icon, iconClass: ListingActivityConstants.Disliked.iconClass },
            [NotificationEventAction.ListingActivityShown]: { title: 'NOTIFICATION_ITEM.TITLES.LISTING_SHOWN', params: { userFullName }, icon: ListingActivityConstants.Shown.icon, iconClass: ListingActivityConstants.Shown.iconClass },
            [NotificationEventAction.ExternalListingAdded]: { title: 'NOTIFICATION_ITEM.TITLES.EXTERNAL_LISTING_ADDED', params: { userFullName }, icon: ListingActivityConstants.PickListed.icon, iconClass: ListingActivityConstants.PickListed.iconClass },
        };

        return actionDisplayAttributes[action];
    }

    public static getForGroup(type: NotificationEventAction, numberOfListings: number): NotificationDisplayAttributes | null {
        const actionDisplayAttributes: { [key in GroupNotificationActionsToShow]: NotificationDisplayAttributes } = {
            [NotificationEventAction.ListingActivityAddedToPortfolio]: { title: 'NOTIFICATION_GROUP.TITLES.LISTINGS_ADDED', params: { numberOfListings }, icon: ListingActivityConstants.PickListed.icon, iconClass: ListingActivityConstants.PickListed.iconClass },
            [NotificationEventAction.ListingActivityLiked]: { title: 'NOTIFICATION_GROUP.TITLES.LISTINGS_LIKED', params: { numberOfListings }, icon: ListingActivityConstants.Liked.icon, iconClass: ListingActivityConstants.Liked.iconClass },
            [NotificationEventAction.ListingActivityDisliked]: { title: 'NOTIFICATION_GROUP.TITLES.LISTINGS_DISLIKED', params: { numberOfListings }, icon: ListingActivityConstants.Disliked.icon, iconClass: ListingActivityConstants.Disliked.iconClass },
            [NotificationEventAction.ListingActivityShown]: { title: 'NOTIFICATION_GROUP.TITLES.LISTINGS_SHOWN', params: { numberOfListings }, icon: ListingActivityConstants.Shown.icon, iconClass: ListingActivityConstants.Shown.iconClass },
        };

        return actionDisplayAttributes[type];
    }
}