export const locale = {
    lang: 'en',
    data: {
        AVATAR_INITIALS: {
            TITLES: {
                CHANGE_PHOTO: 'Change Photo',
                EDIT: 'Edit',
                DELETE: 'Delete'
            }
        }
    }
};