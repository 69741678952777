import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProgressBarService } from '@core-utils/progress-bar/progress-bar.service';

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ProgressBarComponent implements OnInit, OnDestroy {

    private readonly unsubscribe$ = new Subject<void>();

    public bufferValue: number;
    public mode: 'determinate' | 'indeterminate' | 'buffer' | 'query';
    public value: number;
    public visible: boolean;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly progressBarService: ProgressBarService
    ) { }

    public ngOnInit(): void {

        this.progressBarService.bufferValue$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((bufferValue) => {
                this.bufferValue = bufferValue;
                this.changeDetectorRef.markForCheck();
            });

        this.progressBarService.mode$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((mode) => {
                this.mode = mode;
                this.changeDetectorRef.markForCheck();
            });

        this.progressBarService.value$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value) => {
                this.value = value;
                this.changeDetectorRef.markForCheck();
            });

        this.progressBarService.visible$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((visible) => {
                this.visible = visible;
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}