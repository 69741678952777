<ng-container *ngIf="settings != null" [formGroup]="form">
    <ng-container *ngFor="let fieldGroup of fieldGroups">
        <div class="notif-alert-title">
            {{ fieldGroup.title | translate | uppercase }}
        </div>
        <div class="notif-alert-list">
            <div class="notif-alert-item"
                *ngFor="let field of fieldGroup.fields">

                <div class="notif-alert-item-text-wrap"
                    [title]="field.title | translate"
                    [ngClass]="{
                        'notif-alert-item-text-wrap--icon' : field.icon != null
                    }">
                        <rpc-icon [class]="'notif-alert-item-text-icon' + (field.iconClass ? ' ' + field.iconClass : '')"
                                *ngIf="field.icon != null"
                                [iconName]="field.icon">
                        </rpc-icon>

                        <div class="notif-alert-item-text">{{field.title | translate}}</div>

                        <div class="notif-alert-item-text-decoration">:</div>
                </div>

                <rpc-select class="notif-alert-item-select"
                    [appearance]="'outline'"
                    [controlOptions]="field.controlOptions"
                    (changed)="onSettingChanged(field.key,$event)">
                </rpc-select>
            </div>
        </div>
    </ng-container>
</ng-container>