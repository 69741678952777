<progress-bar></progress-bar>

<div class="refresher">
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
</div>
<div class="toolbar-wrap" scrollToTopTarget [ngClass]="{
        'toolbar-wrap--minimized':
            (configuration.layout.secondaryToolbar.layout === 'minimized' && !configuration.layout.secondaryToolbar.allowScrollLayoutChange) ||
            (configuration.layout.secondaryToolbar.allowScrollLayoutChange && minimizedToolbar) && !configuration.layout.toolbar.hidden
        }" *ngIf="!configuration.layout.toolbar.hidden">
    <toolbar class="toolbar-global"> </toolbar>
    <div class="toolbar-secondary" [hidden]="configuration.layout.secondaryToolbar.hidden" [ngClass]="{
        'toolbar-secondary--minimized':
            (configuration.layout.secondaryToolbar.layout === 'minimized' && !configuration.layout.secondaryToolbar.allowScrollLayoutChange) ||
            (configuration.layout.secondaryToolbar.allowScrollLayoutChange && minimizedToolbar)
        }">
        <ng-template dynamicComponentHost></ng-template>
    </div>
</div>
<div class="global-content-wrap" id="main" [ngClass]="{
        'global-toolbar-container' : !configuration.layout.toolbar.hidden,
        'secondary-toolbar-container' : !configuration.layout.secondaryToolbar.hidden,
        'secondary-toolbar-container--minimized':
            (configuration.layout.secondaryToolbar.layout === 'minimized' && !configuration.layout.secondaryToolbar.allowScrollLayoutChange) ||
            (configuration.layout.secondaryToolbar.allowScrollLayoutChange && minimizedToolbar) && !configuration.layout.secondaryToolbar.hidden
    }">
    <div class="global-content" cdkScrollable scrollToTopContainer [scrollToTopOffset]="250">

        <!--
            *ngIf="true" hack is required here for router-outlet to work
            correctly. Otherwise, it won't register the changes on the
            layout and won't update the view.
        -->
        <router-outlet *ngIf="true"></router-outlet>

        <div class="global-content--print">View is not supported by the Printed Version</div>
        <footer class="global-footer" *ngIf="!configuration.layout.footer.hidden">
        </footer>

    </div>

    <footer class="global-footer" *ngIf="!configuration.layout.footer.hidden">
    </footer>

    <custom-spinner class="mp-splash-wrap" *ngIf="!configuration.layout.spinner.hidden" [showImage]="false"
        [ngClass]="configuration.layout.spinner.hidden ? '' : 'mobile-spinner--active'">
    </custom-spinner>

</div>

<core-sidebar *ngIf="isUserAuthorized" name="mobileMenu" [position]="'left'" class="mobile-menu">
    <side-menu></side-menu>
</core-sidebar>

<button *ngIf="hasThemeSidebar" mat-raised-button class="mat-elevation-z2 theme-options-button"
    (click)="toggleSidebarOpen('themeOptionsPanel')">
    <rpc-icon iconName="cog"></rpc-icon>
</button>
<core-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <rpc-theme-options></rpc-theme-options>
</core-sidebar>