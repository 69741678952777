import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ActionError } from '@core-models/errors/action-error';
import { ProfileActionErrors } from '@profile/enums/profile-action-errors';
import * as profileActions from '@profile/store/actions/profile.actions';
import * as profileSelectors from '@profile/store/selectors/profile.selector';
import { ProfileState } from '@profile/store/states/profile.state';
import { ChildAccount } from '../../../profile-base/interfaces/child-account/child-account';
import { NewAccountDataModel, OldAccountDataModel } from '../../../profile-base/interfaces/customer-info/account-data-model';
import { CustomerInfo } from '../../../profile-base/interfaces/customer-info/customer-info';
import { UpdatePasswordRequest } from '../../../profile-base/interfaces/customer-settings/update-password-request';

@Injectable({ providedIn: 'root' })
export class ProfileStoreService {

    constructor(
        private readonly store: Store<ProfileState>,
    ) { }

    public readonly topProfileInfo$ = this.store.select(profileSelectors.selectTopProfileInfo);

    public readonly phones$ = this.store.select(profileSelectors.selectPhones);

    public readonly emails$ = this.store.select(profileSelectors.selectEmails);

    public readonly addresses$ = this.store.select(profileSelectors.selectAddresses);

    public readonly selectedAdditionalProfile$ = this.store.select(profileSelectors.selectSelectedAdditionalProfile);

    public readonly isAdditionalProfile$ = this.store.select(profileSelectors.selectIsAdditionalProfile);

    public loadProfileData(): void {
        this.store.dispatch(profileActions.loadProfileData());
    }

    public updateCustomerInfo(customerInfo: CustomerInfo): void {
        this.store.dispatch(profileActions.updateCustomerInfo({ customerInfo }));
    }

    public updateProfileImage(image: File): void {
        this.store.dispatch(profileActions.updateProfileImage({ image }));
    }

    public deleteProfileImage(): void {
        this.store.dispatch(profileActions.deleteProfileImage());
    }

    public updateAccountData(newData: NewAccountDataModel, oldData: OldAccountDataModel): void {
        this.store.dispatch(profileActions.updateAccountData({ newData, oldData }));
    }

    public updatePassword(passwordRequest: UpdatePasswordRequest): void {
        this.store.dispatch(profileActions.updatePassword(passwordRequest));
    }

    public deactivateAccount(): void {
        this.store.dispatch(profileActions.deactivateAccount());
    }

    public getProfileError(): Observable<ActionError<ProfileActionErrors>> {
        return this.store.select(profileSelectors.selectProfileError).pipe(filter(error => error != null));
    }

    public openChildProfileDetails(profile: ChildAccount): void {
        this.store.dispatch(profileActions.additionalProfileDetailsOpening({ profile }));
    }
}