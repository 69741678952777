<mat-button-toggle-group class="rpc-button-toggle-wrap" [appearance]="appearence"
    #rpcButtonToggleGroup="matButtonToggleGroup" (change)="onChanged($event)" [value]="default"
    title="{{ alt | translate }}" [disabled]="disabled">

    <ng-container *ngFor="let toggleItem of toggleItems">
        <mat-button-toggle class="rpc-button-toggle" [value]="toggleItem.value" [disableRipple]="disableRipple"
            title="{{ (toggleItem.alt == null ? toggleItem.title : toggleItem.alt) | translate }}"
            [disabled]="toggleItem.disable">
            {{ toggleItem.title | translate }}
        </mat-button-toggle>
    </ng-container>

</mat-button-toggle-group>