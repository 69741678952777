import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RpcControlsModule } from '@core-controls/controls.module';
import { locale as english } from '@core-layout/scroll-to-top/i18n/en';
import { ScrollToTopContainerDirective } from '@core-layout/scroll-to-top/scroll-to-top-container.directive';
import { ScrollToTopTargetDirective } from '@core-layout/scroll-to-top/scroll-to-top-target.directive';
import { ScrollToTopComponent } from '@core-layout/scroll-to-top/scroll-to-top.component';
import { TranslationLoaderService } from '@core-services/translation-loader.service';

@NgModule({
    declarations: [
        ScrollToTopComponent,
        ScrollToTopContainerDirective,
        ScrollToTopTargetDirective
    ],
    imports: [
        // Library Modules
        CommonModule,

        // Application Modules
        RpcControlsModule
    ],
    exports: [
        ScrollToTopComponent,
        ScrollToTopContainerDirective,
        ScrollToTopTargetDirective
    ]
})
export class ScrollToTopModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english);
    }
}