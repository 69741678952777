import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthState } from '../states/auth.state';

const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectCollaborationSpaces = createSelector(
    selectAuthState,
    state => state?.collaborationSpaces
);

export const selectHasMultipleCollaborationSpaces = createSelector(
    selectAuthState,
    state => state?.hasMultipleCollaborationSpaces
);