import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { WelcomeVideoLinkModel } from '@app-config/models/welcome-video-link-model';

@Component({
  selector: 'welcome-video-link',
  templateUrl: './welcome-video-link.component.html',
  styleUrls: ['./welcome-video-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeVideoLinkComponent {
    @Input() welcomeVideoLinkFeature: WelcomeVideoLinkModel;
}
