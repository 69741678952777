import partition from 'lodash-es/partition';

import { CommonListing } from '@listings/models/listing/common-listing';
import { Listings } from '@listings/models/listing/listings';

export class RemoveRestoreListingHelper {

    public static restore(currentListings: Listings, listingsIds: string[]): Listings {
        return RemoveRestoreListingHelper.removeRestoreListings(currentListings, listingsIds, false);
    }

    public static softDelete(currentListings: Listings, listingIds: string[]): Listings {
        const [newMatchListingsWithoutActivities, listings] = partition(listingIds, id => {
            const { isNewMatch, activities } = currentListings[id];
            return isNewMatch && activities.length === 0;
        });

        const updatedListings = RemoveRestoreListingHelper.hardDelete(currentListings, newMatchListingsWithoutActivities);

        return RemoveRestoreListingHelper.removeRestoreListings(updatedListings, listings, true);
    }

    public static removeRestoreListings(currentListings: Listings, listingsIds: string[], isDeleted: boolean): Listings {
        const removeRestore = (listings: Listings, [id, listing]: [string, CommonListing]): Listings => {
            listings[id] = listingsIds.includes(id)
                ? { ...listing, isDeleted, isNewMatch: false, newMatches: [] }
                : listing;

            return listings;
        };

        return Object.entries(currentListings).reduce(removeRestore, new Listings());
    }

    public static hardDelete(currentListings: Listings, listingsIds: string[]): Listings {
        const hardDeleteListing = (listings: Listings, [id, listing]: [string, CommonListing]): Listings => {
            listings[id] = listingsIds.includes(id)
                ? {
                    ...listing,
                    isDeleted: false,
                    isNewMatch: false,
                    listingOwnerId: null,
                    isMarketListing: true,
                    activitiesUpdateDate: null,
                    activities: [],
                    newMatches: []
                }
                : listing;

            return listings;
        };

        return Object.entries(currentListings).reduce(hardDeleteListing, new Listings());
    }
}