<div class="theme-options-panel">
    <div class="theme-options-header">
        <div class="theme-options-header-title">Theme Options</div>
        <rpc-icon-button class="theme-options-header-btn" [color]="'accent'" [title]="'Close'" [iconName]="'decline'"
            (clicked)="toggleSidebarOpen('themeOptionsPanel')"></rpc-icon-button>
    </div>
    <div class="theme-options-body">
        <div class="theme-options-message-wrap">
            <div class="theme-options-message-title">AVAILABLE FOR TEST PURPOSES ONLY</div>
            <div class="theme-options-message">Following options will modify color theme according to selected company.
            </div>
            <div class="theme-options-message-subtitle">Option will be apllied globaly for all users.</div>
        </div>
        <div class="theme-options-item">
            <div class="theme-options-item-title">Color themes</div>
            <mat-radio-group class="theme-options-list" (ngModelChange)="onModelChanged($event)"
                [(ngModel)]="selectedCompanyId">
                <mat-radio-button *ngFor="let company of companies" [value]="company" class="theme-options-list-item">
                    <span class="theme-options-list-item-text">{{company}}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>