import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ProfileStoreService } from '@profile/store/services/profile-store.service';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { PROFILE_TABS_NAMES } from 'app/modules/user-modules/profile-modules/profile-base/constants/profile-tabs.constants';

@Injectable({ providedIn: 'root' })
export class AdditionalProfileGuard implements CanActivate, CanActivateChild {

    constructor(
        private readonly routeService: RouteService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly profileStoreService: ProfileStoreService,
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute();
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute();
    }

    private canActivateRoute(): Observable<boolean> {
        return this.settingsStoreService.getSettings()
            .pipe(
                take(1),
                switchMap((settings: CustomerSettings) => {
                    const canSeeAdditionalProfilePage = settings?.permissionSettings?.allowMultipleProfiles ?? false;

                    if (!canSeeAdditionalProfilePage) {
                        this.routeService.navigate(RpcRoute.Profile, { state: { tab: PROFILE_TABS_NAMES.GENERAL } }).catch(() => { });

                        return of(false);
                    }

                    return this.profileStoreService.selectedAdditionalProfile$.pipe(
                        map((additionalProfile) => additionalProfile != null)
                    );
                })
            );
    }
}