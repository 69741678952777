import { createFeatureSelector, createSelector } from '@ngrx/store';
import uniqBy from 'lodash-es/uniqBy';

import { selectUser } from '@auth/store/selectors/user.selector';
import { CreateOperationListingComment } from '@comments/models/comments/listing-comment';
import { CommentsState } from '@comments/store/states/comments.state';

const commentsStateSelector = createFeatureSelector<CommentsState>('comments');

export const selectCommentError = createSelector(
    commentsStateSelector,
    (state) => state?.error
);

export const selectIsSingleCommentLoaded = createSelector(
    commentsStateSelector,
    (commentsState) => commentsState.isSingleCommentLoaded
);

export const selectCommentsLodaded = createSelector(
    commentsStateSelector,
    commentsState => commentsState == null ? false : commentsState.commentsLoaded
);

export const selectListingsComments = createSelector(commentsStateSelector, commentsState => commentsState?.listingsComments ?? []);

// TODO: rewrite without parameter - put listingIdHashCode in state or get it from router-state (need to implement)
export const selectListingComments = (listingIdHashCode: number) => createSelector(
    selectListingsComments,
    selectUser,
    (listingsComments, user) => {
        const comments = listingsComments[listingIdHashCode] ?? [];

        return uniqBy(comments, x => x.id || (x as CreateOperationListingComment).operationId)
            .map(x => ({ ...x, isMine: x.createId === user?.customerId }))
            .sort((firstComment, secondComment) =>
                new Date(secondComment.dateCreated).getTime() - new Date(firstComment.dateCreated).getTime());
    }
);

export const selectListingCommentsCount = (listingIdHashCode: number) => createSelector(
    selectListingComments(listingIdHashCode),
    comments => comments.length
);

export const selectListingNewCommentsCount = (listingIdHashCode: number) => createSelector(
    selectListingComments(listingIdHashCode),
    comments => comments.filter(comment => !comment.isMine && !comment.viewed).length
);

export const selectLastComment = (listingHashCode: number) => createSelector(
    selectListingComments(listingHashCode),
    comments => comments[0]
);