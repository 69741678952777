export const locale = {
    lang: 'en',
    data: {
        WELCOME_VIDEO_LINK: {
            TITLES: {
                TITLE: 'Want an introduction to',
                WATCH_VIDEO: 'Watch Video'
            }
        }
    }
};