import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { AgentBaseInfo } from '@agents/models/agent-base-info';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { catchError, map } from 'rxjs/operators';
import * as agentsActions from '../actions/agents.actions';

@Injectable({ providedIn: 'root' })
export class AgentsApiService {
    constructor(
        private readonly http: ApiHttpClient
    ) { }

    public loadAgents(): Observable<Action> {
        return this.http.get('agents/portfolio-agents').pipe(
            map((agents: AgentBaseInfo[]) => agentsActions.loadAgentsSuccess({ agents })),
            catchError((errorResponse: HttpErrorResponse) => of(agentsActions.loadAgentsFailed(errorResponse.error as ApiError)))
        );
    }
}