import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { ListingIdEncryptingResult } from '@listing-id-encrypting/models/listing-id-encrypting-result';
import { ListingsMediaFromApi } from '@listings/models/listing/listings-media-from-api';
import * as listingsActions from '@listings/store/actions/listings.actions';
import { SharedListing } from '@shared-listing-details/models/shared-listing';
import * as sharedListingDetailsActions from '@shared-listing-details/store/actions/shared-listing-details.actions';

@Injectable({ providedIn: 'root' })
export class SharedListingDetailsApiService {

    constructor(
        private readonly http: ApiHttpClient
    ) { }

    public getListing(listingCode: string): Observable<Action> {
        return this.http
            .get('public-listing/getListing', { params: { listingCode } })
            .pipe(
                switchMap((sharedListing: SharedListing) => {
                    const media: ListingsMediaFromApi = {};
                    media[sharedListing.listing.id] = sharedListing.listing.media;

                    return [
                        sharedListingDetailsActions.loadSharedListingSuccess(sharedListing),
                        listingsActions.loadListingsMediaSuccess({ media })
                    ];
                }),
                catchError((errorResponse: HttpErrorResponse) =>
                    of(sharedListingDetailsActions.loadSharedListingFailed(errorResponse.error as ApiError)))
            );
    }

    public getPublicListingCode(code: string): Observable<string> {
        return this.http
            .get('public-listing/public-listing-code', { params: { code: code } })
            .pipe(map(((encryptingResult: ListingIdEncryptingResult) => encryptingResult.code)));
    }
}