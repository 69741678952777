import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { CreateOperationListingAppointment, ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { ListingsAppointments } from '@appointments/models/appointments/listings-appointments';
import * as appointmentsActions from '@appointments/store/actions/appointments.actions';
import { AppointmentActionErrors } from '@appointments/store/enums/appointment-action-errors';
import { AppointmentCreateActionModel } from '@appointments/store/models/appointment-create-action-model';
import { AppointmentDeleteActionModel } from '@appointments/store/models/appointment-delete-action-model';
import { AppointmentUpdateActionModel } from '@appointments/store/models/appointment-update-action-model';
import { AppointmentUpdateStatusActionModel } from '@appointments/store/models/appointment-update-status-action-model';
import { MarkAsViewedActionModel } from '@appointments/store/models/mark-as-viewed-action-model';
import * as appointmentsSelectors from '@appointments/store/selectors/appointments.selectors';
import { AppointmentsState } from '@appointments/store/states/appointments.state';
import { ActionError } from '@core-models/errors/action-error';
import { AppointmentInfo } from '../models/appointment-info';
import * as appointmentInfoSelectors from '../selectors/appointment-info.selectors';

@Injectable({ providedIn: 'root' })
export class AppointmentsStoreService {

    constructor(
        private readonly store: Store<AppointmentsState>,
    ) { }

    public readonly listingsAppointmentsInfoBase$ = this.store.select(appointmentInfoSelectors.selectFlatListingsAppointmentsBaseInfo);
    public readonly listingsAppointmentsInfo$ = this.store.select(appointmentInfoSelectors.selectFlatListingsAppointmentsInfo);
    public readonly unviewedAppointmentsCount$ = this.store.select(appointmentsSelectors.selectUnviewedAppointmentsCount);

    public getAppointmentError(): Observable<ActionError<AppointmentActionErrors>> {
        return this.store.select(appointmentsSelectors.selectAppointmentError).pipe(filter(error => error != null));
    }

    public getIsSingleAppointmentLoaded(): Observable<boolean> {
        return this.store.select(appointmentsSelectors.selectIsSingleAppointmentLoaded);
    }

    public areAppointmentsLoaded(): Observable<boolean> {
        return this.store.pipe(select(appointmentsSelectors.selectAppointmentsLodaded));
    }

    public getListingsAppointments(): Observable<ListingsAppointments> {
        return this.store.select(appointmentsSelectors.selectListingsAppointments);
    }

    public getFlatListingsAppointments(): Observable<ListingAppointment[]> {
        return this.store.pipe(select(appointmentsSelectors.selectFlatListingsAppointments));
    }

    public getFlatListingsAppointmentsCount(): Observable<number> {
        return this.store.pipe(select(appointmentsSelectors.selectFlatListingsAppointmentsCount));
    }

    public getListingAppointments(listingIdHashCode: number): Observable<AppointmentInfo[]> {
        return this.store.select(appointmentInfoSelectors.selectOrderedListingAppointmentsInfo(listingIdHashCode));
    }

    public getListingAppointmentCount(listingIdHashCode: number): Observable<number> {
        return this.store.select(appointmentsSelectors.selectListingAppointmentCount(listingIdHashCode));
    }

    public getListingAppointmentNewCount(listingIdHashCode: number): Observable<number> {
        return this.store.select(appointmentsSelectors.selectListingAppointmentNewCount(listingIdHashCode));
    }

    public getLastAppointmentInfo(listingHashCode: number): Observable<AppointmentInfo> {
        return this.store.select(appointmentInfoSelectors.selectLastListingAppointmentInfo(listingHashCode));
    }

    public loadListingsAppointments(): void {
        this.store.dispatch(appointmentsActions.loadListingsAppointments());
    }

    public loadListingAppointments(listingId: string): void {
        this.store.dispatch(appointmentsActions.loadListingAppointments({ listingId }));
    }

    public createAppointment(
        listingId: string,
        listingIdHashCode: number,
        listingCategory: string,
        listingAddress: string,
        startTime: Date,
        endTime: Date,
        createId: number,
        comment?: string,
        isNewMatch: boolean = false
    ): void {
        const createOperationListingAppointment
            = new CreateOperationListingAppointment(listingIdHashCode, startTime, endTime, createId, comment, Math.floor(Math.random() * -99999));
        const appointmentCreateActionModel
            = new AppointmentCreateActionModel(listingId, listingAddress, listingCategory, createOperationListingAppointment, isNewMatch);
        this.store.dispatch(appointmentsActions.createListingAppointment(appointmentCreateActionModel));
    }

    public updateAppointment(
        listingId: string,
        listingAddress: string,
        listingAppointment: ListingAppointment,
        oldListingAppointment: ListingAppointment
    ): void {
        const appointmentUpdateActionModel = new AppointmentUpdateActionModel(
            listingId,
            listingAddress,
            listingAppointment,
            oldListingAppointment);
        this.store.dispatch(appointmentsActions.updateListingAppointment(appointmentUpdateActionModel));
    }

    public changeAppointmentStatus(
        listingHashcode: number,
        listingAddress: string,
        appointmentId: number,
        customerStatus: AppointmentStatus,
        oldListingAppointment: ListingAppointment,
        updateId: number
    ): void {
        const appointmentUpdateStatusActionModel = new AppointmentUpdateStatusActionModel(
            listingHashcode,
            appointmentId,
            customerStatus,
            oldListingAppointment,
            oldListingAppointment.customerStatus,
            updateId);
        this.store.dispatch(appointmentsActions.changeAppointmentStatus({ request: appointmentUpdateStatusActionModel }));
    }

    public changeStatusById(appointmentId: number, listingHashcode: number, previousCustomerStatus: AppointmentStatus, status: AppointmentStatus): void {
        this.store.dispatch(appointmentsActions.changeStatusById({ appointmentId, listingHashcode, previousCustomerStatus, status }));
    }

    public markAsViewed(markAsViewedActionModel: MarkAsViewedActionModel): void {
        this.store.dispatch(appointmentsActions.markAppointmentsAsViewed(markAsViewedActionModel));
    }

    public deleteAppointment(appointmentId: number, listingId: string, listingAddress: string, listingAppointment: ListingAppointment, isFromStateOnly: boolean = false): void {
        const appointmentDeleteActionModel = new AppointmentDeleteActionModel(
            appointmentId,
            listingId,
            listingAppointment.listingIdHashCode,
            listingAddress,
            listingAppointment);
        this.store.dispatch(appointmentsActions.deleteListingAppointment({ model: appointmentDeleteActionModel, isFromStateOnly }));
    }
}