import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IInviteUserModel } from '../../invite-user/invite-user-model';

@Component({
    selector: 'invite-user-dialog',
    templateUrl: './invite-user-dialog.component.html',
    styleUrls: ['./invite-user-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteUserDialogComponent {
    constructor(
        private readonly dialogRef: MatDialogRef<InviteUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public inviteDialogData: { email?: string, portfolioEmails: string[] }
    ) { }

    public sendEmail(inviteUserModel: IInviteUserModel): void {
        this.dialogRef.close(inviteUserModel);
    }

    public close(): void {
        this.dialogRef.close();
    }
}
