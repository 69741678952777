import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationInfo } from '@core-models/utilities/translation-info';

@Component({
    selector: 'toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {

    @Input() translationInfo: TranslationInfo;

    @Output() public closed = new EventEmitter<void>();
    @Output() public navigateToNotification = new EventEmitter<void>();

    public onClose(): void {
        this.closed.emit();
    }

    public onReviewClick(): void {
        this.navigateToNotification.emit();
        this.onClose();
    }
}
