import { Listings } from '@listings/models/listing/listings';

export class ListingViewHelper {
    public static setIsViewed(currentListings: Listings, listingHashCode: number, isViewed: boolean): Listings {
        const updatedListings = { ...currentListings };

        const matchingListing = Object.values(updatedListings).find(({ hashCode }) => listingHashCode === hashCode);

        if (matchingListing != null) {
            updatedListings[matchingListing.id] = { ...matchingListing, isNewViewed: isViewed };
        }

        return updatedListings;
    }
}