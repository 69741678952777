import { Injectable } from '@angular/core';

import { ActionError } from '@core-models/errors/action-error';
import { ToastService } from '@core-services/toast.service';
import { ProfileActionErrors } from '@profile/enums/profile-action-errors';

@Injectable({ providedIn: 'root' })
export class ProfileErrorsService {
    private static readonly ProfileActionErrorsLocalizaion = new Map<ProfileActionErrors, string>([
        [ProfileActionErrors.EmailUsedBySameCompanyCustomer, 'PROFILE_COMMON.ERRORS.EMAIL_USED_BY_SAME_COMPANY_CUSTOMER'],
        [ProfileActionErrors.EmailUsedByAnotherCompanyCustomer, 'PROFILE_COMMON.ERRORS.EMAIL_USED_BY_ANOTHER_COMPANY_CUSTOMER'],
        [ProfileActionErrors.EmailExistsWithinAnotherAccount, 'PROFILE_COMMON.ERRORS.EMAIL_EXISTS_WITHIN_ANOTHER_ACCOUNT'],
        [ProfileActionErrors.InviteOneMoreChildAccountFailed, 'PROFILE_COMMON.ERRORS.INVITE_ONE_MORE_CHILD_ACCOUNT_FAILED']
    ]);

    constructor(
        private readonly toaster: ToastService
    ) { }

    public showError(actionError: ActionError<ProfileActionErrors>): void {
        const localizationKey = ProfileErrorsService.ProfileActionErrorsLocalizaion.get(actionError.error);
        if (localizationKey != null) {
            this.toaster.showClientError(localizationKey);
        }
    }
}