<div class="rpc-dropdown activities-tooltip-wrap">
    <rpc-button *ngIf="!isLiked && (isDisliked || isShown)"
        class="rpc-dropdown-btn activities-tooltip-item liked"
        [title]="'ACTIVITIES_TOOLTIP.TITLES.LIKE'" [iconName]="'like'"
        [actionClass]="'active'" [actionTrigger]="isLiked"
        (clicked)="onActivityClicked(listingActivities.Liked)">
    </rpc-button>
    <rpc-button *ngIf="!isDisliked"
        class="rpc-dropdown-btn activities-tooltip-item disliked"
        [title]="'ACTIVITIES_TOOLTIP.TITLES.DISLIKE'" [iconName]="'dislike'"
        [actionClass]="'active'" [actionTrigger]="isDisliked"
        (clicked)="onActivityClicked(listingActivities.Disliked)">
    </rpc-button>
    <rpc-button *ngIf="!isShown"
        class="rpc-dropdown-btn activities-tooltip-item shown"
        [title]="'ACTIVITIES_TOOLTIP.TITLES.SHOWN'" [iconName]="'eye'"
        [actionClass]="'active'" [actionTrigger]="isShown"
        (clicked)="onActivityClicked(listingActivities.Shown)">
    </rpc-button>
</div>