<div class="price-wrap" [ngClass]="{
    'price-wrap--change' : formattedPriceChangeData != null,
    'price-wrap--up' : formattedPriceChangeData != null && formattedPriceChangeData.isPriceIncreased,
    'price-wrap--down' : formattedPriceChangeData != null && !formattedPriceChangeData.isPriceIncreased
}">
    <div *ngIf="formattedPriceChangeData != null" class="price-btn-wrap">
        <rpc-menu-icon-button class="price-btn" [alt]="'LAST_PRICE_CHANGE.TITLES.SHOW_PRICE_CHANGE'"
            [iconName]="formattedPriceChangeData.isPriceIncreased ? 'up' : 'down'"
            (clicked)="onLastPriceChangeClicked()" (closed)="onLastPriceChangeClosed()"
            [ngClass]="formattedPriceChangeData.isPriceIncreased ? 'price-btn--up' : 'price-btn--down'"
            menuActionClass="price-dropdown-wrap">
            <div class="price-dropdown">
                <div class="price-dropdown-title">{{"LAST_PRICE_CHANGE.TITLES.PRICE" | translate }}
                    {{ formattedPriceChangeData.changeTypeLocalizationKey | translate }}
                </div>
                <div class="price-dropdown-date">
                    {{ formattedPriceChangeData.changeDate }}
                </div>
                <div class="price-dropdown-info-wrap">
                    <div class="price-dropdown-info">
                        <div class="price-dropdown-info-title">
                            {{"LAST_PRICE_CHANGE.TITLES.FROM" | translate }}
                        </div>
                        <div class="price-dropdown-info-option">
                            {{ formattedPriceChangeData.previousPrice }}
                        </div>
                    </div>
                    <div class="price-dropdown-info">
                        <div class="price-dropdown-info-title">
                            {{"LAST_PRICE_CHANGE.TITLES.BY" | translate}}
                        </div>
                        <div class="price-dropdown-info-option">
                            {{ formattedPriceChangeData.delta }}
                            <span>({{ formattedPriceChangeData.percentDelta | number }}%)</span>
                        </div>
                    </div>
                </div>
            </div>
        </rpc-menu-icon-button>
    </div>
    
    <div class="price-simple" title="{{ formattedLastPrice }}">{{ formattedLastPrice }}</div>

</div>