export const locale = {
    lang: 'en',
    data: {
        PROFILE_TOP: {
            TITLES: {
                EDIT: 'Edit',
                FIRST_NAME: 'First Name',
                LAST_NAME: 'Last Name',
                MAIN_EMAIL: 'Main Email',
                EMAIL: 'Email',
                CANCEL: 'Cancel',
                SAVE: 'Save'
            },
            VALIDATION_ERRORS: {
                FIRST_NAME_REQUIRED: 'First name is required',
                LAST_NAME_REQUIRED: 'Last name is required'
            }
        }
    }
};