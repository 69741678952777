import { NotificationEventEntity } from "@notifications/enums/notification-event-entity";
import { NotificationEventAction } from "@notifications/enums/notification-event-action";

export const TABS_INDICES = {
    ALL: 0,
    MESSAGES: 1,
    APPOINTMENTS: 2,
    LISTINGS: 3
};

export const NOTIFICATION_TARGET_SCROLL_TO = 'notificationsContainer';

export const BADGE_COUNT_ALERT_LIMIT = 9;

export const NOTIFICATION_TYPE_NOTIFICATION_BELL_TAB_INDEX_MAP = new Map<NotificationEventEntity, number>([
    [NotificationEventEntity.Appointment, TABS_INDICES.APPOINTMENTS],
    [NotificationEventEntity.Comment, TABS_INDICES.MESSAGES],
    [NotificationEventEntity.ExternalListing, TABS_INDICES.LISTINGS],
    [NotificationEventEntity.ListingActivity, TABS_INDICES.LISTINGS]
]);

export const NOT_SHOWN_NOTIFICATIONS = [
    NotificationEventAction.FolderAdded,
    NotificationEventAction.FolderUpdated,
    NotificationEventAction.FolderRemoved,
    NotificationEventAction.FolderListingsChanged,
    NotificationEventAction.NewMatchAdded,
    NotificationEventAction.ListingActivityRemoved,
    NotificationEventAction.ListingSoftDeleted,
    NotificationEventAction.ListingHardDeleted,
    NotificationEventAction.ListingRestored,
    NotificationEventAction.AppointmentDeleted
] as const;


export type NotShownNotifications = typeof NOT_SHOWN_NOTIFICATIONS[number];

export type NotificationActionsToShow = Exclude<NotificationEventAction, NotShownNotifications>;
export type PlainNotificationActionsToShow = NotificationActionsToShow;
export type GroupNotificationActionsToShow = NotificationEventAction.ListingActivityAddedToPortfolio | NotificationEventAction.ListingActivityLiked | NotificationEventAction.ListingActivityDisliked | NotificationEventAction.ListingActivityShown;

export const PUSH_NOTIFICATION_ACTIONS = [
    NotificationEventAction.AppointmentCreated,
    NotificationEventAction.AppointmentConfirmed,
    NotificationEventAction.AppointmentDeclined,
    NotificationEventAction.CommentCreated,
] as const;

export type PushNotificationActions = typeof PUSH_NOTIFICATION_ACTIONS[number];

export type NotificationCluster = 'reaction' | 'message' | 'appointment' | 'listingAddedToPortfolio' | 'shownReaction' | 'externalListingAdded';

export const CLUSTER_BY_NOTIFICATION_ACTION = new Map<NotificationActionsToShow, NotificationCluster>([
    [NotificationEventAction.AppointmentCreated, 'appointment'],
    [NotificationEventAction.AppointmentUpdated, 'appointment'],
    [NotificationEventAction.AppointmentConfirmed, 'appointment'],
    [NotificationEventAction.AppointmentDeclined, 'appointment'],
    [NotificationEventAction.CommentCreated, 'message'],
    [NotificationEventAction.CommentUpdated, 'message'],
    [NotificationEventAction.ExternalListingAdded, 'externalListingAdded'],
    [NotificationEventAction.ListingActivityAddedToPortfolio, 'listingAddedToPortfolio'],
    [NotificationEventAction.ListingActivityDisliked, 'reaction'],
    [NotificationEventAction.ListingActivityLiked, 'reaction'],
    [NotificationEventAction.ListingActivityShown, 'shownReaction']
]);