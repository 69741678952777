import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { Creator } from '@appointments/enums/creator.enum';

export class ListingAppointment {
    public readonly id: number;
    public readonly listingIdHashCode: number;
    public readonly viewed: boolean;
    public readonly creator: Creator;
    public readonly agentStatus: AppointmentStatus;
    public readonly customerStatus: AppointmentStatus;
    public readonly startDateTime: Date;
    public readonly endDateTime: Date;
    public readonly comment: string;
    public readonly createId: number;
    public readonly updateId?: number;
    public readonly dateCreated?: Date; // TODO: Remove nullable
    public readonly dateUpdated?: Date;
    public isNewlyAdded?: boolean;

    constructor(
        listingIdHashCode: number,
        startDateTime: Date,
        endDateTime: Date,
        createId: number,
        updateId: number = null,
        comment: string = '',
        viewed: boolean = false,
        creator: Creator = Creator.Customer,
        agentStatus: AppointmentStatus = AppointmentStatus.Pending,
        customerStatus: AppointmentStatus = AppointmentStatus.Confirmed,
        appointmentId: number = null,
        dateCreated: Date = null,
        dateUpdated: Date = null,
        isNewlyAdded: boolean = true,
    ) {
        this.id = appointmentId;
        this.listingIdHashCode = listingIdHashCode;
        this.startDateTime = new Date(startDateTime);
        this.endDateTime = new Date(endDateTime);
        this.comment = comment;
        this.viewed = viewed;
        this.creator = creator;
        this.agentStatus = agentStatus;
        this.customerStatus = customerStatus;
        this.createId = createId;
        this.updateId = updateId;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.isNewlyAdded = isNewlyAdded;
    }
}

export class CreateOperationListingAppointment extends ListingAppointment {
    public readonly operationId: number;

    constructor(
        listingIdHashCode: number,
        startDateTime: Date,
        endDateTime: Date,
        createId: number,
        comment: string = '',
        operationId: number = Math.floor(Math.random() * -99999),
        isNewlyAdded: boolean = true
    ) {
        super(listingIdHashCode, startDateTime, endDateTime, createId, null, comment, isNewlyAdded);
        this.operationId = operationId;
    }
}