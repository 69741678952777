export class CurrencyConstants {
    public static Codes = {
        USD: 'USD'
    };

    public static Symbols = {
        USD: '$'
    };

    public static Formats = {
        WithoutFraction: '1.0-0',
        TwoDigitsFraction: '1.2-2'
    };
}