import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
    selector: 'rpc-icon',
    templateUrl: './rpc-icon.component.html',
    styleUrls: ['./rpc-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpcIconComponent {

    @Input() public iconName: string;

    constructor(
        public readonly changeDetactorRef: ChangeDetectorRef
    ) { }

}