import { AbstractControl, ValidationErrors } from '@angular/forms';

export const urlValidator = (): (control: AbstractControl) => ValidationErrors | null => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        const controlValue = currentControl.value as string;
        if (controlValue == null || controlValue === '') {
            return null;
        }

        let url: URL;
        try {
            url = new URL(controlValue);
        } catch (_) {
            return { url: true };
        }

        if (url.protocol !== 'http:' && url.protocol !== 'https:') {
            return { url: true };
        }

        const domainNamePattern = /(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\.)+[a-z]{2,}/;

        if (!domainNamePattern.test(url.host)) {
            return { url: true };
        }

        return null;
    };
};