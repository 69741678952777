import { Pipe, PipeTransform } from '@angular/core';

import { SortOptionBase } from '@core-models/sort/sort-option';
import { SortCollectionHelper } from './sort-collection.helper';

@Pipe({ name: 'sortCollection' })
export class SortCollectionPipe<T> implements PipeTransform {
    transform(collection: T[], selectedSort: SortOptionBase): T[] {
        return SortCollectionHelper.sort(collection, selectedSort);
    }
}