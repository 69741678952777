<div class="rpc-notification-appointment" [class.rpc-notification-appointment--comment]="appointment.comment"
    [class.rpc-notification-appointment--footer]="appointment.statusDetails.isPendingCreatedByAgent || 
    (notificationAction !== NotificationEventAction.AppointmentCreated && !appointment.statusDetails.isPending)">

    <div class="rpc-notification-appointment-address" [title]="listingAddress" (click)="onAppointmentClick(false)">{{
        listingAddress }}</div>

    <div class="rpc-notification-appointment-date">

        <div>
            {{ appointment.startDateTime | timePeriodLabel : appointment.endDateTime }}
        </div>

        <mat-divider class="rpc-notification-appointment-date-divider" [vertical]="true"></mat-divider>

        <div>
            {{ appointment.startDateTime | date: DateConstants.Formats.AngularPipeShortDateSlash}}
        </div>

    </div>

    <div *ngIf="appointment.comment" class="rpc-notification-appointment-comment">{{appointment.comment}}</div>

    <div class="rpc-notification-appointment-footer" *ngIf="appointment.statusDetails.isPendingCreatedByAgent || 
        (notificationAction !== NotificationEventAction.AppointmentCreated && !appointment.statusDetails.isPending)">

        <ng-container *ngIf="appointment.statusDetails.isPendingCreatedByAgent; else statusTemplate">

            <rpc-button class="rpc-notification-appointment-btn rpc-notification-appointment-btn--accept"
                *ngIf="appointment.actionOptions.canAccept" [title]="'APPOINTMENT_NOTIFICATION.TITLES.ACCEPT'"
                [iconName]="'accept'" (clicked)="onAccept($event)">
            </rpc-button>

            <rpc-button class="rpc-notification-appointment-btn rpc-notification-appointment-btn--decline"
                *ngIf="appointment.actionOptions.canDecline" [title]="'APPOINTMENT_NOTIFICATION.TITLES.DECLINE'"
                [iconName]="'decline'" (clicked)="onDecline($event)">
            </rpc-button>

        </ng-container>

    </div>

    <ng-template #statusTemplate>

        <div *ngIf="notificationAction !== NotificationEventAction.AppointmentCreated &&
            (appointment.statusDetails.isConfirmed || appointment.statusDetails.isDeclined || appointment.statusDetails.isShown)" 
            class="rpc-notification-appointment-status" [class]="appointmentStatusAttributes.get(appointment.status).class">

            <rpc-icon class="rpc-notification-appointment-status-icon"
                [iconName]="appointmentStatusAttributes.get(appointment.status).icon">
            </rpc-icon>

            <div class="rpc-notification-appointment-status-text">
                {{appointmentStatusAttributes.get(appointment.status).title | translate}}</div>

        </div>

    </ng-template>

    <rpc-button class="rpc-notification-appointment-link" [title]="'APPOINTMENT_NOTIFICATION.TITLES.ALL_APPOINTMENTS'"
        [color]="'accent'" (clicked)="onAppointmentClick(true)">
    </rpc-button>

</div>