
import { ActivityListingCandidate } from '@listings/models/api/set-listing-activity-request';
import { Listings } from '@listings/models/listing/listings';

export class ListingActivityHelper {
    public static getActivityListingsCandidates(
        listingsIds: string[],
        allListings: Listings,
        clearActivities: boolean = false
    ): ActivityListingCandidate[] {
        const activityListingsCandidatesIds = [...new Set(listingsIds)];
        const activityListingsCandidates = activityListingsCandidatesIds.map(id => ({ ...allListings[id] }));

        if (clearActivities) {
            activityListingsCandidates.forEach(candidate => candidate.activities = []);
        }

        return activityListingsCandidates;
    }
}