import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { UserBaseInfo } from '@users/models/user-base-info';
import * as usersActions from '../actions/users.actions';

@Injectable({ providedIn: 'root' })
export class UsersApiService {
    constructor(
        private readonly http: ApiHttpClient
    ) { }

    public loadCustomers(): Observable<Action> {
        return this.http.get('profile/user-filter-accounts').pipe(
            map((customers: UserBaseInfo[]) => usersActions.loadCustomersSuccessful({ customers })),
            catchError((errorResponse: HttpErrorResponse) =>
                of(usersActions.loadCustomersFailed(errorResponse.error as ApiError)))
        );
    }
}