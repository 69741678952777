import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Apollo } from 'apollo-angular';

import * as notificationActions from '../actions/notifications.actions';
import { AppointmentDeletedEventInput, AppointmentEventInput, AppointmentUpdatedEventInput, CommentCreatedInput, CommentUpdatedInput, ExternalListingEventInput, FolderEventInput, ListingActivityInput, ListingsEventInput, NotificationsGraphQl_RemoveListingActivityInput } from 'app/graphql/graphql';
import * as appointmentNotificationGraphqlConstants from '@notifications/constants/appointment-notification-graphql.constants';
import * as commentNotificationGraphqlConstants from '@notifications/constants/comment-notification-graphql.constants';
import * as folderNotificationGraphqlConstants from '@notifications/constants/folder-notification-graphql.constants';
import * as listingNotificationGraphqlConstants from '@notifications/constants/listing-notification-graphql.constants';
import * as userNotificationGraphqlConstants from '@notifications/constants/user-notification-graphql.constants';

@Injectable({ providedIn: 'root' })
export class CreateNotificationApiService {
    constructor(private readonly apollo: Apollo) { }

    public createAppointmentCreatedNotification(input: AppointmentEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: appointmentNotificationGraphqlConstants.CREATE_APPOINTMENT_CREATED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createAppointmentConfirmedNotification(input: AppointmentEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: appointmentNotificationGraphqlConstants.CREATE_APPOINTMENT_CONFIRMED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createAppointmentDeclinedNotification(input: AppointmentEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: appointmentNotificationGraphqlConstants.CREATE_APPOINTMENT_DECLINED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createAppointmentUpdatedNotification(input: AppointmentUpdatedEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: appointmentNotificationGraphqlConstants.CREATE_APPOINTMENT_UPDATED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createAppointmentDeletedNotification(input: AppointmentDeletedEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: appointmentNotificationGraphqlConstants.CREATE_APPOINTMENT_DELETED_NOTIFICATION,
            variables: { input }
        }));
    }

    public createFolderAddedNotification(input: FolderEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: folderNotificationGraphqlConstants.CREATE_FOLDER_ADDED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createFolderUpdatedNotification(input: FolderEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: folderNotificationGraphqlConstants.CREATE_FOLDER_UPDATED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createFolderRemovedNotification(input: FolderEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: folderNotificationGraphqlConstants.CREATE_FOLDER_REMOVED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createFolderListingsChangedNotification(input: FolderEventInput) {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: folderNotificationGraphqlConstants.CREATE_FOLDER_LISTING_CHANGED_NOTIFICATION,
            variables: { input }
        }));
    }

    public createNewListingActivityNotification(input: ListingActivityInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: listingNotificationGraphqlConstants.CREATE_NEW_LISTING_ACTIVITY_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createListingActivityChangedNotification(input: ListingActivityInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: listingNotificationGraphqlConstants.CREATE_LISTING_ACTIVITY_CHANGED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createListingActivityRemovedNotification(input: NotificationsGraphQl_RemoveListingActivityInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: listingNotificationGraphqlConstants.CREATE_LISTING_ACTIVITY_REMOVED_NOTIFICATION,
            variables: { input }
        }));
    }

    public createExternalListingAddedNotification(input: ExternalListingEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: listingNotificationGraphqlConstants.CREATE_EXTERNAL_LISTING_ADDED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createListingHardDeletedNotification(input: ListingsEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: listingNotificationGraphqlConstants.CREATE_LISTING_HARD_DELETED_NOTIFICATION,
            variables: { input }
        }));
    }

    public createListingSoftDeletedNotification(input: ListingsEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: listingNotificationGraphqlConstants.CREATE_LISTING_SOFT_DELETED_NOTIFICATION,
            variables: { input }
        }));
    }

    public createListingRestoredNotification(input: ListingsEventInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: listingNotificationGraphqlConstants.CREATE_LISTING_RESTORED_NOTIFICATION,
            variables: { input }
        }));
    }

    public createCommentCreatedNotification(input: CommentCreatedInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: commentNotificationGraphqlConstants.CREATE_COMMENT_CREATED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createCommentUpdatedNotification(input: CommentUpdatedInput): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: commentNotificationGraphqlConstants.CREATE_COMMENT_UPDATED_NOTIFICATION,
            variables: { input: input }
        }));
    }

    public createUserDeactivatedNotification(userId: number): Observable<Action> {
        return this.commonRequestHandler(this.apollo.mutate({
            mutation: userNotificationGraphqlConstants.CREATE_USER_DEACTIVATED_NOTIFICATION,
            variables: { userId }
        }));
    }

    private commonRequestHandler<T>(request: Observable<T>): Observable<Action> {
        return request.pipe(
            map(() => notificationActions.notificationCreationSuccess()),
            catchError(() => of(notificationActions.notificationCreationFailure()))
        );
    }
}