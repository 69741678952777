export const locale = {
    lang: 'en',
    data: {
        EMAIL_ALERT_SETTINGS: {
            LISTINGS: {
                TITLE: 'Listings',
                ACTIVITIES: 'Activities',
                ADDED: 'Added',
                LIKED: 'Liked',
                DISLIKED: 'Disliked',
                REMOVED: 'Removed',
                MESSAGES: 'Messages',
            },
            APPOINTMENTS: {
                TITLE: 'Appointments',
                CONFIRMATIONS: 'Confirmations',
                REQUESTS: 'Requests',
                DECLINES: 'Declines'
            },
            OPTIONS: {
                DAILY: 'Daily (at 6pm EST)',
                REAL_TIME: 'Real Time',
                DISABLED: 'Disabled'
            }
        }
    }
};