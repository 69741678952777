export const locale = {
    lang: 'en',
    data: {
        NEW_MATCH_TOOLTIP: {
            NEW_MATCH: 'New Match',
            DATE: 'Date:',
            SAVED_SEARCH: 'Saved Search:',
            AGENTS_SAVED_SEARCH: '{{ownerFullName}} Saved Search',
            FOLDER: 'Folder:',
        }
    }
};