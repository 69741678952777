export const PROFILE_TABS_NAMES = {
    GENERAL: 'general',
    SETTINGS: 'settings',
    ADDITIONAL_PROFILES: 'additional-profiles'
};

export const PROFILE_TABS_INDICES = {
    GENERAL_TAB_INDEX: 0,
    SETTINGS_TAB_INDEX: 1,
    ADDITIONAL_PROFILES_TAB_INDEX: 2
};

export const PROFILE_TABS = new Map<string, number>([
    [PROFILE_TABS_NAMES.GENERAL, PROFILE_TABS_INDICES.GENERAL_TAB_INDEX],
    [PROFILE_TABS_NAMES.SETTINGS, PROFILE_TABS_INDICES.SETTINGS_TAB_INDEX],
    [PROFILE_TABS_NAMES.ADDITIONAL_PROFILES, PROFILE_TABS_INDICES.ADDITIONAL_PROFILES_TAB_INDEX],
]);