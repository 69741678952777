import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AgentsState } from '../states/agents.state';

const agentsStateSelector = createFeatureSelector<AgentsState>('agents');

export const selectAgents = createSelector(
    agentsStateSelector,
    state => state?.agents ?? []
);

export const selectAgentIds = createSelector(
    agentsStateSelector,
    state => state?.agents?.map(agent => agent.agentId) ?? []
);

export const selectAgentsCount = createSelector(
    agentsStateSelector,
    state => state?.agents?.length ?? 0
);