<div class="rpc-date-wrap" [ngClass]="{'rpc-date-wrap--hidden' : hideInput}">
    <mat-form-field class="rpc-datepicker field-{{appearance}}" [appearance]="appearance"
        (click)="$event.stopPropagation();">

        <mat-label *ngIf="!hideInput && label != null && label != ''">
            {{ label | translate}}
        </mat-label>

        <input matInput *ngIf="options == null" [matDatepicker]="picker" [hidden]="hideInput" [min]="minDate"
            [max]="maxDate" (click)="openPicker()" (dateChange)="onDateChanged($event)" autocomplete="off" readonly>

        <input matInput *ngIf="options != null" name="{{options.controlName}}" [formControl]="formControl"
            [matDatepicker]="picker" [hidden]="hideInput" [min]="minDate" [max]="maxDate"
            [matDatepickerFilter]="options.excludeFilter" (click)="openPicker()" (dateChange)="onDateChanged($event)"
            autocomplete="off" readonly>

        <rpc-icon class="rpc-datepicker-icon--clear"
            *ngIf="formControl != null && formControl.value != null && formControl.value !== '' && !hideInput"
            (click)="clearDate()" iconName="cross">
        </rpc-icon>

        <mat-datepicker-toggle matSuffix [for]="picker">
            <rpc-icon class="rpc-datepicker-icon" matDatepickerToggleIcon
                iconName="{{iconName != null && iconName != '' ? iconName : 'current-day'}}">
            </rpc-icon>
        </mat-datepicker-toggle>

        <mat-datepicker #picker [type]="options.type" [startView]="options.startView" [clockStep]="options.clockStep"
            [twelveHour]="options.twelveHour" [color]="color" [dateClass]="dateClass"></mat-datepicker>
    </mat-form-field>
</div>