import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdditionalProfileGuard } from '@auth/guards/additional-profile.guard';
import { AgentSelectionGuard } from '@auth/guards/agent-selection.guard';
import { AuthorizedOnlyGuard } from '@auth/guards/authorized-only.guard';
import { ListingAuthorizedGuard } from '@auth/guards/listing-authorized.guard';
import { ListingGuard } from '@auth/guards/listing.guard';
import { MyListingsGuard } from '@auth/guards/my-listings.guard';
import { NotAuthorizedOnlyGuard } from '@auth/guards/not-authorized-only.guard';
import { ProfilesGuard } from '@auth/guards/profiles.guard';
import { SearchGuard } from '@auth/guards/search.guard';
import { mortgageReportMatcher } from '@core-matchers/mortgage-report.matcher';
import { sharedListingDetailsMatcher } from '@core-matchers/shared-listing.matcher';
import { DeprecatedRpcRoute } from './models/deprecated-rpc-route';
import { RpcRoute } from './models/rpc-route';

const routes: Routes = [
    {
        path: RpcRoute.Portfolio,
        loadChildren: () => import('@my-listings/my-listings.module').then(m => m.MyListingsModule),
        canActivateChild: [AuthorizedOnlyGuard, MyListingsGuard]
    },
    {
        path: RpcRoute.SearchListings,
        loadChildren: () => import('@on-market/on-market.module').then(m => m.OnMarketModule),
        canActivateChild: [AuthorizedOnlyGuard, SearchGuard]
    },
    {
        path: RpcRoute.Appointments,
        loadChildren: () => import('@my-appointments/my-appointments.module').then(m => m.MyAppointmentsModule),
        canActivateChild: [AuthorizedOnlyGuard]
    },
    {
        path: RpcRoute.Listing,
        loadChildren: () => import('@internal-listing-details/internal-listing-details.module').then(m => m.InternalListingDetailsModule),
        canActivateChild: [ListingAuthorizedGuard, ListingGuard]
    },
    {
        matcher: sharedListingDetailsMatcher,
        loadChildren: () => import('@shared-listing-details/shared-listing-details.module').then(m => m.SharedListingDetailsModule)
    },
    {
        path: RpcRoute.Profile,
        loadChildren: () => import('@profile/profile.module').then(m => m.ProfileModule),
        canActivateChild: [AuthorizedOnlyGuard, ProfilesGuard]
    },
    {
        path: RpcRoute.NewMatch,
        loadChildren: () => import('@listing-id-encrypting/listing-id-encrypting.module').then(m => m.ListingIdEncryptingModule)
    },
    {
        path: RpcRoute.AdditionalProfile,
        loadChildren: () => import('@additional-profile/additional-profile.module').then(m => m.AdditionalProfileModule),
        canActivateChild: [AuthorizedOnlyGuard, AdditionalProfileGuard]
    },
    {
        path: RpcRoute.AgentSelection,
        loadChildren: () => import('@agent-selection/agent-selection.module').then(m => m.AgentSelectionModule),
        canActivateChild: [NotAuthorizedOnlyGuard, AgentSelectionGuard]
    },
    {
        path: RpcRoute.KnowledgeBase,
        loadChildren: () => import('@knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule),
        canActivateChild: [AuthorizedOnlyGuard]
    },
    {
        path: RpcRoute.NotFound,
        loadChildren: () => import('@error/error.module').then(m => m.ErrorModule)
    },
    {
        loadChildren: () => import('@mortgage-report/mortgage-report.module').then(m => m.MortgageReportModule),
        matcher: mortgageReportMatcher,
    },
    { path: DeprecatedRpcRoute.OnMarket, redirectTo: RpcRoute.SearchListings, pathMatch: 'full' },
    { path: DeprecatedRpcRoute.Dashboard, redirectTo: RpcRoute.Portfolio, pathMatch: 'full' },
    { path: DeprecatedRpcRoute.MyListings, redirectTo: RpcRoute.Portfolio, pathMatch: 'full' },
    { path: DeprecatedRpcRoute.MyPortfolio, redirectTo: RpcRoute.Portfolio, pathMatch: 'full' },
    { path: DeprecatedRpcRoute.MyAppointments, redirectTo: RpcRoute.Appointments, pathMatch: 'full' },
    { path: '', redirectTo: RpcRoute.Login, pathMatch: 'full' },
    { path: '**', redirectTo: RpcRoute.NotFound, pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
