import { UrlSegment } from '@angular/router';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { restoreSlashedUrlCode } from './utils/restore-slashed-url-code';

export const registrationMatcher = (url: UrlSegment[]) => {
    const [, ...segments] = url;
    if (url[0]?.path === RpcRoute.Registration) {
        return { consumed: url, posParams: { code: restoreSlashedUrlCode(segments) } };
    }

    return null;
};