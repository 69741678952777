import { CreateOperationListingComment } from '@comments/models/comments/listing-comment';
import { CommonListing } from '@listings/models/listing/common-listing';

export class CommentCreateActionModel {
    public readonly listing: CommonListing;
    public readonly listingAddress: string;
    public readonly createOperationListingComment: CreateOperationListingComment;
    public readonly isNewMatch: boolean;

    constructor(
        listing: CommonListing,
        listingAddress: string,
        createOperationListingComment: CreateOperationListingComment,
        isNewMatch: boolean = false
    ) {
        this.listing = listing;
        this.listingAddress = listingAddress;
        this.createOperationListingComment = createOperationListingComment;
        this.isNewMatch = isNewMatch;
    }
}