export const locale = {
    lang: 'en',
    data: {
        PHONES_EDIT_FORM: {
            TITLES: {
                PHONE: 'Phone',
                DELETE: 'Delete',
                TYPE: 'Type',
                ADD_NEW: 'Add New',
                OFFICE: 'Office',
                MOBILE: 'Mobile',
                HOME: 'Home',
                OTHER: 'Other'
            },
            ERRORS: {
                CLIENT: {
                    PHONE_TYPE_REQUIRED: 'Phone type is required'
                }
            }
        }
    }
};