import { gql } from "app/graphql";

export const CREATE_APPOINTMENT_CREATED_NOTIFICATION = gql(`
    mutation CreateAppointmentCreatedNotification($input: AppointmentEventInput!) {
        notifications {
            events {
                appointmentCreated (input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_APPOINTMENT_CONFIRMED_NOTIFICATION = gql(`
    mutation CreateAppointmentConfirmedNotification($input: AppointmentEventInput!) {
        notifications {
            events {
                appointmentAccepted (input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_APPOINTMENT_DECLINED_NOTIFICATION = gql(`
    mutation CreateAppointmentDeclinedNotification($input: AppointmentEventInput!) {
        notifications {
            events {
                appointmentDeclined (input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_APPOINTMENT_UPDATED_NOTIFICATION = gql(`
    mutation CreateAppointmentUpdatedNotification($input: AppointmentUpdatedEventInput!) {
        notifications {
            events {
                appointmentUpdated (input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_APPOINTMENT_DELETED_NOTIFICATION = gql(`
    mutation CreateAppointmentDeletedNotification($input: AppointmentDeletedEventInput!) {
        notifications {
            events {
                appointmentDeleted(input: $input) {
                    id
                }
            }
        }
    }
`);