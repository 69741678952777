export class ScrollConstants {
    private static readonly MinScrollElementsNumber = 30;
    private static readonly MediumScrollElementsNumber = 60;

    public static ScrollTime = {
        MinScrollTime: 1500,
        MediumScrollTime: 2500,
        MaxScrollTime: 5000
    };

    public static ScrollToTopClasses = {
        Target: 'scroll-to-top-target',
        Container: 'scroll-to-top-container'
    };

    public static ScrollToTopVisibilityOffset = 300;

    public static getDefaultColletionScrollTime(index: number): number {
        return index < ScrollConstants.MinScrollElementsNumber
            ? ScrollConstants.ScrollTime.MinScrollTime
            : index < ScrollConstants.MediumScrollElementsNumber
                ? ScrollConstants.ScrollTime.MediumScrollTime
                : ScrollConstants.ScrollTime.MaxScrollTime;
    }
}