import { createReducer, on } from '@ngrx/store';

import { CommonListing } from '@listings/models/listing/common-listing';
import { ListingsMedia } from '@listings/models/listing/listings-media';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingsState } from '@listings/store/states/listings.state';
import { FormattedMedia } from '@media/models/formatted-media';
import { ListingViewHelper } from './helpers/listing-view.helper';
import { ListingsReducerHelpers } from './helpers/listings.reducer.helper';
import { RemoveRestoreListingHelper } from './helpers/remove-restore-listing.helper';
import { listingActivityReducerTypes } from './listing-activity.reducer-types';
import { listingSelectionReducerTypes } from './listing-selection.reducer-types';
import { marketListingReducerTypes } from './market-listing.reducer-types';
import { Listings } from '@listings/models/listing/listings';

const initialState = new ListingsState();
export const listingsReducer = createReducer(
    initialState,
    on(listingActions.loadListing, (state): ListingsState => ({ ...state, isListingLoading: true })),
    on(listingActions.loadListingSuccess, (state, action): ListingsState => {
        const exsistingListings = { ...state.listings };

        const matchingListing = Object.values(exsistingListings)
            .find((exsistingListing: CommonListing) => exsistingListing.hashCode === action.listing.hashCode);

        if (matchingListing != null) {
            action.listing.id = matchingListing.id;
        }
        exsistingListings[action.listingId] = action.listing;

        return {
            ...state,
            listings: exsistingListings,
            isListingLoading: false
        };
    }),
    on(listingActions.loadListingFailed, (state): ListingsState => ({ ...state, isListingLoading: false })),
    on(listingActions.loadCustomerListings, (state, { shouldSetLoading }): ListingsState => {
        return { ...state, customerListingsLoaded: false, isListingsLoading: shouldSetLoading };
    }),
    on(listingActions.loadCustomerListingsSuccess, (state, action): ListingsState => {
        const listings = Object.values(action.listings)
            .map(listing => ({
                ...listing
            }))
            .reduce((acc, curr) => (acc[curr.id] = curr, acc), {});

        return {
            ...state,
            listings: ListingsReducerHelpers.updateListings({ ...state.listings }, listings, true),
            customerListingsLoaded: true,
            isListingsLoading: false
        };
    }),
    on(listingActions.loadCustomerListingsFailed, (state, action): ListingsState => {
        return { ...state, customerListingsLoaded: false, isListingsLoading: false };
    }),
    on(listingActions.loadListingsMedia, (state, action): ListingsState => {
        return { ...state, mediaLoaded: false };
    }),
    on(listingActions.cleanupListingsMedia, (state): ListingsState => {
        if (state.media == null) {
            return { ...state };
        }

        const newMedia: ListingsMedia = {};
        Object.keys(state.media)
            .filter(mediaKey => state.listings[mediaKey] != null)
            .reduce((acc, key) => (acc[key] = state.media[key], acc), newMedia);

        return { ...state, media: newMedia };
    }),
    on(listingActions.loadListingsMediaSuccess, (state, action): ListingsState => {
        const newMedia = { ...state.media };
        Object.keys(action.media).forEach(key => {
            const media = action.media[key];
            if (media == null) {
                newMedia[key] = new FormattedMedia(null, null, null);
                return;
            }
            newMedia[key] = new FormattedMedia(media.originalImages, media.videos, media.tours);
        });

        return {
            ...state,
            media: newMedia,
            mediaLoaded: true
        };
    }),
    on(listingActions.markAsViewed, (state, { listingHashCode }) => {
        return { ...state, listings: ListingViewHelper.setIsViewed(state.listings, listingHashCode, true) };
    }),
    on(listingActions.markAsViewedFailed, (state, { listingHashCode }) => {
        return { ...state, listings: ListingViewHelper.setIsViewed(state.listings, listingHashCode, false) };
    }),
    on(listingActions.resetState, () => ({ ...initialState })),
    on(listingActions.reloadListingsByIds, (state) => ({ ...state, isListingsLoading: true })),
    on(listingActions.reloadListingsByIdsSuccess, (state, { loadedListings, listingsToDeleteIds }) => {
        let listingsUpdated = new Set<number>();

        const existingListings = Object.entries({ ...state.listings }).reduce(
            (acc, [key, listing]) => {
                const updatedListing = loadedListings.find(x => x.hashCode === listing.hashCode);

                if (updatedListing != null) {
                    listingsUpdated = listingsUpdated.add(updatedListing.hashCode);
                }
                // keep previous id to match listing on details page and prevent infinity requests
                return { ...acc, [key]: updatedListing == null ? listing : { ...updatedListing, id: key, isMarketListing: listing.isMarketListing } };
            },
            {} as Listings
        );

        for (const listingId of listingsToDeleteIds) {
            delete existingListings[listingId];
        }

        for (const listing of loadedListings) {
            if (!listingsUpdated.has(listing.hashCode)) {
                existingListings[listing.id] = listing;
            }
        }

        return {
            ...state,
            listings: existingListings,
            isListingsLoading: false
        };
    }),
    on(listingActions.reloadListingsByIdsFailed, (state) => ({ ...state, isListingsLoading: false })),
    on(listingActions.softDelete, (state, { listingIds }) => {
        const listings = RemoveRestoreListingHelper.softDelete(state.listings, listingIds);
        return {
            ...state,
            selectedListingIds: [],
            listings,
        };
    }
    ),
    on(listingActions.softDeleteFailed, (state, { listings }) => ({ ...state, listings })),
    on(listingActions.restore, (state, { listingIds }) => {
        const restoreResult = RemoveRestoreListingHelper.restore(state.listings, listingIds);
        return {
            ...state,
            listings: restoreResult,
            selectedListingIds: []
        };
    }),
    on(listingActions.restoreFailed, (state, { listings }) => ({ ...state, listings })),
    on(listingActions.hardDelete, (state, { listingIds }) =>
        ({ ...state, selectedListingIds: [], listings: RemoveRestoreListingHelper.hardDelete(state.listings, listingIds) })
    ),
    on(listingActions.hardDeleteFailed, (state, { listings }) => ({ ...state, listings })),
    ...marketListingReducerTypes,
    ...listingActivityReducerTypes,
    ...listingSelectionReducerTypes
);