import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomSpinnerComponent } from '@core-layout/custom-spinner/custom-spinner.component';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';

@NgModule({
    declarations: [
        CustomSpinnerComponent
    ],
    imports: [
        // Library Modules
        CommonModule,

        // Application Modules
        RpcIconModule
    ],
    exports: [
        CustomSpinnerComponent
    ]
})
export class CustomSpinnerModule { }