import { gql } from "app/graphql";


export const CREATE_USER_DEACTIVATED_NOTIFICATION = gql(`
    mutation CreateUserDeactivatedNotification($userId: Int!){
        notifications {
            events {
                userDeactivated(userId: $userId) {
                    success
                }
            }
        }
    }
`);
