export const locale = {
    lang: 'en',
    data: {
        NOTIFICATION_ITEM: {
            TITLES: {
                COMMENT_ADDED: '{{userFullName}} **added** a message to {{listingAddress}}.',
                COMMENT_UPDATED: '{{userFullName}} **updated** a message to {{listingAddress}}.',
                NEW_APPOINTMENT: '{{userFullName}} **requested** a new appointment.',
                APPOINTMENT_UPDATED: '{{userFullName}} **modified** this appointment.',
                APPOINTMENT_CONFIRMED: '{{userFullName}} **confirmed** this appointment.',
                APPOINTMENT_DECLINED: '{{userFullName}} **declined** this appointment.',
                EXTERNAL_LISTING_ADDED: '{{userFullName}} **added** an external listing to the portfolio.',
                LISTING_ADDED: '{{userFullName}} **added** a listing to the portfolio.',
                LISTING_LIKED: 'A listing in the portfolio was **liked** by {{userFullName}}.',
                LISTING_DISLIKED: 'A listing in the portfolio was **disliked** by {{userFullName}}.',
                LISTING_SHOWN: 'A listing in the portfolio was **shown** by {{userFullName}}.',
                MARK_AS_READ: 'Mark as Read'
            }
        }
    }
};