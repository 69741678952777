import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { AppointmentService } from '@appointments/services/appointment.service';
import { AppointmentStatusService } from '@appointments/store/services/appointment-status.service';

export class ShareEventBase {
    public title: string;
    public description: string;
    public location: string;
    public start: Date;
    public end: Date;

    constructor(location: string, appointment: ListingAppointment) {
        this.title = AppointmentStatusService.isPendingAppointment(appointment)
            ? 'Tentative: Viewing Appointment'
            : 'Viewing Appointment';
        this.description = appointment.comment;
        this.location = location;
        this.start = appointment.startDateTime;
        this.end = appointment.endDateTime;
    }
}