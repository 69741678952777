import { Pipe, PipeTransform } from '@angular/core';

import { IFilterOptions } from '@core-models/filter/filter-options-interface';

@Pipe({ name: 'filterCollection' })
export class FilterCollectionPipe<TCollection, TArgs> implements PipeTransform {
    transform(collection: TCollection[], selectedFilter: IFilterOptions<TCollection, TArgs>, args: TArgs = null): TCollection[] {
        if (collection == null) {
            return [];
        }
        return selectedFilter.filter(collection, args);
    }
}