<div class="activity-controls-wrapper" [ngClass]="{'activity-controls-wrapper--base' : baseColor}">
    <rpc-icon-button [title]="getActivityTitle(listingActivities.Liked)" iconName="like"
        class="activity-controls-item liked" [actionClass]="'active'"
        [actionTrigger]="calculatedActivities != null && calculatedActivities.isLiked" [disabled]="disabled"
        [enableCustomBackground]="enableCustomBackground" (clicked)="onLikedClicked()">
    </rpc-icon-button>
    <rpc-icon-button [title]="getActivityTitle(listingActivities.Shown)" iconName="eye"
        class="activity-controls-item shown" [actionClass]="'active'"
        [actionTrigger]="calculatedActivities != null && calculatedActivities.isShown" [disabled]="disabled"
        [enableCustomBackground]="enableCustomBackground" (clicked)="onShownClicked()">
    </rpc-icon-button>
    <rpc-icon-button [title]="getActivityTitle(listingActivities.Disliked)" iconName="dislike"
        class="activity-controls-item disliked" [actionClass]="'active'"
        [actionTrigger]="calculatedActivities != null && calculatedActivities.isDisliked" [disabled]="disabled"
        [enableCustomBackground]="enableCustomBackground" (clicked)="onDislikedClicked()">
    </rpc-icon-button>
    <ng-container *ngTemplateOutlet="extraActions"></ng-container>
</div>