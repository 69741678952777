import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ValidationService } from '@core-validation/validation.service';
import { IInviteUserModel } from '../../invite-user/invite-user-model';
import { MAXIMUM_MESSAGE_LENGTH } from './constants/invite-user-form.constants';
import { emailAvailableForInvitation } from './validators/email-available-for-invitation.validator';

@Component({
    selector: 'invite-user-form',
    templateUrl: './invite-user-form.component.html',
    styleUrls: ['./invite-user-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteUserFormComponent implements OnChanges {

    @Input() public email?: string;

    @Input() public portfolioEmails: string[] = [];

    @Output() public readonly sendEmailClicked = new EventEmitter<IInviteUserModel>();
    @Output() public readonly canceled = new EventEmitter<void>();

    public readonly maximumMessageLength = MAXIMUM_MESSAGE_LENGTH;
    public readonly inviteUserFormGroup: FormGroup = new FormGroup({});
    public recepientEmailControlOptions: RpcInputOptions;

    constructor(
        private readonly validationService: ValidationService,
    ) { }

    public ngOnChanges(changes: SimpleChanges<InviteUserFormComponent>): void {
        const { email, portfolioEmails } = changes;

        if (portfolioEmails.currentValue != null) {
            this.initializeForm(portfolioEmails.currentValue, email.currentValue);
        }
    }

    public get recepientEmail(): string {
        return this.inviteUserFormGroup.get('recepientEmail')?.value as string;
    }

    public get message(): string {
        return this.inviteUserFormGroup.get('message')?.value as string;
    }

    public onSendEmailClicked(): void {
        if (this.inviteUserFormGroup.invalid) {
            return;
        }
        this.sendEmailClicked.emit({
            message: this.message,
            recepientEmail: this.recepientEmail
        });
    }

    public cancel(): void {
        this.canceled.emit();
    }

    private initializeForm(portfolioEmails: string[], email: string): void {
        this.recepientEmailControlOptions = {
            controlName: 'recepientEmail',
            formGroup: this.inviteUserFormGroup,
            defaultValue: email,
            validatorsMap: [
                {
                    message: 'INVITE_USER_FORM.ERRORS.EMAIL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'INVITE_USER_FORM.ERRORS.WRONG_EMAIL',
                    showError: (control: FormControl) => control.hasError('email'),
                    validator: this.validationService.emailWithDotValidator
                },
                {
                    message: 'PROFILE_COMMON.ERRORS.EMAIL_USED_BY_SAME_COMPANY_CUSTOMER',
                    showError: (control: FormControl) => control.hasError('unavailable'),
                    validator: emailAvailableForInvitation(portfolioEmails.filter(x => x !== email))
                }
            ]
        };

        const messageFormControl = new FormControl('', [Validators.maxLength(this.maximumMessageLength)]);
        this.inviteUserFormGroup.addControl('message', messageFormControl);
    }
}
