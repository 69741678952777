import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({ name: 'bypassSecurityTrust' })
export class BypassSecurityTrustPipe implements PipeTransform {

    constructor(private readonly sanitizer: DomSanitizer) { }

    transform(value: string, context: 'html' | 'style' | 'script' | 'url' | 'resourceUrl'): string {
        const bypassSecurityActionMap = new Map<string, (val: string) => SafeUrl>([
            ['html', this.sanitizer.bypassSecurityTrustHtml],
            ['style', this.sanitizer.bypassSecurityTrustStyle],
            ['script', this.sanitizer.bypassSecurityTrustScript],
            ['url', this.sanitizer.bypassSecurityTrustUrl],
            ['resourceUrl', this.sanitizer.bypassSecurityTrustResourceUrl],
        ]);

        return bypassSecurityActionMap.get(context)(value) as string;
    }
}
