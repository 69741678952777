import { createAction, props } from '@ngrx/store';

import { Credential } from '@auth/models/credential';
import { EncryptedCredential } from '@auth/models/encrypted-credential';
import { EncryptedExactCustomerCredential } from '@auth/models/encrypted-exact-customer-credential';
import { LoginData } from '@auth/models/login-data';
import { User } from '@auth/models/user';
import { ApiError } from '@error/models/api-error';

export const login = createAction(
    '[Auth] Login', props<{ credential: EncryptedExactCustomerCredential }>());
export const tryLogin = createAction(
    '[Auth] Try Login', props<{ credential: Credential }>());
export const loginSuccessful = createAction(
    '[Auth] Login Successful', (user: User, duringLoginSync: boolean = false) => ({ user, duringLoginSync }));
export const loginFailed = createAction(
    '[Auth] Login Failed', (error: ApiError) => error);

export const loginWithinAgentsSelection = createAction(
    '[Auth] Login Within Agents Selection', (credentials: EncryptedCredential) => ({ credentials: credentials }));
export const setLoginProcessData = createAction(
    '[Auth] Set Login Process Data', props<{ credentials: EncryptedCredential }>());
export const removeLoginProcessData = createAction(
    '[Auth] Remove Login Process Data');

export const refresh = createAction(
    '[Auth] Refresh');
export const refreshSuccessful = createAction(
    '[Auth] Refresh Successful', (user: User) => user);
export const refreshFailed = createAction(
    '[Auth] Refresh Failed', (error: ApiError) => error);

export const logout = createAction(
    '[Auth] Logout', (forced: boolean = true, duringLoginSync: boolean = false, redirect: boolean = true) => ({ forced, duringLoginSync, redirect }));
export const logoutSuccessful = createAction(
    '[Auth] Logout Successful', (forced: boolean = true, duringLoginSync: boolean = false) => ({ forced, duringLoginSync }));
export const logoutFailed = createAction(
    '[Auth] Logout Failed', props<{ forced: boolean }>());

export const collaborationSpaceLogin = createAction('[Auth] Collaboration Space Login', props<{ response: LoginData }>());

export const collaborationSpaceLoginSuccessful = createAction(
    '[Auth] Collaboration Space Login Successful',
    props<{ user: User, duringLoginSync: boolean }>()
);
