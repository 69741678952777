import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'custom-spinner',
    templateUrl: './custom-spinner.component.html',
    styleUrls: ['./custom-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSpinnerComponent {
    @Input() hasBackdrop = true;
    @Input() showImage = true;
}