import { Directive, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RouteService } from '@core-layout/app/services/route.service';
import { ScrollConstants } from '@core-layout/scroll-to-top/scroll.constants';
import { ScrollService } from '@core-layout/scroll-to-top/scroll.service';

@Directive({ selector: '[scrollToTopContainer]' })
export class ScrollToTopContainerDirective implements OnInit, OnDestroy {

    @Input('scrollToTopOffset') scrollToTopOffset: number = ScrollConstants.ScrollToTopVisibilityOffset;
    @HostBinding(`class.${ScrollConstants.ScrollToTopClasses.Container}`) hostClass = true;

    private readonly unsubscribe$ = new Subject<void>();

    constructor(
        private readonly scrollService: ScrollService,
        private readonly routeService: RouteService
    ) { }

    public ngOnInit(): void {
        this.initialize();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    @HostListener('scroll', ['$event.target'])
    public onScroll(container: HTMLElement): void {
        const isScrollToTopVisible = container.scrollTop > this.scrollToTopOffset;
        this.scrollService.scrollToTopVisibility$.next(isScrollToTopVisible);
    }

    private initialize(): void {
        this.routeService.getNavigateEndCurrentRoute()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                setTimeout(() => {
                    this.scrollService.scrollToTop();
                    this.scrollService.scrollToTopVisibility$.next(false);
                });
            });
    }
}