import { gql } from "app/graphql";

export const CREATE_COMMENT_CREATED_NOTIFICATION = gql(`
    mutation CommentCreatedNotification($input: CommentCreatedInput!) {
        notifications {
            events {
                commentCreated (input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_COMMENT_UPDATED_NOTIFICATION = gql(`
    mutation CommentUpdatedNotification($input: CommentUpdatedInput!) {
        notifications {
            events {
                commentUpdated (input: $input) {
                    id
                }
            }
        }
    }
`);