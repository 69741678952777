export const locale = {
    lang: 'en',
    data: {
        PRC_FILE_UPLOAD: {
            VALIDATION: {
                WRONG_FILE_FORMAT: 'Supported file formats',
                MAX_SIZE: 'Max supported file size is'
            },
            ERRORS: {
                FILE_UPLOAD_ERROR: 'An error occured during file upload',
                FILE_FETCH_ERROR: 'Sorry, we could not upload this file. Try saving it, and upload it manually.'
            },
            BUTTONS: {
                DELETE: 'Delete'
            }
        }
    }
};