import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { RpcSelectControltOptions } from '@core-controls/components/rpc-select/models/rpc-select-controlt-options';
import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';
import { COUNTRIES } from '../../constants/countries.constants';
import { STATES } from '../../constants/states.constants';
import { Address } from '../../interfaces/customer-info/address';
import { AddressTypes } from '../../interfaces/enums/address-types.enum';

@Component({
    selector: 'addresses-edit-form',
    templateUrl: './addresses-edit-form.component.html',
    styleUrls: ['../profile-info-base/profile-info-base.component.scss', './addresses-edit-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddressesEditFormComponent implements AfterViewInit {

    @Input() parentGroup: FormGroup;
    @Input() controlName: string;

    @Input()
    public set addresses(addresses: Address[]) {
        this.initializeAddressControls(addresses);
    }

    private readonly addressSelectOptionValues: RpcSelectOption[] = [
        { title: 'PROFILE_COMMON.ENUM_TYPES.office', value: AddressTypes.office, iconName: 'user' },
        { title: 'PROFILE_COMMON.ENUM_TYPES.work', value: AddressTypes.work, iconName: 'user' },
        { title: 'PROFILE_COMMON.ENUM_TYPES.home', value: AddressTypes.home, iconName: 'user' },
        { title: 'PROFILE_COMMON.ENUM_TYPES.other', value: AddressTypes.other, iconName: 'user' }
    ];

    private addressArray: FormArray;
    private tempAddressId = -1;

    public readonly defaultCountryCode = 'US';

    public addressControls: {
        id: number,
        selectTypeOptions: RpcSelectControltOptions,
        line1InputOptions: RpcInputOptions,
        line2InputOptions: RpcInputOptions,
        cityInputOptions: RpcInputOptions,
        selectStateOptions: RpcSelectControltOptions,
        zipCodeInputOptions: RpcInputOptions,
        selectCountryOptions: RpcSelectControltOptions
    }[];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) { }

    public ngAfterViewInit(): void {
        this.parentGroup.addControl(this.controlName, this.addressArray);
    }

    public onCountryChanged(event: RpcSelectOption, index: number): void {
        if (event.value !== this.defaultCountryCode) {
            this.addressArray.controls[index].get('state').setValue(null);
        }
        this.addressControls[index].selectCountryOptions.selectedValue = event.value;
    }

    public onAdd(): void {
        this.createAddressControl(this.tempAddressId);
        this.tempAddressId -= 1;
        this.changeDetectorRef.detectChanges();
    }

    public onRemove(i: number): void {
        this.addressControls.splice(i, 1);
        this.addressArray.removeAt(i);
    }

    private initializeAddressControls(addresses: Address[]): void {
        this.addressControls = [];
        this.addressArray = this.formBuilder.array([]);

        addresses.forEach(address => this.createAddressControl(address.id, address));
    }

    private createAddressControl(id: number, address: Address = null): void {
        const currentGroup = this.formBuilder.group({ id });

        this.addressControls.push({
            id: id,
            selectTypeOptions: this.getSeletTypeOptions(currentGroup, address?.type),
            line1InputOptions: this.getLine1InputOptions(currentGroup, address?.line1),
            line2InputOptions: new RpcInputOptions(currentGroup, 'line2', address?.line2),
            cityInputOptions: this.getCityInputOptions(currentGroup, address?.city),
            selectStateOptions: this.getSelectStateOptions(currentGroup, address?.state),
            zipCodeInputOptions: this.getZipCodeInputOptions(currentGroup, address?.zipCode),
            selectCountryOptions: new RpcSelectControltOptions(currentGroup, 'country', COUNTRIES, address?.country ?? this.defaultCountryCode)
        });

        this.addressArray.push(currentGroup);
    }

    private getSeletTypeOptions(group: FormGroup, selectedType: AddressTypes = null): RpcSelectControltOptions {
        const validatorsMap = [{
            message: 'ADDRESS_EDIT_FORM.ERRORS.CLIENT.ADDRESS_TYPE_REQUIRED',
            showError: (control: FormControl) => control.hasError('required'),
            validator: Validators.required
        }];
        return new RpcSelectControltOptions(group, 'type', this.addressSelectOptionValues, selectedType, validatorsMap);
    }

    private getLine1InputOptions(group: FormGroup, selectedValue: string = null): RpcInputOptions {
        const validatorsMap = [
            {
                message: 'ADDRESS_EDIT_FORM.ERRORS.CLIENT.ADDRESS_LINE_1_REQUIRED',
                showError: (control: FormControl) => control.hasError('required'),
                validator: Validators.required
            }
        ];
        return new RpcInputOptions(group, 'line1', selectedValue, validatorsMap);
    }

    private getCityInputOptions(group: FormGroup, selectedValue: string = null): RpcInputOptions {
        const validatorsMap = [
            {
                message: 'ADDRESS_EDIT_FORM.ERRORS.CLIENT.CITY_REQUIRED',
                showError: (control: FormControl) => control.hasError('required'),
                validator: Validators.required
            }
        ];
        return new RpcInputOptions(group, 'city', selectedValue, validatorsMap);
    }

    private getSelectStateOptions(group: FormGroup, selectedValue: string = null): RpcSelectControltOptions {
        const validatorsMap = [
            {
                message: 'ADDRESS_EDIT_FORM.ERRORS.CLIENT.STATE_REQUIRED',
                showError: (control: FormControl) => control.hasError('required'),
                validator: Validators.required
            },
            {
                message: 'ADDRESS_EDIT_FORM.ERRORS.CLIENT.STATE_LENGTH_REQUIRED',
                showError: (control: FormControl) => control.hasError('minlength'),
                validator: Validators.minLength(2)
            }
        ];
        return new RpcSelectControltOptions(group, 'state', STATES, selectedValue, validatorsMap);
    }

    private getZipCodeInputOptions(group: FormGroup, selectedValue: string = null): RpcInputOptions {
        const validatorsMap = [
            {
                message: 'ADDRESS_EDIT_FORM.ERRORS.CLIENT.ZIP_CODE_REQUIRED',
                showError: (control: FormControl) => control.hasError('required'),
                validator: Validators.required
            }
        ];
        return new RpcInputOptions(group, 'zipCode', selectedValue, validatorsMap);
    }
}