import { createAction, props } from '@ngrx/store';

import { AgentCandidate } from '@agents/models/agent-candidate';
import { LoginData } from '@auth/models/login-data';
import { ApiError } from '@error/models/api-error';

export const loadCollaborationSpaces = createAction('[Auth] Load Collaboration Spaces');
export const loadCollaborationSpacesSuccessful = createAction(
    '[Auth] Load Collaboration Spaces Successful',
    props<{ collaborationSpaces: AgentCandidate[] }>()
);
export const loadCollaborationSpacesFailed = createAction(
    '[Auth] Load Collaboration Spaces Failed',
    (error: ApiError) => error
);

export const loadHasMultipleCollaborationSpaces = createAction('[Auth] Load Has Multiple Collaboration Spaces');
export const loadHasMultipleCollaborationSpacesSuccessful = createAction(
    '[Auth] Load Has Multiple Collaboration Spaces Successful',
    props<{ hasMultipleCollaborationSpaces: boolean }>()
);
export const loadHasMultipleCollaborationSpacesFailed = createAction(
    '[Auth] Load Has Multiple Collaboration Spaces Failed',
    (error: ApiError) => error
);

export const switchCollaborationSpace = createAction(
    '[Auth] Switch Collaboration Space',
    props<{ selectedCustomerId: number }>()
);
export const switchCollaborationSpaceSuccessful = createAction(
    '[Auth] Switch Collaboration Space Successful',
    props<{ loginResponse: LoginData, selectedCustomerId: number }>()
);
export const switchCollaborationSpaceFailed = createAction(
    '[Auth] Switch Collaboration Space Failed',
    (error: ApiError) => error
);

export const initializeNotifications = createAction('[Auth] Initialize Notifications');

export const resetState = createAction('[Auth] Reset State', props<{ selectedCustomerId: number }>());

export const initializeMyPortfolio = createAction('[Auth] Initialize Portfolio');