import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { ListingsAppointments } from '@appointments/models/appointments/listings-appointments';
import { ActionError } from '@core-models/errors/action-error';
import { AppointmentActionErrors } from '../enums/appointment-action-errors';

export class AppointmentsState {

    listingsAppointments: ListingsAppointments;
    error: ActionError<AppointmentActionErrors>;

    isSingleAppointmentLoaded: boolean;

    appointmentsLoaded: boolean;

    constructor() {
        this.listingsAppointments = {};
        this.error = null;

        this.isSingleAppointmentLoaded = false;

        this.appointmentsLoaded = false;
    }
}