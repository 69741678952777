import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { AnimationConfig } from '@core-controls/models/animation-config';

import { nameof } from '@core-models/utilities/nameof';

@Component({
    selector: 'rpc-icon-button',
    templateUrl: './rpc-icon-button.component.html',
    styleUrls: ['./rpc-icon-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpcIconButtonComponent implements OnInit {

    @Input() public title?: string;
    @Input() public iconName: string;
    @Input() public actionClass?: string;
    @Input() public actionTrigger?: boolean = null;
    @Input() public badgeCount?: number;
    @Input() public badgeCountAlert?: number;
    @Input() public badgeCountAlertLimit?: number;
    @Input() public animationConfig: AnimationConfig = null;
    @Input() public badgeIcon?: boolean = null;
    @Input() public badgeIconName?: string;
    @Input() public disabled?: boolean;
    @Input() public appearanceType: 'icon' | 'mini-fab' | 'fab' = 'icon';
    @Input() public enableCustomBackground?: boolean = null;
    @Input() public color?: ThemePalette;
    @Input() public type?: 'submit' | 'reset' | 'button' = 'button';

    @Output() public clicked = new EventEmitter<MouseEvent>();

    public ngOnInit(): void {
        if (this.iconName == null || this.iconName === '') {
            throw new Error(`'${nameof<RpcIconButtonComponent>('iconName')}' is required.`);
        }
    }
}