import { gql } from "app/graphql";

export const CREATE_FOLDER_ADDED_NOTIFICATION = gql(`
    mutation FolderAddedNotification($input: FolderEventInput!){
        notifications {
            events {
                folderAdded(input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_FOLDER_UPDATED_NOTIFICATION = gql(`
    mutation FolderUpdateNotification($input: FolderEventInput!){
        notifications {
            events {
                folderUpdated(input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_FOLDER_REMOVED_NOTIFICATION = gql(`
    mutation FolderRemovedNotification($input: FolderEventInput!){
        notifications {
            events {
                folderRemoved(input: $input) {
                    id
                }
            }
        }
    }
`);

export const CREATE_FOLDER_LISTING_CHANGED_NOTIFICATION = gql(`
    mutation FolderListingsChangedNotification($input: FolderEventInput!) {
        notifications {
            events {
                folderListingsChanged(input: $input) {
                    id
                }
            }
        }
    }
`);
