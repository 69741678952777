import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { UserStoreService } from '@auth/store/services/user-store.service';
import * as commentsActions from '@comments/store/actions/comments.actions';
import { CreateNotificationApiService } from '../services/create-notification-api.service';

@Injectable()
export class CreateCommentNotificationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly createNotificationApiService: CreateNotificationApiService,
        private readonly userStoreService: UserStoreService
    ) { }

    public readonly createCommentCreatedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(commentsActions.createListingCommentSuccess),
        concatLatestFrom(() => this.userStoreService.customerCollaborationId$),
        switchMap(([{ comment }, customerCollaborationId]) => {
            const commentCreatedInput = {
                commentId: comment.id,
                date: comment.dateCreated,
                creatorId: comment.createId,
                customerCollaborationId: customerCollaborationId,
                message: comment.comment,
                listingId: comment.listingIdHashCode
            };

            return this.createNotificationApiService.createCommentCreatedNotification(commentCreatedInput);
        })
    ));

    public readonly createCommentUpdatedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(commentsActions.updateListingCommentSuccess),
        concatLatestFrom(() => this.userStoreService.customerCollaborationId$),
        switchMap(([{ newComment, oldListingComment }, customerCollaborationId]) => {
            const commentUpdatedInput = {
                commentId: oldListingComment.id,
                date: new Date(),
                creatorId: oldListingComment.createId,
                customerCollaborationId: customerCollaborationId,
                newMessage: newComment,
                listingId: oldListingComment.listingIdHashCode,
                message: oldListingComment.comment
            };

            return this.createNotificationApiService.createCommentUpdatedNotification(commentUpdatedInput);
        })
    ));
}