<div class="confirm-wrap">
    <h1 matDialogTitle class="confirm-title" *ngIf="options.showTitle">
        {{'SIMPLE_DIALOG.TITLES.CONFIRM'| translate}}
    </h1>
    <div mat-dialog-content class="confirm-message">
        {{options.message | translate}}
    </div>
    <div mat-dialog-actions class="confirm-actions">
        <rpc-button *ngIf="options.showCancel" class="confirm-btn confirm-btn--cancel" [mainButton]="true"
            [color]="'accent'" title="SIMPLE_DIALOG.BUTTONS.CANCEL" (clicked)="dialogRef.close(false)">
        </rpc-button>
        <rpc-button class="confirm-btn confirm-btn--submit" title="SIMPLE_DIALOG.BUTTONS.OK" [mainButton]="true"
            [color]="'accent'" [appearanceType]="'flat'" (clicked)="dialogRef.close(true)">
        </rpc-button>
    </div>
</div>