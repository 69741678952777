import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { RpcControlsModule } from '@core-controls/controls.module';

import { SidebarModule } from '@core-layout/sidebar/sidebar.module';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { RpcThemeOptionsComponent } from '@core-utils/theme-options/theme-options.component';

@NgModule({
    declarations: [
        RpcThemeOptionsComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        MatRadioModule,
        FormsModule,
        FlexLayoutModule,

        // Application Modules
        SidebarModule,
        RpcIconModule,
        RpcControlsModule
    ],
    exports: [
        RpcThemeOptionsComponent
    ]
})
export class RpcThemeOptionsModule { }