import { NewMatchCheckingFrequency } from '../enums/new-match-checking-frequency.enum';
import { NewMatchEmailTemplate } from '../enums/new-match-email-template.enum';
import { NewMatchNotificationFrequency } from './new-match-notification-frequency';

export class NewMatchSettingsModel {
    public checkingFrequency: NewMatchCheckingFrequency;
    public emailTemplate: NewMatchEmailTemplate;
    public emailNotificationFrequency: NewMatchNotificationFrequency;

    constructor(checkingFrequency: NewMatchCheckingFrequency, emailTemplate: NewMatchEmailTemplate, emailNotificationFrequency: NewMatchNotificationFrequency) {
        this.checkingFrequency = checkingFrequency;
        this.emailTemplate = emailTemplate;
        this.emailNotificationFrequency = emailNotificationFrequency;
    }
}