import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { SortOptionBase } from '@core-models/sort/sort-option';
import { SYSTEM_FOLDERS_ID_TO_URL_NAME_MAP, SYSTEM_FOLDERS_INFO } from '@folders/constants/folder.constants';
import { FolderManagerModel } from '@folders/models/folder-management/folder-manager-model';
import { IManageFolderModel } from '@folders/models/manage-folder-model';
import { IRemoveFolderModel } from '@folders/models/remove-folder-model';
import * as foldersActions from '../actions/folders.actions';
import { FoldersState } from '../states/folders.state';

@Injectable({ providedIn: 'root' })
export class FoldersStoreWriteService {
    constructor(
        private readonly store: Store<FoldersState>,
        private readonly routeService: RouteService,
    ) { }

    public setDefaultFolder(folderId: number | null): void {
        this.store.dispatch(foldersActions.setDefaultFolder({ folderId }));
    }

    public update(folderData: IManageFolderModel): void {
        this.store.dispatch(foldersActions.update({ folderData }));
    }

    public create(name: string): void {
        this.store.dispatch(foldersActions.create({ name }));
    }

    public setFoldersSorting(sort: SortOptionBase): void {
        this.store.dispatch(foldersActions.setFoldersSort({ sort }));
    }

    public loadFolders(): void {
        this.store.dispatch(foldersActions.loadFolders({ shouldSetLoading: true }));
        this.store.dispatch(foldersActions.loadListingFolderIdsMappings());
        this.store.dispatch(foldersActions.loadFolderSavedSearches());
        this.store.dispatch(foldersActions.loadFolderNewMatches());
    }

    public loadListingFolderIdsMappings(): void {
        this.store.dispatch(foldersActions.loadListingFolderIdsMappings());
    }

    public selectFolder(folderId?: number): void {
        this.store.dispatch(foldersActions.selectFolder({ folderId }));
    }

    public navigateToFolderView(): void {
        this.store.dispatch(foldersActions.unselectFolder());
        this.routeService.navigate(RpcRoute.Portfolio).catch(() => { });
    }

    public unselectFolder(): void {
        this.store.dispatch(foldersActions.unselectFolder());
    }

    public load(): void {
        this.store.dispatch(foldersActions.load());
    }

    public openFolderManagementDialog(shouldUnselectListings: boolean, listingId?: string): void {
        this.store.dispatch(foldersActions.openFolderManagementDialog({ listingId, shouldUnselectListings }));
    }

    public remove(model: IRemoveFolderModel, isFromStateOnly = false): void {
        model.isRemoveListings
            ? this.store.dispatch(foldersActions.removeWithListings({ model }))
            : this.store.dispatch(foldersActions.remove({ model, isFromStateOnly }));
    }

    public manageListingsFolders(models: FolderManagerModel[], shouldUnselectListings: boolean): void {
        this.store.dispatch(foldersActions.manageListingsFolders({ models, shouldUnselectListings }));
    }

    public navigateToFolder(folderId: number): void {
        if (folderId === SYSTEM_FOLDERS_INFO.ExternalListings.id) {
            this.routeService.navigate(RpcRoute.ExrernalListingsFullPath).catch(() => { });
        } else {
            const folder = SYSTEM_FOLDERS_ID_TO_URL_NAME_MAP.get(folderId) ?? folderId.toString();

            this.routeService.navigate(RpcRoute.FolderFullPath, null, null, folder).catch(() => { });
        }
    }

    public attachListingsToSavedSearchFolder(shouldUnselectListings: boolean, listingId?: string): void {
        this.store.dispatch(foldersActions.attachListingsToSavedSearchFolder({ shouldUnselectListings, listingId }));
    }
}