import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, share, take } from 'rxjs/operators';

import { ImportantDialogOptions } from '@core-utils/important-dialog//models/important-dialog-options';
import { ImportantDialogComponent } from '@core-utils/important-dialog/components/important-dialog/important-dialog.component';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportantDialogService {

    private simpleAlertDialogRef: MatDialogRef<ImportantDialogComponent>;

    constructor(
        private readonly matDialog: MatDialog
    ) { }

    public show(options: ImportantDialogOptions, callback: (successful: boolean) => void = null): void {
        this.simpleAlertDialogRef = this.matDialog.open(
            ImportantDialogComponent,
            { disableClose: false, panelClass: options.wraperClass == null ? 'important-dialog' : ['important-dialog', options.wraperClass] });

        this.simpleAlertDialogRef.componentInstance.options = options;

        this.simpleAlertDialogRef
            .afterClosed()
            .pipe(share(), take(1))
            .subscribe((successful: boolean) => {
                if (callback != null) {
                    callback(successful as boolean);
                }
                this.simpleAlertDialogRef = null;
            });
    }

    // this method is supposed to be called from effects in observable pipeline
    public showForStream(options: ImportantDialogOptions): Observable<boolean> {
        this.simpleAlertDialogRef = this.matDialog.open(
            ImportantDialogComponent,
            { disableClose: false, panelClass: options.wraperClass == null ? 'important-dialog' : ['important-dialog', options.wraperClass] });

        this.simpleAlertDialogRef.componentInstance.options = options;

        return this.simpleAlertDialogRef
            .afterClosed()
            .pipe(
                share(),
                take(1),
                map((successful: boolean) => {
                    this.simpleAlertDialogRef = null;

                    return successful;
                })
            );
    }
}