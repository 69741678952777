export const locale = {
    lang: 'en',
    data: {
        INVITE_USER_DIALOG: {
            TITLES: {
                TITLE: 'Invite User',
                DESCRIPTION: 'You can invite a user to share your portfolio with them.'
            },
            BUTTONS: {
                CLOSE: 'Close',
                CANCEL: 'Cancel',
                SEND_EMAIL: 'Send Email'
            },
        }
    }
};
