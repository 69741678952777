import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { ValidationService } from '@core-validation/validation.service';
import { UpdatePasswordModel } from '../../interfaces/customer-settings/update-password-model';
import { UpdatePasswordRequest } from '../../interfaces/customer-settings/update-password-request';

@Component({
    selector: 'password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordComponent implements OnInit {

    @Input() isAdditionalProfile: boolean;
    @Output() passwordChanged: EventEmitter<UpdatePasswordModel> = new EventEmitter<UpdatePasswordModel>();

    public changePasswordForm: FormGroup;
    public oldPasswordControlOptions: RpcInputOptions;
    public newPasswordControlOptions: RpcInputOptions;
    public confirmPasswordControlOptions: RpcInputOptions;

    constructor(
        private readonly validationService: ValidationService) {
    }

    public get passwordButtonTitle(): string {
        return this.isAdditionalProfile ? 'PASSWORD.BUTTONS.UPDATE_PASSWORD' : 'PASSWORD.BUTTONS.CHANGE_PASSWORD';
    }

    public get isUpdateButtonDisabled(): boolean {
        return !this.changePasswordForm.dirty
            || this.changePasswordForm.invalid
            || (!this.isAdditionalProfile && this.changePasswordForm.controls['oldPassword'].value == null)
            || this.changePasswordForm.controls['newPassword'].value == null
            || this.changePasswordForm.controls['confirmPassword'].value == null;
    }

    public ngOnInit(): void {
        this.buildForm();
    }

    public cancel(): void {
        this.resetForm();
    }

    public save = () => {
        if (this.changePasswordForm.invalid) {
            return;
        }
        this.passwordChanged.emit({
            data: this.changePasswordForm.getRawValue() as UpdatePasswordRequest,
            successCallback: (() => { this.resetForm(); }).bind(this) as () => void,
            failureCallback: (() => { this.resetForm(); }).bind(this) as () => void
        });
    }

    private buildForm(): void {
        this.changePasswordForm = new FormGroup({});
        this.oldPasswordControlOptions = {
            formGroup: this.changePasswordForm,
            controlName: 'oldPassword',
            validatorsMap: [
                {
                    message: '',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };

        this.newPasswordControlOptions = {
            formGroup: this.changePasswordForm,
            controlName: 'newPassword',
            validatorsMap: [
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.PASSWORD_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.NUMBER_REQIRED',
                    showError: (control: FormControl) => control.hasError('noNumber'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.CAPITAL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('noCapitalCase'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.SMALL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('noSmallCase'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.SPECIAL_REQIRED',
                    showError: (control: FormControl) => control.hasError('noSpecialCharacter'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.MINIMUM_LENGTH',
                    showError: (control: FormControl) => control.hasError('minlength'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.MAXIMUM_LENGTH',
                    showError: (control: FormControl) => control.hasError('maxlength'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.PASSWORDS_MATCH',
                    showError: (control: FormControl) => control.hasError('matching'),
                    validator: this.validationService.getNotMatchValidator('oldPassword')
                }
            ]
        };

        this.confirmPasswordControlOptions = {
            formGroup: this.changePasswordForm,
            controlName: 'confirmPassword',
            validatorsMap: [
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.PASSWORD_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'PASSWORD.VALIDATION_ERRORS.PASSWORDS_NOT_MATCH',
                    showError: (control: FormControl) => control.hasError('notMatching'),
                    validator: this.validationService.getMatchValidator('newPassword')
                }
            ]
        };
    }

    private resetForm(): void {
        this.changePasswordForm.reset();
        this.changePasswordForm.markAsUntouched();
        Object.keys(this.changePasswordForm.controls).forEach(key => {
            this.changePasswordForm.get(key).setErrors(null);
        });
    }
}