import { Injectable } from '@angular/core';

import { CommentActionErrors } from '@comments/store/enums/comment-action-errors';
import { ActionError } from '@core-models/errors/action-error';
import { ToastService } from '@core-services/toast.service';

@Injectable({ providedIn: 'root' })
export class CommentErrorsService {

    private static readonly CommentsActionErrorsLocalizaion = new Map<CommentActionErrors, string>([
        [CommentActionErrors.ListingsCommentsLoading, 'COMMENTS_COMMON.ERRORS.LISTINGS_COMMENTS_LOADING'],
        [CommentActionErrors.ListingCommentsLoading, 'COMMENTS_COMMON.ERRORS.LISTING_COMMENTS_LOADING'],
        [CommentActionErrors.CommentCreation, 'COMMENTS_COMMON.ERRORS.COMMENT_CREATION'],
        [CommentActionErrors.MarkAsViewed, 'COMMENTS_COMMON.ERRORS.COMMENTS_MARK_AS_VIEWED'],
        [CommentActionErrors.CommentUpdate, 'COMMENTS_COMMON.ERRORS.COMMENT_UPDATE'],
        [CommentActionErrors.ListingRemovedError, 'COMMENTS_COMMON.ERRORS.LISTING_REMOVED_ERROR']
    ]);

    constructor(
        private readonly toaster: ToastService
    ) { }

    public showError(actionError: ActionError<CommentActionErrors>): void {
        const localizationKey = CommentErrorsService.CommentsActionErrorsLocalizaion.get(actionError.error);
        if (localizationKey != null) {
            this.toaster.showClientError(localizationKey);
        }
    }
}