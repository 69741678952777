import { ConnectionPositionPair, FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, Type } from '@angular/core';
import { Debounce } from '@core-decorators/debounce.decorator';
import { DynamicComponentHostDirective } from '@core-directives/dynamic-component-host/dynamic-component-host';
import { Subject, takeUntil, } from 'rxjs';

import { RpcTooltipComponent } from '../components/rpc-tooltip.component';

@Directive({
    selector: '[rpcTooltip]'
})
export class RpcTooltipDirective implements OnInit, OnDestroy {

    private static readonly positions = [
        new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
        new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' }),
        new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
        new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' })
    ];

    @Input() showToolTip = true;
    @Input('rpcTooltip') text: string;
    @Input() customClass: string | null;
    @Input() contentTemplate: TemplateRef<HTMLElement>;
    @Input() component: Type<unknown>;
    @Input() componentScopeData: unknown;
    @Input() trigger: 'default' | 'custom' | 'singleClicked'= 'default';

    @Output() tooltipInitialized = new EventEmitter<ComponentRef<DynamicComponentHostDirective>>();

    public tooltipRef: ComponentRef<RpcTooltipComponent>;

    private unsubscribe$ = new Subject<void>();
    private positionStrategy: FlexibleConnectedPositionStrategy;
    private scrollStrategy: ScrollStrategy;
    private overlayRef: OverlayRef;
    private mouseInTooltip = false;
    private isOpen = false;

    private tooltipEnterListenerStopAction: () => void;
    private tooltipLeaveListenerStopAction: () => void;
    private clickOutsideListener?: (e: Event) => void;

    constructor(
        private readonly overlay: Overlay,
        private readonly overlayPositionBuilder: OverlayPositionBuilder,
        private readonly elementRef: ElementRef,
        private readonly renderer2: Renderer2,
        private readonly scrollStrategyOptions: ScrollStrategyOptions
    ) { }

    public ngOnInit(): void {
        this.positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions(RpcTooltipDirective.positions)
            .withFlexibleDimensions(false)
            .withPush(false);

        this.scrollStrategy = this.scrollStrategyOptions.close();
    }

    public ngOnDestroy(): void {
        this.removeClickOutsideListener();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.closeTooltip();
    }

    public show(): void {
        if (!this.showToolTip) {
            return;
        }

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$ = new Subject<void>();

        if (this.overlayRef != null) {
            this.closeTooltip();
        }

        this.overlayRef = this.overlay.create({ positionStrategy: this.positionStrategy, scrollStrategy: this.scrollStrategy });

        if (this.overlayRef != null && !this.overlayRef.hasAttached()) {
            this.addClickOutsideListener();
            this.tooltipRef = this.overlayRef.attach(new ComponentPortal(RpcTooltipComponent));
            this.tooltipRef.instance.text = this.text;
            this.tooltipRef.instance.customClass = this.customClass;
            this.tooltipRef.instance.contentTemplate = this.contentTemplate;
            this.tooltipRef.instance.component = this.component;
            this.tooltipRef.instance.componentScopeData = this.componentScopeData;
            this.tooltipRef.instance.initialized
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(component => this.onComponentInitialized(component));

            this.renderer2.setStyle(this.overlayRef.overlayElement, 'visibility', 'hidden');
            this.renderer2.setStyle(this.overlayRef.overlayElement, 'oppacity', 0);

            this.tooltipLeaveListenerStopAction = this.renderer2
                .listen(this.overlayRef.overlayElement, 'mouseleave', () => {
                    this.mouseInTooltip = false;
                    this.closeTooltip();
                });

            this.tooltipEnterListenerStopAction = this.renderer2
                .listen(this.overlayRef.overlayElement, 'mouseenter', () => {
                    this.mouseInTooltip = true;
                });

            this.isOpen = true;
        }
    }

    @HostListener('mouseenter')
    @HostListener('touchstart')
    public onEnter(): void {
        if (this.trigger !== 'custom' && this.trigger !== 'singleClicked') {
            this.show();
        }
    }

    public hide(force = false): void {
        if (!this.showToolTip) {
            return;
        }

        if (force) {
            this.mouseInTooltip = false;
        }

        if (!this.mouseInTooltip) {
            this.closeTooltip();
        }
    }

    @Debounce(100)
    @HostListener('mouseleave')
    @HostListener('touchend')
    public onLeave(): void {
        if (this.trigger !== 'custom' && this.trigger !== 'singleClicked') {
            this.hide();
        }
    }

    private closeTooltip(): void {
        if (this.overlayRef != null) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
        }
        if (this.tooltipEnterListenerStopAction != null) {
            this.tooltipEnterListenerStopAction();
        }
        if (this.tooltipLeaveListenerStopAction != null) {
            this.tooltipLeaveListenerStopAction();
        }

        this.isOpen = false;
    }

    private onComponentInitialized(component: ComponentRef<DynamicComponentHostDirective>): void {
        setTimeout(() => {
            if (this.overlayRef?.overlayElement?.style != null) {
                this.overlayRef.updatePosition();
                this.renderer2.setStyle(this.overlayRef.overlayElement, 'visibility', null);
                this.renderer2.setStyle(this.overlayRef.overlayElement, 'oppacity', 1);
            }
        });

        this.tooltipInitialized.emit(component);
    }

    private addClickOutsideListener(): void {
        this.clickOutsideListener ??= e => {
            if (!this.isOpen) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            const isClickedInside = this.elementRef.nativeElement.contains(e.target) || this.tooltipRef.instance.elementRef.nativeElement.contains(e.target);
            if (!isClickedInside) {
                e.stopPropagation();
                this.closeTooltip();
            }
        };

        document.addEventListener('click', this.clickOutsideListener, { capture: true });
    }

    private removeClickOutsideListener(): void {
        document.removeEventListener('click', this.clickOutsideListener);
    }
}