import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { SortOptionBase } from '@core-models/sort/sort-option';
import * as folderSavedSearchSelectors from '@folders/store/selectors/folder-saved-search.selectors';
import { ListingCategories } from '@listings/enums/listing-categories.enum';
import { SavedSearch } from '@listings/models/saved-search/saved-search';
import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { MapShapesSearchOptions } from '@listings/models/search/map-shapes-search-options';
import { NeighborhoodProperty } from '@on-market/models/neighborhood-property';
import { SavedSearchModification } from '@on-market/models/saved-search-modification';
import * as onMarketActions from '@on-market/store/actions/on-market.actions';
import * as savedSearchActions from '@on-market/store/actions/saved-search.actions';
import * as searchOptionsActions from '@on-market/store/actions/search-options.actions';
import * as onMarketSelectors from '@on-market/store/selectors/on-market.selector';
import { OnMarketSettings } from '@settings/models/settings/on-market-settings';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { PatternSearchResult } from '@toolbars/on-market-toolbar/models/pattern-search-result';
import { ViewModes } from '@view-base/models/view-modes.enum';

@Injectable({ providedIn: 'root' })
export class OnMarketStoreService {

    constructor(
        private readonly store: Store<{}>,
        private readonly settingsStoreService: SettingsStoreService
    ) { }

    public readonly ableEditDeleteSavedSearchIds$ = this.store.select(onMarketSelectors.selectSavedSearchIdsAbleEditDelete);

    public readonly shapeOptions$ = this.store.select(onMarketSelectors.selectMapShapeOptions);

    public readonly mapShapesCount$ = this.store.select(onMarketSelectors.selectMapShapesCount);

    public readonly activeSavedSearchFolderId$ = this.store.select(onMarketSelectors.selectActiveSavedSearchId).pipe(
        switchMap(savedSearchId => savedSearchId == null ? of(null) : this.store.select(folderSavedSearchSelectors.selectFolderIdAttachedToSavedSearch(savedSearchId)))
    );

    public getSort(): Observable<SortOptionBase> {
        return this.store.pipe(select(onMarketSelectors.selectSort));
    }

    public getSearchOptions(): Observable<ListingSearchOptions> {
        return this.store.pipe(select(onMarketSelectors.selectSearchOptions));
    }

    public isZeroSearchState(): Observable<boolean> {
        return this.store.pipe(select(onMarketSelectors.selectIsZeroSearchState));
    }

    public getViewMode(): Observable<ViewModes> {
        return this.store.pipe(select(onMarketSelectors.selectSelectedViewMode));
    }

    public getMapSizePercent(): Observable<number> {
        return this.store.pipe(select(onMarketSelectors.selectMapSizePercent));
    }

    public getNeighborhoodProperties(): Observable<NeighborhoodProperty[]> {
        return this.store.pipe(select(onMarketSelectors.selectNeighborhoodProperties));
    }

    public getPreviousSearchOptions(): Observable<ListingSearchOptions> {
        return this.store.pipe(select(onMarketSelectors.selectPreviousSearchOptions));
    }

    public getSavedSearches(): Observable<SavedSearch[]> {
        return this.store.pipe(select(onMarketSelectors.selectSavedSearches));
    }

    public getSavedSearch(id: number): Observable<SavedSearch> {
        return this.store.select(onMarketSelectors.selectSavedSearch(id));
    }

    public getActiveSavedSearchId(): Observable<number> {
        return this.store.pipe(select(onMarketSelectors.selectActiveSavedSearchId));
    }

    public getActiveSavedSearchIdIfIsNotDefault(): Observable<number> {
        return this.store.pipe(select(onMarketSelectors.selectActiveSavedSearchIdIfIsNotDefault));
    }

    public setSorting(sort: SortOptionBase): void {
        this.store.dispatch(searchOptionsActions.setSort({ sort: sort }));
    }

    public changeSearchOptions(searchOptions: ListingSearchOptions): void {
        this.store.dispatch(searchOptionsActions.changeSearchOptions({ searchOptions: searchOptions }));
    }

    public setViewMode(mode: ViewModes): void {
        this.store.dispatch(searchOptionsActions.setViewMode({ viewMode: mode }));
    }

    public setMapSizePercent(size: number): void {
        this.store.dispatch(searchOptionsActions.setMapSizePercent({ mapSizePercent: size }));
    }

    public loadNeighborhoodProperties(): void {
        this.store.dispatch(onMarketActions.loadNeighborhoodProperties());
    }

    public loadSavedSearches(): void {
        this.settingsStoreService.getSettings()
            .pipe(take(1))
            .subscribe(settings => {
                this.store.dispatch(savedSearchActions.loadSavedSearches(settings));
            });
    }

    public saveView(): void {
        combineLatest([
            this.settingsStoreService.getSettings(),
            this.getSort(),
            this.getSearchOptions(),
            this.getViewMode(),
            this.getMapSizePercent(),
            this.getSavedSearches()
        ])
            .pipe(take(1))
            .subscribe(([settings, sort, searchOptions, viewMode, mapSizePercent]) => {
                const selectedSearchCategory = searchOptions.categoryId ?? ListingCategories.Sales;

                const updatedSettings = {
                    ...settings,
                    layoutSettings: {
                        ...settings.layoutSettings,
                        onMarketSettings: new OnMarketSettings(
                            sort,
                            selectedSearchCategory,
                            viewMode,
                            mapSizePercent,
                            settings.layoutSettings?.onMarketSettings?.defaultSavedSearchId)
                    }
                };

                this.settingsStoreService.updateSettings(updatedSettings, settings);
            });
    }

    public clearSearchOptions(): void {
        this.store.dispatch(searchOptionsActions.clearSelectedSearch({ categoryId: null }));
    }

    public resetSavedView(): void {
        this.settingsStoreService.getSettings()
            .pipe(take(1))
            .subscribe(settings => this.store.dispatch(searchOptionsActions.resetSavedView(settings)));
    }

    public createSavedSearch(savedSearchModification: SavedSearchModification): void {
        this.store.dispatch(savedSearchActions.createSavedSearchRequested({ savedSearchModification }));
    }

    public updateSavedSearch(savedSearchModification: SavedSearchModification): void {
        this.store.dispatch(savedSearchActions.updateSavedSearchRequested({ savedSearchModification }));
    }

    public deleteSavedSearch(deletionParams: { savedSearch: SavedSearch, removeDefaultSavedSearchId: boolean, removeActiveSavedSearchId: boolean }): void {
        this.store.dispatch(savedSearchActions.deleteSavedSearch(deletionParams));
    }

    public setDefaultSavedSearch(savedSearchId: number): void {
        this.settingsStoreService.getSettings()
            .pipe(take(1))
            .subscribe(settings => {
                const updatedSettings = {
                    ...settings,
                    layoutSettings: {
                        ...settings.layoutSettings,
                        onMarketSettings: {
                            ...settings.layoutSettings.onMarketSettings,
                            defaultSavedSearchId: savedSearchId === settings.layoutSettings.onMarketSettings?.defaultSavedSearchId ? null : savedSearchId
                        }
                    }
                };

                this.settingsStoreService.updateSettings(updatedSettings, settings);
            });
    }

    public setActiveSavedSearchId(activeSavedSearchId: number, reloadListings: boolean = true): void {
        this.store.dispatch(savedSearchActions.setActiveSavedSearchId({ activeSavedSearchId, reloadListings }));
    }

    public setMapShapesSearchOptions(mapOptions: MapShapesSearchOptions): void {
        this.store.dispatch(searchOptionsActions.setMapShapesSearchOptions({ mapOptions }));
    }

    public setSelectedMapNeighborhoods(selectedNeighborhoodIds: number[]): void {
        this.store.dispatch(searchOptionsActions.setSelectedMapNeighborhoods({ selectedNeighborhoodIds }));
    }

    public patternResultChanged(patternResults: PatternSearchResult[]): void {
        this.store.dispatch(searchOptionsActions.patternResultChanged({ patternResults }));
    }

    public resetState(): void {
        this.store.dispatch(onMarketActions.resetState());
    }
}