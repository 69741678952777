<ng-template [cdkPortalOutlet]="_calendarHeaderPortal"></ng-template>

<div class="mat-calendar-content" [ngSwitch]="currentView" cdkMonitorSubtreeFocus tabindex="-1">

  <mat-clock-view
      *ngSwitchDefault
      [(activeDate)]="activeDate"
      [selected]="selected"
      [(currentView)]="currentView"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [dateClass]="dateClass"
      [clockStep]="clockStep"
      [twelveHour]="twelveHour"
      (selectedChange)="setDate($event)"
      (hourSelected)="_hourSelectedInClockView($event)"
      (_userSelection)="_timeSelectedInClockView($event)">
  </mat-clock-view>

  <mat-month-view
      *ngSwitchCase="'month'"
      [(activeDate)]="activeDate"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [dateClass]="dateClass"
      [comparisonStart]="comparisonStart"
      [comparisonEnd]="comparisonEnd"
      (_userSelection)="_daySelected($event)">
  </mat-month-view>

  <mat-year-view
      *ngSwitchCase="'year'"
      [(activeDate)]="activeDate"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [dateClass]="dateClass"
      (monthSelected)="_monthSelectedInYearView($event)"
      (selectedChange)="hasOutput('month') ? _dateEmit($event) : _goToDateInView($event, 'month')">
  </mat-year-view>

  <mat-multi-year-view
      *ngSwitchCase="'multi-year'"
      [yearsPerPage]="yearsPerPage"
      [yearsPerRow]="yearsPerRow"
      [(activeDate)]="activeDate"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [dateClass]="dateClass"
      (yearSelected)="_yearSelectedInMultiYearView($event)"
      (selectedChange)="hasOutput('year') ? _dateEmit($event) : _goToDateInView($event, 'year')">
  </mat-multi-year-view>
</div>
