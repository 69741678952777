import { UrlSegment } from '@angular/router';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { restoreSlashedUrlCode } from './utils/restore-slashed-url-code';

export const sharedListingDetailsMatcher = (url: UrlSegment[]) => {
    const [pageSegment, ...segments] = url;

    if (pageSegment?.path === RpcRoute.SharedListing) {
        return { consumed: [pageSegment], posParams: { code: restoreSlashedUrlCode(segments) } };
    }

    return null;
};