import { ChildAccountStatus } from './child-account-status';

export class ChildAccount {
    customerId: number | null;
    profileImage: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    status: ChildAccountStatus;
    lastActivityDate: Date;
}