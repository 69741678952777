export class MarkAsViewedActionModel {
    public listingId: string;
    public listingIdHashCode: number;
    public commentIds: number[];

    constructor(listingId: string, listingIdHashCode: number, commentIds: number[]) {
        this.listingId = listingId;
        this.listingIdHashCode = listingIdHashCode;
        this.commentIds = commentIds;
    }
}