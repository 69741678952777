import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RpcInfoComponent } from './rpc-info.component';


@NgModule({
    declarations: [
        RpcInfoComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        RpcInfoComponent
    ]
})
export class RpcInfoModule { }