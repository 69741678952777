import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { UsersModule } from '@users/users.module';
import { ListingContributorsLabelComponent } from './components/listing-activity-contribution/listing-contributors-label/listing-contributors-label.component';
import { locale as english } from './i18n/en';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        TranslateModule,

        UsersModule,
        RpcIconModule,
    ],
    declarations: [ListingContributorsLabelComponent],
    exports: [ListingContributorsLabelComponent]
})
export class PortfolioModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}
