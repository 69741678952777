import { AppConfiguration } from '@app-config/models/app-configuration';

export const appConfigurationDefault: AppConfiguration = {
    layout: {
        toolbar: { hidden: false },
        secondaryToolbar: { hidden: true, component: null, layout: 'full', allowScrollLayoutChange: true },
        footer: { hidden: false },
        spinner: { hidden: true }
    },
    company: null
};