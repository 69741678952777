import { TokenInitializerService } from '@auth/services/token-initializer.service';
import { AppService } from '@core-layout/app/services/app.service';
import { PwaService } from '@rpc-pwa/services/pwa.service';

export const initializeApplication = (tokenInitializerService: TokenInitializerService, appService: AppService, pwaService: PwaService) => {
    return (): Promise<boolean> => new Promise(resolve => {
        Promise.all([
            appService.initializeGoogleAnalytics(),
            appService.initializeNewRelic(),
            pwaService.initializePwaPrompt(),
            tokenInitializerService.initialize(),
            appService.initializeSvgSprite(),
            appService.initializeCompanyConfiguration(),
            appService.initializeDeviceInfo()
        ]).then(() => resolve(true)).catch(() => resolve(true));
    });
};