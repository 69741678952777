import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ListingInfo } from '@listings/models/listing/listing-info';

@Component({
    selector: 'listings-list',
    templateUrl: './listings-list.component.html',
    styleUrls: ['./listings-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingsListComponent {
    @Input() public listings: ListingInfo[];
    @Input() public isMarkedRemoved?: boolean;
    @Output() public unselectListingClicked = new EventEmitter<string>();

    public onUnselectClicked(listingId: string): void {
        this.unselectListingClicked.emit(listingId);
    }
}
