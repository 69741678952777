import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'rpc-icon-field',
    templateUrl: './rpc-icon-field.component.html',
    styleUrls: ['./rpc-icon-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpcIconFieldComponent {
    @Input() public readonly iconName: string;
    @Input() public readonly title: string;
    @Input() public readonly value: string | number | null;
}
