import { ServerMessagesMapping } from '@core-models/messages/server-messages-mapping';

export const serverMessagesMapping: ServerMessagesMapping = {
    Hash_Code_Is_Corrupted: {
        code: 400,
        messageTranslationPath: 'SERVER.ERRORS.LINK_IS_CORRUPTED',
        title: ''
    },
    Request_Is_Corrupted: {
        code: 400,
        messageTranslationPath: 'SERVER.ERRORS.REQUEST_IS_CORRUPTED',
        title: ''
    }
};