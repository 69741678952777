export const locale = {
    lang: 'en',
    data: {
        REMOVED_MULTI_ACTIVITY_CONTROLS: {
            TITLES: {
                RESTORE: 'Restore',
                DELETE: 'Delete',
                CURRENT_LISTING: 'Current listing',
                ALL_SELECTED_LISTINGS: 'All selected listings'
            }
        }
    }
};