import { AbstractControl, ValidationErrors } from '@angular/forms';

const fileTypesMap = new Map<string, string>([
    ['png', 'image/png'],
    ['jpeg', 'image/jpeg'],
    ['jpg', 'image/jpeg'],
    ['webp', 'image/webp']
]);

const webserverExtensions = new Set(['aspx']);

const mapExtensionsToTypes = (extension: string): string | null => {
    if (fileTypesMap.has(extension)) {
        return fileTypesMap.get(extension);
    }
    return null;
};

export const fileTypeValidator = (acceptedFileTypes: string[], allowWithoutExtension: boolean = false): ((control: AbstractControl) => ValidationErrors | null) => {
    return (currentControl: AbstractControl): ValidationErrors | null => {
        const acceptedFileExtensions = acceptedFileTypes;
        if (currentControl.value instanceof File) {
            const file = currentControl.value as File;
            const fileTypesArray = acceptedFileExtensions
                .map(mapExtensionsToTypes)
                .filter(type => type != null);

            const isValid = fileTypesArray.some(type => file.type === type);
            return isValid ? null : { fileType: true };
        } else if (typeof currentControl.value === 'string') {
            const file = currentControl.value as string;
            const url = new URL(file);
            const urlWithoutDomainAndArgs = url.pathname;
            const extensionIndex = urlWithoutDomainAndArgs.lastIndexOf('.');
            if (extensionIndex < 0) {
                if (allowWithoutExtension) {
                    return null;
                }
                return { fileType: true };
            }

            const fileExtension = urlWithoutDomainAndArgs.slice(extensionIndex + 1, urlWithoutDomainAndArgs.length);
            if (webserverExtensions.has(fileExtension) && allowWithoutExtension) {
                return null;
            }

            const isValid = acceptedFileExtensions.some(extension => fileExtension.toLowerCase() === extension);
            return isValid ? null : { fileType: true };
        }
        return null;
    };
};