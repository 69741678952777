<div class="profile-item" *ngFor="let addressControl of addressControls; let i = index">
    <mat-divider class="profile-item-divider profile-item-divider--top"></mat-divider>
    <div class="profile-item-header">
        <div class="profile-item-header-title text-truncate">
            {{ 'ADDRESS_EDIT_FORM.TITLES.ADDRESS' | translate }} {{ i + 1 }}
        </div>
        <rpc-button class="profile-item-header-btn" [color]="'accent'" title="ADDRESS_EDIT_FORM.TITLES.DELETE"
            [iconName]="'decline'" (clicked)="onRemove(i)">
        </rpc-button>
    </div>
    <div class="profile-item-box">
        <rpc-select class="profile-item-select" [color]="'accent'" [label]="'ADDRESS_EDIT_FORM.TITLES.TYPE'"
            [controlOptions]="addressControl.selectTypeOptions" [appearance]="'outline'">
        </rpc-select>
        <rpc-input class="profile-item-input" label="ADDRESS_EDIT_FORM.TITLES.ADDRESS_LINE_1" appearance="outline"
            type="text" [options]="addressControl.line1InputOptions">
        </rpc-input>
        <rpc-input class="profile-item-input" label="ADDRESS_EDIT_FORM.TITLES.ADDRESS_LINE_2" appearance="outline"
            type="text" [options]="addressControl.line2InputOptions">
        </rpc-input>
        <rpc-input class="profile-item-input" label="ADDRESS_EDIT_FORM.TITLES.CITY" appearance="outline" type="text"
            [options]="addressControl.cityInputOptions">
        </rpc-input>
        <rpc-select class="profile-item-select" [color]="'accent'" label="ADDRESS_EDIT_FORM.TITLES.STATE"
            appearance="outline" [controlOptions]="addressControl.selectStateOptions"
            [disabled]="addressControl.selectCountryOptions.selectedValue != defaultCountryCode">
        </rpc-select>
        <rpc-input class="profile-item-input" label="ADDRESS_EDIT_FORM.TITLES.ZIP_CODE" appearance="outline" type="text"
            [options]="addressControl.zipCodeInputOptions">
        </rpc-input>
        <rpc-select class="profile-item-select" [color]="'accent'" label="ADDRESS_EDIT_FORM.TITLES.COUNTRY"
            appearance="outline" (changed)="onCountryChanged($event, i)"
            [controlOptions]="addressControl.selectCountryOptions">
        </rpc-select>
    </div>
</div>
<mat-divider class="profile-item-divider profile-item-divider--bottom"></mat-divider>
<div class="profile-item-btn-wrap">
    <rpc-button class="profile-item-btn" [mainButton]="true" [color]="'accent'" [appearanceType]="'flat'"
        title="ADDRESS_EDIT_FORM.TITLES.ADD_NEW" (clicked)="onAdd()"></rpc-button>
    <rpc-icon-button class="profile-item-btn profile-item-btn--mobile" [appearanceType]="'fab'" [color]="'accent'"
        iconName="plus" title="ADDRESS_EDIT_FORM.TITLES.ADD_NEW" (clicked)="onAdd()"></rpc-icon-button>
</div>