import { createAction, props } from '@ngrx/store';

import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { ListingsAppointments } from '@appointments/models/appointments/listings-appointments';
import { AppointmentCreateActionModel } from '@appointments/store/models/appointment-create-action-model';
import { AppointmentDeleteActionModel } from '@appointments/store/models/appointment-delete-action-model';
import { AppointmentUpdateActionModel } from '@appointments/store/models/appointment-update-action-model';
import { MarkAsViewedActionModel } from '@appointments/store/models/mark-as-viewed-action-model';
import { ApiError } from '@error/models/api-error';
import { AppointmentUpdateStatusActionModel } from '../models/appointment-update-status-action-model';
import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';

export const loadListingsAppointments = createAction(
    '[Appointments] Load Listings Appointments'
);
export const loadListingsAppointmentsSuccess = createAction(
    '[Appointments] Load Listings Appointments Success',
    props<{ appointments: ListingsAppointments }>()
);
export const loadListingsAppointmentsFailed = createAction(
    '[Appointments] Load Listings Appointments Failed',
    (error: ApiError) => error
);

export const loadAppointment = createAction('[Appointments] Appointment Loaded', props<{ id: number }>());
export const loadAppointmentSuccess = createAction('[Appointments] Load Appointment Success', props<{ appointment: ListingAppointment }>());
export const loadAppointmentFailed = createAction('[Appointments] Load Appointment Failed');

export const loadListingAppointments = createAction(
    '[Appointments] Load Listing Appointments',
    props<{ listingId: string }>()
);
export const loadListingAppointmentsSuccsess = createAction(
    '[Appointments] Load Listing Appointments Success',
    props<{ appointments: ListingAppointment[]; }>()
);
export const loadListingAppointmentsFailed = createAction(
    '[Appointments] Load Listing Appointments Failed',
    (error: ApiError) => error
);

export const createListingAppointment = createAction(
    '[Appointments] Create Listing Appointment',
    props<AppointmentCreateActionModel>()
);
export const createListingAppointmentSuccess = createAction(
    '[Appointments] Create Listing Appointment Success',
    props<{ appointment: ListingAppointment, request: AppointmentCreateActionModel }>()
);
export const createListingAppointmentFailed = createAction(
    '[Appointments] Create Listing Appointments Failed',
    (error: ApiError, request: AppointmentCreateActionModel) => {
        return { error: error, request: request };
    }
);

export const updateListingAppointment = createAction(
    '[Appointments] Update Listing Appointment',
    props<AppointmentUpdateActionModel>()
);
export const updateListingAppointmentSuccess = createAction(
    '[Appointments] Update Listing Appointment Success',
    props<{ request: AppointmentUpdateActionModel }>()
);
export const updateListingAppointmentFailed = createAction(
    '[Appointments] Update Listing Appointments Failed',
    (error: ApiError, request: AppointmentUpdateActionModel) => {
        return { error: error, request: request };
    }
);

export const changeAppointmentStatus = createAction(
    '[Appointments] Change Appointment Status',
    props<{ request: AppointmentUpdateStatusActionModel }>()
);
export const changeStatusById = createAction(
    '[Appointments] Change Appointment Status By Id',
    props<{ appointmentId: number, listingHashcode: number, previousCustomerStatus: AppointmentStatus, status: AppointmentStatus }>()
);
export const changeAppointmentStatusSuccess = createAction(
    '[Appointments] Change Appointment Status Success',
    props<{ request: AppointmentUpdateStatusActionModel }>()
);
export const changeAppointmentStatusFailed = createAction(
    '[Appointments] Change Appointment Status Failed',
    (error: ApiError, request: AppointmentUpdateStatusActionModel) => {
        return { error: error, request: request };
    }
);

export const markAppointmentsAsViewed = createAction(
    '[Appointments] Mark Appointment As Viewed',
    props<MarkAsViewedActionModel>()
);
export const markAppointmentsAsViewedSuccess = createAction(
    '[Appointments] Mark Appointment As Viewed Success',
    props<MarkAsViewedActionModel>()
);
export const markAppointmentsAsViewedFailed = createAction(
    '[Appointments] Mark Appointment As Viewed Failed',
    (error: ApiError, request: MarkAsViewedActionModel) => {
        return { error: error, request: request };
    }
);

export const deleteListingAppointment = createAction(
    '[Appointments] Delete Listing Appointment',
    props<{ model: AppointmentDeleteActionModel, isFromStateOnly?: boolean }>()
);
export const deleteListingAppointmentSuccess = createAction(
    '[Appointments] Delete Listing Appointment Success',
    props<{ request: AppointmentDeleteActionModel }>()
);
export const deleteListingAppointmentFailed = createAction(
    '[Appointments] Delete Listing Appointments Failed',
    (error: ApiError, request: AppointmentDeleteActionModel) => {
        return { error: error, request: request };
    }
);

export const resetState = createAction('[Appointments] Reset State');