import { AppointmentStatus } from "@appointments/enums/appointment-status.enum";
import { Creator } from "@appointments/enums/creator.enum";
import { AppointmentActionOptions } from "@appointments/store/models/appointment-action-options";
import { AppointmentStatusDetails } from "@appointments/store/models/appointment-status-details";

export class AppointmentNotification {
    readonly status: AppointmentStatus;
    readonly customerStatus: AppointmentStatus;
    readonly agentStatus: AppointmentStatus;
    readonly startDateTime: Date;
    readonly endDateTime: Date;
    readonly comment: string | null;
    readonly actionOptions: AppointmentActionOptions;
    readonly statusDetails: AppointmentStatusDetails;
    readonly creator: Creator;
}

export class CommentNotification {
    readonly text: string;
}

export class ListingNotification {
    readonly listingUrl: string;
}