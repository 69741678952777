import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonToggleAppearance, MatButtonToggleChange } from '@angular/material/button-toggle';

import { nameof } from '@core-models/utilities/nameof';

import { RpcButtonToggleItem } from './models/rpc-button-toggle-item';

@Component({
    selector: 'rpc-button-toggle',
    templateUrl: './rpc-button-toggle.component.html',
    styleUrls: ['./rpc-button-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpcButtonToggleComponent implements OnInit {

    @Input() public alt?: string;
    @Input() public toggleItems: RpcButtonToggleItem[];
    @Input() public default?: string;
    @Input() public appearence?: MatButtonToggleAppearance = 'standard';
    @Input() public disableRipple?: boolean;
    @Input() public disabled?: boolean;

    @Output() public changed = new EventEmitter<string>();

    public ngOnInit(): void {
        if (this.toggleItems == null ||
            this.toggleItems.length === 0 ||
            this.toggleItems.some(item => item == null || item.value == null || item.value === '')
        ) {
            throw new Error(`'${nameof<RpcButtonToggleComponent>('toggleItems')}' is required.`);
        }
    }

    public onChanged(event: MatButtonToggleChange): void {
        this.changed.emit(event.value as string);
    }
}