import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ListingDetailsTabNames } from '@listings/enums/listing-details-tab-names.enum';

@Injectable({ providedIn: 'root' })
export class ListingGuard implements CanActivate, CanActivateChild {

    constructor(private readonly routeService: RouteService) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivateRoute(childRoute, state);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivateRoute(route, state);
    }

    private canActivateRoute(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
        const params = route.queryParams;

        if (params?.tab == null || ![ListingDetailsTabNames.Comment, ListingDetailsTabNames.Appointment].some(x => x === params.tab)) {
            return true;
        }

        const listingId: string = route.params.id;
        this.routeService.navigate(RpcRoute.Listing, { state: { tab: params.tab } }, null, listingId).catch(() => { });

        return false;
    }
}