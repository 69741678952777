export const locale = {
    lang: 'en',
    data: {
        LISTING_MANAGEMENT_DIALOG: {
            TITLES: {
                TITLE: 'Are you sure you want to remove listing from your portfolio?',
                CONFIRMATION_MESSAGE_1: 'Are you sure you want to remove ',
                CONFIRMATION_MESSAGE_2: 'from your portfolio?',
                CONFIRMATION_MESSAGE_3: 'from your portfolio entirely?',
                LISTING: ' listing ',
                LISTINGS: ' listings ',
                REMOVE_NEW_MATCHES: 'Remove New Matches',
                WARNINGS: {
                    MESSAGE_1: 'Selected list contains already removed listing(s).',
                    MESSAGE_2: 'To delete them follow ',
                    MESSAGE_3: 'Removed listings',
                    MESSAGE_4: 'folder.',
                    REMOVE_NEW_MACTH_WITHOUT_ACTIVITY: 'New Match listings removed from your portfolio are not kept in the \'Removed Listings\' folder.'
                }
            },
            BUTTONS: {
                CLOSE: 'Close',
                CANCEL: 'Cancel',
                REMOVE: 'Remove',
                DELETE: 'Delete'
            }
        }
    }
};