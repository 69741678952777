import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ProgressBarComponent } from '@core-utils/progress-bar/progress-bar.component';

@NgModule({
    declarations: [
        ProgressBarComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        MatProgressBarModule
    ],
    exports: [
        ProgressBarComponent
    ]
})
export class ProgressBarModule { }