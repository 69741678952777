export class RpcSelectOption {
    title: string;
    value: any;
    iconName?: string;

    constructor(value: any, title: string, iconName: string = null) {
        this.value = value;
        this.title = title;
        this.iconName = iconName;
    }
}