import { CreateOperationListingAppointment } from '@appointments/models/appointments/listing-appointment';

export class AppointmentCreateActionModel {
    public readonly listingId: string;
    public readonly listingAddress: string;
    public readonly listingCategory: string;
    public readonly createOperationListingAppointment: CreateOperationListingAppointment;
    public readonly isNewMatch: boolean;

    constructor(
        listingId: string,
        listingAddress: string,
        listingCategory: string,
        createOperationListingAppointment: CreateOperationListingAppointment,
        isNewMatch: boolean = false
    ) {
        this.listingId = listingId;
        this.listingAddress = listingAddress;
        this.listingCategory = listingCategory;
        this.createOperationListingAppointment = createOperationListingAppointment;
        this.isNewMatch = isNewMatch;
    }
}