import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as portfolioSelectors from '../selectors/portfolio.selectors';

@Injectable({ providedIn: 'root' })
export class PortfolioStoreService {
    constructor(private readonly store: Store<{}>) { }

    public readonly portfolioRouterLink$ = this.store.select(portfolioSelectors.selectPortfolioRouterLink);
}
