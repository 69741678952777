import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectAgents } from '@agents/store/selectors/agents.selectors';
import { UsersService } from '@users/services/users.service';
import { UserBaseInfo } from '../../models/user-base-info';
import { UsersState } from '../states/users.state';

const usersStateSelector = createFeatureSelector<UsersState>('users');

export const selectCustomers = createSelector(
    usersStateSelector,
    state => state?.customers == null ? [] : state.customers
);

export const selectCustomersAndAgents = createSelector(
    selectCustomers,
    selectAgents,
    (customers, agents) => {
        return agents
            .map(({ agentId, profilePictureUrl, fullName }): UserBaseInfo => ({ customerId: agentId, fullName, profileImage: profilePictureUrl }))
            .concat(customers);
    }
);

export const selectCustomersAndAgentsCount = createSelector(
    selectCustomersAndAgents,
    customersAndSharedAgents => customersAndSharedAgents.length
);

export const selectUserInfoOrRemovedAccount = (userId: number) => createSelector(
    selectCustomersAndAgents,
    users => UsersService.getUserInfoOrRemovedAccount(users, userId)
);

export const selectCustomersAndAgentsIds = createSelector(
    selectCustomersAndAgents,
    users => users.map(user => user.customerId)
);
