import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { RpcControlsModule } from '@core-controls/controls.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { ImportantDialogComponent } from '@core-utils/important-dialog/components/important-dialog/important-dialog.component';
import { locale as english } from '@core-utils/important-dialog/i18n/en';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';

@NgModule({
    declarations: [
        ImportantDialogComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        MatButtonModule,
        FlexLayoutModule,

        RpcIconModule,
        RpcControlsModule
    ]
})
export class ImportantDialogModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english);
    }
}