import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RpcSelectControltOptions } from '@core-controls/components/rpc-select/models/rpc-select-controlt-options';
import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';
import { PhoneInfo } from '@core-controls/models/phone-info';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ValidationService } from '@core-validation/validation.service';
import { Phone } from '../../interfaces/customer-info/phone';
import { PhoneTypes } from '../../interfaces/enums/phone-types.enum';

@Component({
    selector: 'phones-edit-form',
    templateUrl: './phones-edit-form.component.html',
    styleUrls: ['../profile-info-base/profile-info-base.component.scss', './phones-edit-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PhonesEditFormComponent implements OnChanges, AfterViewInit {

    @Input() public parentGroup: FormGroup;

    @Input() public controlName: string;

    @Input() public phones: Phone[];

    private tempPhoneId = -1;

    private readonly phoneSelectOptionValues: RpcSelectOption[] = [
        { title: 'PHONES_EDIT_FORM.TITLES.OFFICE', value: PhoneTypes.office, iconName: 'user' },
        { title: 'PHONES_EDIT_FORM.TITLES.MOBILE', value: PhoneTypes.mobile, iconName: 'user' },
        { title: 'PHONES_EDIT_FORM.TITLES.HOME', value: PhoneTypes.home, iconName: 'user' },
        { title: 'PHONES_EDIT_FORM.TITLES.OTHER', value: PhoneTypes.other, iconName: 'user' }
    ];

    private phoneArray: FormArray;

    public phoneControls: { id: number, selectOptions: RpcSelectControltOptions, phoneInfo: PhoneInfo }[];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly validationService: ValidationService
    ) { }

    public ngOnChanges(changes: SimpleChanges<PhonesEditFormComponent>): void {
        const { controlName, parentGroup, phones } = changes;

        if ([controlName?.currentValue, parentGroup?.currentValue, phones?.currentValue].every(x => x != null)) {
            this.buildPhoneControls(controlName.currentValue, parentGroup.currentValue, phones.currentValue);
        }
    }

    public ngAfterViewInit(): void {
        this.parentGroup.addControl(this.controlName, this.phoneArray);
    }

    public onAddPhone(): void {
        this.createPhoneControl(this.tempPhoneId);
        this.tempPhoneId -= 1;
    }

    public onRemovePhone(i: number): void {
        this.phoneControls.splice(i, 1);
        this.phoneArray.removeAt(i);
    }

    public onPhoneNumberChanged(data: PhoneInfo): void {
        this.phoneArray.controls.find(control => control.get('id').value === data.phoneId).get('number').setValue(data);
    }

    private buildPhoneControls(controlName: string, parentGroup: FormGroup, phones: Phone[]): void {

        this.phoneControls = [];
        this.phoneArray = this.formBuilder.array([]);
        phones.forEach(phone => this.createPhoneControl(phone.id, phone));
    }

    private createPhoneControl(id: number, phone: Phone = null): void {
        // TODO: Add unique validations
        const phoneValidatorsMap = [{
            message: 'PHONES_EDIT_FORM.ERRORS.CLIENT.PHONE_TYPE_REQUIRED',
            showError: (control: FormControl) => control.hasError('required'),
            validator: Validators.required
        }];

        const currentGroup = this.formBuilder.group({
            id,
            number: [null, [Validators.required, this.validationService.getExternalDependencyValidator(true)]]
        });

        const phoneInfo: PhoneInfo = phone?.number ?? new PhoneInfo();
        phoneInfo.phoneId = id;

        this.phoneControls.push({
            id,
            selectOptions: new RpcSelectControltOptions(currentGroup, 'type', this.phoneSelectOptionValues, phone?.type, phoneValidatorsMap),
            phoneInfo
        });

        this.phoneArray.push(currentGroup);
    }
}