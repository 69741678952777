import { locale as phoneFormInput } from '@core-controls/components/phone-form-input/i18n/en';
import { locale as rpcFileUpload } from '@core-controls/components/rpc-file-upload/i18n/en';

const localesData = {
    ...phoneFormInput.data,
    ...rpcFileUpload.data
};

export const locale = {
    lang: 'en',
    data: localesData
};