import { ListingComment } from '@comments/models/comments/listing-comment';

export class CommentUpdateActionModel {
    public readonly listingId: string;
    public readonly listingIdHashCode: number;
    public readonly listingAddress: string;
    public readonly newComment: string;
    public readonly oldListingComment: ListingComment;

    constructor(listingId: string, listingIdHashCode: number, listingAddress: string, newComment: string, oldListingComment: ListingComment) {
        this.listingId = listingId;
        this.listingIdHashCode = listingIdHashCode;
        this.listingAddress = listingAddress;
        this.newComment = newComment;
        this.oldListingComment = oldListingComment;
    }
}