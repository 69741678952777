import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { DateConstants } from '@core-constants/dates/date.constants';

@Pipe({ name: 'dateLabel' })
export class DateLabelPipe implements PipeTransform {
    transform(date: string | Date): string {
        return this.formatTime(date);
    }

    private formatTime(date: string | Date): string {
        const currDate = this.shortDate(date);
        const today = this.shortDate(new Date());
        if (currDate === today) {
            return 'DATE_LABEL.TITLES.TODAY';
        } else {
            return currDate;
        }
    }

    private shortDate(date: string | Date): string {
        return moment(date).format(DateConstants.Formats.ShortDateSlash);
    }
}