import { createAction, props } from "@ngrx/store";

import { GoogleAnalyticsEventName } from "app/modules/core-modules/enums/google-analytics-event-name";

export const setCustomerAttributes = createAction('[GoogleAnalytics] Set Customer Attributes');

export const addPageEvent = createAction('[GoogleAnalytics] Add Page Event', props<{ name: GoogleAnalyticsEventName, payload?: Object }>());
export const addListingReactionEvent = createAction(
    '[GoogleAnalytics] Add Listing Reaction Event',
    props<{ name: GoogleAnalyticsEventName, payload?: Object }>()
);
export const addEvent = createAction('[GoogleAnalytics] Add Event', props<{ name: GoogleAnalyticsEventName, payload?: Object }>());