import { Injectable } from '@angular/core';

import { AppointmentActionErrors } from '@appointments/store/enums/appointment-action-errors';
import { ActionError } from '@core-models/errors/action-error';
import { ToastService } from '@core-services/toast.service';

// TODO : Refactor during appointments views implementation
@Injectable({ providedIn: 'root' })
export class AppointmentErrorsService {

    constructor(
        private readonly toaster: ToastService
    ) { }

    public showError(actionError: ActionError<AppointmentActionErrors>): void {
        switch (actionError.error) {
            case AppointmentActionErrors.ListingsAppointmentsLoading:
                this.toaster.showClientError('APPOINTMENTS_COMMON.ERRORS.LISTINGS_APPOINTMENTS_LOADING');
                break;
            case AppointmentActionErrors.ListingAppointmentsLoading:
                this.toaster.showClientError('APPOINTMENTS_COMMON.ERRORS.LISTING_APPOINTMENTS_LOADING');
                break;
            case AppointmentActionErrors.AppointmentCreation:
                this.toaster.showClientError('APPOINTMENTS_COMMON.ERRORS.APPOINTMENT_CREATION');
                break;
            case AppointmentActionErrors.AppointmentUpdate:
                this.toaster.showClientError('APPOINTMENTS_COMMON.ERRORS.APPOINTMENTS_UPDATE');
                break;
            case AppointmentActionErrors.AppointmentStatusUpdate:
                this.toaster.showClientError('APPOINTMENTS_COMMON.ERRORS.APPOINTMENTS_STATUS_UPDATE');
                break;
            case AppointmentActionErrors.AppointmentDeletion:
                this.toaster.showClientError('APPOINTMENTS_COMMON.ERRORS.APPOINTMENTS_DELETION');
                break;
            case AppointmentActionErrors.MarkAsViewed:
                this.toaster.showClientError('APPOINTMENTS_COMMON.ERRORS.APPOINTMENTS_MARK_AS_VIEWED');
                break;
            case AppointmentActionErrors.ListingRemovedError:
                this.toaster.showClientError('APPOINTMENTS_COMMON.ERRORS.LISTING_REMOVED_ERROR');
                break;
            default:
                break;
        }
    }
}