import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AgentBaseInfo } from '@agents/models/agent-base-info';
import * as agentsActions from '../actions/agents.actions';
import * as agentsSelectors from '../selectors/agents.selectors';
import { AgentsState } from '../states/agents.state';

@Injectable({ providedIn: 'root' })
export class AgentsStoreService {
    constructor(
        private readonly store: Store<AgentsState>
    ) { }

    public loadAgents(): void {
        this.store.dispatch(agentsActions.loadAgents());
    }

    public getAgents(): Observable<AgentBaseInfo[]> {
        return this.store.select(agentsSelectors.selectAgents);
    }

    public getAgentIds(): Observable<number[]> {
        return this.store.select(agentsSelectors.selectAgentIds);
    }

    public getAgentsCount(): Observable<number> {
        return this.store.select(agentsSelectors.selectAgentsCount);
    }
}