import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { DropZoneDataTransfer } from './drop-zone-data-transfer';

@Directive({
    selector: '[dropZone]'
})
export class DropZoneDirective {

    @Output() public dataTransfer = new EventEmitter<DropZoneDataTransfer>();

    @HostBinding('class.active') public active = false;

    @HostListener('drop', ['$event'])
    public onDrop(event: DragEvent): void {
        event.preventDefault();
        this.active = false;
        let files: File[] = [];
        const strings = new Set<string>();

        const { dataTransfer } = event;
        if (dataTransfer.items != null && dataTransfer.items.length > 0) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < dataTransfer.items.length; i += 1) {
                const {kind} = dataTransfer.items[i];
                if (kind === 'file') {
                    files.push(dataTransfer.items[i].getAsFile());
                } else {
                    dataTransfer.items[i].getAsString((str: string) => strings.add(str));
                }
            }
            dataTransfer.items.clear();
        } else if (dataTransfer.files != null && dataTransfer.files.length > 0) {
            const fileList = dataTransfer.files;
            dataTransfer.clearData();
            files = Array.from(fileList);
        }
        setTimeout(() => {
            this.dataTransfer.emit(new DropZoneDataTransfer(Array.from(strings), files));
        });
    }

    @HostListener('dragover', ['$event'])
    public onDragOver(event: DragEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.active = true;
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(event: DragEvent): void {
        this.active = false;
    }

    @HostListener('body:dragover', ['$event'])
    public onBodyDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('body:drop', ['$event'])
    public onBodyDrop(event: DragEvent): void {
        event.preventDefault();
    }
}