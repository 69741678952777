import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';

import { RpcSelectControltOptions } from './models/rpc-select-controlt-options';
import { RpcSelectOption } from './models/rpc-select-option';

@Component({
    selector: 'rpc-select',
    templateUrl: './rpc-select.component.html',
    styleUrls: ['./rpc-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RpcSelectComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() controlOptions: RpcSelectControltOptions;
    @Input() appearance: MatFormFieldAppearance;
    @Input() label: string;
    @Input() disabled?: boolean;
    @Input() public color?: ThemePalette = undefined;
    @Input() shouldTranslate? = true;

    @Output() public changed = new EventEmitter<RpcSelectOption>();

    public formControl: FormControl;
    public selectedItem: RpcSelectOption;

    public ngOnInit(): void {
        this.initialize(this.controlOptions);
    }

    public ngOnChanges(changes: SimpleChanges<RpcSelectComponent>): void {
        if (changes.controlOptions?.currentValue != null  && !changes.controlOptions.isFirstChange()) {
            if (changes.controlOptions?.previousValue != null) {
                changes.controlOptions.currentValue.formGroup.removeControl(changes.controlOptions.previousValue.controlName);
            }
            this.initialize(changes.controlOptions.currentValue);
        }
        if (changes.disabled?.currentValue != null) {
            this.disableControl(changes.disabled.currentValue);
        }
    }

    public ngAfterViewInit(): void {
        setTimeout(() => this.disableControl(this.disabled));
    }

    private initialize(controlOptions: RpcSelectControltOptions): void {
        this.formControl = new FormControl(
            controlOptions.selectedValue,
            controlOptions.validatorsMap?.map(validationItem => validationItem.validator)
        );
        this.selectedItem = controlOptions.options.find(x => x.value === controlOptions.selectedValue);
        controlOptions.formGroup.setControl(controlOptions.controlName, this.formControl);
    }

    private disableControl(disabled: boolean): void {
        if (this.formControl != null) {
            if (disabled) {
                this.formControl.disable();
            } else {
                this.formControl.enable();
            }
        }
    }

    public onChanged(event: MatSelectChange): void {
        this.selectedItem = this.controlOptions.options.find(x => x.value === event.value);
        this.changed.emit(this.selectedItem);
    }
}