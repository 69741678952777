import { Injectable } from '@angular/core';
import { forkJoin, map, mergeMap, of, switchMap } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { CreateNotificationApiService } from '../services/create-notification-api.service';
import * as profileActions from '@profile/store/actions/profile.actions';
import { ProfileBaseStoreService } from 'app/modules/user-modules/profile-modules/profile-base/store/services/profile-base-store.service';
import * as profileBaseActions from 'app/modules/user-modules/profile-modules/profile-base/store/actions/profile-base.actions';
import * as loginActions from '@auth/store/actions/login.actions';

@Injectable()
export class CreateUserNotificationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly createNotificationApiService: CreateNotificationApiService,
        private readonly userStoreService: UserStoreService,
        private readonly profileBaseStoreService: ProfileBaseStoreService,
    ) { }


    public readonly createPrimaryCustomerDeactivatedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(profileActions.deactivateAccountSuccessful),
        concatLatestFrom(() => [this.userStoreService.isPrimaryCustomer$, this.profileBaseStoreService.activeAdditionalProfiles$]),
        switchMap(([, isPrimaryCustomer, activeAdditionalProfiles]) => {
            return !isPrimaryCustomer
                ? of(loginActions.logout(false))
                : forkJoin(activeAdditionalProfiles.map(x => this.createNotificationApiService.createUserDeactivatedNotification(x.customerId)));
        }),
        map(x => loginActions.logout(false))
    ));

    public readonly createChildCustomerDeactivatedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.deactivateChildAccountByPrimarySuccessful),
        mergeMap(({ childCustomerId }) => this.createNotificationApiService.createUserDeactivatedNotification(childCustomerId))
    ));
}