import { TranslationInfo } from "@core-models/utilities/translation-info";
import { NotificationEventAction } from "@notifications/enums/notification-event-action";
import { NotificationEventEntity } from "@notifications/enums/notification-event-entity";

export class NotificationItemBase {
    readonly type: NotificationEventEntity;
    readonly isGroup: boolean;
    readonly translationInfo: TranslationInfo;
    readonly icon: string;
    readonly iconClass: string;
    readonly isViewed: boolean;
    readonly action: NotificationEventAction;
}