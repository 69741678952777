<div class="tc-wrap">
    <div class="tc-box" @fadeIn>
        <div class="tc-content">
            <div (click)="onBackClick()" class="tc-nav-link">
                <rpc-icon iconName="arrow-left" class="icon"></rpc-icon>
                <div class="text">{{ 'TERMS_AND_CONDITIONS.BUTTONS.BACK' | translate }}</div>
            </div>

            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.PRIVACY_POLICY' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.TITLES.UPDATE' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_P_2' | translate}}</p>
            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.INTERPRETATION_AND_DEFINITIONS' | translate}}</h1>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.INTERPRETATION' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.INTERPRETATION_P_1' | translate}}</p>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.DEFINITIONS' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSES' | translate}}</p>
            <ul>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_1' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_2' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_3' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_4' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_5' | translate}}</p></li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_6_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_6_2' | translate}}</p>
                </li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_7' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_8' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_9' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_10' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_11' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_12' | translate}}</p></li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_13_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_13_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_13_3' | translate}}</p>
                </li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_14' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_15' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_16' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_17' | translate}}</p></li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_18_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_PURPOSE_18_2' | translate}}</p>
                </li>
            </ul>

            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.COLLECTING_AND_USING_PERSONAL_DATA' | translate}}</h1>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.TYPES_OF_COLLECTED_DATA' | translate}}</h2>
            <h3>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_DATA' | translate}}</h3>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_P_1' | translate}}</p>
            <ul>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_ITEM_1' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_ITEM_2' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_ITEM_3' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_ITEM_4' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_ITEM_5' | translate}}</p></li>
            </ul>

            <h3>{{'TERMS_AND_CONDITIONS.HEADINGS.USAGE_DATA' | translate}}</h3>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_DATA_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_DATA_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_DATA_P_3' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_DATA_P_4' | translate}}</p>

            <h3>{{'TERMS_AND_CONDITIONS.HEADINGS.THIRD_PARTY_SERVICES_INFORMATION' | translate}}</h3>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.THIRD_PARTY_SERVICES_INFORMATION_P_1' | translate}}</p>
            <ul>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.THIRD_PARTY_SERVICES_INFORMATION_ITEM_1' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.THIRD_PARTY_SERVICES_INFORMATION_ITEM_2' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.THIRD_PARTY_SERVICES_INFORMATION_ITEM_3' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.THIRD_PARTY_SERVICES_INFORMATION_ITEM_4' | translate}}</li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.THIRD_PARTY_SERVICES_INFORMATION_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.THIRD_PARTY_SERVICES_INFORMATION_P_3' | translate}}</p>
            <h3>{{'TERMS_AND_CONDITIONS.HEADINGS.COLLECTED_INFORMATION' | translate}}</h3>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_INFORMATION_P_1' | translate}}</p>
            <ul>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_INFORMATION_ITEM_1' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_INFORMATION_ITEM_2' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_INFORMATION_ITEM_3' | translate}}</li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_INFORMATION_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_INFORMATION_P_3' | translate}}</p>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.USE_OF_PERSONAL_DATA' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_PURPOSES' | translate}}</p>
            <ul>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_PURPOSE_1' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_PURPOSE_2' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_PURPOSE_3' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_PURPOSE_4' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_PURPOSE_5' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_PURPOSE_6' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_PURPOSE_7' | translate}}</p></li>
                <li><p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_PURPOSE_8' | translate}}</p></li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_SHARE' | translate}}</p>
            <ul>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_SHARE_ITEM_1' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_SHARE_ITEM_2' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_SHARE_ITEM_3' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_SHARE_ITEM_4' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_SHARE_ITEM_5' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USE_OF_PERSONAL_DATA_SHARE_ITEM_6' | translate}}</li>
            </ul>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_DATA_RETENTION' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_RETENTION_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_RETENTION_P_2' | translate}}</p>
            
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_DATA_TRANSFER' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_TRANSFER_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_TRANSFER_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_TRANSFER_P_3' | translate}}</p>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_DATA_DELETION' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_DELETION_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_DELETION_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_DELETION_P_3' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_DELETION_P_4' | translate}}</p>
            
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_DATA_DISCLOSURE' | translate}}</h2>
            <h3>{{'TERMS_AND_CONDITIONS.HEADINGS.BUSINESS_TRANSACTIONS' | translate}}</h3>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.BUSINESS_TRANSACTIONS_P_1' | translate}}</p>
            <h3>{{'TERMS_AND_CONDITIONS.HEADINGS.LAW_ENFORCEMENT' | translate}}</h3>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.LAW_ENFORCEMENT_P_1' | translate}}</p>
            <h3>{{'TERMS_AND_CONDITIONS.HEADINGS.OTHER_LEGAL_REQUIREMENTS' | translate}}</h3>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.OTHER_LEGAL_REQUIREMENTS_P_1' | translate}}</p>
            <ul>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.OTHER_LEGAL_REQUIREMENTS_ITEM_1' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.OTHER_LEGAL_REQUIREMENTS_ITEM_2' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.OTHER_LEGAL_REQUIREMENTS_ITEM_3' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.OTHER_LEGAL_REQUIREMENTS_ITEM_4' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.OTHER_LEGAL_REQUIREMENTS_ITEM_5' | translate}}</li>
            </ul>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_DATA_SECURITY' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_SECURITY_P_1' | translate}}</p>
            
            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_DATA_PROCESSING_DETAIL_INFORMATION' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_DATA_PROCESSING_DETAIL_INFORMATION_P_1' | translate}}</p>
            
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.ANALYTICS' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.ANALYTICS_P_1' | translate}}</p>
            <ul>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.ANALYTICS_ITEM_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.ANALYTICS_ITEM_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.ANALYTICS_ITEM_3' | translate}}
                        <a href="https://policies.google.com/privacy" rel="external nofollow noopener"
                            target="_blank">https://policies.google.com/privacy
                        </a>
                    </p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.ANALYTICS_ITEM_4' | translate}}
                        <a href="https://policies.google.com/privacy" rel="external nofollow noopener"
                            target="_blank">https://policies.google.com/privacy
                        </a>
                    </p>
                </li>
            </ul>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.ADVERTISING' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.ADVERTISING_P_1' | translate}}</p>
            <ul>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.ADVERTISING_ITEM_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.ADVERTISING_ITEM_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.ADVERTISING_ITEM_3' | translate}} 
                        <a href="http://www.google.com/ads/preferences/"
                            rel="external nofollow noopener" target="_blank">http://www.google.com/ads/preferences/
                        </a>
                    </p>
                </li>
            </ul>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.EMAIL_MARKETING' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.EMAIL_MARKETING_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.EMAIL_MARKETING_P_2' | translate}}</p>
            <ul>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.EMAIL_MARKETING_ITEM_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.EMAIL_MARKETING_ITEM_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.EMAIL_MARKETING_ITEM_3' | translate}}
                        <a href="https://mailchimp.com/legal/privacy/" rel="external nofollow noopener"
                            target="_blank">https://mailchimp.com/legal/privacy/
                        </a>
                    </p>
                </li>
            </ul>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.USAGE_PERFORMANCE_MISCELLANEOUS' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_PERFORMANCE_MISCELLANEOUS_P_1' | translate}}</p>
            <ul>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_PERFORMANCE_MISCELLANEOUS_ITEM_1_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_PERFORMANCE_MISCELLANEOUS_ITEM_1_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_PERFORMANCE_MISCELLANEOUS_ITEM_1_3' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_PERFORMANCE_MISCELLANEOUS_ITEM_1_4' | translate}}
                        <a href="https://www.google.com/intl/en/policies/privacy/" rel="external nofollow noopener"
                            target="_blank">https://www.google.com/intl/en/policies/privacy/
                        </a>
                    </p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_PERFORMANCE_MISCELLANEOUS_ITEM_2_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_PERFORMANCE_MISCELLANEOUS_ITEM_2_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_PERFORMANCE_MISCELLANEOUS_ITEM_2_3' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.USAGE_PERFORMANCE_MISCELLANEOUS_ITEM_2_4' | translate}}
                        <a href="https://www.google.com/intl/en/policies/privacy/" rel="external nofollow noopener"
                            target="_blank">https://www.google.com/intl/en/policies/privacy/
                        </a>
                    </p>
                </li>
            </ul>

            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.GDPR_PRIVACY' | translate}}</h1>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PROCESSING_PERSONAL_DATA_UNDER_GDPR' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PROCESSING_PERSONAL_DATA_UNDER_GDPR_P_1' | translate}}</p>
            <ul>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PROCESSING_PERSONAL_DATA_UNDER_GDPR_ITEM_1' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PROCESSING_PERSONAL_DATA_UNDER_GDPR_ITEM_2' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PROCESSING_PERSONAL_DATA_UNDER_GDPR_ITEM_3' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PROCESSING_PERSONAL_DATA_UNDER_GDPR_ITEM_4' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PROCESSING_PERSONAL_DATA_UNDER_GDPR_ITEM_5' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PROCESSING_PERSONAL_DATA_UNDER_GDPR_ITEM_6' | translate}}</li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PROCESSING_PERSONAL_DATA_UNDER_GDPR_P_2' | translate}}</p>
            
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.RIGHTS_UNDER_GDPR' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_GDPR_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_GDPR_P_2' | translate}}</p>
            <ul>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_GDPR_ITEM_1' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_GDPR_ITEM_2' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_GDPR_ITEM_3' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_GDPR_ITEM_4' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_GDPR_ITEM_5' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_GDPR_ITEM_6' | translate}}</li>
            </ul>

            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.GDPR_DATA_PROTECTION_RIGHTS_EXERCISING' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.GDPR_DATA_PROTECTION_RIGHTS_EXERCISING_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.GDPR_DATA_PROTECTION_RIGHTS_EXERCISING_P_2' | translate}}</p>
            
            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.CCPA_CPRA_PRIVACY_NOTICE' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_PRIVACY_NOTICE_P_1' | translate}}</p>

            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_INFORMATION_CATEGORIES_COLLECTED' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_P_2' | translate}}</p>
            <ul>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_1_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_1_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_YES_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_2_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_2_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_YES_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_3_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_3_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_NO_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_4_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_4_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_NO_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_5_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_5_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_NO_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_6_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_6_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_YES_ITEM' | translate}}</p>
                    
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_7_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_7_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_YES_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_8_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_8_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_NO_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_9_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_9_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_NO_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_10_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_10_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_NO_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_11_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_11_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_NO_ITEM' | translate}}</p>
                </li>
                <li>
                    <p wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_12_1' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_CATEGORIES_COLLECTED_ITEM_12_2' | translate}}</p>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.COLLECTED_YES_ITEM' | translate}}</p>
                </li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DOES_NOT_INCLUDE' | translate}}</p>
            <ul>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DOES_NOT_INCLUDE_ITEM_1' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DOES_NOT_INCLUDE_ITEM_2' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DOES_NOT_INCLUDE_ITEM_3' | translate}}
                    <ul>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DOES_NOT_INCLUDE_ITEM_3_1' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DOES_NOT_INCLUDE_ITEM_3_2' | translate}}</li>
                    </ul>
                </li>
            </ul>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_INFORMATION_SOURCES' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SOURCES_P_1' | translate}}</p>
            <ul>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SOURCES_ITEM_1' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SOURCES_ITEM_2' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SOURCES_ITEM_3' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SOURCES_ITEM_4' | translate}}</li>
            </ul>
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_INFORMATION_USAGE' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_P_1' | translate}}</p>
            <ul>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_ITEM_1' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_ITEM_2' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_ITEM_3' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_ITEM_4' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_ITEM_5' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_ITEM_6' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_ITEM_7' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_ITEM_8' | translate}}</li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_USAGE_P_3' | translate}}</p>
            
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_INFORMATION_DISCLOSURE' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DISCLOSURE_P_1' | translate}}</p>
            <ul>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DISCLOSURE_ITEM_1' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DISCLOSURE_ITEM_2' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DISCLOSURE_ITEM_3' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DISCLOSURE_ITEM_4' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DISCLOSURE_ITEM_5' | translate}}</p></li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DISCLOSURE_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_DISCLOSURE_P_3' | translate}}</p>

            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_INFORMATION_SHARE' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SHARE_P_1' | translate}}</p>
            <ul>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SHARE_ITEM_1' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SHARE_ITEM_2' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SHARE_ITEM_3' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SHARE_ITEM_4' | translate}}</p></li>
            </ul>

            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.PERSONAL_INFORMATION_SALE' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SALE_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SALE_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SALE_P_3' | translate}}</p>
            <ul>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SALE_ITEM_1' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SALE_ITEM_2' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SALE_ITEM_3' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SALE_ITEM_4' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SALE_ITEM_5' | translate}}</p></li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PERSONAL_INFORMATION_SALE_P_4' | translate}}</p>

            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.MINORS_UNDER_16_PERSONAL_INFORMATION_SALE' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.MINORS_UNDER_16_PERSONAL_INFORMATION_SALE_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.MINORS_UNDER_16_PERSONAL_INFORMATION_SALE_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.MINORS_UNDER_16_PERSONAL_INFORMATION_SALE_P_3' | translate}}</p>
            
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.RIGHTS_UNDER_THE_CCPA_CPRA' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_P_1' | translate}}</p>
            <ul>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_1' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_2' | translate}}
                    <ul>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_2_1' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_2_2' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_2_3' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_2_4' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_2_5' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_2_6' | translate}}
                            <ul>
                                <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_2_6_1' | translate}}</li>
                                <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_2_6_2' | translate}}</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_3' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_4' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_5' | translate}}</li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6' | translate}}
                    <ul>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6_1' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6_2' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6_3' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6_4' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6_5' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6_6' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6_7' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6_8' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_6_9' | translate}}</li>
                    </ul>
                </li>
                <li wrapWords>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_7' | translate}}
                    <ul>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_7_1' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_7_2' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_7_3' | translate}}</li>
                        <li>{{'TERMS_AND_CONDITIONS.CONTENT.RIGHTS_UNDER_THE_CCPA_CPRA_ITEM_7_4' | translate}}</li>
                    </ul>
                </li>
            </ul>
            
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_P_2' | translate}}</p>
            <ul>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_ITEM_1' | translate}}</p></li>
                <li>
                    <p>
                        {{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_ITEM_2' | translate}}
                        <a href="https://realplusonline.com/#contact" rel="external nofollow noopener" target="_blank">https://realplusonline.com/#contact</a>
                    </p>
                </li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_ITEM_3' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_ITEM_4' | translate}}</p></li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_P_3' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_P_4' | translate}}</p>
            <ul>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_ITEM_5' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_ITEM_6' | translate}}</li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_P_5' | translate}}</p>
            <ul>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_ITEM_7' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_ITEM_8' | translate}}</li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_P_6' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_P_7' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CCPA_CPRA_DATA_PROTECTION_RIGHTS_EXERCISING_P_8' | translate}}</p>
            
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.DO_NOT_SELL_PERSONAL_INFORMATION' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.DO_NOT_SELL_PERSONAL_INFORMATION_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.DO_NOT_SELL_PERSONAL_INFORMATION_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.DO_NOT_SELL_PERSONAL_INFORMATION_P_3' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.DO_NOT_SELL_PERSONAL_INFORMATION_P_4' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.DO_NOT_SELL_PERSONAL_INFORMATION_P_5' | translate}}</p>

            <h3>{{'TERMS_AND_CONDITIONS.HEADINGS.WEBSITE' | translate}}</h3>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.WEBSITE_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.WEBSITE_P_2' | translate}}</p>
            <ul>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.WEBSITE_ITEM_1' | translate}}
                    <a href="http://www.networkadvertising.org/choices/" rel="external nofollow noopener" target="_blank">http://www.networkadvertising.org/choices/</a>
                </li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.WEBSITE_ITEM_2' | translate}}
                    <a href="http://www.youronlinechoices.com/" rel="external nofollow noopener" target="_blank">http://www.youronlinechoices.com/</a>
                </li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.WEBSITE_ITEM_3' | translate}}
                    <a href="http://optout.aboutads.info/?c=2&amp;lang=EN"
                        rel="external nofollow noopener"
                        target="_blank">http://optout.aboutads.info/?c=2&amp;lang=EN</a>
                </li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.WEBSITE_P_3' | translate}}</p>
            <h3>{{'TERMS_AND_CONDITIONS.HEADINGS.MOBILE_DEVICES' | translate}}</h3>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.MOBILE_DEVICES_P_1' | translate}}</p>
            <ul>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.MOBILE_DEVICES_ITEM_1' | translate}}</li>
                <li>{{'TERMS_AND_CONDITIONS.CONTENT.MOBILE_DEVICES_ITEM_2' | translate}}</li>
            </ul>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.MOBILE_DEVICES_P_2' | translate}}</p>
            
            <h2>{{'TERMS_AND_CONDITIONS.HEADINGS.SENSITIVE_PERSONAL_INFORMATION_USE_OR_DISCLOSURE_LIMIT' | translate}}</h2>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.SENSITIVE_PERSONAL_INFORMATION_USE_OR_DISCLOSURE_LIMIT_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.SENSITIVE_PERSONAL_INFORMATION_USE_OR_DISCLOSURE_LIMIT_P_2' | translate}}</p>
            
            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.DO_NOT_TRACK_POLICY' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.DO_NOT_TRACK_POLICY_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.DO_NOT_TRACK_POLICY_P_2' | translate}}</p>
            
            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.SHINE_THE_LIGHT_LAW' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.SHINE_THE_LIGHT_LAW_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.SHINE_THE_LIGHT_LAW_P_2' | translate}}</p>

            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.PRIVACY_RIGHTS_FOR_MINOR_USERS' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_RIGHTS_FOR_MINOR_USERS_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_RIGHTS_FOR_MINOR_USERS_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_RIGHTS_FOR_MINOR_USERS_P_3' | translate}}</p>
            
            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.CHILDRENS_PRIVACY' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CHILDRENS_PRIVACY_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CHILDRENS_PRIVACY_P_2' | translate}}</p>
            
            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.OTHER_WEBSITES_LINKS' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.OTHER_WEBSITES_LINKS_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.OTHER_WEBSITES_LINKS_P_2' | translate}}</p>
            
            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.PRIVACY_POLICY_CHANGES' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_CHANGES_P_1' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_CHANGES_P_2' | translate}}</p>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.PRIVACY_POLICY_CHANGES_P_3' | translate}}</p>
            
            <h1>{{'TERMS_AND_CONDITIONS.HEADINGS.CONTACT_US' | translate}}</h1>
            <p>{{'TERMS_AND_CONDITIONS.CONTENT.CONTACT_US_P_1' | translate}}</p>
            <ul>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.CONTACT_US_ITEM_1' | translate}}</p></li>
                <li>
                    <p>{{'TERMS_AND_CONDITIONS.CONTENT.CONTACT_US_ITEM_2' | translate}}
                        <a href="https://realplusonline.com/#contact" rel="external nofollow noopener" target="_blank">https://realplusonline.com/#contact</a>
                    </p>
                </li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.CONTACT_US_ITEM_3' | translate}}</p></li>
                <li><p>{{'TERMS_AND_CONDITIONS.CONTENT.CONTACT_US_ITEM_4' | translate}}</p></li>
            </ul>
        </div>
    </div>
    <rpc-scroll-to-top></rpc-scroll-to-top>
</div>