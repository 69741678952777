import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { EncryptedCredential } from '@auth/models/encrypted-credential';
import { LoginData } from '@auth/models/login-data';
import { RegistrationData } from '@auth/models/registration-data';
import { TokenService } from '@auth/services/token.service';
import * as registrationActions from '@auth/store/actions/invitation.actions';
import * as loginActions from '@auth/store/actions/login.actions';
import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { Action } from '@ngrx/store';
import * as settingsActions from '@settings/store/actions/settings.actions';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RegisterApiService {

    constructor(
        private readonly http: ApiHttpClient
    ) { }

    public register(registrationData: RegistrationData): Observable<Action> {
        const registrationEndpoint = registrationData.primaryCustomerId == null
            ? 'auth/register'
            : 'auth/register/child';
        return this.http.post(registrationEndpoint, registrationData).pipe(
            switchMap((registrationResponse: ApiDataResult<LoginData | EncryptedCredential>) => {

                const encryptedCredentials = registrationResponse.result as EncryptedCredential;
                if (encryptedCredentials.encryptedAccountName != null && encryptedCredentials.encryptedPassword != null) {
                    return [loginActions.setLoginProcessData({ credentials: encryptedCredentials })];
                }

                const user = TokenService.processUserData(registrationResponse.result as LoginData);

                return [
                    settingsActions.saveLoadedSettings(user.loginData.customerSettings, true),
                    registrationActions.registrationSuccessful(user),
                    registrationActions.addLoginActivityAfterRegistration()
                ];
            }),
            catchError((errorResponse: HttpErrorResponse) => of(registrationActions.registrationFailed(errorResponse.error as ApiError)))
        );
    }
}
