import { CommonListing } from '@listings/models/listing/common-listing';
import { ListingInfo } from '@listings/models/listing/listing-info';
import { FormattedMedia } from '@media/models/formatted-media';
import { ListingFolderIdsMap } from '../listing-folder-ids-map';

export class ListingFolderManagerInfo extends ListingInfo {
    public readonly foldersIds: number[];
    public readonly isPickCandidate: boolean;

    constructor(
        listing: CommonListing,
        listingMedia: FormattedMedia,
        listingFolderIdsMap: ListingFolderIdsMap[],
        isHideOpenRentals: boolean
    ) {
        super(listing, listingMedia, isHideOpenRentals);

        this.foldersIds = listingFolderIdsMap
            .filter(({ listingId }) => listingId === listing.hashCode)
            .map(({ folderId }) => folderId);
        this.isPickCandidate = listing.activities.length === 0 || listing.isNewMatch;
    }
}