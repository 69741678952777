import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from '@thisissoon/angular-inviewport';

import { CommentsComponent } from '@comments/components/comments/comments.component';
import { locale as english } from '@comments/i18n/en';
import { CommentsEffects } from '@comments/store/effects/comments.effects';
import { commentsReducer } from '@comments/store/reducers/comments.reducer';
import { RpcControlsModule } from '@core-controls/controls.module';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { CustomSpinnerModule } from '@core-layout/custom-spinner/custom-spinner.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { UsersModule } from '@users/users.module';

@NgModule({
    declarations: [
        CommentsComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        InViewportModule,
        MatDividerModule,

        // Application Modules
        RpcDirectivesModule,
        CustomSpinnerModule,
        RpcIconModule,
        UsersModule,
        RpcControlsModule,

        // Module Configuration
        StoreModule.forFeature('comments', commentsReducer),
        EffectsModule.forFeature([CommentsEffects])
    ],
    exports: [
        CommentsComponent
    ]
})
export class CommentsModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}