import { ActionReducer, createReducer, on } from '@ngrx/store';

import { invitationGotSuccessful, registrationSuccessful } from '@auth/store/actions/invitation.actions';
import { collaborationSpaceLoginSuccessful, loginSuccessful, logout, logoutSuccessful, refreshSuccessful, removeLoginProcessData, setLoginProcessData } from '@auth/store/actions/login.actions';
import { AuthState } from '@auth/store/states/auth.state';
import * as switchCollaborationSpaceActions from '../actions/switch-collaboration-space.actions';

export const authReducer = createReducer(
    new AuthState(),
    on(loginSuccessful, collaborationSpaceLoginSuccessful, (state, action) => {
        return {
            ...state,
            user: action.user
        };
    }),
    on(refreshSuccessful, (state, action) => {
        return {
            ...state,
            user: action
        };
    }),
    on(registrationSuccessful, (state, action) => {
        return {
            ...state,
            user: action
        };
    }),
    on(invitationGotSuccessful, (state, action) => {
        return {
            ...state,
            invitation: action
        };
    }),
    on(logout, (state) => {
        return {
            ...state,
            user: null
        };
    }),
    on(logoutSuccessful, (state) => {
        return {
            ...state,
            user: null
        };
    }),
    on(setLoginProcessData, (state, action) => {
        return {
            ...state,
            loginProcessData: action.credentials
        };
    }),
    on(removeLoginProcessData, (state) => {
        return {
            ...state,
            loginProcessData: null
        };
    }),
    on(switchCollaborationSpaceActions.loadCollaborationSpacesSuccessful, (state, { collaborationSpaces }) => {
        return ({ ...state, collaborationSpaces });
    }),
    on(switchCollaborationSpaceActions.loadHasMultipleCollaborationSpacesSuccessful, (state, { hasMultipleCollaborationSpaces }) => {
        return ({ ...state, hasMultipleCollaborationSpaces });
    }),
);

export function logoutMetaReducer(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
    return function (state, action) {
        // eslint-disable-next-line no-undefined
        return reducer(action.type === logoutSuccessful.type ? undefined : state, action);
    };
}