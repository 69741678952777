<div class="agent-box" [ngClass]="{ 'agent-box--active': isSelected && !isCurrent, 'agent-box--current' : isCurrent }">
    <avatars-icon class="agent-box-avatar" [front]="front" [back]="back" [displayIcon]="isSharedSpace"
        iconName="shared-space">
    </avatars-icon>
    <div class="agent-box-name text-truncate" [title]="fullNames">{{ fullNames }}</div>
    <div class="agent-box-status" *ngIf="isCurrent">{{ 'AGENT_BOX.TITLES.CURRENT_AGENT' | translate }}</div>
    <div class="agent-box-login" *ngIf="agentCandidate.lastLoginDate != null">
        {{ 'AGENT_BOX.TITLES.LAST_LOGIN' | translate }} {{ agentCandidate.lastLoginDate | date: DateConstants.Formats.AngularPipeShortDateSlash }}
    </div>
    <div class="agent-box-title">{{ agentOwner.executiveTitle }}</div>
    <div class="agent-box-company">{{ agentOwner.companyName }}</div>
    <div class="agent-box-info">
        <div class="agent-box-info-item" [ngClass]="{'agent-box-info-item--new' : agentCandidate.hasNewListings}">
            <rpc-icon [iconName]="'listing-in-building'" class="agent-box-info-item-icon"></rpc-icon>
            <div class="agent-box-info-item-text">{{agentCandidate.listingsCount}}</div>
        </div>
        <div class="agent-box-info-item">
            <rpc-icon [iconName]="'like'" class="agent-box-info-item-icon"></rpc-icon>
            <div class="agent-box-info-item-text">{{agentCandidate.likedListingsCount}}</div>
        </div>
        <div class="agent-box-info-item" [ngClass]="{'agent-box-info-item--new' : agentCandidate.hasUnreadComments}">
            <rpc-icon [iconName]="'comments'" class="agent-box-info-item-icon"></rpc-icon>
            <div class="agent-box-info-item-text">{{agentCandidate.commentsCount}}</div>
        </div>
        <div class="agent-box-info-item" [ngClass]="{'agent-box-info-item--new' : agentCandidate.hasUnreadAppointments}">
            <rpc-icon [iconName]="'appointment'" class="agent-box-info-item-icon"></rpc-icon>
            <div class="agent-box-info-item-text">{{agentCandidate.appointmentsCount}}</div>
        </div>
    </div>
</div>