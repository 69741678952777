import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Output } from '@angular/core';
import { ResizeSensor } from 'css-element-queries';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ResizedEventParameters } from './resized-event-parameters';

@Directive({ selector: '[rpcResized]', exportAs: 'rpcResizedApi' })
export class ResizedDirective implements OnChanges, AfterViewInit, OnDestroy {

    @Input('rpcResized') calculationAllowed: boolean;

    @Output() public readonly resized = new EventEmitter<ResizedEventParameters>();

    private resizeSensor: ResizeSensor;
    private oldWidth: number;
    private oldHeight: number;

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly ngZone: NgZone
    ) { }

    public ngOnChanges(changes: SimpleChanges<ResizedDirective>): void {
        if (changes != null && changes.calculationAllowed != null && !changes.calculationAllowed.firstChange) {
            if (changes.calculationAllowed.currentValue) {
                this.subscribe();
            } else {
                this.unsubscribe();
            }
        }
    }

    public ngAfterViewInit(): void {
        if (this.calculationAllowed) {
            this.subscribe();
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    private subscribe(): void {
        if (ResizeSensor != null) {
            this.ngZone.runOutsideAngular(() => {
                this.resizeSensor = new ResizeSensor(this.elementRef.nativeElement, () => this.onResized());
            });
        }
    }

    private unsubscribe(): void {
        if (this.resizeSensor != null) {
            this.resizeSensor.detach();
        }
    }

    private onResized(): void {
        const newWidth = this.elementRef.nativeElement.clientWidth;
        const newHeight = this.elementRef.nativeElement.clientHeight;

        if (newWidth === this.oldWidth && newHeight === this.oldHeight) {
            return;
        }

        const resizedEventParameters = new ResizedEventParameters(
            this.elementRef,
            newWidth,
            newHeight,
            this.oldWidth,
            this.oldHeight
        );

        this.oldWidth = this.elementRef.nativeElement.clientWidth;
        this.oldHeight = this.elementRef.nativeElement.clientHeight;

        this.resized.emit(resizedEventParameters);
    }
}