export const locale = {
    lang: 'en',
    data: {
        LABEL_CONTRIBUTORS_LABEL: {
            LISTING_ACTIVITY_TYPES: {
                PICKED: 'Picked by',
                LIKED: 'Liked by',
                SHOWN: 'Shown by',
                DISLIKED: 'Disliked by',
                ADDED: 'Added by',
            },
            SAVED_SEARCH: 'Saved Search',
            SAVED_SEARCH2: 'Saved Search:',
            NEW_MATCH: 'New Match on ',
            AGENTS_SAVED_SEARCH: '{{ownerFullName}} Saved Search',
            FOLDER: 'Folder:',
        }
    }
};