import { createAction, props } from '@ngrx/store';
import { NotificationEventEntity } from '@notifications/enums/notification-event-entity';

import { CreatorNotifications } from '@notifications/models/creator-notifications';
import { NotificationApiItem } from '@notifications/models/notification-api-item';
import { NotificationGroupItem } from '@notifications/models/notification-group-item';
import { StoreNotification } from '@notifications/models/store-notification';

export const setAppointmentsVisualizationAllowed = createAction(
    '[Notification] Set Appointments Visualization Allowed',
    props<{ isAllowed: boolean }>());

export const resetState = createAction('[Notification] Reset State');
export const setActiveTabIndex = createAction('[Notification] Set Active Tab Index', props<{ index: number }>());

export const loadNotifications = createAction('[Notification] Load Notifications');
export const loadNotificationsSuccess = createAction('[Notification] Load Notifications Success', props<{ apiNotifications: NotificationApiItem[] }>());
export const loadNotificationsFailed = createAction('[Notification] Load Notifications Failed');

export const setNotifications = createAction('[Notification] Set Notifications', props<{ notifications: StoreNotification[] }>());

export const showOnlyUnviewedFilterChanged = createAction('[Notification] Show Only Unviewed Filter Changed', props<{ value?: boolean }>());

export const viewUnviewNotifications = createAction('[Notification] View Unview Notifications', props<{ notificationIds: number[] }>());
export const viewUnviewNotificationsSuccess = createAction('[Notification] View Unview Notifications Success');
export const viewUnviewNotificationsFailed = createAction('[Notification] View Unview Notifications Failed', props<{ notificationIds: number[] }>());

export const viewUnviewCreatorNotifications = createAction('[Notification] View Unview Creator Notifications', props<{ creatorNotifications: CreatorNotifications }>());
export const viewUnviewGroupNotifications = createAction('[Notification] View Unview Group Notifications', props<{ group: NotificationGroupItem }>());
export const markAllViewed = createAction('[Notification] Mark All Viewed');

export const notificationAdded = createAction('[Notification] Notification Added', props<{ notification: NotificationApiItem }>());
export const listingNotificationAdded = createAction('[Notification] Listing Notification Added', props<{ notification: NotificationApiItem }>());
export const folderNotificationAdded = createAction('[Notification] Folder Notification Added', props<{ notification: NotificationApiItem }>());
export const appointmentDeletedNotificationAdded = createAction('[Notification] Appointment Deleted Notification Added', props<{ notification: NotificationApiItem }>());

export const notificationCreationSuccess = createAction('[Notification] Notification Creation Success');

export const notificationCreationFailure = createAction('[Notification] Notification Creation Failure');
export const redirectToNotificationEntity = createAction('[Notification] Redirect To Notification Entity', props<{ notificationId: number, goToAll?: boolean }>());

export const showPushNotification = createAction('[Notification] Show Push Notification', props<{ notification: NotificationApiItem }>());

export const removeListingsNotificationsSuccess = createAction('[Notification] Remove Listings Notifications Success', props<{ listingsHashCodes: number[] }>());
export const removeListingsNotificationsFailed = createAction('[Notification] Remove Listings Notifications Failed');

export const removeNotifications = createAction('[Notification] Remove Notifications', props<{ ids: number[] }>());
export const removeNotificationsSuccess = createAction('[Notification] Remove Notifications Success', props<{ ids: number[] }>());
export const removeNotificationsFailed = createAction('[Notification] Remove Notifications Failed');

export const removeEntitiesNotifications = createAction('[Notification] Remove Entities Notifications', props<{ entitiesIds: number[], entityType: NotificationEventEntity }>());
export const removeEntitiesNotificationsSuccess = createAction('[Notification] Remove Entities Notifications Success', props<{ entitiesIds: number[] }>());
export const removeEntitiesNotificationsFailed = createAction('[Notification] Remove Entities Notifications Failed');