import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { SignalRService } from '@notifications/services/signalr.service';
import { NotificationsStoreService } from '@notifications/store/services/notifications-store.service';
import { NotificationApiItem } from "@notifications/models/notification-api-item";
import { HUB_LISTENER_NAME, HUB_NAME } from '@notifications/constants/communication.constants';

@Injectable({ providedIn: 'root' })
export class CommunicationSignalRService {

    private readonly subscribtionsHolder: { [listenerName: string]: Subscription } = {};

    constructor(
        private readonly signalRService: SignalRService,
        private readonly notificationsStoreService: NotificationsStoreService,
    ) { }

    public createNotificationHub = (tokenObservable: Observable<string>): void => {
        this.signalRService.createHub(HUB_NAME, tokenObservable);
    }

    public removeNotificationHub = (): void => {
        this.removeHubListeners();
        this.signalRService.removeHub(HUB_NAME);
    }

    public createHubListeners = (): void => {
        this.unsubscribe(HUB_LISTENER_NAME);

        const notificationsSubscription = this.signalRService
            .createHubListener<{ notifications: NotificationApiItem[] }>(HUB_LISTENER_NAME)
            .subscribe(notificationsData => notificationsData.notifications.forEach(x => this.notificationsStoreService.notificationAdded(x)));

        this.subscribtionsHolder[HUB_LISTENER_NAME] = notificationsSubscription;
    }

    public removeHubListeners = (): void => {
        [HUB_LISTENER_NAME].forEach(listenerName => {
            this.signalRService.removeHubListener(listenerName);
            this.unsubscribe(listenerName);
        });
    }

    private unsubscribe(listenerName: string): void {
        const currentSubscription = this.subscribtionsHolder[listenerName];
        if (currentSubscription != null) {
            currentSubscription.unsubscribe();
        }
    }
}