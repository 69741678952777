import { UserBaseInfo } from "@users/models/user-base-info";
import { AppointmentNotification, CommentNotification, ListingNotification } from "./notification-data";
import { NotificationItemBase } from "./notification-item-base";

export class NotificationPlainItem extends NotificationItemBase {
    readonly notificationId: number;
    readonly entityId: number;
    readonly listingId: number;
    readonly listingAddress: string;
    readonly dateCreated: Date;
    readonly data: AppointmentNotification | CommentNotification | ListingNotification;
    readonly creator: UserBaseInfo;
}