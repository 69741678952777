import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';

@Directive({ selector: 'mat-tab-group[rpcScrollFix]' })
export class RpcMatTabGroupScrollFixDirective implements AfterViewInit, OnDestroy {

    private readonly matTabGroupHtmlElement: HTMLElement;
    private animationSubscription: Subscription;

    constructor(
        private readonly matTabGroup: MatTabGroup,
        private readonly elementRef: ElementRef
    ) {
        this.matTabGroupHtmlElement = this.elementRef.nativeElement as HTMLElement;
        this.animationSubscription = Subscription.EMPTY;
    }

    public ngAfterViewInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const originalClickHandler = this.matTabGroup._handleClick.bind(this.matTabGroup);

        this.matTabGroup._handleClick = (tab, tabHeader, index) => {
            if (!tab.disabled) {
                this.matTabGroupHtmlElement.style.minHeight = `${this.matTabGroupHtmlElement.clientHeight}px`;
            }

            return originalClickHandler(tab, tabHeader, index);
        };

        this.animationSubscription = this.matTabGroup.animationDone.subscribe(() => {
            this.matTabGroupHtmlElement.style.minHeight = 'unset';
        });
    }

    public ngOnDestroy(): void {
        this.animationSubscription.unsubscribe();
    }
}