export const locale = {
    lang: 'en',
    data: {
        NOTIFICATION_CHECKBOX: {
            TITLES: {
                MARK_AS_READ: 'Mark as Read',
                MARK_AS_UNREAD: 'Mark as Unread'
            }
        }
    }
};