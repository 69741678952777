import { ServerMessagesMapping } from '@core-models/messages/server-messages-mapping';

export const serverMessagesMapping: ServerMessagesMapping = {
    Restore_Password_Change_Error: {
        code: 400,
        messageTranslationPath: 'RESTORE_PASSWORD.ERRORS.SERVER.PASSWORD_CHANGE_ERROR',
        title: ''
    },
    Restore_Invalid_Code: {
        code: 400,
        messageTranslationPath: 'RESTORE_PASSWORD.ERRORS.SERVER.INVALID_RESTORE_CODE',
        title: ''
    },
    Restore_Code_Expired: {
        code: 400,
        messageTranslationPath: 'RESTORE_PASSWORD.ERRORS.SERVER.RESTORE_CODE_EXPIRED',
        title: ''
    },
    Restore_Customer_Not_Found: {
        code: 400,
        messageTranslationPath: 'RESTORE_PASSWORD.ERRORS.SERVER.CUSTOMER_NOT_FOUND',
        title: ''
    },
    Restore_Link_Is_Stale: {
        code: 400,
        messageTranslationPath: 'RESTORE_PASSWORD.ERRORS.SERVER.RESTORE_LINK_IS_STALE',
        title: ''
    },
    Send_Restore_Link_Error:
    {
        code: 400,
        messageTranslationPath: 'RESTORE_PASSWORD.ERRORS.SERVER.SEND_RESTORE_LINK_ERROR',
        title: ''
    }
};