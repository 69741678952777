import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

import * as agentsActions from '../actions/agents.actions';
import { AgentsApiService } from '../services/agents-api.service';

@Injectable()
export class AgentsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly agentsApiService: AgentsApiService
    ) { }

    public readonly loadAgents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(agentsActions.loadAgents),
            switchMap(() => this.agentsApiService.loadAgents())
        )
    );
}