import { createAction, props } from '@ngrx/store';

import { Invitation } from '@auth/models/invitation';
import { RegistrationData } from '@auth/models/registration-data';
import { User } from '@auth/models/user';
import { ApiError } from '@error/models/api-error';

export const getInvitation = createAction('[Auth] Get Invitation', props<{ inviteCode: string }>());
export const invitationGotSuccessful = createAction('[Auth] Invitation Got Successful', (invitation: Invitation) => invitation);
export const invitationGotFail = createAction('[Auth] Invitation Got Fail', (error: ApiError) => error);

export const registration = createAction('[Auth] Registration', props<{ registrationData: RegistrationData }>());
export const registrationSuccessful = createAction('[Auth] Registration Successful', (user: User) => user);
export const registrationFailed = createAction('[Auth] Registration Failed', (error: ApiError) => error);

export const addLoginActivityAfterRegistration = createAction('[Auth] Add Login Activity After Registration');