
export enum NotificationEventEntity {
    Listing = 'LISTING',
    ListingActivity = 'LISTING_ACTIVITY',
    ExternalListing = 'EXTERNAL_LISTING',
    Appointment = 'APPOINTMENT',
    Comment = 'COMMENT',
    NewMatch = 'NEW_MATCH',
    Folder = 'FOLDER'
}
