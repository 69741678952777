import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { Creator } from '@appointments/enums/creator.enum';
import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { AppointmentStatusDetails } from '../models/appointment-status-details';

type AppointmentStatusParams = Pick<ListingAppointment, 'agentStatus' | 'customerStatus' | 'startDateTime' | 'creator'>;
export type AppointmentStatusDetailsParams = Omit<AppointmentStatusParams, 'startDateTime'>;

@Injectable({ providedIn: 'root' })
export class AppointmentStatusService {

    /**
     * Calculates appointment status according to current time.
     * E.g. appointment start time is after current time and status is Confirmed than result would be Shown status.
     * @param appointment Appointment for calculation
     */
    public static calculateTimeDependentStatus({ customerStatus, agentStatus, startDateTime, creator }: AppointmentStatusParams): AppointmentStatus {
        if ([customerStatus, agentStatus].includes(AppointmentStatus.Declined)) {
            return AppointmentStatus.Declined;
        }
        if (moment(startDateTime).isBefore(new Date(), 'minute')) {
            const confirmed = customerStatus === agentStatus && customerStatus === AppointmentStatus.Confirmed;
            if (confirmed) {
                return AppointmentStatus.Shown;
            }
            const pending = [customerStatus, agentStatus].includes(AppointmentStatus.Pending);
            if (pending) {
                return AppointmentStatus.Declined;
            }
        }
        return creator === Creator.Agent ? customerStatus : agentStatus;
    }

    public static filterByStatus(appointments: ListingAppointment[], status: AppointmentStatus): ListingAppointment[] {
        if (appointments == null) {
            return [];
        }

        return appointments.filter(appointment => AppointmentStatusService.calculateTimeDependentStatus(appointment) === status);
    }

    public static getStatusDetails(appointmentStatusDetailsParams: AppointmentStatusDetailsParams, status: AppointmentStatus): AppointmentStatusDetails {
        return {
            isPendingCreatedByAgent: AppointmentStatusService.isAgentRequestedAppointmentIsPending(appointmentStatusDetailsParams, status),
            isPendingCreatedByCustomer: AppointmentStatusService.isCustomerRequestedAppointmentIsPending(appointmentStatusDetailsParams, status),
            isPending: status === AppointmentStatus.Pending,
            isConfirmed: status === AppointmentStatus.Confirmed,
            isDeclined: status === AppointmentStatus.Declined,
            isShown: status === AppointmentStatus.Shown
        };
    }

    public static isPendingAppointment(appointment: ListingAppointment, adjustStatusUsingCurrentTime: boolean = true): boolean {
        if (adjustStatusUsingCurrentTime) {
            return AppointmentStatusService.calculateTimeDependentStatus(appointment) === AppointmentStatus.Pending;
        }
        return appointment.agentStatus === AppointmentStatus.Pending || appointment.customerStatus === AppointmentStatus.Pending;
    }

    public static isAgentRequestedAppointmentIsPending({ customerStatus, creator }: AppointmentStatusDetailsParams, status: AppointmentStatus, adjustStatusUsingCurrentTime: boolean = true): boolean {
        const statusToCompare = adjustStatusUsingCurrentTime ? status : customerStatus;

        return creator === Creator.Agent && statusToCompare === AppointmentStatus.Pending;
    }

    public static isCustomerRequestedAppointmentIsPending({ agentStatus, creator }: AppointmentStatusDetailsParams, status: AppointmentStatus, adjustStatusUsingCurrentTime: boolean = true): boolean {
        const statusToCompare = adjustStatusUsingCurrentTime ? status : agentStatus;

        return creator === Creator.Customer && statusToCompare === AppointmentStatus.Pending;
    }
}
