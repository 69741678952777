import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ScrollService } from '@core-layout/scroll-to-top/scroll.service';

@Component({
    selector: 'rpc-scroll-to-top',
    templateUrl: './scroll-to-top.component.html',
    styleUrls: ['./scroll-to-top.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollToTopComponent implements OnInit {

    private readonly unsubscribe$ = new Subject<void>();

    public isScrollToTopVisible = false;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly scrollService: ScrollService
    ) { }

    public ngOnInit(): void {
        this.scrollService.scrollToTopVisibility$
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
            .subscribe((isScrollToTopVisible) => {
                this.isScrollToTopVisible = isScrollToTopVisible;
                this.changeDetectorRef.detectChanges();
            });
    }

    public onScrollToTopClicked(): void {
        this.scrollService.scrollToTop();
    }
}