<div class="agent-short-info-wrap">
    <avatar-initials class="agent-short-info-img" [userFullName]="agent.fullName"
        [userProfileImageUrl]="agent.profilePictureUrl">
    </avatar-initials>
    <div class="agent-short-info">
        <div class="agent-short-info-item agent-name text-truncate" title="{{agent.fullName}}">
            {{agent.fullName}}
        </div>
        <div *ngIf="showTitles && agent.executiveTitle" class="agent-short-info-item text-truncate"
            title="{{agent.executiveTitle}}">
            {{agent.executiveTitle}}
        </div>
        <div *ngIf="showTitles && agent.dosTitle" class="agent-short-info-item text-truncate"
            title="{{agent.dosTitle}}">
            {{agent.dosTitle}}
        </div>
        <div class="agent-short-info-item text-truncate" title="{{agent.email}}">
            <a href="mailto:{{agent.email}}" class="link">
                {{agent.email}}
            </a>
        </div>
        <div *ngIf="agent.phone" class="agent-short-info-item text-truncate" title="{{agent.phone}}">
            <a href="tel:{{agent.phone}}" class="link">
                {{agent.phone}}
            </a>
        </div>
        <div class="agent-short-info-item text-truncate" title="{{agent.companyName}}">
            {{agent.companyName}}
        </div>
    </div>
</div>