import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { Folder } from '@folders/models/folder';
import { ListingFolderManagerInfo } from '@folders/models/folder-management/listing-folder-manager-info';
import * as folderSavedSearchSelectors from '../selectors/folder-saved-search.selectors';
import * as foldersSelectors from '../selectors/folders.selectors';
import { FoldersState } from '../states/folders.state';

@Injectable({ providedIn: 'root' })
export class FoldersStoreReadService {
    constructor(
        private readonly store: Store<FoldersState>,
        private readonly translateService: TranslateService
    ) { }

    public readonly sortedCustomFoldersCover$ = this.store.select(foldersSelectors.selectSortedCustomFoldersCover);
    public readonly systemFoldersCover$ = this.store.select(foldersSelectors.selectSystemFoldersCover);
    public readonly folderListings$ = this.store.select(foldersSelectors.selectFolderListings);
    public readonly folderMediaMap$ = this.store.select(foldersSelectors.selectFolderMediaMap);
    public readonly areFoldersLoaded$ = this.store.select(foldersSelectors.selectAreFoldersLoaded);
    public readonly isLoading$ = this.store.select(foldersSelectors.selectIsLoading);
    public readonly selectedFolderId$ = this.store.select(foldersSelectors.selectSelectedFolderId);
    public readonly getSelectedFolder$ = this.store.select(foldersSelectors.selectSelectedFolder);
    public readonly customFoldersSortedByDate$ = this.store.select(foldersSelectors.selectCustomFoldersSortedByDate);
    public readonly systemFolders$ = this.store.select(foldersSelectors.selectSystemFolders);
    public readonly foldersError$ = this.store.select(foldersSelectors.selectFoldersError);
    public readonly foldersSort$ = this.store.select(foldersSelectors.selectFoldersSort);
    public readonly selectedFolder$ = this.store.select(foldersSelectors.selectSelectedFolder);
    public readonly selectedListingsFolderManagerInfo$ = this.store.select(foldersSelectors.selectSelectedListingsFolderManagerInfo);
    public readonly customFolders$ = this.store.select(foldersSelectors.selectCustomFolders);
    public readonly foldersSavedSearches$ = this.store.select(folderSavedSearchSelectors.selectFoldersSavedSearches);
    public readonly getListingsFolderIdsMapping$ = this.store.select(foldersSelectors.selectListingsFolders);
    public readonly getFolderNewMatchIds$ = this.store.select(foldersSelectors.selectNewMatchFolders);

    public getAllFoldersNames(): Observable<string[]> {
        return this.store.pipe(
            select(foldersSelectors.selectAllFolders),
            map(folders => folders.map(folder => folder.isSystemFolder ? this.translateService.instant('FOLDERS.TITLES.' + folder.name) : folder.name))
        );
    }

    public getFoldersByListingId(id: number): Observable<Folder[]> {
        return this.store.select(foldersSelectors.selectFoldersByListingId(id));
    }

    public getListingFolderManagerInfoById(listingId: string): Observable<ListingFolderManagerInfo> {
        return this.store.select(foldersSelectors.selectListingFolderManagerInfoById(listingId));
    }

    public getListingsIdsAttachedOnlyToThisFolder(id: number): Observable<string[]> {
        return this.store.select(foldersSelectors.selectListingsIdsAttachedOnlyToThisFolder(id));
    }

    public getHasListingsByFolderId(id: number): Observable<boolean> {
        return this.store.select(foldersSelectors.selectHasListingsByFolderId(id));
    }

    public getFolderIdAttachedToSavedSearch(savedSearchId: number): Observable<number | null> {
        return this.store.select(folderSavedSearchSelectors.selectFolderIdAttachedToSavedSearch(savedSearchId));
    }

    public getSavedSearchesNamesAttachedToFolder(folderId: number): Observable<string[]> {
        return this.store.select(folderSavedSearchSelectors.selectSavedSearchesNamesAttachedToFolder(folderId));
    }

    public getNewMatchesIdsNotInFolder(savedSearchId: number, folderId: number): Observable<number[]> {
        return this.store.select(foldersSelectors.selectNewMatchesIdsNotInFolder(savedSearchId, folderId));
    }

    public getFolderById(id: number): Observable<Folder | null> {
        return this.store.select(foldersSelectors.selectFolderById(id));
    }
}