import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RpcIconComponent } from '@core-utils/rpc-icon/rpc-icon.component';

@NgModule({
    declarations: [
        RpcIconComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        RpcIconComponent
    ]
})
export class RpcIconModule { }