import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'notification-checkbox',
    templateUrl: './notification-checkbox.component.html',
    styleUrls: ['./notification-checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCheckboxComponent {
    @Input() public checked = false;

    @Output() public cliked = new EventEmitter<void>();

    public onClick(event: MouseEvent) {
        event.stopPropagation();
        this.cliked.emit();
    }

}
