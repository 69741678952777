
export enum NotificationEventAction {
    ListingActivityAddedToPortfolio = 'LISTING_ACTIVITY_ADDED_TO_PORTFOLIO',
    ListingActivityShown = 'LISTING_ACTIVITY_SHOWN',
    ListingActivityLiked = 'LISTING_ACTIVITY_LIKED',
    ListingActivityDisliked = 'LISTING_ACTIVITY_DISLIKED',
    ListingActivityRemoved = 'LISTING_ACTIVITY_REMOVED',
    ExternalListingAdded = 'EXTERNAL_LISTING_ADDED',
    AppointmentCreated = 'APPOINTMENT_CREATED',
    AppointmentConfirmed = 'APPOINTMENT_ACCEPTED',
    AppointmentDeclined = 'APPOINTMENT_DECLINED',
    AppointmentUpdated = 'APPOINTMENT_UPDATED',
    AppointmentDeleted = 'APPOINTMENT_DELETED',
    CommentCreated = 'COMMENT_CREATED',
    CommentUpdated = 'COMMENT_UPDATED',
    NewMatchAdded = 'NEW_MATCH_ADDED',
    FolderAdded = 'FOLDER_ADDED',
    FolderUpdated = 'FOLDER_UPDATED',
    FolderRemoved = 'FOLDER_REMOVED',
    FolderListingsChanged = 'FOLDER_LISTINGS_CHANGED',
    ListingSoftDeleted = 'LISTING_SOFT_DELETED',
    ListingHardDeleted = 'LISTING_HARD_DELETED',
    ListingRestored = 'LISTING_RESTORED',
}