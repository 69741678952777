import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEqual } from 'lodash-es';

import * as userSelectors from '@auth/store/selectors/user.selector';
import { selectOnMarketSettings } from '@settings/store/selectors/settings.selector';
import { OnMarketState } from '../states/on-market.state';
import { OnMarketStateHelper } from '../states/on-market.state.helper';

const onMarketStateSelector = createFeatureSelector<OnMarketState>('on-market');

export const selectSort = createSelector(
    onMarketStateSelector,
    onMarketState => onMarketState == null ? null : onMarketState.sort
);

export const selectSearchOptions = createSelector(
    onMarketStateSelector,
    onMarketState => onMarketState == null ? null : onMarketState.searchOptions
);

export const selectSelectedViewMode = createSelector(
    onMarketStateSelector,
    onMarketState => onMarketState == null ? null : onMarketState.viewMode
);

export const selectMapSizePercent = createSelector(
    onMarketStateSelector,
    onMarketState => onMarketState == null ? null : onMarketState.mapSizePercent
);

export const selectNeighborhoodProperties = createSelector(
    onMarketStateSelector,
    onMarketState => onMarketState == null ? null : onMarketState.neighborhoodProperties
);

export const selectPreviousSearchOptions = createSelector(
    onMarketStateSelector,
    listingState => listingState == null ? null : listingState.previousSearchOptions
);

export const selectSavedSearches = createSelector(
    onMarketStateSelector,
    onMarketState => onMarketState?.savedSearches
);

export const selectSavedSearch = (savedSearchId: number) => createSelector(
    onMarketStateSelector,
    onMarketState => onMarketState?.savedSearches?.find(search => search.id === savedSearchId)
);

export const selectActiveSavedSearchId = createSelector(
    onMarketStateSelector,
    onMarketState => onMarketState?.activeSavedSearchId
);

export const selectActiveSavedSearchIdIfIsNotDefault = createSelector(
    selectActiveSavedSearchId,
    selectOnMarketSettings,
    (activeSavedSearchId, onMarketSettings) => {
        const defaultSavedSearchId = onMarketSettings?.defaultSavedSearchId;
        return (activeSavedSearchId != null && activeSavedSearchId !== defaultSavedSearchId) ? activeSavedSearchId : null;
    }
);

export const selectIsZeroSearchState = createSelector(
    selectSearchOptions,
    selectActiveSavedSearchId,
    (searchOptions, activeSavedSearchId) => {
        const isDefaultFiltersState = isEqual(searchOptions, OnMarketStateHelper.createDefaultSearchOptions(searchOptions.categoryId));
        return activeSavedSearchId == null && isDefaultFiltersState;
    }
);

export const selectSavedSearchIdsAbleEditDelete = createSelector(
    userSelectors.selectUser,
    selectSavedSearches,
    (user, savedSearches) => {
        const savedSearchesAbleEditDelete = savedSearches.filter(savedSearch => savedSearch.customerId === user.customerId);
        return savedSearchesAbleEditDelete.map(savedSearch => savedSearch.id);
    }
);

export const selectMapShapeOptions = createSelector(selectSearchOptions, ({ mapShapesSearchOptions }) => mapShapesSearchOptions);

export const selectMapShapesCount = createSelector(
    selectMapShapeOptions,
    selectSearchOptions,
    ({ shapes, circles }, { neighborhoodIds }) => shapes.length + circles.length + neighborhoodIds.length);
