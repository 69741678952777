import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MatchMediaService {

    private readonly mediaObserveDebounceTimeMs = 500;

    public activeMediaQuery: string;

    public onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public onAllActivatedBreakpointsMediaChange: BehaviorSubject<MediaChange[]> = new BehaviorSubject<MediaChange[]>([]);

    constructor(
        private readonly mediaObserver: MediaObserver
    ) {
        this.activeMediaQuery = '';
        this.initialize();
    }

    private initialize(): void {
        this.mediaObserver.asObservable()
            .pipe(
                debounceTime(this.mediaObserveDebounceTimeMs),
                filter((changes: MediaChange[]) => changes.length > 0),
                map((changes: MediaChange[]) => changes[0]),
                distinctUntilChanged()
            )
            .subscribe((change: MediaChange) => {
                if (this.activeMediaQuery !== change.mqAlias) {
                    this.activeMediaQuery = change.mqAlias;
                    this.onMediaChange.next(change.mqAlias);
                }
            });

        this.mediaObserver.asObservable()
            .pipe(
                debounceTime(this.mediaObserveDebounceTimeMs),
                filter((changes: MediaChange[]) => changes.length > 0),
                distinctUntilChanged()
            )
            .subscribe((changes: MediaChange[]) => {
                this.onAllActivatedBreakpointsMediaChange.next(changes);
            });
    }
}