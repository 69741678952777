import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';

import { CompanyConfiguration } from '@app-config/models/company-configuration';
import * as appActions from '@core-layout/app/store/actions/app.actions';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';

@Injectable()
export class AppEffects {
    constructor(private readonly actions$: Actions, private readonly http: ApiHttpClient) { }

    loadDomainCompanyConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadDomainCompanyConfiguration),
        switchMap(() => this.http.get('configuration/domain-company')
            .pipe(
                map((companyConfiguration: CompanyConfiguration) => appActions.loadCompanyConfigurationSuccess(companyConfiguration)),
                catchError((errorResponse: HttpErrorResponse) => of(appActions.loadCompanyConfigurationFailed(errorResponse.error as ApiError))))),
        share()
    ));

    loadCompanyConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadCompanyConfiguration),
        switchMap((company) => this.http.get(`configuration/company?companyId=${company.companyId}`)
            .pipe(
                map((companyConfiguration: CompanyConfiguration) => appActions.loadCompanyConfigurationSuccess(companyConfiguration)),
                catchError((errorResponse: HttpErrorResponse) => of(appActions.loadCompanyConfigurationFailed(errorResponse.error as ApiError))))),
        share()
    ));

    loadCompanyConfigurationByInternalCompanyId$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadCompanyConfigurationByInternalId),
        switchMap((company) => this.http.get(`configuration/company-by-internal-id?internalCompanyId=${company.internalCompanyId}`)
            .pipe(
                map((companyConfiguration: CompanyConfiguration) => appActions.loadCompanyConfigurationSuccess(companyConfiguration)),
                catchError((errorResponse: HttpErrorResponse) => of(appActions.loadCompanyConfigurationFailed(errorResponse.error as ApiError))))),
        share()
    ));

}