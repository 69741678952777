import { ElementRef } from '@angular/core';

export class ResizedEventParameters {
    public readonly element: ElementRef;
    public readonly newWidth: number;
    public readonly newHeight: number;
    public readonly oldWidth: number;
    public readonly oldHeight: number;

    constructor(element: ElementRef, newWidth: number, newHeight: number, oldWidth: number, oldHeight: number) {
        this.element = element;
        this.newWidth = newWidth;
        this.newHeight = newHeight;
        this.oldWidth = oldWidth;
        this.oldHeight = oldHeight;
    }
}