import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Agent } from '@agents/models/agent';
import { AuthState } from '@auth/store/states/auth.state';
import { selectSettings } from '@settings/store/selectors/settings.selector';

const authSelector = createFeatureSelector<AuthState>('auth');

export const selectLoginProcessData = createSelector(
    authSelector,
    authState => authState?.loginProcessData
);

export const isAuthorized = createSelector(
    authSelector,
    authState => authState != null && authState.user != null
);

export const selectAccessToken = createSelector(
    authSelector,
    authState => authState == null || authState.user == null || authState.user.loginData == null
        ? null : authState.user.loginData.accessToken
);

export const selectUser = createSelector(
    authSelector,
    authState => authState != null ? authState.user : null
);

export const selectCustomerId = createSelector(selectUser, user => user?.customerId);

export const selectPrimaryCustomerId = createSelector(
    selectUser,
    user => user?.primaryCustomerId
);

export const selectCustomerCollaborationId = createSelector(
    selectUser,
    user => Number(user?.customerCollaborationId ?? 0)
);

export const selectIsPrimaryCustomer = createSelector(
    selectUser,
    selectPrimaryCustomerId,
    (user, primaryCustomerId) => user != null && primaryCustomerId == null
);

export const selectCanSeeAdditionalProfiles = createSelector(
    selectIsPrimaryCustomer,
    selectSettings,
    (isPrimaryCustomer, settings) => isPrimaryCustomer && settings?.permissionSettings?.allowMultipleProfiles
);

export const selectCustomerFullName = createSelector(
    authSelector,
    authState => authState != null
        ? authState.user != null
            ? `${authState.user.customerFirstName} ${authState.user.customerLastName}`
            : null
        : null
);

export const selectCustomerProfileImage = createSelector(
    authSelector,
    authState => authState != null
        ? authState.user != null
            ? authState.user.customerProfilePictureUrl
            : null
        : null
);

export const selectAgent = createSelector(
    selectUser,
    (user): Agent => {
        return user == null
            ? null
            : {
                id: user.agentId,
                fullName: user.agentFullName,
                email: user.agentEmail,
                phone: user.agentPhone,
                profilePictureUrl: user.agentProfilePictureUrl,
                companyName: user.companyName,
                dosTitle: user.agentDosTitle,
                executiveTitle: user.agentExecutiveTitle
            };
    }
);

export const selectCompanyId = createSelector(
    authSelector,
    authState => authState?.user?.companyId
);

export const selectAgentFullName = createSelector(
    authSelector,
    authState => authState != null
        ? authState.user != null
            ? authState.user.agentFullName
            : null
        : null
);

export const selectAgentProfileImage = createSelector(
    authSelector,
    authState => authState != null
        ? authState.user != null
            ? authState.user.agentProfilePictureUrl
            : null
        : null
);

export const selectCustomerProfilePictureUrl = createSelector(
    authSelector,
    authState => authState?.user?.customerProfilePictureUrl
);