import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { ToastService } from '@core-services/toast.service';
import * as listingsActions from '../actions/listings.actions';
import { ListingsStoreService } from '../services/listings-store.service';

@Injectable()
export class ListingDirectionsEffects {

    private readonly maximumDirectionListingsCount = 10;
    private readonly directionListingsNotificationTimeout = 3500;

    constructor(
        private readonly actions$: Actions,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly toastService: ToastService,
    ) { }

    public readonly showDirections$ = createEffect(
        () => this.actions$.pipe(
            ofType(listingsActions.showDirections),
            concatLatestFrom(() => this.listingsStoreService.selectedListingsWithoutOpenRentals$),
            tap(([, selectedListings]) => {
                let timeout = 0;
                if (selectedListings.length >= this.maximumDirectionListingsCount) {
                    this.toastService.showClientWarning('LISTINGS_COMMON.MESSAGES.DIRECTION_LENGTH');
                    timeout = this.directionListingsNotificationTimeout;
                }

                const mapInitializationAction = () => {
                    const listingDirections = selectedListings
                        .slice(0, this.maximumDirectionListingsCount)
                        .map(({ property: { address, zipcode } }) => `${address} ${zipcode}/`)
                        .join();

                    const link = `https://www.google.com/maps/dir/${listingDirections}`;
                    window.open(link, 'blank');
                };
                setTimeout(mapInitializationAction, timeout);
            }),
        ),
        { dispatch: false }
    );
}