<div *ngIf="isIconButtons && !isColored"
    class="activities-wrap activities-wrap--combined-icon activities-wrap--removed">
    <rpc-icon-button [iconName]="'restore'" [title]="'REMOVED_ACTIVITY_CONTROLS.TITLES.RESTORE'"
        [enableCustomBackground]="enableCustomBackground" (clicked)="onRestoreClicked()"></rpc-icon-button>
    <rpc-icon-button [iconName]="'delete-bin'" [title]="'REMOVED_ACTIVITY_CONTROLS.TITLES.DELETE'"
        [enableCustomBackground]="enableCustomBackground" (clicked)="onDeleteListingClicked()"></rpc-icon-button>
    <ng-container *ngTemplateOutlet="extraActions"></ng-container>
</div>
<div *ngIf="isIconButtons && isColored" class="activities-wrap activities-wrap--combined-icon activities-wrap--removed">
    <rpc-icon-button [color]="'accent'" [iconName]="'restore'" [title]="'REMOVED_ACTIVITY_CONTROLS.TITLES.RESTORE'"
        [enableCustomBackground]="enableCustomBackground" (clicked)="onRestoreClicked()"></rpc-icon-button>
    <rpc-icon-button [color]="'accent'" [iconName]="'delete-bin'" [title]="'REMOVED_ACTIVITY_CONTROLS.TITLES.DELETE'"
        [enableCustomBackground]="enableCustomBackground" (clicked)="onDeleteListingClicked()"></rpc-icon-button>
    <ng-container *ngTemplateOutlet="extraActions"></ng-container>
</div>
<div *ngIf="!isIconButtons" class="activity-controls-wrapper activity-controls-wrapper--removed"
    [ngClass]="{'activity-controls-wrapper--base' : baseColor}">
    <rpc-button [appearanceType]="'normal'" [iconName]="'restore'" [title]="'REMOVED_ACTIVITY_CONTROLS.TITLES.RESTORE'"
        [enableCustomBackground]="enableCustomBackground" (clicked)="onRestoreClicked()"></rpc-button>
    <rpc-button [appearanceType]="'normal'" [iconName]="'delete-bin'"
        [title]="'REMOVED_ACTIVITY_CONTROLS.TITLES.DELETE'" [enableCustomBackground]="enableCustomBackground"
        (clicked)="onDeleteListingClicked()"></rpc-button>
    <ng-container *ngTemplateOutlet="extraActions"></ng-container>
</div>