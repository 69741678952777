import { Injectable, OnDestroy } from "@angular/core";
import { Subject, take } from "rxjs";
import { IndividualConfig } from "ngx-toastr";

import { TranslationInfo } from "@core-models/utilities/translation-info";
import { ToastService } from "@core-services/toast.service";
import { NotificationEventEntity } from "@notifications/enums/notification-event-entity";
import { ToastComponent } from "@toast/components/toast/toast.component";
import { AppointmentStatus } from "@appointments/enums/appointment-status.enum";
import { User } from "@auth/models/user";
import { NotificationApiItem } from "@notifications/models/notification-api-item";
import { APPOINTMENT_STATUS_DEPENDING_MESSAGES } from "@notifications/store/notifications.constants";
import { UserBaseInfo } from "@users/models/user-base-info";
import { UsersService } from "@users/services/users.service";

@Injectable({ providedIn: 'root' })
export class ListingCommunicationsService implements OnDestroy {
    public readonly openNotificationBellTab$ = new Subject<{ notificationType: NotificationEventEntity }>();

    constructor(private readonly toastService: ToastService) { }

    public ngOnDestroy(): void {
        this.openNotificationBellTab$.complete();
    }

    public showAppointmentToast(
        notification: NotificationApiItem,
        user: User,
        customersAndAgents: UserBaseInfo[],
        customers: UserBaseInfo[],
        status: AppointmentStatus
    ): void {
        const [statusDependingMessage, statusDependingMessageUserParticipate] = APPOINTMENT_STATUS_DEPENDING_MESSAGES.get(status);

        const creator = UsersService.getUserInfoOrRemovedAccount(customersAndAgents, notification.appointment.createId);
        const updater = UsersService.getUserInfoOrRemovedAccount(customersAndAgents, notification.appointment.updateId);

        const isPrimaryWithoutChildAccounts = customers.length == 1;
        const isUserAppointmentParticipant = isPrimaryWithoutChildAccounts || creator.customerId === user.customerId;

        const translationInfo: TranslationInfo = isUserAppointmentParticipant
            ? { key: statusDependingMessageUserParticipate, params: null }
            : { key: statusDependingMessage, params: { requestorFullName: creator.fullName, updaterFullName: updater.fullName } };

        this.showCustomerCollaborationToast(notification.entityType, translationInfo);
    }

    public showCustomerCollaborationToast(notificationType: NotificationEventEntity, translationInfo: TranslationInfo) {
        const sixSeconds = 6000;
        const config: Partial<IndividualConfig> = { timeOut: sixSeconds, extendedTimeOut: sixSeconds };
        const { componentRef, toast } = this.toastService.showCustomToast(ToastComponent, config)

        componentRef.instance.navigateToNotification.pipe(take(1)).subscribe(() => this.openNotificationBellTab$.next({ notificationType }));
        componentRef.instance.closed.pipe(take(1)).subscribe(() => toast.toastRef.close());

        componentRef.instance.translationInfo = translationInfo;

        toast.onTap.pipe(take(1)).subscribe(() => toast.toastRef.close());
    }
}