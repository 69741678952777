import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortNumber' })
export class ShortNumberPipe implements PipeTransform {

    private static readonly ShortNumbersLabels = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

    transform(value: number, isCurrency = false, fractionDigits = 2): string {
        if (value == null || Number.isNaN(value)) {
            return isCurrency ? '$0' : '0';
        }
        const i = value === 0 ? value : Math.floor(Math.log(value) / Math.log(1000));
        let result = parseFloat((value / Math.pow(1000, i)).toFixed(fractionDigits)).toString();
        result += `${ShortNumberPipe.ShortNumbersLabels[i]}`;
        return isCurrency ? '$' + result : result;
    }

}