export enum NewMatchMonthlyFrequency {
    Every1st = 3557,
    Every2nd = 3558,
    Every3rd = 3559,
    Every4th = 3560,
    Every5th = 3561,
    Every6th = 3562,
    Every7th = 3563,
    Every8th = 3564,
    Every9th = 3565,
    Every10th = 3566,
    Every11th = 3567,
    Every12th = 3568,
    Every13th = 3569,
    Every14th = 3570,
    Every15th = 3571,
    Every16th = 3572,
    Every17th = 3573,
    Every18th = 3574,
    Every19th = 3575,
    Every20th = 3576,
    Every21st = 3577,
    Every22nd = 3578,
    Every23rd = 3579,
    Every24th = 3580,
    Every25th = 3581,
    Every26th = 3582,
    Every27th = 3583,
    Every28th = 3584,
    Every29th = 3585,
    Every30th = 3586,
    Every31st = 3587
}