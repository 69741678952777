import { gql } from "app/graphql";

export const GET_ALL_NOTIFICATIONS = gql(`
    query GetAllNotifiactions{
        notifications {
            isViewed
            entityDate
            comment {
                text
            }
            appointment {
                createId
                updateId
                agentStatus
                customerStatus
                startDateTime
                endDateTime
                comment
                isCreatedByAgent
            }
            listing {
                address
                imageUrl
            }
            notificationId
            listingId
            entityId
            entityType
            collaborationId
            createId
            createDate
            action
        }
    }
`);

export const VIEW_UNVIEW_NOTIFICATIONS = gql(`
    mutation ViewUnviewNotifications($notificationIds: [Int!]){
        notifications {
            viewUnviewNotifications(notificationIds: $notificationIds) {
                success
            }
        }
    }
`);


export const REMOVE_NOTIFICATIONS_BY_LISTINGS = gql(`
    mutation RemoveNotificationsByListings($input: RemoveNotificationsByListingsInput!){
        notifications {
            removeByListings(input: $input) {
                success
            }
        }
    }
`);

export const REMOVE_NOTIFICATIONS = gql(`
    mutation RemoveNotifications($ids: [Int!]!){
        notifications {
            remove(notificationIds: $ids) {
            success
            }
        }
    }
`);

export const REMOVE_ENTITIES_NOTIFICATIONS = gql(`
    mutation RemoveEntitiesNotifications($input: RemoveByEntitiesInput!){
        notifications {
            removeByEntities(input: $input) {
              success
            }
        }
    }
`);