import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { AvatarInitialsComponent } from '@users/components/avatar-initials/avatar-initials.component';
import { AvatarComponent } from '@users/components/avatar/avatar.component';
import { locale as english } from '@users/i18n/en';
import { AvatarsIconComponent } from './components/avatars-icon/avatars-icon.component';
import { UserActivityHistoryTooltipComponent } from './components/user-activity-history-tooltip/user-activity-history-tooltip.component';
import { UsersEffects } from './store/effects/users.effects';
import { usersReducer } from './store/reducers/users.reducer';

@NgModule({
    declarations: [
        AvatarComponent,
        AvatarInitialsComponent,
        AvatarsIconComponent,
        UserActivityHistoryTooltipComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        MaterialFileInputModule,
        MatFormFieldModule,

        // Application Modules
        RpcIconModule,

        StoreModule.forFeature('users', usersReducer),
        EffectsModule.forFeature([UsersEffects])
    ],
    exports: [
        AvatarComponent,
        AvatarInitialsComponent,
        AvatarsIconComponent,
        UserActivityHistoryTooltipComponent
    ]
})
export class UsersModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english);
    }
}