import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';

import { AgentsModule } from '@agents/agents.module';
import { RpcControlsModule } from '@core-controls/controls.module';
import { locale as english } from '@core-layout/side-menu/i18n/en';
import { SideMenuComponent } from '@core-layout/side-menu/side-menu.component';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { UsersModule } from '@users/users.module';
import { ProfileBaseModule } from 'app/modules/user-modules/profile-modules/profile-base/profile-base.module';

@NgModule({
    declarations: [
        SideMenuComponent
    ],
    imports: [
        MatDividerModule,
        TranslateModule,
        NgLetModule,

        CommonModule,
        UsersModule,
        RpcControlsModule,
        AgentsModule,
        RpcIconModule,
        ProfileBaseModule
    ],
    exports: [
        SideMenuComponent
    ]
})
export class SideMenuModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english);
    }
}