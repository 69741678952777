import { Image } from '@media/models/image';
import { Thumbnail } from '@media/models/thumbnail';
import { Tour } from '@media/models/tour';
import { Video } from '@media/models/video';
import * as imageService from '@media/services/image-preferences.service';

export class FormattedMedia {

    public static notLoaded = new FormattedMedia([], [], []);

    public originalImages: Image[] = this.storedMedia?.map(imageService.formatOriginalImage);
    public largeImages: Image[] = this.storedMedia?.map(imageService.formatLargeImage);
    public mediumImages: Image[] = this.storedMedia?.map(imageService.formatMediumImage);
    public smallImages: Image[] = this.storedMedia?.map(imageService.formatSmallImage);
    public extraSmallImages: Image[] = this.storedMedia?.map(imageService.formatExtraSmallImage);
    public thumbnails: Thumbnail[] = this.storedMedia?.map(imageService.formatThumbnail);

    constructor(
        private readonly storedMedia: imageService.StoredMedia[],
        public videos: Video[],
        public tours: Tour[]
    ) { }

    public hasAny = () => this.originalImages?.length > 0 || this.videos?.length > 0 || this.tours?.length > 0;

}