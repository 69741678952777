import { createAction, props } from '@ngrx/store';

import { ListingActivityType } from '@customer-activity/enums/listing-activity-type.enum';
import { ApiError } from '@error/models/api-error';
import { ActivityListingCandidate, SetListingActivityRequest } from '@listings/models/api/set-listing-activity-request';
import { ListingActivityId } from '@listings/models/listing/listing-activity-id';

export const setListingsActivity = createAction(
    '[Listings] Set Listings Activity',
    props<{ request: SetListingActivityRequest, customerId: number }>()
);

export const setListingsActivitySuccess = createAction(
    '[Listings] Set Listings Activity Success',
    props<{ request: SetListingActivityRequest, activitiesSet: ListingActivityId[] }>()
);

export const setListingsActivityFailed = createAction(
    '[Listings] Set Listings Activity Failed',
    (error: ApiError, request: SetListingActivityRequest, customerId: number) => ({ error, request, customerId })
);

export const addListingsToPickedList = createAction(
    '[Listings] Add Listings To Picked List',
    props<{ listingCandidates: ActivityListingCandidate[], customerId: number }>()
);

export const removeListingsFromPickedList = createAction(
    '[Listings] Remove Listings From Picked List',
    props<{ listingCandidates: ActivityListingCandidate[], customerId: number }>()
);

export const addListingSessionActivity = createAction(
    '[Listings] Add Listing Session Activity',
    props<{ listingActivityType: ListingActivityType, listingCategory: { listingId: string, category: string }[] }>()
);