export const locale = {
    lang: 'en',
    data: {
        REGISTRATION: {
            WORKSPACE_TITLE: 'Add Additional Workspaces',
            WORKSPACE_WELCOME: 'Another agent has created a workspace for you in',
            WORKSPACE_INVITE: 'To add this agent and their workspace to your portfolio, please confirm your information and click below',
            TITLE: 'Registration',
            WELCOME: 'Welcome to',
            INVITE: 'You have been invited by Agent',
            LOGOUT_WARNING_1: 'You will be logged out of your current session.',
            LOGOUT_WARNING_2: 'After adding the new workspace you will be prompted to log in again.',
            BUTTONS: {
                CANCEL: 'CANCEL',
                CONTINUE: 'CONTINUE'
            },
            ERRORS: {
                SERVER: {
                    INVITE_EXPIRED: 'Invitation is expired. Please, contact your agent.',
                    INVITE_INVALID: 'Invitation is invalid. Please, contact your agent.',
                    ACCOUNT_REGISTERED: 'Email address is registered. Please, contact your agent.'
                }
            }
        }
    }
};