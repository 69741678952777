import * as moment from 'moment';

import { AppointmentInfo } from '@appointments/store/models/appointment-info';

export class AppointmentService {
    public static isAfter(date1: string | Date, date2: string | Date, isExactDateMatch: boolean): boolean {
        const moment1 = isExactDateMatch ? moment(date1) : moment(date1).startOf('day');
        const moment2 = isExactDateMatch ? moment(date2) : moment(date2).startOf('day');
        return moment1.isAfter(moment2);
    }

    public static isSame(date1: string | Date, date2: string | Date, isFull: boolean): boolean {
        const moment1 = isFull ? moment(date1) : moment(date1).startOf('day');
        const moment2 = isFull ? moment(date2) : moment(date2).startOf('day');
        return moment1.isSame(moment2);
    }

    /**
     * Performs ascending appointments sorting.
     * @param appointments Appointments to sort
     */
    public static sort(appointments: AppointmentInfo[]): AppointmentInfo[] {
        appointments.sort((item1, item2) =>
            AppointmentService.isAfter(item1.appointment.startDateTime, item2.appointment.startDateTime, false)
                ? 1
                : AppointmentService.isSame(item1.appointment.startDateTime, item2.appointment.startDateTime, false)
                    ? AppointmentService.isSame(item1.appointment.startDateTime, item2.appointment.startDateTime, true)
                        ? 0
                        : AppointmentService.isAfter(item1.appointment.startDateTime, item2.appointment.startDateTime, true)
                            ? 1
                            : -1
                    : -1);
        return appointments;
    }
}