import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { share, take } from 'rxjs/operators';

import { SimpleDialogComponent } from '@core-utils/simple-dialog/components/simple-dialog/simple-dialog.component';
import { SimpleDialogOptions } from '@core-utils/simple-dialog/models/simple-dialog-options';

@Injectable({ providedIn: 'root' })
export class SimpleDialogService {

    private simpleAlertDialogRef: MatDialogRef<SimpleDialogComponent>;

    constructor(
        private readonly matDialog: MatDialog
    ) { }

    public show(options: SimpleDialogOptions, callback: (successful: boolean) => void = null): void {
        this.simpleAlertDialogRef = this.matDialog.open(
            SimpleDialogComponent,
            { disableClose: false, panelClass: options.panelClass == null ? 'confirm-dialog' : ['confirm-dialog', options.panelClass] });

        this.simpleAlertDialogRef.componentInstance.options = options;

        this.simpleAlertDialogRef
            .afterClosed()
            .pipe(share(), take(1))
            .subscribe((successful: boolean) => {
                if (callback != null) {
                    callback(successful as boolean);
                }
                this.simpleAlertDialogRef = null;
            });
    }
}