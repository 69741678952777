import { createAction, props } from '@ngrx/store';

import { CheckRestoreCodeRequest } from '@auth/models/requests/check-restore-code-request';
import { ForgotPasswordRequest } from '@auth/models/requests/forgot-password-request';
import { RestorePasswordRequest } from '@auth/models/requests/restore-password-request';
import { ApiError } from '@error/models/api-error';

export const sendRestorePasswordLink = createAction(
    '[Restore Password] Send Link to Reset Password', props<{ request: ForgotPasswordRequest }>());
export const sendRestorePasswordLinkSuccessful = createAction(
    '[Restore Password] Send Link to Reset Password Successful');
export const sendRestorePasswordLinkFailed = createAction(
    '[Restore Password] Send Link to Reset Password Failed', (error: ApiError) => error);

export const checkCode = createAction(
    '[Restore Password] Check Restore Password Code', props<{ request: CheckRestoreCodeRequest }>());
export const checkCodeSuccessful = createAction(
    '[Restore Password] Check Restore Password Code Successful');
export const checkCodeFailed = createAction(
    '[Restore Password] Check Restore Password Code Failed', (error: ApiError) => error);

export const changePassword = createAction(
    '[Restore Password] Change Password', props<{ request: RestorePasswordRequest }>());
export const changePasswordSuccessful = createAction(
    '[Restore Password] Change Password Successful');
export const changePasswordFailed = createAction(
    '[Restore Password] Change Password Failed', (error: ApiError) => error);