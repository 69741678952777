<div class="agent-selection-inner">
    <div class="agent-selection-title">{{ texts.title | translate }}</div>
    <div class="agent-selection-description">{{ texts.description | translate }}</div>
    <div class="agent-selection-list">
        <ng-container *ngFor="let agentCandidate of agentCandidates">
            <agent-box class="agent-selection-list-item" (click)="onAgentSelected(agentCandidate.customerId)" 
            [isSelected]="selectedCustomerId === agentCandidate.customerId" [agentCandidate]="agentCandidate"
            [isCurrent]="currentCustomerId === agentCandidate.customerId">
            </agent-box>
        </ng-container>
    </div>
    <div class="agent-selection-action-wrap">
        <!-- <rpc-button class="agent-selection-action agent-selection-action--cancel" [appearanceType]="'stroke'" [mainButton]="true" [color]="'accent'" [title]="'Cancel'"></rpc-button> -->
        <rpc-button class="agent-selection-action agent-selection-action--submit" [appearanceType]="'flat'" [mainButton]="true" [color]="'accent'" [title]="texts.buttonText" [disabled]="!canSubmit" (clicked)="onAgentSelectionConfirmed()">
        </rpc-button>
    </div>
</div>