export const locale = {
    lang: 'en',
    data: {
        APPOINTMENT_NOTIFICATION: {
            TITLES: {
                ACCEPT: 'Accept',
                DECLINE: 'Decline',
                CONFIRMED: 'Confirmed',
                DECLINED: 'Declined',
                SHOWN: 'Shown',
                ALL_APPOINTMENTS: 'All Appointments'
            }
        }
    }
};