import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { locale as english } from '@settings/i18n/en';
import { SettingsEffects } from '@settings/store/effects/settings.effects';
import { settingsReducer } from '@settings/store/reducers/settings.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,

        // Module Configuration
        StoreModule.forFeature('settings', settingsReducer),
        EffectsModule.forFeature([SettingsEffects])
    ]
})
export class SettingsModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}
