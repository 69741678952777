import { createSelector } from '@ngrx/store';

import * as agentsSelectors from '@agents/store/selectors/agents.selectors';
import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { Creator } from '@appointments/enums/creator.enum';
import * as appointmentsSelectors from '@appointments/store/selectors/appointments.selectors';
import { AppointmentStatusService } from '@appointments/store/services/appointment-status.service';
import * as userSelector from '@auth/store/selectors/user.selector';
import { DELETED_USER_BASE_INFO } from '@users/constants/deleted-user.constants';
import { UserBaseInfo } from '@users/models/user-base-info';
import { UsersService } from '@users/services/users.service';
import * as usersSelectors from '@users/store/selectors/users.selectors';

export const selectAppointmentParticipantsMap = createSelector(
    appointmentsSelectors.selectFlatListingsAppointments,
    agentsSelectors.selectAgents,
    userSelector.selectAgent,
    usersSelectors.selectCustomersAndAgents,
    (appointments, agents, agent, users) => {
        return appointments.reduce(
            (map, appointment) => {
                const isCustomerRequested = appointment.creator === Creator.Customer;
                const isDeclined = AppointmentStatusService.calculateTimeDependentStatus(appointment) === AppointmentStatus.Declined;

                const confirmer = appointment.updateId != null
                    ? UsersService.getUserInfoOrRemovedAccount(users, appointment.updateId)
                    : isCustomerRequested
                        ? agents.length === 1
                            ? UsersService.getUserInfoOrRemovedAccount(users, agent?.id)
                            : isDeclined ? { ...DELETED_USER_BASE_INFO, fullName: '' } : null
                        : null;

                const requestor = UsersService.getUserInfoOrRemovedAccount(users, appointment.createId);

                return map.set(appointment.id, { requestor, confirmer });
            },
            new Map<number, { requestor: UserBaseInfo, confirmer: UserBaseInfo | null }>()
        );
    }
);