import { ErrorHandler, Injectable } from '@angular/core';

import { ChunksErrorHandler } from './chunks.error.handler';
import { ElmahIoErrorHandler } from './elmah.io.error.handler';

@Injectable({ providedIn: 'root' })
export class RootErrorHandler implements ErrorHandler {

    constructor(
        private readonly chunksErrorHandler: ChunksErrorHandler,
        private readonly elmahIoErrorHandler: ElmahIoErrorHandler
    ) { }

    public handleError(error: Error): void {
        if (this.chunksErrorHandler.isApplicable(error)) {
            this.chunksErrorHandler.handleError(error);
        } else {
            this.elmahIoErrorHandler.handleError(error);
        }
    }
}